import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { UserActions } from './user.actions';
import { User } from 'src/app/models/User';

export const userFeatureKey = 'user';

export const adapter = createEntityAdapter<User>({
  selectId: (user) => user._id,
});

export interface State extends EntityState<User> {
  error: HttpErrorResponse | null;
  loading: boolean;
  invited: boolean;
  deleted: boolean;
  updated: boolean;
}

export const initialState: State = adapter.getInitialState({
  error: null,
  loading: false,
  invited: false,
  deleted: false,
  updated: false,
});

export const reducer = createReducer(
  initialState,

  on(UserActions.loadAll, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  // load user
  on(UserActions.loadUser, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(UserActions.loadUserSuccess, (state, action): State => {
    return {
      ...adapter.upsertOne(action.user, state),
      loading: false,
      error: null,
    };
  }),

  // load users
  on(UserActions.loadUsers, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(UserActions.loadUsersSuccess, (state, action): State => {
    return {
      ...adapter.setAll(action.users, state),
      loading: false,
      error: null,
    };
  }),

  on(UserActions.loadUsersFailure, (state, action): State => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  // delete user
  on(UserActions.deleteUser, (state): State => {
    return {
      ...state,
      deleted: false,
      error: null,
    };
  }),

  on(UserActions.deleteUserSuccess, (state, action): State => {
    return {
      ...adapter.removeOne(action.id, state),
      deleted: true,
      error: null,
    };
  }),

  // update user
  on(UserActions.updateUser, (state): State => {
    return {
      ...state,
      loading: true,
      updated: false,
      error: null,
    };
  }),

  on(UserActions.updateUserSuccess, (state, action): State => {
    return {
      ...adapter.setOne(action.user, state),
      loading: false,
      updated: true,
      error: null,
    };
  }),

  // invite user
  on(UserActions.inviteUser, (state): State => {
    return {
      ...state,
      loading: true,
      invited: false,
    };
  }),

  on(UserActions.inviteUserSuccess, (state, action): State => {
    return {
      ...adapter.addOne(action.user, state),
      loading: false,
      invited: true,
      error: null,
    };
  }),

  on(UserActions.lockUser, (state): State => {
    return {
      ...state,
      loading: true,
    };
  }),

  // ...
  on(UserActions.patchPushFactoryUserStore, (state): State => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(UserActions.patchPullFactoryUserStore, (state): State => {
    return {
      ...state,
      loading: true,
    };
  }),
);
