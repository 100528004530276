import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';

import { LayoutRoutingModule } from './layout-routing.module';
import { LayoutComponent } from './layout.component';

import { UsersModule } from '../views/users/users.module';
import { FactoriesModule } from '../views/factories/factories.module';
import { ContractsModule } from '../views/contracts/contracts.module';
import { StationsModule } from '../views/stations/stations.module';
import { DocumentsModule } from '../views/documents/documents.module';
import { TelemetriesModule } from '../views/telemetries/telemetries.module';
import { ArchivesModule } from '../views/archives/archives.module';
import { RisksModule } from '../views/risks/risks.module';

import '@cds/core/icon/register.js';

import {
  ClarityIcons,
  updateIcon,
  refreshIcon,
  // user-menu
  userIcon,
  usersIcon,
  angleIcon,
  cogIcon,
  logoutIcon,
  // nav-menu
  homeIcon,
  hostGroupIcon,
  contractIcon,
  factoryIcon,
  libraryIcon,
  fileGroupIcon,
  cpuIcon,
  gavelIcon,
  // theme
  sunIcon,
  moonIcon,
} from '@cds/core/icon';

ClarityIcons.addIcons(
  updateIcon,
  refreshIcon,
  // user-menu
  userIcon,
  usersIcon,
  angleIcon,
  cogIcon,
  logoutIcon,
  // nav-menu
  homeIcon,
  hostGroupIcon,
  contractIcon,
  factoryIcon,
  libraryIcon,
  fileGroupIcon,
  cpuIcon,
  gavelIcon,
  // theme
  sunIcon,
  moonIcon,
);

// vrielmann-bw [black & white]
import { vrielmann_bw } from '../icons/vrielmann-bw';

ClarityIcons.addIcons(['vrielmann-bw', vrielmann_bw]);

@NgModule({
  declarations: [LayoutComponent],
  imports: [
    CommonModule,
    FormsModule,
    LayoutRoutingModule,
    ClarityModule,
    UsersModule,
    FactoriesModule,
    ContractsModule,
    StationsModule,
    DocumentsModule,
    TelemetriesModule,
    ArchivesModule,
    RisksModule,
  ],
})
export class LayoutModule {}
