<div class="card">
  <h3 class="card-header">
    <cds-icon shape="file-group" size="md"></cds-icon>
    {{ name }}
    <span style="float: right">
      <cds-icon shape="details" size="md"></cds-icon>
    </span>
  </h3>

  <div class="card-block">
    <!-- <h4 class="card-title" *ngIf="(error$ | async)?.status">
      <div
        *ngIf="error$ | async as error"
        class="alert alert-danger"
        role="alert"
      >
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon
                class="alert-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </div>
            <span class="alert-text">
              {{ error.status }} | {{ error.statusText }}
              <ng-container *ngIf="error.error.name">
                | {{ error.error.name }} | {{ error.error.message }}
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </h4> -->

    <div class="card-text">
      <app-documents-list-grid [documents]="category"></app-documents-list-grid>
    </div>
  </div>

  <!-- <div class="card-footer">footer</div> -->
</div>
