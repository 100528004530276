import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Document } from 'src/app/models/Document';
import { DocumentStoreService } from 'src/app/services/document-store.service';

@Component({
  selector: 'app-documents-list-grid',
  templateUrl: './documents-list-grid.component.html',
  styleUrls: ['./documents-list-grid.component.css'],
})
export class DocumentsListGridComponent {
  @Input() documents = [] as Document[];
  @Input() extended = true;
  @Input() hiddenYear = false;

  error = new HttpErrorResponse({});

  file = false;
  test = false;
  base64Src: any;

  document = {} as Document;

  constructor(private ds: DocumentStoreService) {}

  onClick(document: Document) {
    this.error = new HttpErrorResponse({});

    this.test = false;
    this.file = true;

    this.document = document;

    this.ds.getSingleBlob(document.Uid).subscribe(
      (result) => {
        this.test = true;
        this.base64Src = result.data;
      },
      (error) => {
        this.error = error;
      },
    );
  }
}
