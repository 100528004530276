import { IEnvironment, MqttPort, MqttProtocol } from './IEnvironment';

export const environment: IEnvironment = {
  name: 'production',
  production: true,

  userApi: 'https://connect.vrielmann.com/api/store-user',
  importApi: 'https://connect.vrielmann.com/api/store-import',
  exportApi: 'https://connect.vrielmann.com/api/store-export',
  tsdbApi: 'https://connect.vrielmann.com/api/store-tsdb',
  riskApi: 'https://connect.vrielmann.com/api/store-risk',

  mqttHostname: 'connect.vrielmann.com',
  mqttProtocol: MqttProtocol.wss,
  mqttPort: MqttPort.WSS_PORT_CLOSED,
  mqttPath: '',

  VAPID_PUBLIC_KEY:
    'BMCAKS7hV828LgiepWW3OcHBcIp2_oUtSszMQjMq2-bOwyT4cBum2f6zbYiLf34cySRGETKbKtmpH6_kqOg6mH4',
};
