import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Factory } from 'src/app/models/Factory';

import { selectProfil } from '../../users/+profil/profil.selectors';
import { ProfilActions } from '../../users/+profil/profil.actions';

import { selectCombinedFactory } from '../+store/factory.selectors';
import { FactoryActions } from '../+store/factory.actions';

@Component({
  selector: 'app-factories-detail',
  templateUrl: './factories-detail.component.html',
  styleUrls: ['./factories-detail.component.css'],
})
export class FactoriesDetailComponent implements OnInit {
  profil$ = this.store.select(selectProfil);

  combinedFactory$: Observable<{
    factory: Factory;
    loading: boolean;
    error: HttpErrorResponse | null;
  }> = new Observable();

  constructor(
    private store: Store,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(ProfilActions.loadProfil());

    this.route.params.subscribe((params) => {
      const uid = params['uid'];

      this.profil$.subscribe((profil) => {
        if (profil?._id) {
          this.store.dispatch(FactoryActions.loadFactory({ uid: uid }));
        }
      });

      this.combinedFactory$ = this.store.select(selectCombinedFactory(uid));
    });
  }
}
