import { Component, Input, OnInit, inject } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { ClrDatagridFilterInterface, ClrLoadingState } from '@clr/angular';
import { Role } from 'src/app/models/Role';
import { User } from 'src/app/models/User';

import {
  selectUser,
  selectUsersError,
  selectUpdateUserSuccess,
} from '../+store/user.selectors';
import { UserActions } from '../+store/user.actions';

import { selectProfil, selectProfilRoles } from '../+profil/profil.selectors';

export class IsBlockedFilter implements ClrDatagridFilterInterface<User> {
  public selected: string[] = [];

  public changes = new Subject<boolean>();

  public isActive(): boolean {
    return this.selected.length > 0;
  }

  public accepts(user: User): boolean {
    return (
      this.selected.indexOf(
        user.SignIn.IsBlocked.toString().toLowerCase() || 'true',
      ) > -1
    );
  }
}

@Component({
  selector: 'app-users-list-grid',
  templateUrl: './users-list-grid.component.html',
  styleUrls: ['./users-list-grid.component.css'],
})
export class UsersListGridComponent implements OnInit {
  isBlockedFilter: IsBlockedFilter = new IsBlockedFilter();

  submitBtnUser: ClrLoadingState = ClrLoadingState.DEFAULT;
  submitBtnInvite: ClrLoadingState = ClrLoadingState.DEFAULT;

  profil$ = this.store.select(selectProfil);

  selected = {} as User;
  selected$ = new Observable<User>();

  @Input() users$ = new Observable<User[]>();
  @Input() loading$ = new Observable<boolean>();
  @Input() error$ = new Observable<HttpErrorResponse | null>();

  @Input() hideUid = false;
  @Input() hideStatus = false;
  @Input() hideFirstname = false;
  @Input() hideLastname = false;

  failure$ = this.store.select(selectUsersError);
  updated$ = this.store.select(selectUpdateUserSuccess);

  formBuilder = inject(FormBuilder);

  formEdit = this.formBuilder.group({
    Firstname: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),

    Lastname: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    Roles: new FormControl([] as Role[], { nonNullable: false }),
  });

  get firstname() {
    return this.formEdit.get('Firstname');
  }
  get lastname() {
    return this.formEdit.get('Lastname');
  }

  roles = [] as Role[];

  invite = false;
  detail = false;
  delete = false;

  isService$ = this.store.select(selectProfilRoles(['service']));
  isManager$ = this.store.select(selectProfilRoles(['manager']));

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.profil$.subscribe((profil) => {
      if (profil) {
        this.roles = [];
        profil?.Roles?.map((r) => {
          this.roles.push(r);
        });
      }
    });
  }

  selectionChanged(selected: User) {
    if (selected) {
      const roles = this.roles.filter((role) =>
        selected.Roles.some(
          (selectedRole) => selectedRole.Symbol === role.Symbol,
        ),
      );

      this.formEdit.get('Roles')?.setValue(roles);
      this.formEdit.get('Firstname')?.setValue(selected.Firstname);
      this.formEdit.get('Lastname')?.setValue(selected.Lastname);

      this.selected$ = this.store.select(selectUser(selected.Username));
    }
  }

  onInvite() {
    this.invite = true;
  }

  onInvited(value: boolean) {
    this.invite = false;
  }

  onDelete() {
    this.delete = true;
  }

  onDeleted(value: boolean) {
    this.delete = false;
  }

  onEdit() {
    this.detail = true;
  }

  onEdited(value: boolean) {
    this.detail = false;
  }

  onEditSelected() {
    this.detail = true;
  }

  onLock(user: User) {
    this.store.dispatch(UserActions.lockUser({ id: user._id, status: true }));
  }

  onUnLock(user: User) {
    this.store.dispatch(UserActions.lockUser({ id: user._id, status: false }));
  }

  onUpdateProfil() {
    this.submitBtnUser = ClrLoadingState.LOADING;

    const data = this.formEdit.getRawValue();
    const roles = data.Roles || ([] as Role[]);
    const firstname = data.Firstname;
    const lastname = data.Lastname;

    this.store.dispatch(
      UserActions.updateUser({
        id: this.selected._id,
        firstname: firstname,
        lastname: lastname,
        roles: roles.map((role) => role._id),
      }),
    );

    this.updated$.subscribe((result) => {
      this.submitBtnUser = ClrLoadingState.SUCCESS;
      this.detail = false;
    });
  }

  toggleBlocked(event: any) {
    if (event.target.checked) {
      this.isBlockedFilter.selected.push(event.target.value);
    } else {
      const colorName = event.target.value;
      const index = this.isBlockedFilter.selected.indexOf(colorName);
      if (index > -1) {
        this.isBlockedFilter.selected.splice(index, 1);
      }
    }
    this.isBlockedFilter.changes.next(true);
  }
}
