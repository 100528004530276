<clr-datagrid>
  <clr-dg-placeholder>Wir konnten keine Einträge finden!</clr-dg-placeholder>

  <clr-dg-column [clrDgField]="'Title'">
    <ng-container *clrDgHideableColumn="{ hidden: false }">
      <cds-icon shape="tag"></cds-icon>&nbsp;Name
    </ng-container>
  </clr-dg-column>

  <clr-dg-column [clrDgField]="'Created'" [style.width.px]="110">
    <ng-container *clrDgHideableColumn="{ hidden: hiddenYear }">
      <cds-icon shape="calendar"></cds-icon>&nbsp;Jahr
    </ng-container>
  </clr-dg-column>

  <clr-dg-row *clrDgItems="let document of documents" [clrDgItem]="document">
    <clr-dg-cell>
      <cds-icon shape="pdf-file"></cds-icon>
      &nbsp;<a [routerLink]="[]" (click)="onClick(document)" role="button">{{
        document.Title
      }}</a>
    </clr-dg-cell>

    <clr-dg-cell>
      <span style="float: right">{{ document.Created | date: "YYYY" }}</span>
    </clr-dg-cell>
  </clr-dg-row>

  <ng-container ngProjectAs="clr-dg-detail" *ngIf="extended">
    <clr-dg-detail *clrIfDetail="let detail">
      <clr-dg-detail-header>
        <cds-icon shape="pop-out" size="md"></cds-icon>
        &nbsp;
        <a [routerLink]="[]" (click)="onClick(detail)" role="button">
          {{ detail.Title | uppercase }}
        </a>
      </clr-dg-detail-header>

      <clr-dg-detail-body>
        <table class="table table-vertical">
          <tbody>
            <tr>
              <th><cds-icon shape="calendar"></cds-icon> Erstellt:</th>
              <td>
                {{ detail.Created | date: "EEEE, dd.MM.YYYY HH:mm:ss" }}
              </td>
            </tr>
          </tbody>
        </table>
      </clr-dg-detail-body>
    </clr-dg-detail>
  </ng-container>

  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10">
      <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"
        >Dokumente pro Seite</clr-dg-page-size
      >
      {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} von
      {{ pagination.totalItems }} Dokumente
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>

<clr-modal [(clrModalOpen)]="file" clrModalSize="xl">
  <h3 class="modal-title">
    <cds-icon shape="pdf-file" size="md"></cds-icon>
    {{ document.Title }}
  </h3>

  <div class="modal-body">
    <ngx-extended-pdf-viewer
      *ngIf="test; else elsetest"
      [base64Src]="base64Src"
      [height]="'60vh'"
      [showBorders]="true"
      [useBrowserLocale]="true"
      [showHandToolButton]="true"
      [handTool]="true"
      [filenameForDownload]="document.Filename"
    >
    </ngx-extended-pdf-viewer>

    <ng-template #elsetest>
      <div class="pdf-viewer-template">
        <div *ngIf="error.status" class="alert alert-danger" role="alert">
          <div class="alert-items">
            <div class="alert-item static">
              <div class="alert-icon-wrapper">
                <cds-icon
                  class="alert-icon"
                  shape="exclamation-circle"
                ></cds-icon>
              </div>
              <span class="alert-text">
                {{ error.status }} | {{ error.statusText }}
                <ng-container *ngIf="error.error.name">
                  | {{ error.error.name }} | {{ error.error.message }}
                </ng-container>
              </span>
            </div>
          </div>
        </div>

        <span class="spinner spinner-inline"></span>
        <span> {{ document.Filename }} </span>
        | <span> {{ document.Filesize || 0 | filesize }} </span>

        <!-- <div class="progress loop"><progress></progress></div> -->
      </div>
    </ng-template>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="file = false">
      Ok
    </button>
  </div>
</clr-modal>
