import { createReducer, on } from '@ngrx/store';
// import { Cabinet } from 'src/app/models/Cabinet';
import { Evaluation } from 'src/app/models/Evaluation';
import { EvaluationActions } from './cabinetEvaluation.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const cabinetEvaluationFeatureKey = 'cabinetEvaluation';

export const adapter = createEntityAdapter<Evaluation>({
  selectId: (cabinetEvaluation) => cabinetEvaluation._id || '',
});

export interface State extends EntityState<Evaluation> {
  loading: boolean;
  error: any;
}

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: {},
});

export const reducer = createReducer(
  initialState,

  on(EvaluationActions.loadEvaluation, (state): State => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(EvaluationActions.loadEvaluationSuccess, (state, action): State => {
    return {
      // ...adapter.upsertMany(action.data, state),
      // ...adapter.updateOne(action.data, state),
      ...adapter.upsertOne(action.data, state),
      // ...state,
      loading: false,
      error: {},
    };
  }),

  on(EvaluationActions.loadEvaluationFailure, (state, action): State => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),
);
