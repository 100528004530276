import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxFilesizeModule } from 'ngx-filesize';

import { TelemetryMessagesGridComponent } from './telemetry-messages-grid/telemetry-messages-grid.component';
import { TelemetryPresetsTableComponent } from './telemetry-presets-table/telemetry-presets-table.component';
import { TelemetryMeasureGridComponent } from './telemetry-measure-grid/telemetry-measure-grid.component';
import { TelemetryMeasureChartComponent } from './telemetry-measure-chart/telemetry-measure-chart.component';
import { MeasureArchiveChartComponent } from './measure-archive-chart/measure-archive-chart.component';
import { MeasureArchiveTableComponent } from './measure-archive-table/measure-archive-table.component';

import '@cds/core/icon/register.js';

import {
  ClarityIcons,
  noteIcon,
  lineChartIcon,
  calendarIcon,
  clockIcon,
  storeIcon,
  fileSettingsIcon,
} from '@cds/core/icon';

ClarityIcons.addIcons(
  noteIcon,
  lineChartIcon,
  calendarIcon,
  clockIcon,
  storeIcon,
  fileSettingsIcon,
);

@NgModule({
  declarations: [
    TelemetryMeasureChartComponent,
    TelemetryMeasureGridComponent,
    TelemetryMessagesGridComponent,
    TelemetryPresetsTableComponent,
    MeasureArchiveChartComponent,
    MeasureArchiveTableComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ClarityModule,
    NgxExtendedPdfViewerModule,
    NgxFilesizeModule,
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
  ],
  exports: [
    TelemetryPresetsTableComponent,
    TelemetryMeasureGridComponent,
    TelemetryMeasureChartComponent,
    TelemetryMessagesGridComponent,
    MeasureArchiveChartComponent,
    MeasureArchiveTableComponent,
  ],
})
export class HelperModule {}
