import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClrLoadingState } from '@clr/angular';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth.service';
import { CustomValidators } from 'src/app/utils/validation';

interface Token extends JwtPayload {
  Username: string;
}

@Component({
  selector: 'app-renew',
  templateUrl: './renew-pwd.component.html',
  styleUrls: ['./renew-pwd.component.css'],
})
export class RenewPwdComponent implements OnInit {
  _background = [
    'Hintergrund_Microsoft_Teams_v1.jpg',
    'Hintergrund_Microsoft_Teams_v2.jpg',
    'Hintergrund_Microsoft_Teams_v3.jpg',
    'Hintergrund_Microsoft_Teams_v4.jpg',
    'Hintergrund_Microsoft_Teams_v5.jpg',
  ];
  background = this._background[this._background.length];

  multifactor = false;
  isErrorRenew = false;
  errorRenew = new HttpErrorResponse({});

  userEmails = this.formBuilder.group(
    {
      token: '',
      username: [
        { value: '', disabled: true },
        [
          Validators.required,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          CustomValidators.complex(),
        ],
      ],
      confirm: ['', [Validators.required]],
    },
    {
      validators: [
        CustomValidators.match('password', 'confirm'),
        CustomValidators.unmatch('username', 'password'),
      ],
    },
  );

  renewOberserver = {
    next: (user: User) => this.onRenewNext(user),
    error: (error: HttpErrorResponse) => this.onRenewError(error),
    complete: () => this.onRenewComplete(),
  };

  renewBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
  ) {
    const min = 0;
    const max = this._background.length - 1;

    this.background =
      this._background[Math.floor(Math.random() * (max - min + 1)) + min];
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.userEmails.controls['token'].setValue(params['token']);

      const token = jwtDecode<Token>(params['token']);
      this.userEmails.controls['username'].setValue(token.Username);
    });
  }

  get username() {
    return this.userEmails.get('username');
  }

  get password() {
    return this.userEmails.get('password');
  }

  get confirm() {
    return this.userEmails.get('confirm');
  }

  onSubmit() {
    this.renewBtnState = ClrLoadingState.LOADING;

    this.isErrorRenew = false;
    this.errorRenew = new HttpErrorResponse({});

    const user = {
      Password: this.userEmails.value.password,
      Token: this.userEmails.value.token,
    };

    this.authService.renewPassword(user).subscribe(this.renewOberserver);
  }

  onRenewNext(user: User) {
    this.renewBtnState = ClrLoadingState.SUCCESS;
    this.multifactor = true;
  }

  onRenewError(error: HttpErrorResponse) {
    this.renewBtnState = ClrLoadingState.ERROR;

    this.isErrorRenew = true;
    this.errorRenew = error;
  }

  onRenewComplete() {
    this.renewBtnState = ClrLoadingState.DEFAULT;
  }

  onOkay() {
    this.router.navigate(['/']);
  }
}
