import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Telemetry } from 'src/app/models/Telemetry';
import {
  MeasureStoreService,
  ArchivedMeasurement,
  ArchivedMearueOject,
} from 'src/app/services/measurearchive-store';

interface valueEntry {
  key: string;
  time: Date;
  value: number;
}

@Component({
  selector: 'app-measure-archive-chart',
  templateUrl: './measure-archive-chart.component.html',
  styleUrls: ['./measure-archive-chart.component.css'],
})
export class MeasureArchiveChartComponent implements OnInit, AfterViewInit {
  @Input() telemetry = {
    MQTT: {
      Enabled: false,
      Id: '0',
      Username: 'name',
      Password: 'pw',
    },
  } as Telemetry;

  public mergeOption: any;

  public initOpts = {
    locale: 'DE',
  };

  public chartOption: any = {
    grid: {
      left: '1%',
      right: '1%',
      // containLabel: true,
    },

    notMerge: false,

    xAxis: {
      // type: 'category',
      type: 'time',
      // name: 'Time',
      // splitLine: {
      //   show: false,
      // },
      data: [] as any,
      // gridIndex: 0,
    },
    yAxis: {
      type: 'value',
      // type: 'ordinal',
      // name: 'Value',
      // data: [] as any,
      splitLine: {
        show: true,
      },
      // gridIndex: 0,
    },

    // tooltip: {
    //   trigger: 'axis',
    //   axisPointer: {
    //     type: 'cross',
    //     label: {
    //       backgroundColor: '#6a7985',
    //     },
    //   },
    // },
    // dataZoom: [
    //   {
    //     type: 'inside',
    //   },
    //   {
    //     // For slider zoom
    //   },
    // ],
    toolbox: {
      show: true,
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
        },
        magicType: { type: ['line', 'bar', 'stack'] },
        restore: {},
        saveAsImage: {},
      },
    },

    legend: {},
    // legend: {
    //   orient: 'vertical',
    //   right: 10,
    //   top: 'center',
    //   data: [],
    // },

    dataset: {
      // dimensions: [],
      source: [],
    },
    series: [],
  };

  public myChart!: any;

  myObserver = {
    next: (data: ArchivedMeasurement[]) => this.onNext(data),
    error: (error: any) => this.onError(error),
    complete: () => this.onComplete(),
  };

  telemetryData$ = new Observable<ArchivedMearueOject[]>();

  constructor(
    public measureStoreService: MeasureStoreService, // @Inject(DOCUMENT) document: Document,
  ) {
    this.telemetryData$ = this.measureStoreService.getAll();
  }

  // meinDictionary: { [key: string]: any[][] } = {};

  @Input() public refreshArchiveChart() {
    if (this.myChart != null) {
      console.log('clear my chart ....');
      this.myChart.clear();
    }
    // this.chartOption.series = [] as any[]; // reset();
    this.telemetryData$ = this.measureStoreService.getAll();
    // const valuesByTime: { [key: string]: valueEntry } = {};
    // const valuesByKey: { [key: string]: ArchivedMearueOject } = {};
    // const valuesByKey: { [key: string]: ArchivedMearueOject[] } = {};
    // const datasetSource = [] as any [][];
    // this.chartOption.dataset.source = this.telemetryData$;
    // this.chartOption.series = legendSeries;
    // this.chartOption.series = [
    //   {
    //     type: 'line',
    //     encode: {
    //       tooltip: ['Client', 'System', 'Location', 'Device', 'SignalName'],
    //       x: 'Time',
    //       y: 'Value',
    //       seriesLayoutBy: 'row',
    //     },
    //   },
    // ];
    // if (this.myChart != null) {
    //   this.myChart.setOption(this.chartOption);
    // }
    // const seriesDict: {
    //   [key: string]: any;
    // } = {};

    const seriesDict: {
      [key: string]: {
        name: string;
        type: string;
        // encode: {},
        // lineStyle: {},
        smooth: boolean;
        symbol: string;
        animation: boolean;
        dimensions: string[];
        // data: ArchivedMearueOject[];
      };
    } = {};
    // const seriesDict: {
    //   [key: string]: {
    //     name: string,
    //     type: string,
    //     encode: {},
    //     lineStyle: {},
    //     smooth: boolean,
    //     symbol: string,
    //     animation: boolean,
    //     dimensions: [],
    //     data: [],
    //   };
    // } = {};

    this.telemetryData$.subscribe((archivedMeasurements) => {
      // const xAxisLegend = [] as any[];
      const xAxisTimes = [] as Date[];

      archivedMeasurements.map((entry) => {
        const key = `${entry.System}_${entry.Location}_${entry.Device}_${entry.SignalName}`;
        // valuesByKey[key] = entry;
        if (!xAxisTimes.includes(entry.Time)) xAxisTimes.push(entry.Time);
        console.log('Key : ', key);
        const s = {
          // legenden-eintrag
          name: key, //entry.SignalName,
          type: 'line',
          encode: {
            // tooltip: ['Client', 'System', 'Location', 'Device', 'SignalName'],
            x: 'Time',
            y: 'Value',
            seriesLayoutBy: 'row',
          },
          lineStyle: { width: 2 },
          smooth: true,
          symbol: 'none',
          animation: false,
          // xAxisIndex: 0,
          // yAxisIndex: 0,
          dimensions: [
            'Client',
            'System',
            'Location',
            'Device',
            'SignalName',
            'Time',
            'Value',
          ],
          // data: [],
        }; //as SeriesOption;
        seriesDict[key] = s;
        // valuesByKey[key] = [];
        // this.meinDictionary[key] = [];
        // valuesByKey[key] = [];
      });

      xAxisTimes.sort();
      const newLegend = [];
      const legendSeries = [];
      // this.chartOption.xAxis.data = [];

      const datasource = [] as any[];
      datasource[0] = [] as any[];
      datasource[0][0] = 'Signal';
      for (const time in xAxisTimes) {
        // console.log(`Zeit : ${xAxisTimes[time]}`);
        for (const key in seriesDict) {
          const result = archivedMeasurements.find(
            (obj) =>
              obj.Time === xAxisTimes[time] &&
              // obj.getKey() === key,
              `${obj.System}_${obj.Location}_${obj.Device}_${obj.SignalName}` ===
                // obj.Key === key,
                key,
          ) as any;

          const xIndexSignal = Object.keys(seriesDict).indexOf(key) + 1;
          const yIndexTime = Object.keys(xAxisTimes).indexOf(time) + 1;

          datasource[0][yIndexTime] = xAxisTimes[time];
          try {
            if (datasource[xIndexSignal] == null) datasource[xIndexSignal] = [];
            if (result !== undefined) {
              datasource[xIndexSignal][0] = key; //result.SignalName;
            }
            datasource[xIndexSignal][yIndexTime] = result;
            // datasource[xIndexSignal] = [result.Time, result.Value];
            // datasource[xIndexSignal].push([result.Time, result.Value]);
          } catch (err) {
            console.log(err);
          }
          // valuesByKey[key].push(result);
        }
        // this.chartOption.dataset.dimensions.push(
        //   xAxisTimes[time]
        //   // { name: 'score' },
        //   // // can be abbreviated as 'string', to indicate dimension name 。
        //   // 'amount',
        //   // // Specify dimensions in 'type'.
        //   // { name: 'Value', type: 'ordinal' },
        // );
      }

      for (const keyX in datasource) {
        this.chartOption.dataset.source.push(datasource[keyX]);
        for (const kayY in datasource[keyX]) {
          const value: any =
            datasource[keyX][kayY]?.Value == undefined
              ? datasource[keyX][kayY]
              : datasource[keyX][kayY].Value;
          console.log(
            // `added result to seriesDict.data -> time:${result?.Time} value:${result?.Value}`,
            `datasource[${keyX}][${kayY}] -> value:${value}`,
          );
        }
      }
      // for (const key in this.meinDictionary) {
      //   // series.push({ data: this.meinDictionary[key] });
      //   this.chartOption.dataset.source.push(this.meinDictionary[key]);
      // }
      // for (const key in seriesDict) {
      //   seriesDict[key].data.sort((a, b) => {
      //     if (a < b) {
      //       return -1;
      //     }
      //     if (a > b) {
      //       return 1;
      //     }
      //     return 0;
      //   });
      // }
      // const datasource = [] as any[];
      // let xCount = 0;
      // let yCount = 0;

      // let time = ' ';
      // for (const key in xAxisTimes) {
      //   time += ` ${xAxisTimes[key]}`;
      // }
      // for (const key in valuesByKey) {
      //   valuesByKey[key].sort((a, b) => {
      //     if (a < b) {
      //       return -1;
      //     }
      //     if (a > b) {
      //       return 1;
      //     }
      //     return 0;
      //   });
      // this.chartOption.dataset.source = datasource;
      // for (const key in seriesDict) {
      //   seriesDict.sort((a, b) => {
      //     if (a < b) {
      //       return -1;
      //     }
      //     if (a > b) {
      //       return 1;
      //     }
      //     return 0;
      //   });
      // }
      for (const key in seriesDict) {
        const seviesItm = seriesDict[key];
        let values = '';
        // for (const key in seviesItm.data) {
        //   values += ` ${seviesItm.data[key]?.Value}`;
        // }
        // for (const key in datasource) {
        for (const val in datasource[0]) values += ` ${datasource[0][val]}`;
        // }
        console.log(`Itm(${seviesItm.name}) y-Achse (Werte) : ${values}`);
        legendSeries.push(seviesItm);
        newLegend.push(key); //(seviesItm.name);
        // this.chartOption.dataset.source.push(valuesByKey[key]);
        // this.chartOption.dataset.source.push(this.meinDictionary[key]);
        // this.chartOption.dataset.source.push(seviesItm.data);
        // this.chartOption.dataset.source.push({
        //   Time: seviesItm.data.Time,
        //   Value: seviesItm.data.Value,
        // });
        // this.chartOption.dataset.dimensions.push(
        //   { name: 'score' },
        //   // can be abbreviated as 'string', to indicate dimension name 。
        //   'amount',
        //   // Specify dimensions in 'type'.
        //   { name: 'Value', type: 'ordinal' },
        // );
        this.chartOption.series.push(seviesItm);
        // this.chartOption.dataset.dimensions.push([
        //   'Client',
        //   'System',
        //   'Location',
        //   'Device',
        //   'SignalName',
        //   'Time',
        //   'Value',
        // ]);
      }
      // this.chartOption.dataset.dimensions = [
      //   'Client',
      //   'System',
      //   'Location',
      //   'Device',
      //   'SignalName',
      //   'Time',
      //   'Value',
      // ];

      let time = ' ';
      for (const key in xAxisTimes) {
        time += ` ${xAxisTimes[key]}`;
      }
      console.log(`x-Achse (Zeiten) : ${time}`);
      // this.chartOption.dataset.source = this.telemetryData$;
      this.chartOption.series = legendSeries;
      xAxisTimes.sort();
      this.chartOption.xAxis.data = xAxisTimes;
      // this.chartOption.legend = {
      //   orient: 'vertical',
      //   right: 10,
      //   top: 'center',
      //   data: newLegend as any,
      // };
      if (this.myChart != null) {
        console.log('set options of eChart');
        this.myChart.setOption(this.chartOption);
      }
    });
  }

  ngOnInit(): void {
    this.telemetryData$ = this.measureStoreService.getAll();
    this.refreshArchiveChart();
  }

  onChartInit(e: any) {
    this.myChart = e;
  }

  ngAfterViewInit() {}

  onNext(telemetryMeasurement: ArchivedMeasurement[]) {}

  onError(error: any) {}

  onComplete() {}

  // @Input() public refreshArchiveChart() {
  //   if (this.myChart != null) {
  //     this.myChart.clear();
  //   }
  //   this.chartOption.series = [] as any[]; // reset();
  //   this.telemetryData$ = this.measureStoreService.getAll();
  //   const legendDict: {
  //     [key: string]: any;
  //   } = {};
  //   this.telemetryData$.subscribe((archivedMeasurements) => {
  //     const xAxisLegend = [] as any[];
  //     const xAxisTimes = [] as any[];

  //     archivedMeasurements.map((entry) => {
  //       const key = `${entry.System}_${entry.Location}_${entry.Device}_${entry.SignalName}`;
  //       if (!xAxisTimes.includes(entry.Time)) xAxisTimes.push(entry.Time);
  //       const s = { // legenden-eintrag
  //         name: entry.SignalName,
  //         type: 'line',
  //         lineStyle: { width: 1 },
  //         smooth: false,
  //         symbol: 'none',
  //         animation: false,
  //         data: [] as any,
  //       }; //as SeriesOption;
  //       legendDict[key] = s;
  //     });

  //     const newLegend = [];
  //     const legendSeries = [];
  //     for (const time in xAxisTimes) {
  //       // console.log(`Zeit : ${xAxisTimes[time]}`);
  //       for (const key in legendDict) {
  //         const result = archivedMeasurements.find(
  //           (obj) =>
  //             obj.Time === xAxisTimes[time] &&
  //             `${obj.System}_${obj.Location}_${obj.Device}_${obj.SignalName}` ===
  //               key,
  //         );
  //         if (result == null) continue;
  //         // console.log(`for ${key} at ${xAxisTimes[time]} is ${result?.Value}`);
  //         legendDict[key].data.push(result?.Value);
  //       }
  //     }
  //     for (const key in legendDict) {
  //       const seviesItm = legendDict[key];
  //       let values = '';
  //       for (const key in seviesItm.data) {
  //         values += ` ${seviesItm.data[key]}`;
  //       }
  //       legendSeries.push(seviesItm);
  //       newLegend.push(seviesItm.name);
  //     }

  //     let time = ' ';
  //     for (const key in xAxisTimes) {
  //       time += ` ${xAxisTimes[key]}`;
  //     }
  //     this.chartOption.series = legendSeries;
  //     this.chartOption.xAxis.data = xAxisTimes;
  //     this.chartOption.legend = {
  //       orient: 'vertical',
  //       right: 10,
  //       top: 'center',
  //       data: newLegend as any,
  //     };
  //     if (this.myChart != null) {
  //       this.myChart.setOption(this.chartOption);
  //     }
  //   });
  // }
}
