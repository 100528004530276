import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TelemetryMeasurement } from './Telemetry-Measurements';

@Injectable({
  providedIn: 'root',
})
export class TelemetryMeasurementsService {
  private measurementsDictionarySubject = new BehaviorSubject<{
    [key: string]: TelemetryMeasurement;
  }>({});
  measurementsDictionary$ = this.measurementsDictionarySubject.asObservable();

  addMeasurement(measurement: TelemetryMeasurement, initialSize = 0): void {
    const key = this.generateKey(measurement);
    const currentDictionary = { ...this.measurementsDictionarySubject.value };

    if (currentDictionary[key]) {
      // Messung existiert bereits
      currentDictionary[key].Signals = Array.from(
        { length: initialSize },
        () => ({
          Name: '',
          Alias: '',
          Unit: '',
          Type: '',
          Time: new Date(),
          Value: null,
        }),
      );
    } else {
      // Messung existiert noch nicht
      const newMeasurement: TelemetryMeasurement = {
        ...measurement,
        Signals: Array.from({ length: initialSize }, () => ({
          Name: '',
          Alias: '',
          Unit: '',
          Type: '',
          Time: new Date(),
          Value: null,
        })),
      };
      currentDictionary[key] = newMeasurement;
    }

    this.measurementsDictionarySubject.next(currentDictionary);
  }

  updateSignals(
    client: string,
    system: string,
    location: string,
    device: string,
    channel: string,
    signals: {
      Name: string;
      Alias: string;
      Unit: string;
      Type: string;
      Time: Date;
      Value: any;
    }[],
  ): void {
    const key = this.generateKey({
      Client: client,
      System: system,
      Location: location,
      Device: device,
      Channel: channel,
    });
    const currentDictionary = { ...this.measurementsDictionarySubject.value };

    if (currentDictionary[key]) {
      currentDictionary[key].Signals = signals;
      // currentDictionary[key].Signal.Value = signal.Value;
      // currentDictionary[key].Signal.Time = signal.Time;
    }

    this.measurementsDictionarySubject.next(currentDictionary);
  }

  getObjectByKey(
    client: string,
    system: string,
    location: string,
    device: string,
    channel: string,
  ): Observable<TelemetryMeasurement> {
    const key = this.generateKey({
      Client: client,
      System: system,
      Location: location,
      Device: device,
      Channel: channel,
    });
    return this.measurementsDictionary$.pipe(
      map((dictionary) => {
        const telemetryMeasurement = dictionary[key];
        return telemetryMeasurement;
      }),
    );
  }

  private generateKey({
    Client,
    System,
    Location,
    Device,
    Channel,
  }: {
    Client: string;
    System: string;
    Location: string;
    Device: string;
    Channel: string;
  }): string {
    return `${Client}_${System}_${Location}_${Device}_${Channel}`;
  }

  areMeasurementsEqual(
    a: TelemetryMeasurement,
    b: TelemetryMeasurement,
  ): boolean {
    return (
      a.Client === b.Client &&
      a.System === b.System &&
      a.Location === b.Location &&
      a.Device === b.Device &&
      a.Channel === b.Channel
    );
  }
}
