import { createReducer, on } from '@ngrx/store';
import { Template } from 'src/app/models/Template';
import { TemplateActions } from './template.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const templateFeatureKey = 'template';

export const adapter = createEntityAdapter<Template>({
  // selectId: (template) => template.Uid,
  selectId: (template) => template._id || '',
});

export interface State extends EntityState<Template> {
  loading: boolean;
  error: any;
}

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: {},
});

export const reducer = createReducer(
  initialState,

  on(TemplateActions.loadTemplatesUid, (state): State => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(TemplateActions.loadTemplates, (state): State => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(TemplateActions.loadTemplatesSuccess, (state, action): State => {
    return {
      ...adapter.upsertMany(action.data, state),
      loading: false,
      error: {},
    };
  }),

  on(TemplateActions.loadTemplatesFailure, (state, action): State => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  // on(TemplateActions.loadTemplate, (state): State => {
  //   return {
  //     ...state,
  //     loading: true,
  //   };
  // }),

  // on(TemplateActions.loadTemplateUid, (state): State => {
  //   return {
  //     ...state,
  //     loading: true,
  //   };
  // }),

  on(TemplateActions.postTemplate, (state): State => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(TemplateActions.patchTemplate, (state): State => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(TemplateActions.deleteTemplate, (state): State => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(TemplateActions.deleteTemplateSuccess, (state, action): State => {
    return {
      ...adapter.removeOne(action.id, state),
      loading: false,
      error: {},
    };
  }),

  on(TemplateActions.deleteTemplateFailure, (state, action): State => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),
);
