<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <h3 class="card-header">
        <a routerLink="/">Home</a> / Gefährdungsbeurteilung
        <span style="float: right">
          <a href="javascript:history.back()">
            <cds-icon
              shape="circle-arrow"
              direction="left"
              size="md"
            ></cds-icon>
          </a>
        </span>
      </h3>
    </div>
  </div>
</div>

<div class="card">
  <h3 class="card-header">
    <cds-icon shape="host-group" size="md"></cds-icon>
    Schaltanlagen
    <span style="float: right">
      <cds-icon shape="view-list" size="md"></cds-icon>
    </span>
  </h3>

  <div class="card-block">
    <h4 class="card-title" *ngIf="(error$ | async)?.status">
      <div
        *ngIf="error$ | async as error"
        class="alert alert-danger"
        role="alert"
      >
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon
                class="alert-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </div>
            <span class="alert-text">
              {{ error.status }} | {{ error.statusText }}
              <ng-container *ngIf="error.error.name">
                | {{ error.error.name }} | {{ error.error.message }}
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </h4>

    <div class="card-text">
      <div class="btn-group btn-primary">
        <button class="btn btn-outline" (click)="onBtnAddClick()">
          <cds-icon shape="plus-circle"></cds-icon> hinzufügen
        </button>
        <button class="btn btn-outline" (click)="onBtnImportClick()">
          <cds-icon shape="plus-circle"></cds-icon> importieren
        </button>
        <button
          class="btn btn-outline"
          (click)="onBtnEditClick()"
          [disabled]="selected.length !== 1"
        >
          <cds-icon shape="eraser"></cds-icon> bearbeiten
        </button>
        <button
          class="btn btn-outline"
          (click)="onDeleteSelected()"
          [disabled]="selected.length === 0"
        >
          <cds-icon shape="minus-circle"></cds-icon> entfernen
        </button>
      </div>

      <ng-container *ngIf="template$">
        <ng-container *ngIf="cabinets$ | async as cabinets">
          <clr-datagrid
            #gridchild
            [clrDgLoading]="(loading$ | async) === true"
            [(clrDgSelected)]="selected"
          >
            <clr-dg-placeholder>
              Wir konnten keine Einträge finden!
            </clr-dg-placeholder>
            <!-- ----------column-------------- -->
            <ng-container *ngIf="template$ | async as template">
              <clr-dg-column
                [clrDgSortBy]="addSortationFor(itm._id)"
                *ngFor="let itm of template.Anlage"
              >
                <clr-dg-filter>
                  <app-risk-list-filter
                    [id]="'' + itm._id"
                  ></app-risk-list-filter>
                </clr-dg-filter>
                <ng-container *clrDgHideableColumn="{ hidden: !itm.IsPrimary }">
                  {{ itm.Title }}
                </ng-container>
              </clr-dg-column>
            </ng-container>
            <!-- -------------row---------------- -->
            <clr-dg-row
              *clrDgItems="let cabinet of cabinets"
              [clrDgItem]="cabinet"
            >
              <clr-dg-cell *ngFor="let anlage of getValues(cabinet.Anlage)">
                {{ anlage["col" + anlage.ItemId] }}
              </clr-dg-cell>
            </clr-dg-row>
            <!-- ------------detail-(Anlage)----- -->
            <ng-template
              clrIfDetail
              let-detail
              (clrIfDetailChange)="onDetail($event)"
            >
              <clr-dg-detail *clrIfDetail="let cabinet">
                <ng-container *ngIf="template$ | async as template">
                  <table class="table table-vertical">
                    <tbody>
                      <tr *ngFor="let anlageItem of template.Anlage">
                        <th>{{ anlageItem.Title }}</th>
                        <td>{{ ValueFromItem(cabinet, anlageItem) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </ng-container>
                <app-risks-evaluations-overview
                  #auswertungenTab
                  [cabinet]="cabinet"
                ></app-risks-evaluations-overview>
                <!-- <clr-dg-detail-body>
                  <a
                    [routerLink]="['review', cabinet._id]"
                    class="label clickable"
                  >
                    <span class="text">Bewertung</span>
                  </a>
                </clr-dg-detail-body> -->
              </clr-dg-detail>
            </ng-template>
            <!-- -------------footer---------------- -->
            <clr-dg-footer>
              <clr-dg-pagination #pagination [clrDgPageSize]="10">
                <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"
                  >Anlagen pro Seite</clr-dg-page-size
                >
                <!-- {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} von
              {{ pagination.totalItems }} Anlagen -->
              </clr-dg-pagination>
            </clr-dg-footer>
          </clr-datagrid>
          <p></p>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <!-- <div class="card-footer">Footer</div> -->
</div>

<!-- DIALOGE -->

<app-dialog-add-cabinet
  *ngIf="dialogAddOpen"
  [template$]="template$"
  (basic)="onDialogAddClick()"
></app-dialog-add-cabinet>

<app-dialog-import-cabinet
  [template$]="template$"
  *ngIf="dialogImportOpen"
  (basic)="onDialogImportClick()"
></app-dialog-import-cabinet>

<clr-modal [(clrModalOpen)]="dialogDeleteOpen">
  <h3 class="modal-title">
    <cds-icon shape="host" size="md"></cds-icon> Schaltanlage
  </h3>
  <div class="modal-body">
    <ul>
      <li *ngFor="let s of selected">id: {{ s._id }}</li>
    </ul>
  </div>
  <div class="modal-footer">
    <button class="btn btn-danger" (click)="deleteSelected()">löschen</button>
  </div>
</clr-modal>
