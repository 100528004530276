import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { ClarityModule } from '@clr/angular';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { RisksRoutingModule } from './risks-routing.module';

import { RisksListGridComponent } from './risks-list-grid/risks-list-grid.component';
import { RisksReviewComponent } from './risks-review/risks-review.component';
// import { RisksEvaluationComponent } from './risks-evaluation/risks-evaluation.component';

// import { RisksFormComponent } from './risks-form/risks-form.component';
import { DialogAddFileComponent } from './dialog-add-file/dialog-add-file.component';
import { DialogAddCabinetComponent } from './dialog-add-cabinet/dialog-add-cabinet.component';
import { DialogImportCabinetComponent } from './dialog-import-cabinet/dialog-import-cabinet.component';
import { RiskTabComponent } from './risks-tab/risks-tab.component';
import { RiskTabResultsComponent } from './risks-tab-results/risks-tab-results.component';
import { RiskTabFilesComponent } from './risks-tab-files/risks-tab-files.component';
import { RiskTabEditTemplateComponent } from './risks-tab-edit-template/risks-tab-edit-template.component';
import { RiskListFilterComponent } from './risk-list-filter/risks-list-filter.component';
import { RiskEvaluationComponent } from './risks-evaluations-overview/risks-evaluations-overview.component';
import { RisksNewEvaluationComponent } from './risks-evaluation-new/risks-evaluation-new.component';
import { RisksEditEvaluationComponent } from './risks-evaluation-edition/risks-evaluation-edition.component';
import { RisksEditCabinetComponent } from './risks-cabinet-edition/risks-cabinet-edition.component';
import { RisksEditTemplateComponent } from './risks-template-edition/risks-template-edition.component';
// import { RiskSelectComponent } from './risk-select-component/risk-select-component.component';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCabinet from './+cabinet/cabinet.reducer';
import { CabinetEffects } from './+cabinet/cabinet.effects';
import * as fromTemplate from './+template/template.reducer';
import { TemplateEffects } from './+template/template.effects';
import * as fromCabinetEvaluation from './+cabinetEvaluation/cabinetEvaluation.reducer';
import { CabinetEvaluationEffects } from './+cabinetEvaluation/cabinetEvaluation.effects';

// OBSOLETE
// import { ReviewComponent } from './review/review.component';
// import { EvaluationComponent } from './evaluation/evaluation.component';

import '@cds/core/icon/register.js';

import {
  ClarityIcons,
  hostGroupIcon,
  hostIcon,
  minusCircleIcon,
  plusCircleIcon,
  dotCircleIcon,
  infoCircleIcon,
  infoStandardIcon,
  syncIcon,
  eraserIcon,
  trashIcon,
  cogIcon,
} from '@cds/core/icon';

ClarityIcons.addIcons(
  hostGroupIcon,
  hostIcon,
  plusCircleIcon,
  minusCircleIcon,
  dotCircleIcon,
  infoCircleIcon,
  infoStandardIcon,
  syncIcon,
  eraserIcon,
  trashIcon,
  cogIcon,
);

@NgModule({
  declarations: [
    RisksListGridComponent,
    // ReviewComponent,
    // EvaluationComponent,
    DialogAddFileComponent,
    DialogAddCabinetComponent,
    DialogImportCabinetComponent,
    // RisksFormComponent,
    // RiskSelectComponent,
    RisksReviewComponent,
    // RisksEvaluationComponent,
    RiskTabComponent,
    RiskTabFilesComponent,
    RiskTabResultsComponent,
    RiskTabEditTemplateComponent,
    RiskListFilterComponent,
    RiskEvaluationComponent,
    RisksNewEvaluationComponent,
    RisksEditEvaluationComponent,
    RisksEditCabinetComponent,
    RisksEditTemplateComponent,
  ],
  imports: [
    RisksRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    ClarityModule,
    StoreModule.forFeature(fromCabinet.cabinetFeatureKey, fromCabinet.reducer),
    EffectsModule.forFeature([CabinetEffects]),
    StoreModule.forFeature(
      fromCabinetEvaluation.cabinetEvaluationFeatureKey,
      fromCabinetEvaluation.reducer,
    ),
    EffectsModule.forFeature([CabinetEvaluationEffects]),
    StoreModule.forFeature(
      fromTemplate.templateFeatureKey,
      fromTemplate.reducer,
    ),
    EffectsModule.forFeature([TemplateEffects]),
    NgxExtendedPdfViewerModule,
  ],
})
export class RisksModule {}
