import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { FactoryActions } from './factory.actions';
import { Factory } from 'src/app/models/Factory';

export const factoryFeatureKey = 'factory';

export const adapter = createEntityAdapter<Factory>({
  selectId: (factory) => factory._id,
});

export interface State extends EntityState<Factory> {
  loading: boolean;
  error: HttpErrorResponse | null;
}

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
});

export const reducer = createReducer(
  initialState,

  on(FactoryActions.updateFactory, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(FactoryActions.loadFactory, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(FactoryActions.loadFactorySuccess, (state, action): State => {
    return {
      ...adapter.upsertOne(action.factory, state),
      loading: false,
      error: null,
    };
  }),

  on(FactoryActions.loadFactoryFailure, (state, action): State => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  on(FactoryActions.loadFactories, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(FactoryActions.loadFactoriesSuccess, (state, action): State => {
    return {
      ...adapter.setAll(action.factories, state),
      loading: false,
      error: null,
    };
  }),

  on(FactoryActions.loadFactoriesFailure, (state, action): State => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  // on(FactoryActions.loadPaging, (state): State => {
  //   return {
  //     ...state,
  //     loading: true,
  //     error: null,
  //   };
  // }),

  // on(FactoryActions.loadPagingSuccess, (state, action): State => {
  //   return {
  //     ...adapter.upsertMany(action.factories, state),
  //     loading: false,
  //     error: null,
  //   };
  // }),

  // on(FactoryActions.loadPagingFailure, (state, action): State => {
  //   return {
  //     ...state,
  //     loading: false,
  //     error: action.error,
  //   };
  // }),
);
