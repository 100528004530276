import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTelemetry from './telemetry.reducer';
import { Telemetry } from 'src/app/models/Telemetry';

const { selectAll } = fromTelemetry.adapter.getSelectors();

export const selectTelemetryState = createFeatureSelector<fromTelemetry.State>(
  fromTelemetry.telemetryFeatureKey,
);

export const selectTelemetries = createSelector(
  selectTelemetryState,
  selectAll,
);

export const selectTelemetriesLoading = createSelector(
  selectTelemetryState,
  (state) => state.loading,
);

export const selectTelemetriesError = createSelector(
  selectTelemetryState,
  (state) => state.error,
);

export const selectMqttFromTelemetry = (uid: string) =>
  createSelector(
    selectTelemetries,
    (telemetries) =>
      telemetries.find((telemetry) => telemetry.Uid == uid) ||
      ({} as Telemetry),
  );
