<p cds-text="section">Angaben gemäß § 5 TMG</p>
<p></p>
<ul class="list list-unstyled">
  <li>Vrielmann GmbH</li>
  <li>Heinrich-Focke-Straße 25</li>
  <li>48531 Nordhorn</li>
</ul>

<p></p>
<p cds-text="section">Vertreten durch</p>
<p></p>
<ul class="list list-unstyled">
  <li>Geschäftsführer Dipl.-Ing. (FH) Robert Vrielmann</li>
  <li>Geschäftsführer Dipl.-Ing. (FH) Heiko Ensink</li>
</ul>

<p></p>
<p cds-text="section">Kontakt</p>
<p></p>
<ul class="list list-unstyled">
  <li>Telefon: +49 59 21 8 19 18-0</li>
  <li>Telefax: +49 59 21 8 19 18-18</li>
  <li>E-Mail: info&commat;vrielmann.com</li>
</ul>

<p></p>
<p cds-text="section">Registereintrag</p>
<p></p>
<ul class="list list-unstyled">
  <li>Eintragung im Handelsregister.</li>
  <li>Registergericht: Osnabrück</li>
  <li>Registernummer: HRB 130450</li>
</ul>

<p></p>
<p cds-text="section">Umsatzsteuer</p>
<p>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:</p>
DE 117046074

<p></p>
<p cds-text="section">Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</p>
<p></p>
<ul class="list list-unstyled">
  <li>Vrielmann GmbH</li>
  <li>Heinrich-Focke-Straße 25</li>
  <li>48531 Nordhorn</li>
</ul>

<p></p>
<p cds-text="section">Streitschlichtung</p>
<p>
  Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung
  (OS) bereit:
  <a href="https://ec.europa.eu/consumers/odr"
    >https://ec.europa.eu/consumers/odr</a
  >. Unsere E-Mail-Adresse finden Sie oben im Impressum.
</p>

<p></p>
<p cds-text="section">Haftung für Inhalte</p>
<p>
  Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen
  Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind
  wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
  gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
  forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
</p>
<p>
  Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen
  nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche
  Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
  Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
  Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
</p>

<p></p>
<p cds-text="section">Haftung für Links</p>
<p>
  Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte
  wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch
  keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der
  jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten
  Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße
  überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
  erkennbar.
</p>
<p>
  Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
  konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
  Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend
  entfernen.
</p>

<p></p>
<p cds-text="section">Urheberrecht</p>
<p>
  Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten
  unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
  Verbreitung und jede Art der Verwertung außerhalb der Grenzen des
  Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors
  bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten,
  nicht kommerziellen Gebrauch gestattet.
</p>
<p>
  Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden,
  werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter
  als solche gekennzeichnet. Sollten Sie trotzdem auf eine
  Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden
  Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte
  umgehend entfernen.
</p>
