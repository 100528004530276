import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { ProfilActions } from './profil.actions';
import { AuthService } from 'src/app/services/auth.service';

@Injectable()
export class ProfilEffects {
  private service = inject(AuthService);

  updateKennung$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(ProfilActions.updateKennung),
      switchMap((action) =>
        this.service.updateKennung(action.profil).pipe(
          map((profil) =>
            ProfilActions.updateProfilSuccess({ profil: profil }),
          ),
          catchError((error) =>
            of(ProfilActions.updateProfilFailure({ error })),
          ),
        ),
      ),
    );
  });

  updateProfil$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(ProfilActions.updateProfil),
      switchMap((action) =>
        this.service.patchProfil(action.profil).pipe(
          map((profil) =>
            ProfilActions.updateProfilSuccess({ profil: profil }),
          ),
          catchError((error) =>
            of(ProfilActions.updateProfilFailure({ error })),
          ),
        ),
      ),
    );
  });

  loadProfil$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(ProfilActions.loadProfil),
      switchMap(() =>
        this.service.getProfil().pipe(
          map((profil) => ProfilActions.loadProfilSuccess({ profil: profil })),
          catchError((error) => of(ProfilActions.loadProfilFailure({ error }))),
        ),
      ),
    );
  });

  // signinUser$ = createEffect(() => {
  //   return inject(Actions).pipe(
  //     ofType(ProfilActions.signinUser),
  //     mergeMap((action) =>
  //       this.service
  //         .signin(action.username, action.password, action.token)
  //         .pipe(
  //           map((result) =>
  //             ProfilActions.loadUsersSuccess({ users: [result.User] }),
  //           ),
  //           catchError((error) => of(ProfilActions.loadUsersFailure({ error }))),
  //         ),
  //     ),
  //   );
  // });
}
