import { createReducer, on } from '@ngrx/store';
import { Cabinet } from 'src/app/models/Cabinet';
import { CabinetActions } from './cabinet.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';

export const cabinetFeatureKey = 'cabinet';

export const adapter = createEntityAdapter<Cabinet>({
  selectId: (cabinet) => cabinet._id || '',
});

export interface State extends EntityState<Cabinet> {
  loading: boolean;
  error: any;
}

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: {},
});

export const reducer = createReducer(
  initialState,

  on(CabinetActions.loadCabinets, (state): State => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(CabinetActions.loadCabinetsSuccess, (state, action): State => {
    return {
      ...adapter.upsertMany(action.data, state),
      loading: false,
      error: {},
    };
  }),

  on(CabinetActions.loadCabinetsFailure, (state, action): State => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  on(CabinetActions.loadCabinet, (state): State => {
    return {
      ...state,
      loading: true,
    };
  }),

  // on(CabinetActions.updateCabinet, (state): State => {
  //   return {
  //     ...state,
  //     loading: true,
  //   };
  // }),

  on(CabinetActions.postCabinet, (state): State => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(CabinetActions.patchCabinet, (state): State => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(CabinetActions.deleteCabinet, (state): State => {
    return {
      ...state,
      loading: true,
    };
  }),

  on(CabinetActions.deleteCabinetSuccess, (state, action): State => {
    return {
      ...adapter.removeOne(action.id, state),
      loading: false,
      error: {},
    };
  }),

  on(CabinetActions.deleteCabinetFailure, (state, action): State => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  // ---
  // on(CabinetActions.postAnlage, (state): State => {
  //   return {
  //     ...state,
  //     loading: true,
  //   };
  // }),

  // on(CabinetActions.deleteAnlage, (state): State => {
  //   return {
  //     ...state,
  //     loading: true,
  //   };
  // }),
  // // ---

  on(CabinetActions.deleteImage, (state): State => {
    return {
      ...state,
      loading: true,
    };
  }),
);
