import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  RisksListGridComponent,
  // CustomFilterComponent,
} from './risks-list-grid/risks-list-grid.component';
import { RiskListFilterComponent } from './risk-list-filter/risks-list-filter.component';
import { RisksReviewComponent } from './risks-review/risks-review.component';
import { RisksNewEvaluationComponent } from './risks-evaluation-new/risks-evaluation-new.component';
import { RiskEvaluationComponent } from './risks-evaluations-overview/risks-evaluations-overview.component';
import { RisksEditEvaluationComponent } from './risks-evaluation-edition/risks-evaluation-edition.component';
import { RisksEditCabinetComponent } from './risks-cabinet-edition/risks-cabinet-edition.component';
import { RisksEditTemplateComponent } from './risks-template-edition/risks-template-edition.component';

const routes: Routes = [
  { path: '', component: RisksListGridComponent },
  { path: '', component: RiskListFilterComponent },
  { path: 'review/:id', component: RisksReviewComponent },
  { path: 'evaluation/:id', component: RiskEvaluationComponent },
  { path: 'newevaluation/:id', component: RisksNewEvaluationComponent },
  { path: 'editevaluation/:id', component: RisksEditEvaluationComponent },
  { path: 'editecabinet/:id', component: RisksEditCabinetComponent },
  { path: 'editetemplate/:id', component: RisksEditTemplateComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RisksRoutingModule {}
