import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Document } from 'src/app/models/Document';

@Component({
  selector: 'app-documents-list-grid-card',
  templateUrl: './documents-list-grid-card.component.html',
  styleUrls: ['./documents-list-grid-card.component.css'],
})
export class DocumentsListGridCardComponent implements OnInit {
  @Output() hasDocuments = new EventEmitter<boolean>();

  @Input() number = '';
  @Input() name = '';
  @Input() documents = [] as Document[];

  category = [] as Document[];

  ngOnInit(): void {
    this.category = this.documents.filter((d) =>
      d.Categories.some((c) => c.Uid === this.number),
    );

    this.hasDocuments.emit(this.category.length > 0);
  }
}
