import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { ReactiveFormsModule } from '@angular/forms';
import { TelemetriesModule } from '../telemetries/telemetries.module';
import { ArchivesRoutingModule } from './archives-routing.module';
import { ArchivesTestComponent } from './archives-test/archives-test.component';
import { HelperModule } from 'src/app/helper/helper.module';

@NgModule({
  declarations: [ArchivesTestComponent],
  imports: [
    HelperModule,
    CommonModule,
    ClarityModule,
    ArchivesRoutingModule,
    TelemetriesModule,
    ReactiveFormsModule,
  ],
})
export class ArchivesModule {}
