import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/User';
import { Router } from '@angular/router';
import { Contact } from '../models/Contact';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authApi = environment.userApi;

  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  // getRoles() {
  //   return this.http.get<Role[]>(`${this.authApi}/roles`);
  // }

  getAll(): Observable<User[]> {
    return this.http.get<User[]>(`${this.authApi}/service/users`);
  }

  getUsers(): Observable<User[]> {
    // const headers = new HttpHeaders({
    //   'X-Route': `users`,
    //   'X-Route-Interceptor': 'true',
    // });
    // return this.http.get<User[]>(`${this.authApi}`, { headers });
    return this.http.get<User[]>(`${this.authApi}/manager/users`);
  }

  getUser(uid: string): Observable<User> {
    return this.http.get<User>(`${this.authApi}/users/${uid}`);
  }

  deleteUser(id: string): Observable<User> {
    return this.http.delete<User>(`${this.authApi}/manager/users/${id}`);
  }

  invite(
    uid: string,
    xid: string,
    username: string,
    firstname: string,
    lastname: string,
    roles: string[],
  ): Observable<any> {
    const payload = {
      Uid: uid,
      Xid: xid,
      Username: username,
      Firstname: firstname,
      Lastname: lastname,
      Roles: roles,
    };

    return this.http.post<User>(`${this.authApi}/manager/invite`, payload);
  }

  signup(user: any): Observable<User> {
    return this.http.post<User>(
      `${this.authApi}/signup?token=${user.Token}`,
      user,
    );
  }

  // signin über Username und Password
  signin(
    username: string,
    password: string,
    token: string | null,
  ): Observable<any> {
    const payload = {
      Token: token,
      Username: username,
      Password: password,
    };

    return this.http.post<any>(`${this.authApi}/signin`, payload);
  }

  // signin über MFA
  confirm(username: string, password: string, token: string): Observable<any> {
    const payload = {
      Token: token,
      Username: username,
      Password: password,
    };

    return this.http.post<any>(`${this.authApi}/confirm`, payload);
  }

  verify(token: string): Observable<Contact> {
    return this.http.post<any>(`${this.authApi}/verify?token=${token}`, {});
  }

  requestPassword(user: any): Observable<User> {
    return this.http.post<any>(`${this.authApi}/request/password`, user);
  }

  renewPassword(user: any): Observable<User> {
    return this.http.post<any>(
      `${this.authApi}/renew/password?token=${user.Token}`,
      user,
    );
  }

  // multifactorStatus(): Observable<any> {
  //   return this.http.get<any>(`${this.authApi}/status`);
  // }

  // mfa, activate, request
  // multifactorRequest(): Observable<any> {
  //   return this.http.get<any>(`${this.authApi}/multifactor/request`);
  // }

  // // mfa, activate, confirm
  // multifactorConfirm(): Observable<User> {
  //   return this.http.get<User>(`${this.authApi}/multifactor/confirm`);
  // }

  // // mfa, deactivate
  // multifactorDeactivate(): Observable<User> {
  //   return this.http.get<User>(`${this.authApi}/multifactor/deactivate`);
  // }

  /**
   * @deprecated Muss überarbeitet werden, siehe mfaMailRequest / mfaMailEnable / mfaMailDisable.
   */
  requestMultifactor(user: any): Observable<User> {
    return this.http.post<any>(`${this.authApi}/request/multifactor`, user);
  }

  /**
   * @deprecated Muss überarbeitet werden, siehe mfaMailRequest / mfaMailEnable / mfaMailDisable.
   */
  renewMultifactor(token: string): Observable<User> {
    return this.http.post<any>(
      `${this.authApi}/renew/multifactor?token=${token}`,
      {},
    );
  }

  mfaMailRequestEnable() {
    return this.http.post<any>(`${this.authApi}/mfa-mail-request/enable`, {});
  }

  mfaMailRequestDisable() {
    return this.http.post<any>(`${this.authApi}/mfa-mail-request/disable`, {});
  }

  mfaMailEnable(code: string) {
    const payload = {
      token: code,
    };

    return this.http.post<any>(`${this.authApi}/mfa-mail-enable`, payload);
  }

  mfaMailDisable(code: string) {
    const payload = {
      token: code,
    };

    return this.http.post<any>(`${this.authApi}/mfa-mail-disable`, payload);
  }

  logout() {
    localStorage.removeItem('Token');

    this.router.navigate(['/signin']);
  }

  loggedIn() {
    return !!this.readToken();
  }

  isTokenExpired() {
    try {
      const token = this.readToken();

      if (!token) {
        return true;
      }

      const payload = JSON.parse(atob(token.split('.')[1]));

      return payload.exp * 1000 < Date.now();
    } catch (e) {
      return true;
    }
  }

  writeToken(token: string) {
    localStorage.setItem('Token', token);
  }

  readToken() {
    return localStorage.getItem('Token') || '';
  }

  /**
   * @deprecated Verwenden Sie stattdessen den Store - Profil
   */
  readUid() {
    return '#';
  }

  // user
  getProfil(): Observable<User> {
    return this.http.get<User>(`${this.authApi}/users`);
  }

  // user
  patchProfil(user: User): Observable<User> {
    return this.http.patch<User>(`${this.authApi}/users`, user);
  }

  updateKennung(user: User): Observable<User> {
    return this.http.patch<User>(`${this.authApi}/service/users`, user);
  }

  // manager
  lockUser(id: string, status: boolean): Observable<User> {
    const payload = {
      status,
    };

    return this.http.post<User>(
      `${this.authApi}/manager/users/lock/${id}`,
      payload,
    );
  }

  // profil / manager
  loginWithMfaMail(id: string, status: boolean): Observable<User> {
    const payload = {
      status,
    };

    const headers = new HttpHeaders({
      // 'X-Route': `users/activate/${id}`,
      // 'X-Route-Interceptor': true',
    });

    return this.http.post<User>(
      `${this.authApi}/users/mfa/mail/${id}`,
      payload,
      {
        headers,
      },
    );
  }

  // manager
  updateUser(
    id: string,
    firstname: string | null = null,
    lastname: string | null = null,
    roles: string[] = [],
  ): Observable<User> {
    const payload = {
      firstname,
      lastname,
      roles,
    };

    return this.http.patch<User>(
      `${this.authApi}/manager/users/${id}`,
      payload,
    );
  }

  // manager
  patchPushFactoryUserStore(user: string, factory: string): Observable<User> {
    const payload = {
      Factory: factory,
    };

    return this.http.post<User>(
      `${this.authApi}/manager/users/push/${user}`,
      payload,
    );
  }

  // manager
  patchPullFactoryUserStore(user: string, factory: string): Observable<User> {
    const payload = {
      Factory: factory,
    };

    return this.http.post<User>(
      `${this.authApi}/manager/users/pull/${user}`,
      payload,
    );
  }
}
