import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { NgxEchartsModule } from 'ngx-echarts';

import { FactoriesRoutingModule } from './factories-routing.module';
import { FactoriesListComponent } from './factories-list/factories-list.component';
import { FactoriesListTreeComponent } from './factories-list-tree/factories-list-tree.component';
import { FactoriesDetailComponent } from './factories-detail/factories-detail.component';
import { FactoriesDetailItemComponent } from './factories-detail-item/factories-detail-item.component';
import { FactoriesListGridComponent } from './factories-list-grid/factories-list-grid.component';
import { FactoriesListGridCardComponent } from './factories-list-grid-card/factories-list-grid-card.component';
import { FactoriesListGraphComponent } from './factories-list-graph/factories-list-graph.component';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromFactory from './+store/factory.reducer';
import { FactoryEffects } from './+store/factory.effects';

import { ContractsModule } from '../contracts/contracts.module';
import { DocumentsModule } from '../documents/documents.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

import '@cds/core/icon/register.js';

import {
  ClarityIcons,
  factoryIcon,
  contractIcon,
  circleArrowIcon,
  hashtagIcon,
} from '@cds/core/icon';

ClarityIcons.addIcons(factoryIcon, contractIcon, circleArrowIcon, hashtagIcon);

@NgModule({
  declarations: [
    FactoriesListComponent,
    FactoriesListGridComponent,
    FactoriesListTreeComponent,
    FactoriesDetailComponent,
    FactoriesDetailItemComponent,
    FactoriesListGridCardComponent,
    FactoriesListGraphComponent,
  ],
  imports: [
    FactoriesRoutingModule,
    CommonModule,
    ClarityModule,
    StoreModule.forFeature(fromFactory.factoryFeatureKey, fromFactory.reducer),
    EffectsModule.forFeature([FactoryEffects]),
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
    ContractsModule,
    DocumentsModule,
    PipesModule,
  ],
  exports: [
    FactoriesListGridCardComponent,
    FactoriesListGridComponent,
    FactoriesListTreeComponent,
    FactoriesDetailItemComponent,
  ],
})
export class FactoriesModule {}
