import {
  Component,
  Input,
  OnInit,
  inject,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Cabinet } from 'src/app/models/Cabinet';
import { AuthService } from 'src/app/services/auth.service';

import { ClrLoadingState } from '@clr/angular';
import { Observable } from 'rxjs';
import { Item } from 'src/app/models/Item';
// import { number } from 'echarts';
// import { selectTemplate } from '../+template/template.selectors';
// import { TemplateActions } from '../+template/template.actions';
// import { Template } from 'src/app/models/Template';
import {
  Evaluation,
  AssesmentItem,
  AssesmentItemObject,
  // EvaluationItem,
  PruefItem,
} from 'src/app/models/Evaluation';
import { EvaluationActions } from '../+cabinetEvaluation/cabinetEvaluation.actions';
import { selectCabinetEvaluation } from '../+cabinetEvaluation/cabinetEvaluation.selectors';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import { RiskTabResultsComponent } from '../risks-tab-results/risks-tab-results.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-risks-evaluations-overview',
  templateUrl: './risks-evaluations-overview.component.html',
  styleUrls: ['./risks-evaluations-overview.component.css'],
})
export class RiskEvaluationComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() cabinet = undefined as unknown as Cabinet;
  evaluation = undefined as unknown as Evaluation;
  tabAuswertung = undefined as unknown as RiskTabResultsComponent;
  // evaluationItems = [] as EvaluationItem[];
  // assesmentItems = [] as AssesmentItemObject[];
  // pruefItems = [] as PruefItem[];
  // @Input() cabinet$ = undefined as unknown as Observable<Cabinet>;
  // @Input() cabinet$ = new Observable<Cabinet>();
  // @Input() cabinetObject = {} as CabinetObject;
  // @Input() cabinetCalculations$ = {} as CabinetCalculations;
  // @Input() evaluations$ = undefined as unknown as Observable<[Evaluation]>; //new Observable<CabinetEvaluation>() | undefined;
  evaluations$ = undefined as unknown as Observable<[Evaluation]>;
  // evaluations$ = new Observable<[Evaluation]>() | undefined;
  // @Output() updated = new EventEmitter();
  // @Output() init = new EventEmitter();
  selected = [] as Evaluation[];
  selectedEvaluation = undefined as unknown as Evaluation;
  extended = false;
  pdfShown = false;
  onHelper = false;
  onNewEvaluation = false;
  pdfBase64Src = {} as any;
  title = '';
  message = '';
  showAuswahl = false;
  result = {} as {
    SummeBewertung: number;
    SchlechtesteBewertung: number;
    Reduktionsfaktor: number;
    Kurzschlusstrom: number;
  };

  // private formBuilder = inject(FormBuilder);

  // formDynamisch = this.formBuilder.group({});

  constructor(
    private store: Store,
    private router: Router,
    private riskService: AssessmentStoreService,
    private activatedRoute: ActivatedRoute,
  ) {
    // this.cabinet$?.subscribe((cabinet) => {
    //   console.log('constructor() cabinet:', cabinet);
    //   this.cabinet = cabinet;
    //   this.evaluations$ = this.riskService.getEvaluationsFor(this.cabinet._id);
    //   this.evaluations$?.subscribe((cabinetEvaluation) => {
    //     console.log(
    //       'constructor() cabinetEvaluations: ',
    //       cabinetEvaluation.length,
    //     );
    //   });
    // });
  }
  ngAfterViewInit(): void {
    console.log(
      `ngAfterViewInit() - cabinet[${this.cabinet?.Uid}] and try to dispatch CabinetEvaluationActions.loadCabinetEvaluation`,
    );
    if (this.cabinet && this.cabinet?._id) {
      this.evaluations$ = this.riskService.getEvaluationsFor(this.cabinet?._id);
      this.evaluations$?.subscribe((cabinetEvaluations) => {
        console.log(
          'ngAfterViewInit() cabinetEvaluation',
          cabinetEvaluations?.length,
        );
      });
    }
  }

  initAuswertung($event: any) {
    console.log('initAuswertung(event: any(tab)=>this.tab))');
    try {
      this.tabAuswertung = $event as RiskTabResultsComponent;
      console.log('initAuswertung(event: any(tab)=>this.tab))');
      if (this.evaluation && this.tabAuswertung) {
        console.log('initAuswertung(event: any(tab)=>this.tab))');
        this.tabAuswertung.setEvaluation(this.evaluation);
      }
      // this.evaluation$.subscribe((evaluation) => {
      //   console.log('initAuswertung(event: any(tab)=>this.tab))');
      //   this.tabAuswertung.setEvaluation(evaluation);
      // });
    } catch (err) {
      console.error('FEHLER', err);
    }
  }

  refresh(event: any) {
    console.log('refresh() : ', this.cabinet);
  }

  newEvaluationClick() {
    this.router.navigate(['/risks/newevaluation', this.cabinet._id], {
      relativeTo: this.activatedRoute,
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['cabinet']) {
      this.cabinet = changes['cabinet'].currentValue as Cabinet;
      if (this.cabinet && this.cabinet?._id) {
        this.evaluations$ = this.riskService.getEvaluationsFor(
          this.cabinet?._id,
        );
        this.evaluations$?.subscribe((cabinetEvaluations) => {
          console.log(
            'ngAfterViewInit() cabinetEvaluation',
            cabinetEvaluations?.length,
          );
        });
      }
    }
  }

  onInput(event: any) {
    console.log(event);
    // event.preventDefault();
    return false;
  }

  ngOnInit() {
    // this.init.emit(this);

    // this.cabinet$?.subscribe((cabinet) => {
    //   console.log('ngOnInit()');
    //   this.cabinet = cabinet;
    //   console.log(
    //     `subscribe(cabinet) [${cabinet.Uid}] and try to dispatch CabinetEvaluationActions.loadCabinetEvaluation`,
    //   );
    //   this.evaluations$ = this.riskService.getEvaluationsFor(this.cabinet._id);
    //   this.evaluations$?.subscribe((cabinetEvaluations) => {
    //     console.log('ngOnInit() cabinetEvaluation)', cabinetEvaluations.length);
    //   });
    // });
    console.log(
      `ngOnInit() - cabinet[${this.cabinet?.Uid}] and try to dispatch CabinetEvaluationActions.loadCabinetEvaluation`,
    );
    if (this.cabinet && this.cabinet?._id) {
      this.evaluations$ = this.riskService.getEvaluationsFor(this.cabinet?._id);
      this.evaluations$?.subscribe((cabinetEvaluations) => {
        console.log('ngOnInit() cabinetEvaluation', cabinetEvaluations?.length);
      });
    }
  }

  disabledPdfCreateBtn(): boolean {
    if (this.selected.length === 1) {
      this.selectedEvaluation = this.selected[0];
      return this.selectedEvaluation?.PDFResult?.File != undefined;
    } else {
      this.selectedEvaluation = undefined as unknown as Evaluation;
    }
    return true;
  }

  onNewPdfResultClick() {
    this.pdfShown = false;
    this.extended = true;
    this.riskService
      .requestNewPdfResultFor(this.selectedEvaluation._id!)
      .subscribe((result) => {
        this.pdfBase64Src = result?.data; //.toString('base64');
        this.pdfShown = true;
      });
  }

  onHelperClick(title: string, message: string | undefined) {
    this.title = title || '';
    this.message = message || '';
    this.onHelper = true;
    return false;
  }

  showPdf(item: Evaluation) {
    this.pdfShown = false;
    this.extended = true;
    this.riskService.getPdfEvaluation(item._id!).subscribe((result) => {
      this.pdfBase64Src = result?.data; //.toString('base64');
      this.pdfShown = true;
    });
  }

  onPdfDocumentClick() {
    this.pdfShown = false;
    this.extended = true;
    this.riskService
      .getPdfEvaluationsFor(this.cabinet._id)
      .subscribe((result) => {
        this.pdfBase64Src = result.data; //.toString('base64');
        this.pdfShown = true;
      });
  }

  onBtnEditClick() {
    const evaluation = this.selected[0] as Evaluation;
    // this.onEdit(evaluation);
  }
}
