import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  selectTelemetries,
  selectTelemetriesError,
  selectTelemetriesLoading,
} from '../+store/telemetry.selectors';
import { TelemetryActions } from '../+store/telemetry.actions';

@Component({
  selector: 'app-telemetries-measures',
  templateUrl: './telemetries-measures.component.html',
  styleUrls: ['./telemetries-measures.component.css'],
})
export class TelemetriesMeasuresComponent implements OnInit {
  telemetriesList$ = this.store.select(selectTelemetries);
  loadingTelemetries$ = this.store.select(selectTelemetriesLoading);
  errorTelemetries$ = this.store.select(selectTelemetriesError);

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(TelemetryActions.loadTelemetries());
  }
}
