import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Contact } from '../models/Contact';

@Injectable({
  providedIn: 'root',
})
export class ContactStoreService {
  private apiUrl = environment.exportApi;

  constructor(private http: HttpClient) {}

  getAll(): Observable<Contact[]> {
    return this.http.get<Contact[]>(`${this.apiUrl}/service/contacts`);
  }

  getSingle(uid: string): Observable<Contact> {
    return this.http.get<Contact>(`${this.apiUrl}/service/contacts/${uid}`);
  }
}
