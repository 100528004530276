import {
  Component,
  Input,
  OnInit,
  inject,
  Output,
  EventEmitter,
  AfterViewInit,
  OnChanges,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Cabinet, CabinetCalculations } from 'src/app/models/Cabinet';
import { Template } from 'src/app/models/Template';
import { AuthService } from 'src/app/services/auth.service';
import { formatDate } from '@angular/common';

import { CabinetActions } from '../+cabinet/cabinet.actions';
import { ClrLoadingState } from '@clr/angular';
import { Observable } from 'rxjs';
import { Item, LowItem } from 'src/app/models/Item';
import { TemplateActions } from '../+template/template.actions';
import { selectTemplate } from '../+template/template.selectors';
import { User } from 'src/app/models/User';
import {
  selectProfil,
  selectProfilRoles,
} from '../../users/+profil/profil.selectors';
import { id } from '@cds/core/internal';

@Component({
  selector: 'app-risks-tab',
  templateUrl: './risks-tab.component.html',
  styleUrls: ['./risks-tab.component.css'],
})
export class RiskTabComponent implements OnInit, AfterViewInit, OnChanges {
  cabinet = {} as Cabinet;
  // templateInput = [] as Item[];
  // templateDropDowns = [] as Item[];
  // templateDates = [] as Item[];
  tempAuswahlElements = [] as string[];
  @Input() tabFor$ = '';
  @Input() itmArray$ = [] as Item[];
  @Input() cabinet$ = new Observable<Cabinet>() as Observable<Cabinet> | null;
  @Input() cabinetCalculations$ = {} as CabinetCalculations;

  @Output() updated = new EventEmitter();
  @Output() changed = new EventEmitter();
  @Output() init = new EventEmitter();

  title = '';
  message = '';
  showAuswahl = false;

  private formBuilder = inject(FormBuilder);

  formDynamisch = this.formBuilder.group({});

  formItem = this.formBuilder.group({
    _id: new FormControl('', { nonNullable: true }),
    IsPrimary: new FormControl(false, { nonNullable: true }),
    ItemIndex: new FormControl(0, { nonNullable: true }),
    Type: new FormControl('String', { nonNullable: true }),
    Title: new FormControl('', { nonNullable: true }),
    Auswahl: new FormGroup({}),
    Addfield: new FormControl('', { nonNullable: true }),
    Info: new FormControl('', { nonNullable: false }),
  });

  submitTabState: ClrLoadingState = ClrLoadingState.DEFAULT;

  onAdd = false;
  onEdit = false;
  onDelete = false;
  onHelper = false;

  onEditSubmitState: ClrLoadingState = ClrLoadingState.DEFAULT;
  onAddSubmitState: ClrLoadingState = ClrLoadingState.DEFAULT;
  onDeleteSubmitState: ClrLoadingState = ClrLoadingState.DEFAULT;
  user = {} as User;

  isService$ = this.store.select(selectProfilRoles(['service']));
  isManager$ = this.store.select(selectProfilRoles(['manager']));

  constructor(private store: Store) {
    if (this.cabinet$) {
      this.cabinet$.subscribe((cabinet) => {
        console.log('constructor() cabinet:', cabinet);
        this.cabinet = cabinet;
        this.init.emit(this);
      });
    }
  }

  refresh(itemsArray: Item[]) {
    console.log('refresh()');
    this.itmArray$ = itemsArray;
    this.ngOnInit();
  }

  inputChanged() {
    const tab = this.formDynamisch.getRawValue();
    const arrayItem = Object.entries(tab).map(
      ([Title, Value], index) =>
        ({
          ItemId: this.itmArray$.find((x) => x.ItemIndex == index)?._id,
          Value: Value,
        }) as { ItemId: string; Value: object },
    );
    const cabinet: Cabinet = {
      _id: this.cabinet._id,
      Uid: this.user.Uid,
    } as Cabinet;
    switch (this.tabFor$) {
      case 'Anlage':
        cabinet.Anlage = arrayItem;
        break;
      case 'Pruefung':
        cabinet.Pruefung = arrayItem;
        break;
      case 'Gefaehrdung':
        cabinet.Gefaehrdung = arrayItem;
        break;
    }
    this.changed.emit(cabinet);
  }

  ngOnInit() {
    this.init.emit(this);
    console.log(`ngOnInit() ${this.tabFor$ ? this.tabFor$ : ''} START`);
    const profil = this.store.select(selectProfil);
    profil.subscribe((usr) => {
      this.user = usr;
    });
  }

  ngAfterViewInit() {
    this.cabinet$?.subscribe((cabinet) => {
      this.cabinet = cabinet;
      cabinet?.Anlage?.map((templateItem) => {
        const formControl = this.formDynamisch?.get<string>(
          templateItem.ItemId,
        ) as FormControl;
        if (
          formControl !== undefined &&
          formControl !== null &&
          templateItem.Value !== undefined &&
          templateItem.Value !== null
        ) {
          try {
            if (
              this.itmArray$.find(
                (tmpDate) => tmpDate._id == templateItem.ItemId,
              )
            ) {
              try {
                const date = new Date(templateItem.Value as unknown as string);
                const val = formatDate(date, 'yyyy-MM-dd', 'de-DE');
                formControl.setValue(val);
              } catch (err) {
                console.log(err);
              }
            } else {
              formControl.setValue(templateItem.Value);
            }
          } catch (err) {
            console.log(err);
          }
        }
      });
      cabinet?.Pruefung?.map((templateItem) => {
        const formControl = this.formDynamisch.get<string>(
          templateItem.ItemId,
        ) as FormControl;
        if (
          formControl !== undefined &&
          formControl !== null &&
          templateItem.Value !== undefined &&
          templateItem.Value !== null
        ) {
          try {
            if (
              this.itmArray$.find(
                (tmpDate) => tmpDate._id == templateItem.ItemId,
              )
            ) {
              try {
                const date = new Date(templateItem.Value as unknown as string);
                const val = formatDate(date, 'yyyy-MM-dd', 'de-DE');
                formControl.setValue(val);
              } catch (err) {
                console.log(err);
              }
            } else {
              formControl.setValue(templateItem.Value);
            }
          } catch (err) {
            console.log(err);
          }
        }
      });
      cabinet?.Gefaehrdung?.map((templateItem) => {
        const formControl = this.formDynamisch.get<string>(
          templateItem.ItemId,
        ) as FormControl;
        if (
          formControl !== undefined &&
          formControl !== null &&
          templateItem.Value !== undefined &&
          templateItem.Value !== null
        ) {
          try {
            if (
              this.itmArray$.find(
                (tmpDate) => tmpDate._id == templateItem.ItemId,
              )
            ) {
              try {
                const date = new Date(templateItem.Value as unknown as string);
                const val = formatDate(date, 'yyyy-MM-dd', 'de-DE');
                formControl.setValue(val);
              } catch (err) {
                console.log(err);
              }
            } else {
              formControl.setValue(templateItem.Value);
            }
          } catch (err) {
            console.log(err);
          }
        }
      });
    });
  }

  ngOnChanges(changes: any): void {
    try {
      if (changes['itmArray$']) {
        if (this.itmArray$ && this.itmArray$?.length > 0) {
          this.itmArray$?.map((templateItem) => {
            try {
              if (!this.formDynamisch.get<string>(templateItem._id)) {
                this.formDynamisch?.addControl(
                  templateItem._id!,
                  this.formBuilder?.control(templateItem.Title),
                );
              }
            } catch (err) {
              console.error('FEHLER : ', err);
            }
            try {
              if (templateItem.Fixed == 3) {
                this.formDynamisch?.get(templateItem._id)?.disable();
              } else {
                this.formDynamisch?.get(templateItem._id)?.enable();
              }
            } catch (err) {
              console.error('FEHLER : ', err);
            }
          });
        }
      }
    } catch (err) {
      console.error('FEHLER : ', err);
    }
  }

  onSubmitTab() {
    this.submitTabState = ClrLoadingState.LOADING;
    const tab = this.formDynamisch.getRawValue();
    const arrayItem = Object.entries(tab).map(
      ([Title, Value], index) =>
        ({
          ItemId: this.itmArray$.find((x) => x.ItemIndex == index)?._id,
          Value: Value,
        }) as { ItemId: string; Value: object },
    );
    if (this.cabinet && this.cabinet._id !== null) {
      const cabinet: Cabinet = {
        _id: this.cabinet._id,
        Uid: this.user.Uid,
      } as Cabinet;

      switch (this.tabFor$) {
        case 'Anlage':
          cabinet.Anlage = arrayItem;
          break;
        case 'Pruefung':
          cabinet.Pruefung = arrayItem;
          break;
        case 'Gefaehrdung':
          cabinet.Gefaehrdung = arrayItem;
          break;
      }
      console.log('trying to do patchCabinet with cabinet: ', cabinet);
      this.store.dispatch(CabinetActions.patchCabinet({ cabinet: cabinet }));
    } else {
      console.warn('this.cabinet is null');
    }
    this.submitTabState = ClrLoadingState.SUCCESS;
  }

  selectionChanged(event: any) {
    console.log('selectionChanged(event: any)', event);
  }

  onAddFieldClick() {
    let newItemIndex = 0;
    try {
      let maxResultIndex = 0;
      maxResultIndex = Math.max(...this.itmArray$.map((o) => o.ItemIndex));
      newItemIndex = maxResultIndex == 0 ? 0 : maxResultIndex + 1;
    } catch (err) {
      console.log(err);
    }
    this.formItem.get('ItemIndex')?.disable();
    this.formItem.get('IsPrimary')?.enable();
    this.formItem.get('Title')?.enable();
    this.formItem.get('Info')?.enable();
    this.formItem.get('Type')?.enable();

    this.formItem.patchValue({
      ItemIndex: newItemIndex,
      IsPrimary: false,
      Title: '',
      Info: '',
    });

    this.onAdd = true;
    return false;
  }

  deleteAuswahlByIndex(event: any, index: number) {
    this.tempAuswahlElements.splice(index, 1);
    event.preventDefault();
    return false;
  }

  onAddAuswahlElementClick(event: any) {
    if (event.key == 'Enter') {
      event.preventDefault();
      const form = this.formItem.getRawValue();
      const value = form.Addfield;
      this.tempAuswahlElements.push(value);
      this.formItem.patchValue({
        Addfield: '',
      });
      return false;
    }
    return true;
  }

  onFieldTypeChanged(event: any) {
    const form = this.formItem.getRawValue();
    if (form.Type == 'Strings') {
      this.showAuswahl = true;
    } else {
      this.showAuswahl = false;
    }
  }

  onAddFieldSubmit() {
    // Add-Dialog wird beendet
    this.onAddSubmitState = ClrLoadingState.LOADING;
    try {
      const form = this.formItem.getRawValue();
      let strings = [] as string[];
      if (form.Type == 'Strings') strings = this.tempAuswahlElements;
      const selected = () => {
        switch (form.Type) {
          case 'String':
            return 'String';
          case 'Number':
            return 'Number';
          case 'Strings':
            // strings = this.tempAuswahlElements;
            return 'Strings';
          case 'Date':
            return 'Date';
          case 'Boolean':
            return 'Boolean';
          default:
            return undefined;
        }
      };
      const item: LowItem = {
        IsPrimary: form.IsPrimary,
        ItemIndex: form.ItemIndex,
        Title: form.Title,
        Info: form.Info === null ? undefined : form.Info,
        ItemType: selected(),
        Selection: strings,
      };
      this.store.dispatch(
        TemplateActions.postTemplateItem({
          tab: this.tabFor$,
          item: item,
          // templateId: this.template._id,
          templateId: this.cabinet.Template!,
        }),
      );
      this.onAddSubmitState = ClrLoadingState.SUCCESS;
      this.onAdd = false;
      this.updated.emit(this);
    } catch (err) {
      console.log('onAddFieldSubmit() ERROR: ', err);
    }
  }

  onEditFieldSubmit() {
    // Edit-Dialog wird beendet
    const form = this.formItem.getRawValue();
    let strings = [] as string[];
    if (form.Type == 'Strings') strings = this.tempAuswahlElements;
    const selected = () => {
      switch (form.Type) {
        case 'String':
          return 'String';
        case 'Number':
          return 'Number';
        case 'Strings':
          // strings = this.tempAuswahlElements;
          return 'Strings';
        case 'Date':
          return 'Date';
        case 'Boolean':
          return 'Boolean';
        default:
          return undefined;
      }
    };
    const item: Item = {
      _id: form._id,
      IsPrimary: form.IsPrimary,
      ItemIndex: form.ItemIndex,
      Title: form.Title,
      Info: form.Info === null ? undefined : form.Info,
      ItemType: selected(),
      Selection: strings,
    };
    console.log(`try to update item[${item?._id}] Titel:${item?.Title}`, item);
    this.store.dispatch(
      TemplateActions.editTemplateItem({
        tab: this.tabFor$,
        item: item,
      }),
    );
    this.onEdit = false;
    this.updated.emit(this);
    this.ngOnInit();
    return false;
  }

  onDeleteClick(id: string) {
    const item =
      this.itmArray$.length > 0
        ? // this.itmArray$[0]
          (this.itmArray$.find((x) => x._id == id) as Item)
        : {
            IsPrimary: false,
            Title: '',
            Info: '',
            ItemIndex: 0,
            ItemType: '',
            Selection: this.tempAuswahlElements,
            _id: id,
          };
    if (item.ItemType == 'Strings') {
      this.showAuswahl = true;
      this.tempAuswahlElements = item.Selection as string[];
    } else {
      this.showAuswahl = false;
    }
    const typeValue = item.ItemType as string;
    this.formItem.get('Type')?.patchValue(typeValue);
    this.formItem.patchValue({
      IsPrimary: item.IsPrimary,
      Title: item.Title,
      Info: item.Info,
      ItemIndex: item.ItemIndex,
      Type: item.ItemType,
      _id: item._id,
    });
    this.formItem.get('Type')?.disable();
    this.formItem.get('ItemIndex')?.disable();
    this.formItem.get('IsPrimary')?.disable();
    this.formItem.get('Title')?.disable();
    this.formItem.get('Info')?.disable();
    this.onDelete = true;
    return false;
  }

  dateChanged(eventDate: any) {
    const date = eventDate?.srcElement?.value
      ? new Date(eventDate.srcElement.value)
      : new Date();
    console.log('date input changed:', date);
  }

  onDeleteSubmit() {
    console.log('try to delete `Item`');

    const form = this.formItem.getRawValue();
    const item: Item = { _id: form._id } as Item;
    this.store.dispatch(
      TemplateActions.deleteTemplateItem({
        tab: this.tabFor$,
        item,
      }),
    );
    this.onDelete = false;
    this.updated.emit(this);
    return false;
  }

  onEditClick(id: string) {
    const item =
      this.itmArray$.length > 0
        ? (this.itmArray$.find((x) => x._id == id) as Item)
        : {
            IsPrimary: false,
            Title: '',
            Info: '',
            ItemIndex: 0,
            ItemType: '',
            Selection: this.tempAuswahlElements,
            _id: id,
          };
    if (item.ItemType == 'Strings') {
      this.showAuswahl = true;
      this.tempAuswahlElements = item.Selection as string[];
    } else {
      this.showAuswahl = false;
    }
    const typeValue = item.ItemType as string;
    this.formItem.get('Type')?.patchValue(typeValue);
    this.formItem.get('Type')?.enable();
    this.formItem.get('IsPrimary')?.enable();
    this.formItem.get('Title')?.enable();
    this.formItem.get('Info')?.enable();
    this.formItem.get('ItemIndex')?.disable();
    this.formItem.patchValue({
      IsPrimary: item.IsPrimary,
      Title: item.Title,
      Info: item.Info,
      ItemIndex: item.ItemIndex,
      Type: item.ItemType,
      _id: item._id,
    });
    this.onEdit = true;
    // event.preventDefault();
    return false;
  }

  onHelperClick(title: string, message: string | undefined) {
    this.title = title || '';
    this.message = message || '';

    this.onHelper = true;
    // event.preventDefault();
    return false;
  }
}
