import { Component, Input, OnInit } from '@angular/core';
import { TelemetryPresetsService } from 'src/app/shared/telemetry-presets.service';
import { Observable } from 'rxjs';
import { TelemetryPresets } from 'src/app/shared/Telemetry-Presets';

@Component({
  selector: 'app-telemetry-presets-table',
  templateUrl: './telemetry-presets-table.component.html',
  styleUrls: ['./telemetry-presets-table.component.css'],
})
export class TelemetryPresetsTableComponent implements OnInit {
  @Input() client = '';
  @Input() extern = false;

  telemetryData$: Observable<TelemetryPresets> =
    new Observable<TelemetryPresets>();

  constructor(private telemetryPresetsService: TelemetryPresetsService) {}

  ngOnInit() {
    this.telemetryData$ = this.telemetryPresetsService.getObjectByKey(
      this.client,
    );
  }
}
