import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Template } from 'src/app/models/Template';
import { Item, LowItem } from 'src/app/models/Item';

export const TemplateActions = createActionGroup({
  source: 'Template',
  events: {
    'Load Templates Uid': props<{ uid: string }>(),

    'Load Templates': emptyProps(),
    'Load Templates Success': props<{ data: Template[] }>(),
    'Load Templates Failure': props<{ error: HttpErrorResponse }>(),

    'Load Template': props<{ id: string }>(),
    'Load Template Uid': props<{ uid: string }>(),
    'Post Template': props<{ template: Template }>(),
    'Publish Template': props<{ template: Template }>(),
    'Patch Template': props<{ template: Template }>(),

    'Delete Template': props<{ id: string }>(),
    'Delete Template Success': props<{ id: string }>(),
    'Delete Template Failure': props<{ error: HttpErrorResponse }>(),

    // ------------------- Items -------------------- //
    'Post TemplateItem': props<{
      tab: string;
      item: LowItem;
      templateId: string;
    }>(),
    'Post NewTemplateItem': props<{
      tab: string;
      item: LowItem;
      templateId: string;
      uid: string;
    }>(),
    'Edit TemplateItem': props<{ tab: string; item: Item }>(),
    'Delete TemplateItem': props<{ tab: string; item: Item }>(),

    // // ------------------- Anlage ------------------- //
    // 'Post Anlage': props<{ item: Item; templateId: string }>(),
    // 'Post NewAnlage': props<{ item: Item; templateId: string; uid: string }>(),
    // 'Edit Anlage': props<{ item: Item }>(),
    // 'Delete Anlage': props<{ item: Item }>(),
    // // ------------------ Pruefung ------------------ //
    // 'Post Pruefung': props<{ item: Item }>(),
    // 'Edit Pruefung': props<{ item: Item }>(),
    // 'Delete Pruefung': props<{ item: Item }>(),
    // // -   -   -   -   -  Pruefung -   -   -   -   -  //
    // // 'Delete Pruefung': props<{ item: Item; id: string }>(),
    // // 'Delete Pruefung Success': props<{ id: string }>(),
    // // 'Delete Pruefung Failure': props<{ error: HttpErrorResponse }>(),
    // // ----------------- Gefaehrdung ---------------- //
    // 'Post Gefaehrdung': props<{ item: Item }>(),
    // 'Edit Gefaehrdung': props<{ item: Item }>(),
    // 'Delete Gefaehrdung': props<{ item: Item }>(),
  },
});
