import { Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, finalize } from 'rxjs';
import { ClrLoadingState } from '@clr/angular';
import { AuthService } from 'src/app/services/auth.service';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import { Cabinet, CabinetCalculations } from 'src/app/models/Cabinet';
import { Template } from 'src/app/models/Template';
import { CabinetActions } from '../+cabinet/cabinet.actions';
import { selectTemplate } from '../+template/template.selectors';
import { TemplateActions } from '../+template/template.actions';
import { Item } from 'src/app/models/Item';
import { RiskTabComponent } from '../risks-tab/risks-tab.component';
import {
  selectProfil,
  selectProfilRoles,
} from '../../users/+profil/profil.selectors';
import { RiskTabResultsComponent } from '../risks-tab-results/risks-tab-results.component';
import { Evaluation } from 'src/app/models/Evaluation';
import { RiskTabFilesComponent } from '../risks-tab-files/risks-tab-files.component';

@Component({
  selector: 'app-risks-evaluation-edition',
  templateUrl: './risks-evaluation-edition.component.html',
  styleUrls: ['./risks-evaluation-edition.component.css'],
})
export class RisksEditEvaluationComponent implements OnInit, OnDestroy {
  private service = inject(AssessmentStoreService);
  cabinet$ = new Observable<Cabinet>();
  cabinetCalculations$ = {} as CabinetCalculations;
  template$ = new Observable<Template>();
  loading$ = new Observable<boolean>();
  error$ = new Observable<HttpErrorResponse | null>();
  evaluations$ = new Observable<[Evaluation]>();
  evaluation$ = undefined as unknown as Observable<Evaluation>;

  itmsAnlage$ = [] as Item[];
  itmsPruefung$ = [] as Item[];
  itmsGefaehrdung$ = [] as Item[];

  authService$ = {} as AuthService;

  cabinet = {} as Cabinet;
  template = {} as Template;
  evaluation = undefined as unknown as Evaluation;
  templateId = '';

  // @ViewChild('anlageTab') tabAnlage = {} as RiskTabComponent;
  @ViewChild('pruefungTab') tabPruefung = {} as RiskTabComponent;
  @ViewChild('gefaehrdungTab') tabGefaehrdung = {} as RiskTabComponent;
  @ViewChild('fotosTab') tabFotos = {} as RiskTabFilesComponent;
  @ViewChild('Auswertung') tabAuswertung = {} as RiskTabResultsComponent;

  isManager$ = this.store.select(selectProfilRoles(['admin']));

  formBuilder = inject(FormBuilder);

  formMobile = this.formBuilder.group({
    _id: new FormControl('', { nonNullable: true }),
    Anlage: this.formBuilder.group({
      Ortskennzeichnung: new FormControl('', { nonNullable: true }),
      Standort: new FormControl('', { nonNullable: true }),
      Schaltraum: new FormControl('', { nonNullable: true }),
      Anlagenkennzeichnung: new FormControl('', { nonNullable: true }),
      Komponentennummer: new FormControl('', { nonNullable: true }),
      Schaltschrankbezeichnung: new FormControl('', { nonNullable: true }),
      Zusatzbezeichnung: new FormControl('', { nonNullable: true }),
    }),
    Pruefung: this.formBuilder.group({
      Schmutz: new FormControl('', { nonNullable: true }),
      Feuchtigkeit: new FormControl('', { nonNullable: true }),
      Alterung: new FormControl('', { nonNullable: true }),
      Auslastung: new FormControl('', { nonNullable: true }),
      RegelmaessigePruefung: new FormControl(false, { nonNullable: true }),
      LetztePruefung: new FormControl(false, { nonNullable: true }),
      NaechstePruefung: new FormControl(false, { nonNullable: true }),
      DPGID: new FormControl('', { nonNullable: true }),
      Umgebungsbedingungen: new FormControl('', { nonNullable: true }),
      RCD: new FormControl('', { nonNullable: true }),
      NhSicherungVorhanden: new FormControl('', { nonNullable: true }),
      StaendigUeberwachtDurchEfk: new FormControl('', { nonNullable: true }),
      SummeBewertungsZahl: new FormControl('', { nonNullable: true }),
      SchlechtesteBewertung: new FormControl('', { nonNullable: true }),
      Redunktionsfaktor: new FormControl('', { nonNullable: true }),
    }),
    Gefaehrdung: this.formBuilder.group({
      Zugaenglich: new FormControl('', { nonNullable: true }),
      Verriegelung: new FormControl('', { nonNullable: true }),
      Bemessungsstrom_LT_250: new FormControl('', { nonNullable: true }),
      Bemessungsstrom_LT_125: new FormControl('', { nonNullable: true }),
      Gefahr: new FormControl('', { nonNullable: true }),
      Beruehrungsschutz: new FormControl('', { nonNullable: true }),
      Stoerlichtbogengefahr: new FormControl('', { nonNullable: true }),
      Verkehrswege: new FormControl('', { nonNullable: true }),
      EMV: new FormControl('', { nonNullable: true }),
      Gefahrstoffe: new FormControl('', { nonNullable: true }),
      Beschriftungen: new FormControl('', { nonNullable: true }),
      Druckausgleich: new FormControl('', { nonNullable: true }),
      Kurzschlusstrom: new FormControl('', { nonNullable: true }),
      Netzimpedanz_L1L2: new FormControl('', { nonNullable: true }),
      Netzimpedanz_L2L3: new FormControl('', { nonNullable: true }),
      Netzimpedanz_L3L1: new FormControl('', { nonNullable: true }),
      Bemerkungen: new FormControl('', { nonNullable: true }),
    }),
    Image: this.formBuilder.group({
      Path: new FormControl('', { nonNullable: true }),
      Name: new FormControl('', { nonNullable: true }),
      id: new FormControl('', { nonNullable: true }),
      cid: new FormControl('', { nonNullable: true }),
    }),
    Images: this.formBuilder.array([]),
  });
  user: unknown;

  get imagesArray() {
    return this.formMobile.get('Images') as FormArray;
  }

  submitAnlageState: ClrLoadingState = ClrLoadingState.DEFAULT;
  submitPruefungState: ClrLoadingState = ClrLoadingState.DEFAULT;
  submitGefaehrdungenState: ClrLoadingState = ClrLoadingState.DEFAULT;

  helper = false;
  text = '';

  onHelper(text: string) {
    this.helper = true;
    this.text = text.toLowerCase();
  }

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private authService: AuthService,
    private riskService: AssessmentStoreService,
  ) {
    this.authService$ = authService;
  }

  ngOnInit(): void {
    const profil = this.store.select(selectProfil);
    profil.subscribe((usr) => {
      this.user = usr;
    });
    this.route.params.subscribe((params) => {
      this.cabinet$ = this.service.getCabinet(params['id']);
    });
    this.isManager$.subscribe((isManager) => {
      console.log('isManager: ', isManager);
    });
    this.cabinet$?.subscribe((cabinet) => {
      this.cabinet = cabinet;
      this.evaluations$ = this.riskService.getEvaluationsFor(this.cabinet._id);
      this.evaluations$.subscribe((evaluations) => {
        console.log(
          'this.evaluations$.subscribe() lenth: ',
          evaluations.length,
        );
        const firstEvaluation = evaluations[0];
        console.log('firstEvaluation: ', firstEvaluation?._id);
        if (firstEvaluation && this.tabAuswertung) {
          this.evaluation = firstEvaluation;
          console.log('setzen von `evaluation$` !!!');
          this.tabAuswertung.setEvaluation(firstEvaluation);
          // this.evaluation$ = this.riskService.getEvaluation(
          //   firstEvaluation._id as string,
          // );
          // this.evaluation$.subscribe((evaluation) => {
          //   this.evaluation = evaluation;
          //   this.tabAuswertung.setEvaluation(this.evaluation);
          // });
        }
      });
      if (cabinet && cabinet?.Template != undefined) {
        this.templateId = cabinet?.Template?.trim() as string;
        this.template$ = this.service.getTemplate(this.templateId);
        this.template$?.subscribe((template) => {
          this.template = template;
          if (cabinet?.Anlage && template?.Anlage) {
            if (this.cabinetCalculations$?.Calculations) {
              this.cabinetCalculations$.updateCalculation(cabinet);
            } else {
              this.cabinetCalculations$ = new CabinetCalculations(
                cabinet,
                template,
              );
            }
          }
          if (template?.Anlage) {
            this.itmsAnlage$ = template.Anlage;
            this.itmsPruefung$ = template.Pruefung;
            this.itmsGefaehrdung$ = template.Gefaehrdung;
          }
        });
      }
      if (cabinet.Images && cabinet.Images.length > 0) {
        const imagesArray = this.formMobile.get('Images') as FormArray;
        this.imagesArray.clear();

        cabinet.Images.forEach((image) => {
          imagesArray.push(
            this.formBuilder.group({
              Path: image.Path,
              Name: image.Name,
              id: image._id,
              cid: cabinet._id,
            }),
          );
        });
      }
    });
  }

  // onTabChange(event: any) {
  //   console.log(`risk-review: tab changed:${event}`, event);
  //   // this.currentTab = /** Get current tab */;
  // }

  // registerOnChange(event: any) {
  //   console.log(`risk-review: registerOnChange(${event})`, event);
  // }

  ngOnDestroy(): void {
    this.uploadSub$.unsubscribe();
  }

  file: File | null = null;
  excelFile: File | null = null;
  uploadProgress = 0;
  uploadSub$ = new Subscription();

  refresh(event: any) {
    console.log('refresh()');
    this.store.dispatch(TemplateActions.loadTemplate({ id: this.templateId }));
    this.template$ = this.store.select(selectTemplate(this.templateId));
    this.template$?.subscribe((template) => {
      this.template = template;
      console.log('refresh() -> template$.subscribe');
      if (this.cabinet && template.Anlage) {
        if (this.cabinetCalculations$?.Calculations) {
          this.cabinetCalculations$.updateCalculation(this.cabinet);
        } else {
          this.cabinetCalculations$ = new CabinetCalculations(
            this.cabinet,
            template,
          );
        }
      }
      this.itmsAnlage$ = template.Anlage;
      this.itmsPruefung$ = template.Pruefung;
      this.itmsGefaehrdung$ = template.Gefaehrdung;
      switch ((event as RiskTabComponent).tabFor$.toLocaleLowerCase()) {
        // case 'anlage':
        //   this.tabAnlage?.refresh(this.itmsAnlage$);
        //   break;
        case 'pruefung':
          this.tabPruefung?.refresh(this.itmsPruefung$);
          break;
        case 'gefaehrdung':
          this.tabGefaehrdung?.refresh(this.itmsGefaehrdung$);
          break;
        // case 'auswertung':
        //   this.tabAuswertung?.refresh(this.itmsGefaehrdung$);
        //   break;
      }
    });
  }

  calulationFor(cabinet: any) {
    if (this.cabinet?.Anlage && this.template?.Anlage) {
      if (this.cabinetCalculations$?.Calculations) {
        this.cabinetCalculations$.updateCalculation(cabinet);
      } else {
        this.cabinetCalculations$ = new CabinetCalculations(
          cabinet,
          this.template,
        );
      }
    }
  }

  // initAnlage($event: any) {
  //   try {
  //     this.tabAnlage = $event as RiskTabComponent;
  //   } catch (err) {
  //     console.error('FEHLER', err);
  //   }
  // }

  initPruefung($event: any) {
    try {
      this.tabPruefung = $event as RiskTabComponent;
    } catch (err) {
      console.error('FEHLER', err);
    }
  }

  initGefaehrdung($event: any) {
    try {
      this.tabGefaehrdung = $event as RiskTabComponent;
    } catch (err) {
      console.error('FEHLER', err);
    }
  }

  initFiles($event: any) {
    try {
      this.tabFotos = $event as RiskTabFilesComponent;
    } catch (err) {
      console.error('FEHLER', err);
    }
  }

  initAuswertung($event: any) {
    console.log('initAuswertung(event: any(tab)=>this.tab))');
    try {
      this.tabAuswertung = $event as RiskTabResultsComponent;
      // this.cabinet = $event.cabinet as Cabinet;
      console.log('initAuswertung(event: any(tab)=>this.tab))');
      if (this.evaluation) {
        console.log('initAuswertung(event: any(tab)=>this.tab))');
        this.tabAuswertung.setEvaluation(this.evaluation);
      }
      // this.evaluation$.subscribe((evaluation) => {
      //   console.log('initAuswertung(event: any(tab)=>this.tab))');
      //   this.tabAuswertung.setEvaluation(evaluation);
      // });
    } catch (err) {
      console.error('FEHLER', err);
    }
  }

  onFileSelected(event: any) {
    this.file = event.target.files[0];
  }

  onExcelImportSelected(event: any) {
    this.excelFile = event.target.files[0];
  }

  onSubmitFileBackup() {
    if (this.file) {
      console.log('onSubmitFile - content');

      const form = this.formMobile.getRawValue();

      const cabinet: Cabinet = {
        _id: form._id,
        Uid: this.authService.readUid(),
      } as Cabinet;

      const upload$ = this.riskService
        .fileUpload(this.file, '0815', cabinet)
        .pipe(finalize(() => this.resetUpload()));

      this.uploadSub$ = upload$.subscribe((event) => {
        if (event.type == HttpEventType.UploadProgress) {
          const total = event.total || event.loaded;
          this.uploadProgress = Math.round(100 * (event.loaded / total));
        }

        if (event.type == HttpEventType.Response) {
          if (event.body !== null) {
            const cabinet: Cabinet = event.body as Cabinet;

            if (cabinet._id) {
              this.store.dispatch(
                CabinetActions.loadCabinet({ id: cabinet._id }),
              );
            }
          }
        }
      });
    } else {
      console.log('onSubmitFile - empty');
    }
  }

  onSubmitFile() {
    const form = this.formMobile.getRawValue();

    if (this.file) {
      const upload$ = this.riskService
        .fileUpload(this.file, form.Image.Name, this.cabinet)
        .pipe(finalize(() => this.resetUpload()));

      this.uploadSub$ = upload$.subscribe((event) => {
        if (event.type == HttpEventType.UploadProgress) {
          const total = event.total || event.loaded;
          this.uploadProgress = Math.round(100 * (event.loaded / total));
        }

        if (event.type == HttpEventType.Response) {
          if (event.body !== null) {
            const cabinet: Cabinet = event.body as Cabinet;

            if (cabinet._id) {
              this.store.dispatch(
                CabinetActions.loadCabinet({ id: cabinet._id }),
              );
            }
          }
        }
      });
    }
  }

  ValueFromItem(cabinet: Cabinet, item: object) {
    try {
      const itm = item as Item;
      const result = cabinet?.Anlage?.find((a) => a.ItemId == itm._id)?.Value;
      if (result == undefined) return '';
      return result; // cabinet?.Anlage?.find((a) => a.ItemId == item._id)?.Value;
    } catch (err) {
      console.error(err);
      return '';
    }
  }

  onCancelUpload() {
    this.uploadSub$.unsubscribe();
    this.resetUpload();
  }

  onUpdateFile(control: any) {
    const imageControl: FormGroup = control;
    const imagePath = imageControl.get('Path')?.value;
    const imageName = imageControl.get('Name')?.value;
    const imageId = imageControl.get('id')?.value;
    const cabinetId = imageControl.get('cid')?.value;
  }

  onDeleteFile(control: any) {
    const imageControl: FormGroup = control;
    // const imagePath = imageControl.get('Path')?.value;
    // const imageName = imageControl.get('Name')?.value;
    const imageId = imageControl.get('id')?.value;
    const cabinetId = imageControl.get('cid')?.value;

    this.store.dispatch(
      CabinetActions.deleteImage({ cid: cabinetId, id: imageId }),
    );
  }

  resetUpload() {
    this.uploadProgress = 0;
    this.uploadSub$.unsubscribe();
  }
}
