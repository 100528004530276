import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Cabinet, LowCabinet } from 'src/app/models/Cabinet';

export const CabinetActions = createActionGroup({
  source: 'Cabinet',
  events: {
    'Load Cabinets Uid': props<{ uid: string }>(),

    'Load Cabinets': emptyProps(),
    'Load Cabinets Success': props<{ data: Cabinet[] }>(),
    'Load Cabinets Failure': props<{ error: HttpErrorResponse }>(),

    'Load Cabinet': props<{ id: string }>(),
    'Post Cabinet': props<{ cabinet: LowCabinet }>(),
    'Patch Cabinet': props<{ cabinet: Cabinet }>(),

    'Delete Cabinet': props<{ id: string }>(),
    'Delete Cabinet Success': props<{ id: string }>(),
    'Delete Cabinet Failure': props<{ error: HttpErrorResponse }>(),

    'Delete Image': props<{ cid: string; id: string }>(),

    'Load Cabinet Result': props<{ id: string }>(),
  },
});
