import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { EvaluationActions } from './cabinetEvaluation.actions';
import { AssessmentStoreService } from 'src/app/services/assessment.service';

@Injectable()
export class CabinetEvaluationEffects {
  private service = inject(AssessmentStoreService);

  loadCabinetEvaluation$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(EvaluationActions.loadEvaluation),
      mergeMap((action) =>
        this.service.getEvaluation(action.cabinetId).pipe(
          map((cabinetEvaluation) =>
            EvaluationActions.loadEvaluationSuccess({
              data: cabinetEvaluation,
            }),
          ),
          catchError((error) =>
            of(EvaluationActions.loadEvaluationFailure({ error })),
          ),
        ),
      ),
    );
  });
}
