import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { ClarityModule } from '@clr/angular';
import { NgxEchartsModule } from 'ngx-echarts';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxFilesizeModule } from 'ngx-filesize';

import { StationsRoutingModule } from './stations-routing.module';
import { StationsListComponent } from './stations-list/stations-list.component';
import { StationsListGridCardComponent } from './stations-list-grid-card/stations-list-grid-card.component';
import { StationsListGridComponent } from './stations-list-grid/stations-list-grid.component';
import { StationsListTreeComponent } from './stations-list-tree/stations-list-tree.component';
import { StationsListMapComponent } from './stations-list-map/stations-list-map.component';
import { StationsListGraphComponent } from './stations-list-graph/stations-list-graph.component';
import { StationsDetailComponent } from './stations-detail/stations-detail.component';
import { StationsDetailItemComponent } from './stations-detail-item/stations-detail-item.component';

import { StoreModule } from '@ngrx/store';
import * as fromStation from './+store/station.reducer';
import { EffectsModule } from '@ngrx/effects';
import { StationEffects } from './+store/station.effects';

import { DocumentsModule } from '../documents/documents.module';
import { HelperModule } from 'src/app/helper/helper.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

import '@cds/core/icon/register.js';

import {
  ClarityIcons,
  hostIcon,
  hostGroupIcon,
  popOutIcon,
  viewListIcon,
  mapIcon,
  detailsIcon,
  calendarIcon,
  tagIcon,
  fileIcon,
  pdfFileIcon,
  fileSettingsIcon,
  linkIcon,
  flagIcon,
} from '@cds/core/icon';

ClarityIcons.addIcons(
  hostIcon,
  hostGroupIcon,
  popOutIcon,
  viewListIcon,
  mapIcon,
  detailsIcon,
  calendarIcon,
  tagIcon,
  fileIcon,
  pdfFileIcon,
  fileSettingsIcon,
  linkIcon,
  flagIcon,
);

@NgModule({
  declarations: [
    StationsListComponent,
    StationsListGridComponent,
    StationsListTreeComponent,
    StationsListMapComponent,
    StationsListGraphComponent,
    StationsDetailComponent,
    StationsDetailItemComponent,
    StationsListGridCardComponent,
  ],
  imports: [
    StationsRoutingModule,
    CommonModule,
    ClarityModule,
    StoreModule.forFeature(fromStation.stationFeatureKey, fromStation.reducer),
    EffectsModule.forFeature([StationEffects]),
    GoogleMapsModule,
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
    NgxExtendedPdfViewerModule,
    NgxFilesizeModule,
    DocumentsModule,
    HelperModule,
    PipesModule,
  ],
  exports: [
    StationsListGridCardComponent,
    StationsListGridComponent,
    StationsListTreeComponent,
    StationsDetailItemComponent,
    StationsListMapComponent,
    StationsListGraphComponent,
  ],
})
export class StationsModule {}
