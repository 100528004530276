import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromCabinet from './cabinet.reducer';
import { Cabinet } from 'src/app/models/Cabinet';

const { selectAll } = fromCabinet.adapter.getSelectors();

export const selectCabinetState = createFeatureSelector<fromCabinet.State>(
  fromCabinet.cabinetFeatureKey,
);

export const selectCabinets = createSelector(selectCabinetState, selectAll);

export const selectCabinetsLoading = createSelector(
  selectCabinetState,
  (state) => state.loading,
);

export const selectCabinetsError = createSelector(
  selectCabinetState,
  (state) => state.error,
);

export const selectCabinet = (id: string) =>
  createSelector(
    selectCabinets,
    (cabinets) =>
      cabinets.find((cabinet) => cabinet._id == id) || ({} as Cabinet),
  );

export const selectCabinetUid = (uid: string) =>
  createSelector(
    selectCabinets,
    (cabinets) =>
      cabinets.filter((catinet) => catinet.Uid == uid) || ({} as Cabinet),
  );
