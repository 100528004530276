import { createReducer, on } from '@ngrx/store';
import { Telemetry } from 'src/app/models/Telemetry';
import { TelemetryActions } from './telemetry.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

export const telemetryFeatureKey = 'telemetry';

export const adapter = createEntityAdapter<Telemetry>({
  selectId: (telemetry) => telemetry.Uid,
});

export interface State extends EntityState<Telemetry> {
  loading: boolean;
  error: HttpErrorResponse | null;
}

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
});

export const reducer = createReducer(
  initialState,

  on(TelemetryActions.loadTelemetries, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(TelemetryActions.loadTelemetriesSuccess, (state, action): State => {
    return {
      ...adapter.setAll(action.telemetries, state),
      loading: false,
      error: null,
    };
  }),

  on(TelemetryActions.loadTelemetriesFailure, (state, action): State => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),
);
