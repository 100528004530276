import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Telemetry } from '../models/Telemetry';

@Injectable({
  providedIn: 'root',
})
export class TelemetryStoreService {
  private apiUrl = environment.exportApi;

  constructor(private http: HttpClient) {}

  getAll(): Observable<Telemetry[]> {
    return this.http.get<Telemetry[]>(`${this.apiUrl}/telemetries`);
  }

  postEndpoint(
    uid: string,
    Name: string,
    IsEnabled: boolean,
    Permission: string,
    endpoint: string,
    auth: string,
    p256db: string,
    expirationTime: number,
    userVisibleOnly: boolean,
  ): Observable<Telemetry> {
    const payload = {
      Name,
      IsEnabled,
      Permission,
      endpoint,
      auth,
      p256db,
      expirationTime,
      userVisibleOnly,
    };

    return this.http.post<any>(
      `${this.apiUrl}/telemetries/${uid}/mobile`,
      payload,
    );
  }

  patchEndpoint(payload: any): Observable<Telemetry> {
    return this.http.patch<any>(`${this.apiUrl}/telemetries/mobile`, payload);
  }

  deleteEndpoint(payload: any) {
    return this.http.delete(`${this.apiUrl}/telemetries/mobile`, payload);
  }
}
