<div
  class="login-wrapper"
  style="background-image: url(/assets/images/{{ background }});"
>
  <form class="login" [formGroup]="userEmails" (ngSubmit)="signin()">
    <section class="title">
      <h3 class="welcome">
        <img src="assets/logo-c.png" height="24" alt="Vrielmann Connect" />
        Vrielmann Connect
        <!-- <cds-icon shape="connect"></cds-icon> -->
        <!-- &nbsp;<cds-icon shape="disconnect"></cds-icon> -->
      </h3>
      Herzlich Willkommen
      <h5 class="hint">
        <cds-icon shape="login"></cds-icon> Anmeldung&nbsp;
        <!-- <cds-icon shape="resistor"></cds-icon>&nbsp; -->
        <!-- <cds-icon shape="inductor"></cds-icon>&nbsp; -->
        <!-- <cds-icon shape="capacitor"></cds-icon> -->
      </h5>
    </section>
    <div class="login-group">
      <clr-input-container>
        <label for="Username">E-Mail Adresse</label>
        <input
          clrInput
          [class.is-invalid]="Username?.invalid && Username?.touched"
          type="email"
          id="Username"
          name="Username"
          autocomplete="Username"
          placeholder=""
          formControlName="Username"
        />
        <clr-control-error>
          <small *ngIf="Username?.errors?.['required']" class="text-danger"
            >E-Mail Adresse ist erforderlich.</small
          >
        </clr-control-error>
      </clr-input-container>

      <clr-password-container>
        <label for="current-password">Passwort</label>
        <input
          clrPassword
          type="Password"
          id="current-password"
          name="current-password"
          autocomplete="current-password"
          formControlName="Password"
        />
        <clr-control-error>
          <small
            *ngIf="userEmails.get('Password')?.hasError('required')"
            class="text-danger"
          >
            Passwort ist erforderlich!
          </small>
          <small
            *ngIf="userEmails.get('Password')?.hasError('minlength')"
            class="text-danger"
          >
            Mindestlänge sind 8 Zeichen.
          </small>
        </clr-control-error>
      </clr-password-container>

      <!-- <clr-checkbox-wrapper>
        <label>merken</label>
        <input type="checkbox" name="rememberMe" clrCheckbox />
      </clr-checkbox-wrapper> -->

      <clr-alert
        *ngIf="isErrorSignin"
        [clrAlertType]="'danger'"
        [clrAlertClosable]="false"
      >
        <clr-alert-item>
          <span class="alert-text">
            Ungültige Anmeldeinformationen.
            <br />
            <code>
              {{ errorSignin.status }} | {{ errorSignin.statusText }}
              <ng-container *ngIf="errorSignin.error.name">
                | {{ errorSignin.error.name }} |
                {{ errorSignin.error.message }}
              </ng-container>
            </code>
          </span>
          <div class="alert-actions">
            <a [routerLink]="['/request']">Passwort vergessen</a>
          </div>
        </clr-alert-item>
      </clr-alert>

      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="userEmails.invalid"
        [clrLoading]="submitBtnState"
      >
        LOGIN
      </button>
    </div>
  </form>
</div>

<clr-modal
  [(clrModalOpen)]="multifactor"
  [clrModalClosable]="true"
  [clrModalSize]="'md'"
>
  <h3 class="modal-title">
    <section class="title">
      <h3 class="welcome">
        <cds-icon shape="lock" size="lg"></cds-icon>
        Zwei-Faktor-Authentifizierung
      </h3>
    </section>
  </h3>
  <div class="modal-body">
    <form class="login">
      Geben Sie den Sicherheitscode ein:
      <p></p>
      <code-input
        #codeInput
        [isCodeHidden]="false"
        [codeLength]="6"
        (codeChanged)="onCodeChanged($event)"
        (codeCompleted)="onCodeCompleted($event)"
      >
      </code-input>
      <p></p>
      <button
        type="button"
        class="btn btn-primary btn-block"
        (click)="confirm()"
      >
        LOGIN
      </button>

      <clr-alert
        *ngIf="isErrorConfirm"
        [clrAlertType]="'danger'"
        [clrAlertClosable]="false"
      >
        <clr-alert-item>
          <span class="alert-text">
            Ungültige Anmeldeinformationen.
            <br />
            <code>
              {{ errorConfirm.status }} | {{ errorConfirm.statusText }}
              <ng-container *ngIf="errorConfirm.error.name">
                | {{ errorConfirm.error.name }} |
                {{ errorConfirm.error.message }}
              </ng-container>
            </code>
          </span>
          <!-- <div class="alert-actions">
            <a [routerLink]="['/request-mfa']">Wiederherstellen</a>
          </div> -->
        </clr-alert-item>
      </clr-alert>
    </form>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-primary" (click)="verify()">Login</button> -->
  </div>
</clr-modal>
