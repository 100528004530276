import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Station } from 'src/app/models/Station';
import { Document } from 'src/app/models/Document';

import * as CATEGORIES from 'src/app/constants/categories';

interface Item {
  Station: Station;
  Thermografie: Document[];
  Protokoll: Document[];
  Stromlaufplan: Document[];
}

@Component({
  selector: 'app-stations-list-grid',
  templateUrl: './stations-list-grid.component.html',
  styleUrls: ['./stations-list-grid.component.css'],
})
export class StationsListGridComponent implements OnInit, OnChanges {
  @Input() stations = [] as Station[];
  @Input() loading = false;

  @Input() extended = true;
  @Input() hiddenLocation = false;

  Items = [] as Item[];

  STROMLAUFPLAN = CATEGORIES.STROMLAUFPLAN;
  THERMOGRAFIE = CATEGORIES.THERMOGRAFIE;
  PROTOKOLL = CATEGORIES.PROTOKOLL;

  ngOnChanges(changes: SimpleChanges): void {
    this.updateItems();
  }

  ngOnInit(): void {
    this.updateItems();
  }

  updateItems() {
    this.Items = this.stations?.map((station) => ({
      Station: station,
      Stromlaufplan: station.Documents.filter(
        (doc) =>
          doc.Categories?.some(
            (category) => category.Uid === CATEGORIES.STROMLAUFPLAN,
          ),
      ),
      Protokoll: station.Documents.filter(
        (doc) =>
          doc.Categories?.some(
            (category) => category.Uid === CATEGORIES.PROTOKOLL,
          ),
      ),
      Thermografie: station.Documents.filter(
        (doc) =>
          doc.Categories?.some(
            (category) => category.Uid === CATEGORIES.THERMOGRAFIE,
          ),
      ),
    }));
  }
}
