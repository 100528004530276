import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromTelemetry from './+store/telemetry.reducer';
import { TelemetryEffects } from './+store/telemetry.effects';

import { TelemetiesRoutingModule } from './telemetries-routing.module';
import { TelemetiesListComponent } from './telemeties-list/telemeties-list.component';
import { TelemetriesListGridComponent } from './telemetries-list-grid/telemetries-list-grid.component';
import { TelemetriesListGridItemComponent } from './telemetries-list-grid-item/telemetries-list-grid-item.component';

import { HelperModule } from 'src/app/helper/helper.module';

import '@cds/core/icon/register.js';

import { ClarityIcons, cpuIcon } from '@cds/core/icon';
import { TelemetriesMessagesComponent } from './telemetries-messages/telemetries-messages.component';
import { TelemetriesPresetsComponent } from './telemetries-presets/telemetries-presets.component';
import { TelemetriesMeasuresComponent } from './telemetries-measures/telemetries-measures.component';

ClarityIcons.addIcons(cpuIcon);

@NgModule({
  declarations: [
    TelemetiesListComponent,
    TelemetriesListGridComponent,
    TelemetriesListGridItemComponent,
    TelemetriesMessagesComponent,
    TelemetriesPresetsComponent,
    TelemetriesMeasuresComponent,
  ],
  imports: [
    TelemetiesRoutingModule,
    CommonModule,
    ClarityModule,
    StoreModule.forFeature(
      fromTelemetry.telemetryFeatureKey,
      fromTelemetry.reducer,
    ),
    EffectsModule.forFeature([TelemetryEffects]),
    HelperModule,
  ],
})
export class TelemetriesModule {}
