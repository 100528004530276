import { createReducer, on } from '@ngrx/store';
import { Station } from 'src/app/models/Station';
import { StationActions } from './station.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

export const stationFeatureKey = 'station';

export const adapter = createEntityAdapter<Station>({
  selectId: (station) => station._id,
});

export interface State extends EntityState<Station> {
  loading: boolean;
  error: HttpErrorResponse | null;
}

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
});

export const reducer = createReducer(
  initialState,

  on(StationActions.updateStation, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(StationActions.loadStation, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(StationActions.loadStationSuccess, (state, action): State => {
    return {
      ...adapter.upsertOne(action.station, state),
      loading: false,
      error: null,
    };
  }),

  on(StationActions.loadStationFailure, (state, action): State => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),

  on(StationActions.loadStations, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(StationActions.loadStationsSuccess, (state, action): State => {
    return {
      ...adapter.setAll(action.stations, state),
      loading: false,
      error: null,
    };
  }),

  on(StationActions.loadStationsFailure, (state, action): State => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),
);
