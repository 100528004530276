<ng-container *ngIf="telemetriesList$ | async as telemetries">
  <div class="clr-row">
    <div class="clr-col-12">
      <div class="card">
        <h3 class="card-header">Archiv-Daten</h3>
        <div class="card-block">
          <h4 class="card-title">Filter</h4>

          <div class="clr-row">
            <div class="clr-col-xl-4" id="dropDownClientId">
              <clr-dropdown [clrCloseMenuOnItemClick]="true">
                <button
                  clrDropdownTrigger
                  aria-label="Dropdown"
                  class="btn btn-outline"
                  style="width: 110px"
                >
                  <label>Client ID </label>
                  <cds-icon shape="angle" direction="down" />
                </button>
                <clr-dropdown-menu *clrIfOpen>
                  <div
                    aria-label="Dropdown1"
                    clrDropdownItem
                    *ngFor="let element of clientIds$"
                  >
                    <div (click)="onClientIdClick(element)">{{ element }}</div>
                  </div>
                </clr-dropdown-menu>
              </clr-dropdown>
              <label style="padding-right: 5px; float: right; text-align: right"
                >({{ lblTxtClientId }})</label
              >
            </div>
            <div class="clr-col-xl-4" id="dropDownSystem">
              <clr-dropdown [clrCloseMenuOnItemClick]="true">
                <button
                  clrDropdownTrigger
                  aria-label="Dropdown"
                  class="btn btn-outline"
                  style="width: 110px"
                >
                  <label>System </label>
                  <cds-icon shape="angle" direction="down" />
                </button>
                <clr-dropdown-menu *clrIfOpen>
                  <div clrDropdownItem (click)="clearSystemClick()">-leer-</div>
                  <div
                    aria-label="Dropdown1"
                    clrDropdownItem
                    *ngFor="let element of systems$"
                  >
                    <div (click)="onSystemClick(element)">{{ element }}</div>
                  </div>
                </clr-dropdown-menu>
              </clr-dropdown>
              <!-- <div stype="text-align: center">teeexxxt</div> -->
              <label style="padding-right: 5px; float: right; text-align: right"
                >({{ lblTxtSystem }})</label
              >
            </div>
            <div class="clr-col-xl-4" id="dropDownLocation">
              <clr-dropdown [clrCloseMenuOnItemClick]="true">
                <button
                  clrDropdownTrigger
                  aria-label="Dropdown"
                  class="btn btn-outline"
                  style="width: 110px"
                >
                  <label>Location </label>
                  <cds-icon shape="angle" direction="down" />
                </button>
                <clr-dropdown-menu *clrIfOpen>
                  <div clrDropdownItem (click)="clearLocationClick()">
                    -leer-
                  </div>
                  <div
                    aria-label="Dropdown1"
                    clrDropdownItem
                    *ngFor="let element of locations$"
                  >
                    <div (click)="onLocationClick(element)">{{ element }}</div>
                  </div>
                </clr-dropdown-menu>
              </clr-dropdown>
              <label style="padding-right: 5px; float: right; text-align: right"
                >({{ lblTxtLocation }})</label
              >
            </div>
            <div class="clr-col-xl-4" id="dropDownDevice">
              <clr-dropdown [clrCloseMenuOnItemClick]="true">
                <button
                  clrDropdownTrigger
                  aria-label="Dropdown"
                  class="btn btn-outline"
                  style="width: 110px"
                >
                  <label>Device </label>
                  <cds-icon shape="angle" direction="down" />
                </button>
                <clr-dropdown-menu *clrIfOpen>
                  <div clrDropdownItem (click)="clearDeviceClick()">-leer-</div>
                  <div
                    aria-label="Dropdown1"
                    clrDropdownItem
                    *ngFor="let element of devices$"
                  >
                    <div (click)="onDeviceClick(element)">{{ element }}</div>
                  </div>
                </clr-dropdown-menu>
              </clr-dropdown>
              <label style="padding-right: 5px; float: right; text-align: right"
                >({{ lblTxtDevice }})</label
              >
            </div>
          </div>
          <div class="clr-row">
            <div class="clr-col-xl-4" id="multiSelectSignal">
              <label style="padding-left: 5px; float: left; text-align: left"
                >Signale</label
              >
              <form clrForm [formGroup]="formFilter">
                <clr-combobox
                  formControlName="sourceData"
                  name="multiSelect"
                  clrMulti="true"
                  required
                  style="float: top"
                  [(ngModel)]="ngModelSignals"
                >
                  <ng-container *clrOptionSelected="let slct">
                    <ng-component [innerText]="slct.Alias">
                      Signal:{{ slct }}
                    </ng-component>
                  </ng-container>
                  <clr-options>
                    <clr-option
                      *clrOptionItems="let state of signals$; field: 'Alias'"
                      [clrValue]="state"
                    >
                      <div>
                        {{ state.Alias }}
                      </div>
                    </clr-option>
                  </clr-options>
                </clr-combobox>
              </form>
            </div>
            <div class="clr-col-xl-4" id="formInterval">
              <form clrForm [formGroup]="formFilter" clrLayout="horizontal">
                <div class="clr-row">
                  <div class="clr-col-4">
                    <clr-checkbox-wrapper>
                      <input
                        [(ngModel)]="ngModelInterval.Enabled"
                        type="checkbox"
                        clrCheckbox
                        formControlName="intervalCbx"
                        (click)="intervalClicked()"
                      />
                      <label>Interval</label>
                    </clr-checkbox-wrapper>
                  </div>
                  <div class="clr-col-4">
                    <input
                      clrInput
                      placeholder="Wert"
                      formControlName="intervalValue"
                      name="intervalValue"
                      style="width: 80px"
                      [(ngModel)]="ngModelInterval.Value"
                    />
                  </div>
                  <div class="clr-col-4">
                    <clr-select-container>
                      <select
                        clrSelect
                        name="options"
                        formControlName="intervalUnit"
                        style="width: 80px; margin-right: 0px"
                        [(ngModel)]="ngModelInterval.Unit"
                      >
                        <option
                          *ngFor="let unit of units$"
                          value="{{ unit.unit }}"
                        >
                          {{ unit.name }}
                        </option>
                        <!-- <option value="two">Two</option>
                    <option value="three">Three</option> -->
                      </select>
                    </clr-select-container>
                    <!-- </clr-checkbox-container> -->
                  </div>
                </div>
              </form>
            </div>
            <div class="clr-col-xl-4" id="fromStartStop">
              <form clrForm ng-controller="DateController as dateCtrl">
                <div class="clr-row">
                  <div class="clr-col-4">
                    <label>Startzeit</label>
                  </div>
                  <div class="clr-col-7">
                    <input
                      type="datetime-local"
                      [value]="dateTimeStartString"
                      (input)="dateStartChanged($event)"
                    />
                  </div>
                </div>
                <div class="clr-row">
                  <div class="clr-col-4">
                    <label>Endzeit</label>
                  </div>
                  <div class="clr-col-7">
                    <input
                      type="datetime-local"
                      [value]="dateTimeStopString"
                      (input)="dateStopChanged($event)"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div class="card-footer">
          <div class="card-text">
            Wenn Sie die Filteroptionen eingestellt haben, klicken Sie bitte
            hier:
            <button
              class="btn btn-outline"
              style="margin: 5px 5px 5px 5px"
              (click)="filterClicked()"
            >
              Filtern
            </button>
            Zum zurücksetzen der Filteroptionen, klicken Sie bitte hier:
            <button
              class="btn btn-outline"
              style="margin: 5px 5px 5px 5px"
              (click)="clearCliecked()"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<!-- ------------------------------------------------------- -->
<ng-container *ngIf="telemetry">
  <div class="clr-row">
    <div class="clr-col-12" *ngIf="telemetry.MQTT?.Enabled">
      <app-measure-archive-chart
        [telemetry]="telemetry"
      ></app-measure-archive-chart>
    </div>
    <div class="clr-col-12">
      <app-measure-archive-table [telemetry]="telemetry">
      </app-measure-archive-table>
    </div>
  </div>
</ng-container>
<!-- ------------------------------------------------------ -->
<!-- <ng-container *ngIf="telemetriesList$ | async as telemetries">
  <div class="clr-row" *ngFor="let telemetry of telemetries">
    <div class="clr-col-12" *ngIf="telemetry.MQTT?.Enabled">
      <app-measure-archive-chart
        #childTable
        [telemetry]="telemetry"
      ></app-measure-archive-chart>
    </div>
    <div class="clr-col-12">
      <app-measure-archive-table [telemetry]="telemetry">
      </app-measure-archive-table>
    </div>
  </div>
</ng-container> -->
<!-- -------------------------------------------------- -->

<!-- <ng-container *ngIf="telemetriesList$ | async as telemetries">
  <div class="clr-row" *ngFor="let telemetry of telemetries">
    <div class="clr-col-12" *ngIf="telemetry.MQTT?.Enabled">
      <app-telemetry-measure-table
        [telemetry]="telemetry"
      ></app-telemetry-measure-table>
    </div>
  </div>
</ng-container> -->
<!-- </div> -->
<!-- </div> -->

<p></p>
