export const vrielmann_bw = `
<svg
    version="1.1"
    id="svg2"
    width="32"
    height="32"
    viewBox="0 0 111 111"
    sodipodi:docname="logo-c.png"
    inkscape:export-filename="logo-c.svg"
    inkscape:export-xdpi="96"
    inkscape:export-ydpi="96"
    xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
    xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:svg="http://www.w3.org/2000/svg">
   <defs
      id="defs6" />
   <sodipodi:namedview
      id="namedview4"
      pagecolor="#ffffff"
      bordercolor="#000000"
      borderopacity="0.25"
      inkscape:showpageshadow="2"
      inkscape:pageopacity="0.0"
      inkscape:pagecheckerboard="0"
      inkscape:deskcolor="#d1d1d1"
      showgrid="false" />
   <g
      inkscape:groupmode="layer"
      inkscape:label="Image"
      id="g8">
     <image
        width="111"
        height="111"
        preserveAspectRatio="none"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG8AAABvCAYAAADixZ5gAAAAAXNSR0IArs4c6QAAAARnQU1BAACx
 jwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAZdEVYdFNvZnR3YXJlAEFkb2JlIEltYWdlUmVh
 ZHlxyWU8AAAbqElEQVR4Xu2dC3RV1ZnH2xHRgdHMMDqjQusMLVZAAoiC8hQFtCNVRCpWwfGxWh8T
 AUXUyljbqgu0RBSBccAHCliRZ4MQoVFGMIAR5RkeIQIhAfKA8EpISLAz3/92/8/67s4+994k9xXX
 fGt9S8nZ5+x99u/8v/3tfR73ezGy7zv8b5SfZbyZ8bONNzd+jvi54n9rvIXxluJ/Z/w84+crT4nA
 dXkeg8fE8eGoi3WjHXC0ie1je9l+OM8Jrs8V7uoPetKYq3H6JFzQQgGzYWlIf2/8H4y3Uv6PDtfb
 uQ+PoaHaMG2QaGekIPW5w139A0+ouRqkGx0pNA2MqtCwCIlALjB+ofF/svyfjeu/sSz31WAJlDAb
 AtIGGCnEhJirIWxkKGg4eT9o6DgblgZFMBcZv9j4JRE4y2I/DZdACdMPJCESJM+jvhBd/RZXsyvX
 jdPg/KDZwKgwDcyGRUitxdsY/4H4D5Vf6nC9HeWxH47hAuoH0oao1WhDbAjAuJhdKdwPGsHp8BhK
 ZQTmB4tw/sX4v4q3Nf4jy39sXP8N5bAP9ydYGybq1op0QbTVSIgaoAuiH8CYm64Mrhtig7Oh4QRd
 0KgyKozAqCrCIiiCaSd+mfhPjF+uvL1y/XeUwz7Yl2A1UCqTIG012iG1yQDUlcBd0DQ4DQ0nFwoa
 OokK08A0LIIinA7iHcWvEO9kPDWEYzvKYh/sS7AESpgAqRUZDqIdTjVE9AMh+gG0+zXqZlfgAudS
 mw6RuFL9oDEkUmHoRKiDsAiKgDqLd0lJSek2duzYn7377ru/XLly5ficnJz0rVu3vrF79+454n+E
 b9u2beamTZumZmdnv7hkyZLHp0yZMmLgwIF9zXEIlDA1SFw4VGN9IEaiwlAAo2r6wPBIwLnUxjHN
 Dxo6iwojMHRsANbZZ5995UsvvfSLdevWvbR///5lJ0+ezP/2229r/reBVlNTc6y8vDwnPz//3WXL
 lo0dNmxYP1Mf6kX9DYFoh1IboFZhzAHqA8I1ODRAg6PaAM6lNpwoTtgFjSrD1U+Fde7WrVuvzMzM
 Xx88ePBj6eyjpt9jZhUVFbvy8vJmvvnmm3fLxQKQaE+kEHGOWoXhxsKYAtQHgkcCjmHSVhtOEIkI
 xjQXNE9lLVq0uHLevHlpAmzlmTNnTpt+jbtVV1cXAuT48eMHSbuoxFBjIiIKVYhz1ypsKEB4vc0+
 gA0OFcNdYdJWmw6ROGmGRyoN0Dr36tWr94YNG9KrqqoOmv5LCvuL2Pr1639j2oo244L70V133dWl
 oKBg4rFjx5YeOXJk1po1a26Tv9dHhX4A7b6vl9k7u8Ch4lDgOLbZasOViyuY4TG1f//+fSXJmFFb
 W3vS9FdSGeClpaX9VNqKUI42t584ceKNEsZLTRHPJFpMlO0uFTKZsVUYdYB6p0jAoUFomCtMarXh
 imUiktqmTZurJTucJJ1w3Jx7UpqMg3ukvd3Eu6Ld4lccP348x2yuYxkZGQCNc0cfoC+oQr9kxgbI
 Ptcc4GFNF7bBEZ4NTo9vOinBfM1WWyBEvvfee7+qrKzca843qe3AgQN/ljZ3F79KvOuQIUP6mU1O
 O3To0HQph2hDgKHCaNQA6kIanFZdOHAc3xAmMbBzbAuorUePHr0lzf+TOc8mYfv27cuUtl8r3kP8
 6nHjxg0xm5xWWlo6R8oh2oRKZgiQIRT92mCAdgEXOBw8HDiObwSHMIlxovMbb7xxX7IlI5FYWVnZ
 Rml/b/Ge4j1atWp1TahQv2nTpqelHDNSLrVFE2Ad0xu5gx84Jid+4PT4doXMl7oii5QJ9Rlzfk3K
 BFTFJZdcMkDOhQCvWbt2bbrZHGQyvfimS5cuWP2x54U6jPoBRP8SIPo9IoD6j6HA4eqIFBzGt05I
 SoqKijLNuTVZe//995+T88EKTB9xALxWpgYvnz59uhzbJSH9VsLlJ/fcc09/2cYVmsCUQhwqRN8g
 jOpxMBRArT4C1JzgAdN/cIHDwTQ4ZE1+4JiYdMIUQOZAmwJn38Tt5MmTBSkpKVAfAQZUeO655/Yc
 OXLk4K5du2Kt9ErxLuLISANTCnGuzjCMchy0AaJf0b8EGFH41P9gAQ2P4HBQXB16HocGOBV3++23
 3yApdr459++E5ebmLpRzg7KuEwcsQOwljmTmGnFkpJxSdJ48efLQvLy8qc2bN6cKGUYxDhKgDqEE
 iP6OBGBIcAyXBAd5a3A6q/TA3XbbbddLYrLfnPN3yrZv375YEhYsl/lBDGSks2fPfpBrsYWFhfME
 IEIpl9f8AHIeSIB+CYwTngscwyUzS8xXuHKCBjCrDIC75ZZb+n/XFGcbQuiHH374+4svvvhGOecg
 iI8//vjde/fu/QhjoCkeMPnbLNkOgJg2ob9cADmRtzNQP/V54Gx49jinExSAQ+xGAzgduKJ9+/Y9
 ZIzbbNr7nbfa2tpqmUZsKigoWHXw4MEvTp06FXIatHnz5nTpp1AAOZG3Exi/8BlSda5wyQQFgy9C
 ABrSEdMBOYE/m3b+v/nYihUrHkN/mX4jQGahiGgAyAw03PjnUSQ8FNKq0+FSj3PMLHEldZaJ6eum
 fd8Jg6pEKR+89tprj6enp4/B/8s8tdZsbrCdOXOm6uWXX/659BkBQgDMQiEMVwbK8Ak2hAcPqTrs
 rKcFepxjgpKKdUo7xjdlKy4uzrlLTM7teu1z5sx5zhRplCGZ6969O7JTJjEQAgAiovmNf2Cixz94
 HdVFGi4D49yNN954XXV1dZ1bI03RZMJ9dNGiRS/KeQVB03748OGtpnij7MCBA8vleJgPQgAQAify
 9Rn/glTnSlKYXdrhMrBeibvdpj1N2vLz8z/u3bv3EDknJzT6li1b5ptdGm2ZmZnj5Ji4Rwgh2OOf
 X/jUAMOqDgeB6nR2GQiXixcvHmPa0WRNpjVFr7/++hNyPk5YtmOeZ3ZttInSjwwePBgrNvb4hwiH
 Pveb/zGEhlWdTlKYXV4hdq2Ey2LTjiZnWCTfuHHj+23atLlJzqcOpMsvv/zmrVu3zj///PMH6r9j
 PDSHiIrJNGORHBfhk+NfuPCpAfqqjkkKVcckJZBd4rEFU3+Ts/Ly8h1jx459QM4jCBj9nXfeGS9z
 tlLxQ/a2ysrKQ+YwUTE8YjFp0qRfyLFDhU8AhJg0QLAKqI7w/FSnk5ROI0aMGCgpb7Wpv8mYpP+V
 n3zyydRzzjnnBjmPICjwm266aRgm3KY4ss6v9PYLL7xwkCj2L2Zz1Ewupg1yfNxGQvhk9gn1MXzq
 8Q8AGUKDQqYe65hh1lEdnkI29TYZk+xu7bBhw4ZL+4OAwc8666wbli9fno77dqZ4wDC+6XKPPPLI
 vWZT1A1PfEsddvbpWv/UAINCpladzjC9sc6oLmHPUtbXZFw+8sEHH/xO2h4EjA4gZWVlziW9VatW
 TdVlpYOfNZuibqI+qBy3k1yTd66+aIBIYoISFZfqEH891cnV+LapL+lt165dS6+66qpbpN1BwOAI
 gTk5Oe/Ihei7ajJz5sxf631Wr179X2ZTTGzatGn3SD2uuR/DpwYITkGJir2agh0Rf70MU0LLCVNX
 0hpW/SUJGC1tDgJGnzhx4qgTJ04UmOK+dt99943Q++3cuXOp2RQTkznzCqkH6sMTdlQfxMPwqQEi
 OjpDJmIsV1O8ed2nn376G1NPUhpU9OWXX75nbtUEAYN37dr1ZwIgAxme2cXXMJWwpwmlpaUxfSoA
 dd5xxx24W6+nDlw6QxQkQPCBwEKGTMgWB8CBuhw7dmyjqSfpDMtWaWlp90o7g4DRsS4p499hUzys
 iXqL7GNUVVXFfBlQLr7JUhfVx6kDOGiAiIoAGFnIfPDBB//NHD+pTMJ45YoVKyYjY5R2BnU2fOjQ
 ocOLioo+N8UjNslO1+njtG3b9qdmU0xNLprdUh+ehdHqQ/jk+AeAiIqAGFnIlCviVXP8pLHCwsLV
 N998M26vBAGDYy6XlZX1GuZ2pni9bNu2bQv08Z588slfmk0xt9GjR2ONVasPyQsBggvGQED0Juah
 skyEzKR5CkzCXznSdmlXEDD6Y4899sCRI0dyTfEGmaj5VX3MuXPn/tZsirlt2LDhdalTqw/RzwYI
 FXrjnQ6ZIOuFTDzCJ4NpUtyvkyxxz5AhQ+6QdgUBg2OdUk58NgZ+U7zBZi9WZ2dnv2k2xdzkwvtS
 6sR7EVQfoh9YIBISIMQVNN7ZE/PAclhGRsYT5rgJNSQRciENlTYFQYO/+uqrj2G7Kdpou/POO7He
 6B0ft4zMppibZM01l112GZ4L5VtJmLiDBcY/AoS4wo53WA6bY46bMMNaqowF90t7gqD17NnzVmnf
 clMsKoYVJHv9s6ysLCo3YSO1GTNmPCT1InRizRPqQ/gkQIRQ8PHg+Y53x48fj2vDXSZha4a0JQjc
 888//x9Y/jJFomYIzXZdUk/M34fXlpOTg3GPoZPqg5gIEB5yftexRYsW3eRKrDLHTIgB0KWXXooX
 Fr3OHDVq1H2YJpgiUTXJYj/XdWFybzbFzWSq8onUfbV44OlrcaqPADEGhk5WnnrqqdvM8RJmSPml
 LV5nYuUD6jCbo24bN278o67v2WeffchsipvJ+J0vdePxeb7/wFeqNcCgyTmTFcDDxk6LFi0abY6X
 EMNNUSwiS1u8zpQE6iWzOSa2bNmyP+j6FixY8KLZFDeTjLnmvPPOw+PzePeBoRPq0wDrZJpIVphp
 pn7xxRd/MMdLiGH1RNrhdSRUV1FRccBsjom98soreDDWq1PGn1lmU1xt+PDhg6X+wKvU4gidWn3w
 ADxmmoTnrazs2rXrPXOsuFsiVAfDHXVd5549exLyJHh6ejoe1cC4x6zTVl/oacL+/fszzLHibolQ
 XW1t7SldJ7y8vHyn2RxXmzdv3jipn/AYOqk+eB14mCZ48EpKSv7HHCuulijVybQIC8NB8CSrTcj3
 YTIzM/EEgAse1ecLD7LsjIdjzLHiaolQHWzfvn2rdL14ENdsirutWbMGbxXVGx4n6ID3lTlW3Myl
 OmSAZnNMDWujut4JEyY8ajbF3dauXYvF8aYFz1YdQAKo2RxTW7JkCT4/5dUtoXqi2RR3W7duHfrB
 L2GJKGzGFZ5LdYBpNsfcXnjhhTRdN5RoNsXdVq9ejfmmPVUImW0GwSsrK6v3XejGWCJVB7NfNiko
 KPjMbIq7rVy58nlpA+Fp1YGNE17QVCGe31BJtOqk/mJdNxzZp9kcd1u4cOFT0gausHCKAGhgAw8J
 L/Wbb76J29PRS5cuDVqWirfq8OSZrj81NXVwNG7sNtSmTJmCSTrHO8IDF6x+Ba1tOpfHvvrqq9fM
 sWJqmAbYj9rFU3WnT58+Zj+gu3jx4glmc0IsLS0NIVzfkIXqCC5wVyHkXfTly5c/bY4VU8MEXOrz
 Oi6eqhN1/WXGjBkIUV798EStrMCg+FatWuGbLgyZDJeABvfu5+lbQryrELglhK+pm+PFzBKtuqys
 rCm6bvjs2bNj9l5CJFZVVYXnRjHeIWQiUSE43kmHezdj9f0872Zsu3btesT6YwGJVN2OHTuW6Lrh
 yDil8yJ+QDcWVlxc/Jm0BeMdQyYiIcEhJ4GHf+xPlLHXHDPqlkjV4S3Xli1b4vFyr258mqqkpORr
 UyRhhpdXpT0Y7xAyqTqCw7AGdz6AxLkedsKdhZh9mTZRqjtx4sQ+PN6g64bn5eV9ZIok1ObNmzdK
 2oOQqVUHJhjS6N6jf4DnzDhlpv97c8yomkt1q1atmmY2x8zwyQ7Xd1bkPN8wRRJqeBGmT58+/Lkc
 RD+ojuD4zCbcexvWN2l5+OGHbzbHjarZqsNDRrF4Gkwb3iTCYrOuFz5r1qz/RNZpiiXUjh8/vkPa
 xJAJASFcQkwEB3HBw74hBNl2qaysDPs+W30sUapzfSTnmWee+VVtbW1Cn5DTlpubO1PaxZBJ1YEH
 gEFYdN8XTfS4l7pz586oPseRCNVt2LAhaAUFjhdV4vHqVn3M8YYsoiB4ABhfMoHXecXLnqyDfKfp
 06f/uzl2oy0RqsNNVvs1MLy2dfTo0V2mSFKYXMDF+LUyaR/mdlp14AFgEBa9zsuVzvkePsmID56Z
 Ohpl8+fPf0GO6XVirFVXXl6+3X5oFyDxipgpkjQmEe5daR9CJhIV9D1Uh3BJcBAW+OC/vt9fQUHs
 5IXOLVu2NFodeBfcfg8glqqTaUfJoEGDbtf1wfH1I1MkqUzGX/yYFBMV9D3CJYQEHoCGnITu/KCA
 Dp3MOjvee++9AyUja9Q3J0V1uEfldWIsVYcXK11fOpLxNmF3x0OZRIivpX1Yy0SiQtWBgQYHYdEj
 +pSHp75Dhw41+P5ePFWHtN/+FAccHz5t7AUYK5OLapy0kYkKVQcGEBJ4QFQY1ui+H9EB2Trqmzx5
 8nBTV70tnqrDZ6p0XfD7779/pEzQk/JTJBLeC/Gjj9JOTg+06ggOotIe0UfjqD7E4U6HDx9ea+qM
 2OKpOhn0/6TrgXfv3v3WkydPJu3PBUhf/Fba6VIdwiXBQVRgA8f/R/y5RlwJiMMdpk6dWu/bRLbq
 8ElEUV3gp1yiacXFxV8m62Kzn0kWX3jBBRfg9o+tOvQ9GBAchjQ6ADo/2cjExVYf530dpZMiHvtc
 qsvOzp5pNkfNUE8yLzb7WYixDn0PBgQHUdHx74g/Ucx5XyB8jhkzZhBeNTb1hzSX6mTsieqvVWKx
 eeTIkUm72OxneLRS2unKMNHn6HsNDkMaHf8OfKZYf3NTqw87QbK4AjBwMnxiyab9tm3bwj7fEg/V
 YbEZ3xPTdcCTabHZZdK2mueeew5vJOl5HebWTFLQ94iABAdR0fHvsB8It8MnDh4Y/1q3bp1aWVkZ
 8jmPeKiuKSw2u8zccNWrKYhsiHBIUtDnNjiIio5/e5/md6kPO0F9DJ84aND4N23atKG4gkx7gkyy
 u32xVl1TWWy2Tfpmx0UXXYQHarmGif6k6pikoO/BACwgKO34W50fxbDVp8Mns09cHZw+/GTz5s3O
 R+Tmzp07XrZ7nRpt1TWVxWbbJMyfUstgfkkKVIe+BwOwgKDABf+F428BeC71ucInxz9OHyDzds2b
 N28vc78s07aAyViX16xZM9wNxi9cBTo2mqoLsdicsMfTI7UVK1Zg5Yd3yXW41EkK+twGRy6EGPEP
 QdnjH+TtjX/9+vW7SsY/76fXFixY8KT8HT/PCYD9o6m6EIvNc02RpLXdu3fPlbbqG63ov1DhUoND
 VMR/ySbsT7DZAO3xDxUHxr+nn356UE1NzeGKioqdojp8hgK/l4PPMPWLlur8FpvxapYpkrRWUlKy
 OiUlhffqkF2i30KFS4IDCwgKToD4ewCen/pcAEONf5dPnz795wsXLnxE/h+NBMBrO3bsOCgaqvNb
 bMYniZN1sZmGr0h16tQJFzPB6XGOS2B2uKTiCE5zwd+D4PmFTxyE4x9XXzj+1Zn/iWMgxsQT2VSP
 nJycqEyUXYvN+A50tKce0TbJLPP69u2LnyZlgoJ+0uMchMDJuA6XBAcW2snFg6fVFw6gHv/qhE9x
 DMQYkLv26dPnegl1jX4h37XYjBdDknmxGSaK2yZTFyRtNjh77dIFDgoDA7DQjr9hWx14LoCUabjw
 iUYFwqc4BuTOubm5/23Oo8EWYrE57q9c18ew9OVQHCIUwOkERY9zDJfocwiHwFyiCpgfQF0QB3OF
 T2f2Kd5eMsKejVWd32IzfjPBFElKKyoqWo4f+Je2+oFzJSj2OEfVEZwG6MGDRQJQh09X9snkJRA+
 d+zYkW7OpUEmY5lzsfmzzz6bbookneGlHLzTiAe2pK1MTnSotMH5JSg2OBcTzzS8cAAZPnHF6PDJ
 5AUNbXfkyJEGf/bJb7EZv7CVrIvN1dXVZfgJVmmnfscAw0gk4OwExQXOFx7MBZAHwA44sB0+qT47
 eWlbWlra4B8KdC02yzwyaRebZVz+1CQmXDlB4qbBIS8IBw596wfOJag6ZsPThV3q8537rV+/Ps2c
 W71s48aN+EF4rMzgVx3h1w0ePPj2qqqqElMkaUxCe9lHH32Ej4ljaoQwifENYz4n4Ho6wOSE4Pwy
 Sxc4zSQieCxsA3SpD43CVYWrKwAwJSWl7alTp7aY84zIDhw4kNWsWbNesj+W1vDf3u3atRtw7Nix
 HaZIUpiE9Ro8INv3r9kk1MYwycQE0YcTcBc4P8URnguc5uGEB3PtoOG51KfDJwAGxr8JEyb0ramp
 iejnSTEnMl81x8oMvIeAvFaAfmqKJNxkvP22sLBw6ahRo/AtTIxtttr0+MaVE4BDdNJZZUPA0cnD
 1+zCNkCqzw6fzD49gJJ49BYFhvyRChnsD9199924vYMsDUtr8G7bt29/xxRJqInSqvbu3Tv/iSee
 uFXaBWiYAnBs02pjmOT4xpUT13SgIeDgYeHBXDvAtfp0+GT2aQP8QYcOHX6cn5//giQcZaY/PJNx
 o3jSpEn4bAWuYnQKPDUrK+tJUyRhVlFRkY8fZxowYADGXg2NIZJjm1abHSZxYeMC1+B0VhkOHI3/
 jggeTO/Anag+HT5dAPUYiPj/Q0AUKCN27949Yc+ePa+sW7dutEzCMWbgCoajUxCGOrRu3bpzRkbG
 QwcPHsyQ0Bu3T+NXVlbuwytt6enpmGMiEUH7cGHZ0BAiOba51KbBoY8aAw7Gv7FsWNMHIjwCRCPs
 8OkCiCsRVyQhIrTgpHHFogMQcuDoEKTXcHQQxpEA0JYtW3Z8++23h+fm5k4uLy//PJowJYstwopI
 dnb2i48++ihWcwCMKkMigouJ4dEPGsc2rTY9vqGP0FcaHPsyEnA0bosIHkzvQIC2+vwA4mRwUoRI
 JSLEECRCDpxANVQCDYIp3mHMmDEDlixZ8tDXX389UVQ8W+ZaHx89ejQHz4cIkAIZRw/CJfTlSTK0
 vaysbK1AWoafTsV79m+99dYDff+aLRIWFUZgqAd1on60heGR0Bgi7bHNFSajAQ7G7fWGx51YcTiA
 OAlchVQhIeIqJUgqUgPVUG2F2jC9MCuOTg8aN40DDJ1/Yxnso2Hh4iAwrTINDe3W0EKpTYfJxoKD
 sUyD4RGghocrSwPECQAiTghXIyHiRG2QGiaBEqqt0HAwqUxCdTm3E5SGhWPawNAGQkNb/aDhfDU0
 W20Exz6E676Fh7P6lPVM70R4NkBbgQRIFRKiBqlhEqhWp63QUDA1UEJ1ObcTFBzH8AOGNqA9aB/a
 irbjHHA+hOY3tkWqNnikVt/yQZWwATZAQnSp0AbpgmkDtRUaCqYNlA4o2vl3lINjHw0Lx9XAtMoI
 TStNQ4uV2myr9366MngkAG2INkjCtIG6FBoJTAIlVD9nGe5jw6LCNDC0qSHQNDi7D+ENsQbtqyuF
 uwDaELUSCdIFMxxQGyaBoqMJ1Ibq5yhDUBpWfYDVB1o0wdEatL/dABugC6IN0gWTbkPVQP1g2kC1
 E679d5bn/hqWDUxD08ASAc3Y977/f56PHXK342sDAAAAAElFTkSuQmCC
 "
        id="image10" />
   </g>
 </svg>
`;
