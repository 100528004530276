import { Component, Input, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { Telemetry } from 'src/app/models/Telemetry';
import { TelemetryMeasurement } from 'src/app/shared/Telemetry-Measurements';
import { TelemetryMeasurementsService } from 'src/app/shared/telemetry-measurements.service';

@Component({
  selector: 'app-telemetry-measure-grid',
  templateUrl: './telemetry-measure-grid.component.html',
  styleUrls: ['./telemetry-measure-grid.component.css'],
})
export class TelemetryMeasureGridComponent implements OnInit {
  @Input() telemetry = {} as Telemetry;

  telemetryData$ = new Observable<TelemetryMeasurement[]>();

  constructor(
    public telemetryMeasurementsService: TelemetryMeasurementsService,
  ) {}

  ngOnInit(): void {
    const telemetryDataArray$: Observable<TelemetryMeasurement>[] = [];

    this.telemetry.Measurements?.forEach((measurement) => {
      measurement.Channels.forEach((channel) => {
        const telemetryDataX$ =
          this.telemetryMeasurementsService.getObjectByKey(
            this.telemetry.MQTT.Id,
            measurement.System.Name,
            measurement.Location.Name,
            measurement.Device.Name,
            channel.Properties.Name,
          );
        telemetryDataArray$.push(telemetryDataX$);
      });
    });

    this.telemetryData$ = combineLatest(telemetryDataArray$);
  }
}
