import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export type ThemeMode = 'light' | 'dark';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private themeMode = new BehaviorSubject<ThemeMode>(this.readThemeMode());
  themeMode$ = this.themeMode.asObservable();

  toggleMode() {
    const themeMode = this.themeMode.value === 'light' ? 'dark' : 'light';

    this.writeThemeMode(themeMode);

    this.themeMode.next(themeMode);
  }

  getThemeMode() {
    return this.themeMode.value;
  }

  setDarkThemeMode() {
    this.writeThemeMode('dark');
    this.themeMode.next('dark');
  }

  setLightThemeMode() {
    this.writeThemeMode('light');
    this.themeMode.next('light');
  }

  setThemeMode(mode: ThemeMode) {
    this.themeMode.next(mode);
  }

  readThemeMode(): ThemeMode {
    const value = (localStorage.getItem('themeMode') as ThemeMode) || 'light';

    return value;
  }

  writeThemeMode(mode: string) {
    localStorage.setItem('themeMode', mode);
  }
}
