<div class="login-wrapper">
  <form class="login">
    <section class="title">
      <h3 class="welcome">
        <img src="assets/logo-c.png" height="24" alt="Vrielmann Connect" />
        Vrielmann Connect
        <!-- &nbsp;<cds-icon shape="disconnect"></cds-icon> -->
      </h3>
      Herzlich Willkommen
      <h5 class="hint">
        <div cds-text="code">
          <cds-icon shape="disconnect" size="md"></cds-icon>
          404 Not Found
        </div>
      </h5>
    </section>

    <div class="login-group">
      <button type="button" class="btn btn-primary" (click)="onSubmit()">
        <cds-icon shape="home"></cds-icon> Startseite
      </button>
    </div>
  </form>
</div>
