import { Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { Observable, combineLatest } from 'rxjs';
import { Telemetry } from 'src/app/models/Telemetry';
import { TelemetryMeasurement } from 'src/app/shared/Telemetry-Measurements';
import { TelemetryMeasurementsService } from 'src/app/shared/telemetry-measurements.service';

@Component({
  selector: 'app-telemetry-measure-chart',
  templateUrl: './telemetry-measure-chart.component.html',
  styleUrls: ['./telemetry-measure-chart.component.css'],
})
export class TelemetryMeasureChartComponent implements OnInit {
  @Input() telemetry = {} as Telemetry;

  public mergeOption: any;

  public initOpts = {
    locale: 'DE',
  };

  public chartOption: EChartsOption = {
    grid: {
      left: '1%',
      right: '1%',
      containLabel: true,
    },

    xAxis: {
      type: 'time',
      splitLine: {
        show: false,
      },
    },
    yAxis: {
      type: 'value',
      splitLine: {
        show: true,
      },
    },

    tooltip: {
      show: true,
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985',
        },
      },
    },
    dataZoom: [
      {
        type: 'inside',
      },
      {
        // For slider zoom
      },
    ],
    toolbox: {
      show: true,
      feature: {
        dataZoom: {
          yAxisIndex: 'none',
        },
        // magicType: { type: ['line', 'bar', 'stack'] },
        restore: {},
        saveAsImage: {},
      },
    },

    legend: {},

    series: [],
  };

  myObserver = {
    next: (data: TelemetryMeasurement[]) => this.onNext(data),
    error: (error: any) => this.onError(error),
    complete: () => this.onComplete(),
  };

  telemetryData$ = new Observable<TelemetryMeasurement[]>();
  service = new TelemetryMeasurementsService();

  constructor(
    public telemetryMeasurementsService: TelemetryMeasurementsService,
  ) {}

  dataUL1: number[][] = [];
  dataUL2: number[][] = [];
  meinDictionary: { [key: string]: any[][] } = {};

  ngOnInit(): void {
    const telemetryDataArray$: Observable<TelemetryMeasurement>[] = [];

    this.telemetry.Measurements?.forEach((measurement) => {
      measurement.Channels.forEach((channel) => {
        const telemetryDataX$ =
          this.telemetryMeasurementsService.getObjectByKey(
            this.telemetry.MQTT.Id,
            measurement.System.Name,
            measurement.Location.Name,
            measurement.Device.Name,
            channel.Properties.Name,
          );

        channel.Signals.forEach((signal) => {
          const key = `${this.telemetry.MQTT.Id}_${measurement.System.Name}_${measurement.Location.Name}_${measurement.Device.Name}_${channel.Properties.Name}_${signal.Name}`;

          this.meinDictionary[key] = [];
        });

        telemetryDataX$.subscribe((data) => {
          data.Signals.forEach((s) => {
            const k = `${data.Client}_${data.System}_${data.Location}_${data.Device}_${data.Channel}_${s.Name}`;

            const timeValueExists = this.meinDictionary[k].some(
              (entry) => entry[0] === s.Time,
            );
            if (!timeValueExists) {
              this.meinDictionary[k].push([s.Time, s.Value]);
            }
          });

          const series = [];

          for (const key in this.meinDictionary) {
            series.push({ data: this.meinDictionary[key] });
          }

          this.mergeOption = {
            series,
          };
        });

        telemetryDataArray$.push(telemetryDataX$);
      });
    });

    this.telemetryData$ = combineLatest(telemetryDataArray$);

    // this.telemetryData$.subscribe(this.myObserver);
    const series = [] as any[];

    this.telemetry.Measurements?.forEach((measurement) => {
      measurement.Channels.forEach((channel) => {
        channel.Signals.forEach((signal) => {
          const s = {
            name: signal.Name,
            type: 'line',
            lineStyle: {
              width: 1,
            },
            smooth: false,
            symbol: 'none',
            animation: false,
          };
          series.push(s);
        });
      });
    });

    this.chartOption.series = series;
  }

  onNext(telemetryMeasurement: TelemetryMeasurement[]) {
    // console.log(telemetryMeasurement);
  }

  onError(error: any) {
    // console.log(error);
  }

  onComplete() {
    console.log('complete');
  }
}
