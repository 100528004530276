import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromFactory from './factory.reducer';
import { Factory } from 'src/app/models/Factory';

const { selectAll } = fromFactory.adapter.getSelectors();

export const selectFactoryState = createFeatureSelector<fromFactory.State>(
  fromFactory.factoryFeatureKey,
);

export const selectFactories = createSelector(selectFactoryState, selectAll);

export const selectFactoriesLoading = createSelector(
  selectFactoryState,
  (state) => state.loading,
);

export const selectFactoriesError = createSelector(
  selectFactoryState,
  (state) => state.error,
);

export const selectFactory = (uid: string) =>
  createSelector(
    selectFactories,
    (factories) =>
      factories.find((factory) => factory.Uid === uid) || ({} as Factory),
  );

export const selectCombinedFactories = createSelector(
  selectFactories,
  selectFactoriesLoading,
  selectFactoriesError,
  (factories, loading, error) => ({
    factories,
    loading,
    error,
  }),
);

export const selectCombinedFactory = (uid: string) =>
  createSelector(
    selectFactory(uid),
    selectFactoriesLoading,
    selectFactoriesError,
    (factory, loading, error) => ({
      factory,
      loading,
      error,
    }),
  );
