import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  selectTelemetries,
  selectTelemetriesError,
  selectTelemetriesLoading,
} from '../+store/telemetry.selectors';
import { TelemetryActions } from '../+store/telemetry.actions';

@Component({
  selector: 'app-telemetries-presets',
  templateUrl: './telemetries-presets.component.html',
  styleUrls: ['./telemetries-presets.component.css'],
})
export class TelemetriesPresetsComponent implements OnInit {
  telemetries$ = this.store.select(selectTelemetries);
  loading$ = this.store.select(selectTelemetriesLoading);
  error$ = this.store.select(selectTelemetriesError);

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(TelemetryActions.loadTelemetries());
  }
}
