import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Contract } from 'src/app/models/Contract';

import { selectProfil } from '../../users/+profil/profil.selectors';
import { ProfilActions } from '../../users/+profil/profil.actions';

import { selectCombinedContract } from '../+store/contract.selectors';
import { ContractActions } from '../+store/contract.actions';

@Component({
  selector: 'app-contracts-detail',
  templateUrl: './contracts-detail.component.html',
  styleUrls: ['./contracts-detail.component.css'],
})
export class ContractsDetailComponent implements OnInit {
  profil$ = this.store.select(selectProfil);

  combinedContract$: Observable<{
    contract: Contract;
    loading: boolean;
    error: HttpErrorResponse | null;
  }> = new Observable();

  constructor(
    private store: Store,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.store.dispatch(ProfilActions.loadProfil());

    this.route.params.subscribe((params) => {
      const uid = params['uid'];

      this.profil$.subscribe((profil) => {
        if (profil?._id) {
          this.store.dispatch(ContractActions.loadContract({ uid: uid }));
        }
      });

      this.combinedContract$ = this.store.select(selectCombinedContract(uid));
    });
  }
}
