import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Factory } from 'src/app/models/Factory';

@Component({
  selector: 'app-factories-list-grid-card',
  templateUrl: './factories-list-grid-card.component.html',
  styleUrls: ['./factories-list-grid-card.component.css'],
})
export class FactoriesListGridCardComponent {
  @Input() factories = [] as Factory[];
  @Input() loading = false;
  @Input() error: HttpErrorResponse | null = null;

  @Input() extern = false;
}
