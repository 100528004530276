import { Component } from '@angular/core';

@Component({
  selector: 'app-impressum-card',
  templateUrl: './impressum-card.component.html',
  styleUrls: ['./impressum-card.component.css']
})
export class ImpressumCardComponent {

}
