<form clrForm [formGroup]="formFilter">
  <!-- <clr-input-container id="inputContainerInput"> -->
  <input
    style="width: 100%"
    clrInput
    [formControlName]="'filter'"
    (ngModelChange)="inputChanged($event)"
  />
  <!-- <input style="width: 100%" clrInput (change)="inputChanged($event)" /> -->
  <!-- </clr-input-container> -->
</form>
