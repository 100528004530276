import { AbstractControl, FormGroup, ValidatorFn } from '@angular/forms';

export class CustomValidators {
  static match(controlName: string, checkControlName: string): ValidatorFn {
    return (controls: AbstractControl) => {
      const control = controls.get(controlName);
      const checkControl = controls.get(checkControlName);

      if (checkControl?.errors && !checkControl.errors['matching']) {
        return null;
      }

      if (control?.value !== checkControl?.value) {
        controls.get(checkControlName)?.setErrors({ matching: true });
        return { matching: true };
      } else {
        return null;
      }
    };
  }

  static unmatch(
    usernameControlName: string,
    passwordControlName: string,
  ): ValidatorFn {
    return (control: AbstractControl) => {
      const formGroup = control as FormGroup;
      const username = formGroup.controls[usernameControlName];
      const password = formGroup.controls[passwordControlName];

      if (
        username &&
        password &&
        username.value.trim().toLowerCase() ===
          password.value.trim().toLowerCase()
      ) {
        password.setErrors({ unmatching: true });
        return { unmatching: true };
      } else {
        if (password?.errors) {
          delete password.errors['unmatching'];
          if (!Object.keys(password.errors).length) {
            password.setErrors(null);
          }
        }
        return null;
      }
    };
  }

  static complex(): ValidatorFn {
    return (control: AbstractControl) => {
      const value: string = control.value || '';

      // Kleinbuchstaben
      const hasLowerCase = /[a-z]/.test(value);

      // Großbuchstaben
      const hasUpperCase = /[A-Z]/.test(value);

      // Zahlen
      const hasNumber = /[0-9]/.test(value);

      // Sonderzeichen
      const hasSpecial = /[!@#$%^&*(),.?":{}|<>]/.test(value);

      const typesCount = [
        hasLowerCase,
        hasUpperCase,
        hasNumber,
        hasSpecial,
      ].filter(Boolean).length;

      if (typesCount < 4) {
        return { complexity: true };
      }

      return null;
    };
  }
}
