import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  AfterViewInit,
  OnChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ClrLoadingState } from '@clr/angular';
import { Item } from 'src/app/models/Item';
import { User } from 'src/app/models/User';
import {
  selectProfil,
  selectProfilRoles,
} from '../../users/+profil/profil.selectors';

@Component({
  selector: 'app-risks-tab-edit-template',
  templateUrl: './risks-tab-edit-template.component.html',
  styleUrls: ['./risks-tab-edit-template.component.css'],
})
export class RiskTabEditTemplateComponent
  implements OnInit, AfterViewInit, OnChanges
{
  @Input() fixedPropLimit = 0;
  @Input() tabFor$ = '';
  @Input() itmArray$ = [] as Item[];

  @Output() updated = new EventEmitter();
  @Output() changed = new EventEmitter();
  @Output() init = new EventEmitter();

  title = '';
  message = '';
  showAuswahl = false;
  onAdd = false;
  onEdit = false;
  onDelete = false;
  onHelper = false;
  onMoving = false;
  onInfo = false;
  movingIndex = 0;
  tempselection = undefined as unknown as Item[];
  itemToChange = undefined as unknown as Item;
  changingItem = undefined as unknown as Item;

  submitTabState: ClrLoadingState = ClrLoadingState.DEFAULT;
  onEditSubmitState: ClrLoadingState = ClrLoadingState.DEFAULT;
  onAddSubmitState: ClrLoadingState = ClrLoadingState.DEFAULT;
  onDeleteSubmitState: ClrLoadingState = ClrLoadingState.DEFAULT;
  user = {} as User;

  isService$ = this.store.select(selectProfilRoles(['service']));
  isManager$ = this.store.select(selectProfilRoles(['manager']));

  constructor(private store: Store) {
    // this.cabinet$.subscribe((cabinet) => {
    //   console.log('constructor() cabinet:', cabinet);
    //   this.cabinet = cabinet;
    //   this.init.emit(this);
    // });
  }

  refresh(itemsArray: Item[]) {
    console.log('refresh()');
    this.itmArray$ = itemsArray;
    this.ngOnInit();
  }

  ngOnInit() {
    this.init.emit(this);
    const profil = this.store.select(selectProfil);
    profil.subscribe((usr) => {
      this.user = usr;
    });
  }

  ngAfterViewInit() {
    console.log('ngAfterViewInit()');
  }

  ngOnChanges(changes: any): void {
    console.log(changes);
    // try {
    //   if (changes['itmArray$']) {
    //     console.log(
    //       `ngOnChanges() =>'itmArray$' count:${this.itmArray$?.length}`,
    //     );
    //   }
    // } catch (err) {
    //   console.error('FEHLER : ', err);
    // }
  }

  onSubmitTab() {
    this.submitTabState = ClrLoadingState.LOADING;
    // const tab = this.formDynamisch.getRawValue();
    // const arrayItem = Object.entries(tab).map(
    //   ([Title, Value], index) =>
    //     ({
    //       ItemId: this.itmArray$.find((x) => x.ItemIndex == index)?._id,
    //       Value: Value,
    //     }) as { ItemId: string; Value: object },
    // );
    this.submitTabState = ClrLoadingState.SUCCESS;
  }

  onSaveClick(id: number) {
    console.log(`save for id: ${id}`);
  }

  indexToChangeClicked(column: string, itm: Item) {
    console.log(`indexToChangeClicked('${itm.Title}')`, event);
    this.itemToChange = itm;
    switch (column) {
      case 'index':
        this.onMoving = true;
        break;
      case 'info':
        this.onInfo = true;
        break;
    }
  }

  moveUp() {
    if (this.movingIndex + 1 > this.itmArray$.length - 1) {
      this.movingIndex = 0;
    } else {
      this.movingIndex += 1;
    }
    console.log('moveUp()', this.movingIndex);
  }

  moveDown() {
    if (this.movingIndex - 1 < 0) {
      this.movingIndex = this.itmArray$.length - 1;
    } else {
      this.movingIndex -= 1;
    }
    console.log('moveUp()', this.movingIndex);
  }

  txtChange(event: any) {
    console.log(event);
  }

  saveMovingIndex() {
    if (this.movingIndex >= 0 && this.movingIndex < this.itmArray$.length) {
      const changeItm = this.itmArray$.find(
        (i) => i.ItemIndex === this.movingIndex,
      );
      if (changeItm) {
        changeItm.ItemIndex = this.itemToChange.ItemIndex;
        this.itemToChange.ItemIndex = this.movingIndex;
      }
    }
    this.onMoving = false;
  }

  saveInfo() {
    this.onInfo = false;
  }

  onAddFieldClick() {
    let newItemIndex = 0;
    try {
      let maxResultIndex = 0;
      maxResultIndex = Math.max(...this.itmArray$.map((o) => o.ItemIndex));
      newItemIndex = maxResultIndex == 0 ? 0 : maxResultIndex + 1;
    } catch (err) {
      console.log(err);
    }
    this.onAdd = true;
    return false;
  }

  deleteAuswahlByIndex(event: any, index: number) {
    // this.tempAuswahlElements.splice(index, 1);
    event.preventDefault();
    return false;
  }

  onAddAuswahlElementClick(event: any) {
    if (event.key == 'Enter') {
      event.preventDefault();
      // const form = this.formItem.getRawValue();
      // const value = form.Addfield;
      // // this.tempAuswahlElements.push(value);
      // this.formItem.patchValue({
      //   Addfield: '',
      // });
      return false;
    }
    return true;
  }

  onFieldTypeChanged(event: any, itm: Item) {
    console.log(`ItemId: ${itm._id}`, event);
    // const ctl = this.formDynamisch.get(itmId);
    // // const form = this.formItem.getRawValue();
    // const itm = ctl?.get('ItemType');
    // console.log(ctl);
    // console.log(itm);
    // const itm2 = ctl['ItemType'];
    if (itm?.ItemType == 'Strings') {
      this.showAuswahl = true;
    } else {
      this.showAuswahl = false;
    }
  }

  onAddFieldSubmit() {
    // Add-Dialog wird beendet
    this.onAddSubmitState = ClrLoadingState.LOADING;
    try {
      // const form = this.formItem.getRawValue();
      // // let strings = [] as string[];
      // // if (form.Type == 'Strings') strings = this.tempAuswahlElements;
      // const selected = () => {
      //   switch (form.Type) {
      //     case 'String':
      //       return 'String';
      //     case 'Number':
      //       return 'Number';
      //     case 'Strings':
      //       // strings = this.tempAuswahlElements;
      //       return 'Strings';
      //     case 'Date':
      //       return 'Date';
      //     case 'Boolean':
      //       return 'Boolean';
      //     default:
      //       return undefined;
      //   }
      // };
      // const item: LowItem = {
      //   IsPrimary: form.IsPrimary,
      //   ItemIndex: form.ItemIndex,
      //   Title: form.Title,
      //   Info: form.Info === null ? undefined : form.Info,
      //   ItemType: selected(),
      //   // Selection: strings,
      // };
      // // this.store.dispatch(
      // //   TemplateActions.postTemplateItem({
      // //     tab: this.tabFor$,
      // //     item: item,
      // //     // templateId: this.template._id,
      // //     // templateId: this.pa!,
      // //   }),
      // // );
      this.onAddSubmitState = ClrLoadingState.SUCCESS;
      this.onAdd = false;
      this.updated.emit(this);
    } catch (err) {
      console.log('onAddFieldSubmit() ERROR: ', err);
    }
  }

  onEditFieldSubmit() {
    // Edit-Dialog wird beendet
    // const form = this.formItem.getRawValue();
    // // let strings = [] as string[];
    // // if (form.Type == 'Strings') strings = this.tempAuswahlElements;
    // const selected = () => {
    //   switch (form.Type) {
    //     case 'String':
    //       return 'String';
    //     case 'Number':
    //       return 'Number';
    //     case 'Strings':
    //       // strings = this.tempAuswahlElements;
    //       return 'Strings';
    //     case 'Date':
    //       return 'Date';
    //     case 'Boolean':
    //       return 'Boolean';
    //     default:
    //       return undefined;
    //   }
    // };
    // const item: Item = {
    //   _id: form._id,
    //   IsPrimary: form.IsPrimary,
    //   ItemIndex: form.ItemIndex,
    //   Title: form.Title,
    //   Info: form.Info === null ? undefined : form.Info,
    //   ItemType: selected(),
    //   // Selection: strings,
    // };
    // console.log(`try to update item[${item?._id}] Titel:${item?.Title}`, item);
    // this.store.dispatch(
    //   TemplateActions.editTemplateItem({
    //     tab: this.tabFor$,
    //     item: item,
    //   }),
    // );
    this.onEdit = false;
    this.updated.emit(this);
    this.ngOnInit();
    return false;
  }

  onDeleteClick(id: string) {
    const item =
      this.itmArray$.length > 0
        ? // this.itmArray$[0]
          (this.itmArray$.find((x) => x._id == id) as Item)
        : {
            IsPrimary: false,
            Title: '',
            Info: '',
            ItemIndex: 0,
            ItemType: '',
            // Selection: this.tempAuswahlElements,
            _id: id,
          };
    if (item.ItemType == 'Strings') {
      this.showAuswahl = true;
      // this.tempAuswahlElements = item.Selection as string[];
    } else {
      this.showAuswahl = false;
    }
    // const typeValue = item.ItemType as string;
    // this.formItem.get('Type')?.patchValue(typeValue);
    // this.formItem.patchValue({
    //   IsPrimary: item.IsPrimary,
    //   Title: item.Title,
    //   Info: item.Info,
    //   ItemIndex: item.ItemIndex,
    //   Type: item.ItemType,
    //   _id: item._id,
    // });
    // this.formItem.get('Type')?.disable();
    // this.formItem.get('ItemIndex')?.disable();
    // this.formItem.get('IsPrimary')?.disable();
    // this.formItem.get('Title')?.disable();
    // this.formItem.get('Info')?.disable();
    this.onDelete = true;
    return false;
  }

  dateChanged(eventDate: any) {
    const date = eventDate?.srcElement?.value
      ? new Date(eventDate.srcElement.value)
      : new Date();
    console.log('date input changed:', date);
  }

  onDeleteSubmit() {
    console.log('try to delete `Item`');
    // const form = this.formItem.getRawValue();
    // const item: Item = { _id: form._id } as Item;
    // this.store.dispatch(
    //   TemplateActions.deleteTemplateItem({
    //     tab: this.tabFor$,
    //     item,
    //   }),
    // );
    this.onDelete = false;
    this.updated.emit(this);
    return false;
  }

  onEditClick(id: string) {
    const item =
      this.itmArray$.length > 0
        ? (this.itmArray$.find((x) => x._id == id) as Item)
        : {
            IsPrimary: false,
            Title: '',
            Info: '',
            ItemIndex: 0,
            ItemType: '',
            // Selection: this.tempAuswahlElements,
            _id: id,
          };
    if (item.ItemType == 'Strings') {
      this.showAuswahl = true;
      // this.tempAuswahlElements = item.Selection as string[];
    } else {
      this.showAuswahl = false;
    }
    // const typeValue = item.ItemType as string;
    // this.formItem.get('Type')?.patchValue(typeValue);
    // this.formItem.get('Type')?.enable();
    // this.formItem.get('IsPrimary')?.enable();
    // this.formItem.get('Title')?.enable();
    // this.formItem.get('Info')?.enable();
    // this.formItem.get('ItemIndex')?.disable();
    // this.formItem.patchValue({
    //   IsPrimary: item.IsPrimary,
    //   Title: item.Title,
    //   Info: item.Info,
    //   ItemIndex: item.ItemIndex,
    //   Type: item.ItemType,
    //   _id: item._id,
    // });
    this.onEdit = true;
    // event.preventDefault();
    return false;
  }

  onHelperClick(title: string, message: string | undefined) {
    this.title = title || '';
    this.message = message || '';

    this.onHelper = true;
    // event.preventDefault();
    return false;
  }
}
