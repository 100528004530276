import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromProfil from './profil.reducer';

export const selectProfilState = createFeatureSelector<fromProfil.State>(
  fromProfil.profilFeatureKey,
);

export const selectUpdated = createSelector(
  selectProfilState,
  (state) => state.updated,
);

export const selectProfil = createSelector(
  selectProfilState,
  (state) => state.profil,
);

export const selectProfilLoading = createSelector(
  selectProfilState,
  (state) => state.loading,
);

export const selectProfilError = createSelector(
  selectProfilState,
  (state) => state.error,
);

export const selectCombinedProfil = createSelector(
  selectProfil,
  selectProfilLoading,
  selectProfilError,
  (profil, loading, error) => ({
    profil,
    loading,
    error,
  }),
);

export const selectProfilRoles = (roles: string[]) =>
  createSelector(selectProfil, (profil) => {
    if (!profil._id) {
      return false;
    }
    return profil.Roles.some((role) => roles.includes(role.Symbol));
  });
