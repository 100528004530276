import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxFilesizeModule } from 'ngx-filesize';

import { DocumentsRoutingModule } from './documents-routing.module';
import { DocumentsListGridComponent } from './documents-list-grid/documents-list-grid.component';
import { DocumentsListGridCardComponent } from './documents-list-grid-card/documents-list-grid-card.component';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromDocument from './+store/document.reducer';
import { DocumentEffects } from './+store/document.effects';

import '@cds/core/icon/register.js';

import {
  ClarityIcons,
  tagIcon,
  fileGroupIcon,
  factoryIcon,
  organizationIcon,
  calendarIcon,
} from '@cds/core/icon';

ClarityIcons.addIcons(
  tagIcon,
  fileGroupIcon,
  factoryIcon,
  organizationIcon,
  calendarIcon,
);

@NgModule({
  declarations: [DocumentsListGridComponent, DocumentsListGridCardComponent],
  imports: [
    DocumentsRoutingModule,
    CommonModule,
    ClarityModule,
    StoreModule.forFeature(
      fromDocument.documentFeatureKey,
      fromDocument.reducer,
    ),
    EffectsModule.forFeature([DocumentEffects]),
    NgxExtendedPdfViewerModule,
    NgxFilesizeModule,
  ],
  exports: [DocumentsListGridComponent, DocumentsListGridCardComponent],
})
export class DocumentsModule {}
