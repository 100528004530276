import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Factory } from '../models/Factory';

@Injectable({
  providedIn: 'root',
})
export class FactoryStoreService {
  private apiUrl = environment.exportApi;

  constructor(private http: HttpClient) {}

  Paging(page: number, limit: number): Observable<Factory[]> {
    const headers = new HttpHeaders({
      'X-Route-Interceptor': 'true',
      'X-Route': 'factories',
      'X-Query': `?page=${page}&limit=${limit}`,
    });

    return this.http.get<Factory[]>(this.apiUrl, { headers });
  }

  getAll(): Observable<Factory[]> {
    const headers = new HttpHeaders({
      'X-Route-Interceptor': 'true',
      'X-Route': 'factories',
    });

    return this.http.get<Factory[]>(this.apiUrl, { headers });
  }

  getSingle(uid: string): Observable<Factory> {
    const headers = new HttpHeaders({
      'X-Route-Interceptor': 'true',
      'X-Route': 'factories',
      'X-Param': `/` + uid,
    });

    return this.http.get<Factory>(this.apiUrl, { headers });
  }
}
