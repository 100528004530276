import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs';
import { selectProfilRoles } from '../views/users/+profil/profil.selectors';

export const CanNavigateToManagerGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);

  const store = inject(Store);

  const isManager$ = store.select(selectProfilRoles(['manager']));

  return isManager$.pipe(
    take(1),
    map((isManager) => {
      if (isManager) {
        return true;
      } else {
        return router.createUrlTree(['signin'], {
          queryParams: { returnUrl: state.url },
        });
      }
    }),
  );
};
