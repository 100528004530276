<!-- clr-required-mark -->
<clr-modal
  [clrModalOpen]="true"
  [clrModalClosable]="true"
  (clrModalOpenChange)="openChange($event)"
>
  <h3 class="modal-title">
    <cds-icon shape="host" size="md"></cds-icon> Excel-Import
  </h3>
  <div class="modal-body">
    <!-- <ng-container *ngIf="template"></ng-container> -->
    <!-- <form
      clrForm
      [formGroup]="formDynamisch"
      (ngSubmit)="onSubmitAnlage()"
      id="dynamicForm"
    >
      <ng-container *ngIf="template$ | async as template">
        <clr-input-container *ngFor="let anlageItem of template.Anlage">
          <label class="clr-col-12 clr-col-md-4" for="dynamicForm">{{
            anlageItem.Title
          }}</label>
          <input
            class="clr-col-12 clr-col-md-8"
            clrInput
            [formControlName]="anlageItem._id!"
          />
        </clr-input-container>
        <p></p>
      </ng-container>
      <p></p>
      <button type="submit" class="btn btn-primary">speichern</button>
    </form> -->

    <!-- <clr-tab> -->
    <!-- <button clrTabLink>Fotos</button> -->
    <!-- <clr-tab-content *clrIfActive> -->

    <form
      clrForm
      [formGroup]="formMobile"
      (ngSubmit)="onSubmitExcelFile()"
      id="formFotos"
    >
      <div formGroupName="ExcelFile">
        <clr-control-container id="ctlFile">
          <label for="ctlFile">Datei</label>
          <input
            clrControl
            type="file"
            #fileUpload
            (change)="onExcelImportSelected($event)"
            name="thumbnail"
            formControlName="Path"
          />
        </clr-control-container>

        <clr-input-container id="ctlName">
          <label class="clr-required-mark" for="ctlName">Name</label>
          <input type="text" clrInput formControlName="Name" required />
          <clr-control-helper>Dateiname</clr-control-helper>
          <clr-control-error
            >Dieses Feld muss ausgefüllt werden!</clr-control-error
          >
        </clr-input-container>

        <clr-control-container id="ctlProgress">
          <label for="ctlProgress">Progress</label>
          <div class="progress-block">
            <clr-progress-bar
              id="demoProgressBar"
              [clrValue]="uploadProgress"
              clrMax="100"
            ></clr-progress-bar>
            <span>&nbsp; {{ uploadProgress }} %</span>
          </div>
        </clr-control-container>

        <p></p>
      </div>

      <button class="btn btn-primary" type="submit">senden</button>
      <button
        [disabled]="!uploadProgress"
        class="btn btn-outline"
        type="button"
        (click)="onCancelUpload()"
      >
        abbrechen
      </button>
    </form>

    <!-- <p></p> -->

    <!-- Bilder-FormArray -->
    <!-- <form clrForm [formGroup]="formMobile">
      <div formArrayName="Images">
        <div
          *ngFor="let imageControl of imagesArray.controls; let i = index"
          [formGroupName]="i"
        >
          <clr-control-container id="ctlFormMobileName">
            <label for="ctlFormMobileName">Name</label>
            <input clrControl formControlName="Name" />
            &nbsp;<button
              class="btn btn-icon btn-primary"
              title="speichern"
              (click)="onUpdateFile(imageControl)"
            >
              <cds-icon shape="sync"></cds-icon>
            </button>
            &nbsp;<button
              class="btn btn-icon btn-danger"
              title="löschen"
              (click)="onDeleteFile(imageControl)"
            >
              <cds-icon shape="eraser"></cds-icon>
            </button>
          </clr-control-container>
        </div>
      </div>
    </form> -->

    <!-- <p></p> -->

    <!-- <form clrForm [formGroup]="formMobile">
      <div formArrayName="Images">
        <table class="table">
          <thead>
            <tr>
              <th class="left">Name</th>
              <th>Vorschau</th>
              <th>Optionen</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="let imageControl of imagesArray.controls; let i = index"
              [formGroupName]="i"
            >
              <td class="left">
                <input clrControl formControlName="Name" />
              </td>
              <td>-</td>
              <td>
              -->
    <!-- <clr-control-container> -->
    <!-- &nbsp;<button
                  class="btn btn-icon btn-primary"
                  title="speichern"
                  (click)="onUpdateFile(imageControl)"
                >
                  <cds-icon shape="sync"></cds-icon>
                </button>
                &nbsp;<button
                  class="btn btn-icon btn-danger"
                  title="löschen"
                  (click)="onDeleteFile(imageControl)"
                >
                  <cds-icon shape="eraser"></cds-icon>
                </button> -->
    <!-- </clr-control-container> -->
    <!--      </td>
            </tr>
          </tbody>
        </table>
      </div>
    </form> -->

    <!-- <p></p> -->
    <!-- </clr-tab-content> -->
    <!-- </ng-container>
      <p></p>
      <button type="submit" class="btn btn-primary">speichern</button>
    </form> -->
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline" (click)="onCancel()">
      Cancel
    </button> -->
    <!-- <button type="button" class="btn btn-primary" (click)="onOkay()">Ok</button> -->
  </div>
</clr-modal>
