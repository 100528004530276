import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromTemplate from './template.reducer';
import { Template } from 'src/app/models/Template';

const { selectAll } = fromTemplate.adapter.getSelectors();

export const selectTemplateState = createFeatureSelector<fromTemplate.State>(
  fromTemplate.templateFeatureKey,
);

export const selectTemplates = createSelector(selectTemplateState, selectAll);

export const selectTemplatesLoading = createSelector(
  selectTemplateState,
  (state) => state.loading,
);

export const selectTemplatesError = createSelector(
  selectTemplateState,
  (state) => state.error,
);

// export const selectTemplate = (id: string) => {
//   console.log(`selectTemplate(id: ${id})`);
//   return createSelector(selectTemplates, (templates) => {
//     const res =
//       templates.find((template) => template._id == id) || ({} as Template);
//     templates.forEach((x) => console.log(`template.uid: ${x.Uid}`));
//     console.log(`selectTemplate result: ${res?.Uid}`, res);
//     return res;
//   });
// };
export const selectTemplate = (id: string) =>
  createSelector(
    selectTemplates,
    (templates) =>
      templates.find(
        (template) =>
          template._id?.trim().toLocaleLowerCase() ==
          id?.trim().toLocaleLowerCase(),
      ) || ({} as Template),
  );

export const selectTemplateUid = (uid: string) => {
  // console.log(`selectTemplateUid(uid: ${uid})`);
  return createSelector(selectTemplates, (templates) => {
    const res = templates.find(
      (template) =>
        template.Uid?.trim().toLocaleLowerCase() ==
        uid?.trim().toLocaleLowerCase(),
    ) as Template;
    // templates.forEach((x) => console.log(`template.uid: ${x.Uid}`));
    console.log(
      `selectTemplateUid(uid: ${uid}) count of temps:${templates.length} result: ${res?.Uid}`,
      res,
    );
    return res;
  });
};
// export const selectTemplateUid = (uid: string) =>
//   createSelector(
//     selectTemplates,
//     (templates) =>
//       templates.find((template) => template.Uid == uid) as Template,
//   );

// export const selectTemplatesUid = (uid: string) => {
//   console.log(`selectTemplate(uid: ${uid})`);
//   return createSelector(selectTemplates, (templates) => {
//     const res =
//       templates.filter((template) => template.Uid == uid) || ({} as Template);
//     templates.forEach((x) => console.log(`template.uid: ${x.Uid}`));
//     console.log(`selectTemplate result count: ${res?.length}`);
//     return res;
//   });
// };
export const selectTemplatesUid = (uid: string) =>
  createSelector(
    selectTemplates,
    (templates) =>
      templates.filter((template) => template?.Uid?.trim() == uid?.trim()) ||
      ({} as Template),
  );
