<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <h3 class="card-header">
        Fernwirktechnik
        <span style="float: right">
          <a href="javascript:history.back()">
            <cds-icon
              shape="circle-arrow"
              direction="left"
              size="md"
            ></cds-icon>
          </a>
        </span>
      </h3>
    </div>
  </div>
</div>

<ng-container *ngIf="telemetries$ | async as telemetries">
  <div class="clr-row" *ngFor="let telemetry of telemetries">
    <div class="clr-col-12">
      <app-telemetry-presets-table
        [client]="telemetry.MQTT.Id"
      ></app-telemetry-presets-table>
    </div>
  </div>
</ng-container>
