import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FactoriesListComponent } from './factories-list/factories-list.component';
import { FactoriesDetailComponent } from './factories-detail/factories-detail.component';

const routes: Routes = [
  { path: '', component: FactoriesListComponent },
  { path: ':uid', component: FactoriesDetailComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FactoriesRoutingModule {}
