import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Station } from 'src/app/models/Station';

import * as CATEGORIES from 'src/app/constants/categories';

@Component({
  selector: 'app-stations-detail-item',
  templateUrl: './stations-detail-item.component.html',
  styleUrls: ['./stations-detail-item.component.css'],
})
export class StationsDetailItemComponent implements OnInit, OnChanges {
  @Input() station = {} as Station;
  @Input() loading = false;
  @Input() error: HttpErrorResponse | null = null;

  stromlaufplan = false;
  STROMLAUFPLAN = CATEGORIES.STROMLAUFPLAN;
  PROTOKOLL = CATEGORIES.PROTOKOLL;
  THERMOGRAFIE = CATEGORIES.THERMOGRAFIE;

  ngOnInit(): void {
    this.updateItems();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateItems();
  }

  updateItems() {
    this.stromlaufplan =
      this.station?.Documents?.filter((d) =>
        d.Categories.some((c) => c.Uid === CATEGORIES.STROMLAUFPLAN),
      ).length > 0;
  }
}
