import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';

import { InfoRoutingModule } from './info-routing.module';

import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { DatenschutzCardComponent } from './datenschutz-card/datenschutz-card.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { ImpressumCardComponent } from './impressum-card/impressum-card.component';

@NgModule({
  declarations: [
    DatenschutzCardComponent,
    DatenschutzComponent,
    ImpressumComponent,
    ImpressumCardComponent,
  ],
  imports: [CommonModule, ClarityModule, InfoRoutingModule],
  exports: [
    DatenschutzComponent,
    DatenschutzCardComponent,
    ImpressumComponent,
    ImpressumCardComponent,
  ],
})
export class InfoModule {}
