import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TelemetiesListComponent } from './telemeties-list/telemeties-list.component';
import { TelemetriesMessagesComponent } from './telemetries-messages/telemetries-messages.component';
import { TelemetriesPresetsComponent } from './telemetries-presets/telemetries-presets.component';
import { TelemetriesMeasuresComponent } from './telemetries-measures/telemetries-measures.component';

const routes: Routes = [
  { path: '', component: TelemetiesListComponent },
  { path: 'messages', component: TelemetriesMessagesComponent },
  { path: 'presets', component: TelemetriesPresetsComponent },
  { path: 'measures', component: TelemetriesMeasuresComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TelemetiesRoutingModule {}
