import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Document } from '../models/Document';

@Injectable({
  providedIn: 'root',
})
export class DocumentStoreService {
  private apiUrl = environment.exportApi;

  constructor(private http: HttpClient) {}

  getAll(): Observable<Document[]> {
    return this.http.get<Document[]>(`${this.apiUrl}/documents/`);
  }

  getFromClient(uid: string): Observable<Document[]> {
    return this.http.get<Document[]>(
      `${this.apiUrl}/documents/factories/${uid}`,
    );
  }

  getFromContract(uid: string): Observable<Document[]> {
    return this.http.get<Document[]>(
      `${this.apiUrl}/documents/contracts/${uid}`,
    );
  }

  getFromStation(uid: string): Observable<Document[]> {
    return this.http.get<Document[]>(
      `${this.apiUrl}/documents/stations/${uid}`,
    );
  }

  getFromStations(): Observable<Document[]> {
    return this.http.get<Document[]>(`${this.apiUrl}/documents/stations/`);
  }

  getSingleBlob(document: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/documents/${document}`);
  }
}
