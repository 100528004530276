<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <h3 class="card-header">
        <a routerLink="/">Home</a> / Datenschutzgrundverordnung
        <span style="float: right">
          <a href="javascript:history.back()">
            <cds-icon
              shape="circle-arrow"
              direction="left"
              size="md"
            ></cds-icon>
          </a>
        </span>
      </h3>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <h3 class="card-header">
        <cds-icon shape="gavel" size="md"></cds-icon>
        Datenschutz
        <span style="float: right">
          <cds-icon shape="view-list" size="md"></cds-icon>
        </span>
      </h3>

      <div class="card-block">
        <!-- <h4 class="card-title">Title</h4> -->
        <div class="card-text">
          <app-datenschutz></app-datenschutz>
        </div>
      </div>

      <!-- <div class="card-footer"></div> -->
    </div>
  </div>
</div>
