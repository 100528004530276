import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { CabinetActions } from './cabinet.actions';
import { AssessmentStoreService } from 'src/app/services/assessment.service';

@Injectable()
export class CabinetEffects {
  private service = inject(AssessmentStoreService);

  // loadCabinetsUid$ = createEffect(() => {
  //   return inject(Actions).pipe(
  //     ofType(CabinetActions.loadCabinetsUid),
  //     mergeMap((action) =>
  //       this.service.getCabinets().pipe(
  //         map((cabinets) =>
  //           CabinetActions.loadCabinetsSuccess({ data: cabinets }),
  //         ),
  //         catchError((error) =>
  //           of(CabinetActions.loadCabinetsFailure({ error })),
  //         ),
  //       ),
  //     ),
  //   );
  // });

  loadCabinets$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(CabinetActions.loadCabinets),
      mergeMap(() =>
        this.service.getCabinets().pipe(
          map((cabinets) =>
            CabinetActions.loadCabinetsSuccess({ data: cabinets }),
          ),
          catchError((error) =>
            of(CabinetActions.loadCabinetsFailure({ error })),
          ),
        ),
      ),
    );
  });

  loadCabinet$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(CabinetActions.loadCabinet),
      mergeMap((action) =>
        this.service.getCabinet(action.id).pipe(
          map((cabinet) =>
            CabinetActions.loadCabinetsSuccess({ data: [cabinet] }),
          ),
          catchError((error) =>
            of(CabinetActions.loadCabinetsFailure({ error })),
          ),
        ),
      ),
    );
  });

  postCabinet$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(CabinetActions.postCabinet),
      mergeMap((action) =>
        this.service.postCabinet(action.cabinet).pipe(
          map((cabinet) =>
            CabinetActions.loadCabinetsSuccess({ data: [cabinet] }),
          ),
          catchError((error) =>
            of(CabinetActions.loadCabinetsFailure({ error })),
          ),
        ),
      ),
    );
  });

  patchCabinet$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(CabinetActions.patchCabinet),
      mergeMap((action) =>
        this.service.patchCabinet(action.cabinet).pipe(
          map((cabinet) =>
            CabinetActions.loadCabinetsSuccess({ data: [cabinet] }),
          ),
          catchError((error) =>
            of(CabinetActions.loadCabinetsFailure({ error })),
          ),
        ),
      ),
    );
  });

  deleteCabinet$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(CabinetActions.deleteCabinet),
      mergeMap((action) =>
        this.service.deleteCabinet(action.id).pipe(
          map(() => CabinetActions.deleteCabinetSuccess({ id: action.id })),
          catchError((error) =>
            of(CabinetActions.deleteCabinetFailure({ error })),
          ),
        ),
      ),
    );
  });

  deleteImage$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(CabinetActions.deleteImage),
      mergeMap((action) =>
        this.service.deleteImage(action.cid, action.id).pipe(
          map((cabinet) =>
            CabinetActions.loadCabinetsSuccess({ data: [cabinet] }),
          ),
          catchError((error) =>
            of(CabinetActions.loadCabinetsFailure({ error })),
          ),
        ),
      ),
    );
  });

  // loadCabinetsResult$ = createEffect(() => {
  //   return inject(Actions).pipe(
  //     ofType(CabinetActions.loadCabinetResult),
  //     mergeMap((action) =>
  //       this.service.getCabinetResult(action.id).pipe(
  //         map((cabinet) =>
  //           CabinetActions.loadCabinetsResultSuccess({ data: cabinet }),
  //         ),
  //         catchError((error) =>
  //           of(CabinetActions.loadCabinetsFailure({ error })),
  //         ),
  //       ),
  //     ),
  //   );
  // });
}
