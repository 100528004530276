import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { DocumentActions } from './document.actions';
import { DocumentStoreService } from 'src/app/services/document-store.service';
import { SocketClientService } from 'src/app/services/socket-client.service';
import { of } from 'rxjs';

@Injectable()
export class DocumentEffects {
  private service = inject(DocumentStoreService);
  private socket = inject(SocketClientService);

  loadSocket$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DocumentActions.loadSocket),
      mergeMap(() =>
        this.socket.documentObservable.pipe(
          map((documents) =>
            DocumentActions.loadDocumentsSuccess({ documents: [documents] }),
          ),
          catchError((error) =>
            of(DocumentActions.loadDocumentsFailure({ error })),
          ),
        ),
      ),
    );
  });

  loadDocumentsFromStation$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DocumentActions.loadDocumentsStation),
      mergeMap((action) =>
        this.service.getFromStation(action.uid).pipe(
          map((documents) =>
            DocumentActions.loadDocumentsSuccess({ documents: documents }),
          ),
          catchError((error) =>
            of(DocumentActions.loadDocumentsFailure({ error })),
          ),
        ),
      ),
    );
  });

  loadDocumentsFromContract$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DocumentActions.loadDocumentsContract),
      mergeMap((action) =>
        this.service.getFromContract(action.uid).pipe(
          map((documents) =>
            DocumentActions.loadDocumentsSuccess({ documents: documents }),
          ),
          catchError((error) =>
            of(DocumentActions.loadDocumentsFailure({ error })),
          ),
        ),
      ),
    );
  });

  loadDocumentsFromClient$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(DocumentActions.loadDocumentsFactory),
      mergeMap((action) =>
        this.service.getFromClient(action.uid).pipe(
          map((documents) =>
            DocumentActions.loadDocumentsSuccess({ documents: documents }),
          ),
          catchError((error) =>
            of(DocumentActions.loadDocumentsFailure({ error })),
          ),
        ),
      ),
    );
  });
}
