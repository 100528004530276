import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { Store } from '@ngrx/store';
import { environment } from 'src/environments/environment';
import { FactoryActions } from '../views/factories/+store/factory.actions';
import { StationActions } from '../views/stations/+store/station.actions';
import { ContractActions } from '../views/contracts/+store/contract.actions';
import { DocumentActions } from '../views/documents/+store/document.actions';
import { BehaviorSubject } from 'rxjs';
import { Factory } from '../models/Factory';
import { Station } from '../models/Station';
import { Contract } from '../models/Contract';
import { Telemetry } from '../models/Telemetry';
import { Document } from 'src/app/models/Document';
import { TelemetryActions } from '../views/telemetries/+store/telemetry.actions';

@Injectable({
  providedIn: 'root',
})
export class SocketClientService {
  socket: any; // = io(`${environment.importApi}/user`);

  private FactorySubject = new BehaviorSubject<Factory>({} as Factory);
  private StationSubject = new BehaviorSubject<Station>({} as Station);
  private ContractSubject = new BehaviorSubject<Contract>({} as Contract);
  private DocumentSubject = new BehaviorSubject<Document>({} as Document);
  private TelemetrySubject = new BehaviorSubject<Telemetry>({} as Telemetry);

  public factoryObservable = this.FactorySubject.asObservable();
  public stationObservable = this.StationSubject.asObservable();
  public contractObservable = this.ContractSubject.asObservable();
  public documentObservable = this.DocumentSubject.asObservable();
  public telemetryObservable = this.TelemetrySubject.asObservable();

  constructor(private store: Store) {
    // console.log(`path for socket-connection: ${environment.importApi}/user`);
  }

  public connectClientSession(uid: string) {
    this.socket = io(`${environment.importApi}/user`);
    console.log(`user '${uid}' will connect server socket.`);
    this.socket.emit('uid', uid);

    this.socket.on('factory', (factory: Factory) => {
      this.FactorySubject.next(factory);
      this.store.dispatch(FactoryActions.updateFactory());
      console.log('got factory update');
    });

    this.socket.on('station', (station: Station) => {
      this.StationSubject.next(station);
      this.store.dispatch(StationActions.updateStation());
      console.log('got station update');
    });

    this.socket.on('contract', (contract: Contract) => {
      this.ContractSubject.next(contract);
      this.store.dispatch(ContractActions.updateContract());
      console.log('got contract update');
    });

    this.socket.on('telemetry', (telemetry: Telemetry) => {
      this.TelemetrySubject.next(telemetry);
      this.store.dispatch(TelemetryActions.updateTelemetry());
      console.log('got telemetry update');
    });

    this.socket.on('document', (document: Document) => {
      this.DocumentSubject.next(document);
      this.store.dispatch(DocumentActions.loadSocket());
      console.log('got document update');
    });
  }

  public closeClientSession = () => {
    console.log('will disconnect the socket.');
    if (this.socket != null) {
      this.socket.disconnect();
      this.socket.close();
    }
  };
}
