import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Factory } from 'src/app/models/Factory';

export const FactoryActions = createActionGroup({
  source: 'Factory',
  events: {
    'Update Factory': emptyProps(),

    'Load Factory': props<{ uid: string }>(),
    'Load Factory Success': props<{ factory: Factory }>(),
    'Load Factory Failure': props<{ error: HttpErrorResponse }>(),

    'Load Factories': emptyProps(),
    'Load Factories Success': props<{ factories: Factory[] }>(),
    'Load Factories Failure': props<{ error: HttpErrorResponse }>(),

    // 'Load Paging': props<{ page: number; limit: number }>(),
    // 'Load Paging Success': props<{ factories: Factory[] }>(),
    // 'Load Paging Failure': props<{ error: HttpErrorResponse }>(),
  },
});
