<div class="card">
  <h3 class="card-header">
    <cds-icon shape="host-group" size="md"></cds-icon>

    <ng-container *ngIf="extern; else intern"
      >&nbsp;<a [routerLink]="['/stations']">Stationen</a></ng-container
    >
    <ng-template #intern>&nbsp;Stationen</ng-template>
    <span style="float: right">
      <cds-icon shape="map" size="md"></cds-icon>
    </span>
  </h3>

  <div class="card-block">
    <h4 class="card-title" *ngIf="error?.status">
      <div class="alert alert-danger" role="alert" *ngIf="error">
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon
                class="alert-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </div>
            <span class="alert-text">
              {{ error.status }} | {{ error.statusText }}
              <ng-container *ngIf="error.error.name">
                | {{ error.error.name }} | {{ error.error.message }}
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </h4>

    <div class="card-text" style="height: 400px">
      <google-map [options]="mapOptions" [height]="400" width="100%">
        <map-marker
          *ngFor="let markerPosition of markerPositions"
          [position]="markerPosition.position"
          [title]="markerPosition.Station.Name"
          [options]="markerPosition.options"
          (mapClick)="mapClick(markerPosition)"
        >
        </map-marker>
      </google-map>
    </div>
  </div>
  <div class="card-footer">
    {{ stations.length | plural: "Station" : "Stationen" }}
  </div>
</div>
