import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Contract } from 'src/app/models/Contract';

@Component({
  selector: 'app-contracts-list-grid-card',
  templateUrl: './contracts-list-grid-card.component.html',
  styleUrls: ['./contracts-list-grid-card.component.css'],
})
export class ContractsListGridCardComponent {
  @Input() contracts = [] as Contract[];
  @Input() loading = false;
  @Input() error: HttpErrorResponse | null = null;

  @Input() extern = false;
}
