import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UsersProfileComponent } from './users-profile/users-profile.component';
import { UsersListGroupComponent } from './users-list-group/users-list-group.component';
import { UsersListUsersComponent } from './users-list-users/users-list-users.component';
import { UsersRightsFactoriesComponent } from './users-rights-factories/users-rights-factories.component';

const routes: Routes = [
  { path: 'profil', component: UsersProfileComponent },
  { path: 'all', component: UsersListUsersComponent },
  { path: 'group', component: UsersListGroupComponent },
  { path: 'factories', component: UsersRightsFactoriesComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UsersRoutingModule {}
