import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromUser from './user.reducer';
import { User } from 'src/app/models/User';

const { selectAll } = fromUser.adapter.getSelectors();

export const selectUserState = createFeatureSelector<fromUser.State>(
  fromUser.userFeatureKey,
);

// select users
export const selectUsers = createSelector(selectUserState, selectAll);

export const selectUsersLoading = createSelector(
  selectUserState,
  (state) => state.loading,
);

export const selectUsersError = createSelector(
  selectUserState,
  (state) => state.error,
);

// update user
export const selectUpdateUserSuccess = createSelector(
  selectUserState,
  (state) => state.updated,
);

// delete user
export const selectUserDeleteSuccess = createSelector(
  selectUserState,
  (state) => state.deleted,
);

// invite user
export const selectInviteSuccess = createSelector(
  selectUserState,
  (state) => state.invited,
);

export const selectUser = (username: string) =>
  createSelector(
    selectUsers,
    (users) => users.find((user) => user.Username == username) || ({} as User),
  );

// export const selectCombinedUsers = createSelector(
//   selectUsers,
//   selectUsersLoading,
//   selectUsersError,
//   (users, loading, error) => ({
//     users,
//     loading,
//     error,
//   }),
// );

// export const selectCombinedUser = (id: string) =>
//   createSelector(
//     selectUser(id),
//     selectUsersLoading,
//     selectUsersError,
//     (user, loading, error) => ({
//       user,
//       loading,
//       error,
//     }),
//   );
