import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import {
  selectTelemetries,
  selectTelemetriesError,
  selectTelemetriesLoading,
} from '../+store/telemetry.selectors';
import { TelemetryActions } from '../+store/telemetry.actions';
import { TelemetryStoreService } from 'src/app/services/telemetry-store.service';

@Component({
  selector: 'app-telemeties-list',
  templateUrl: './telemeties-list.component.html',
  styleUrls: ['./telemeties-list.component.css'],
})
export class TelemetiesListComponent implements OnInit {
  telemetries$ = this.store.select(selectTelemetries);
  loading$ = this.store.select(selectTelemetriesLoading);
  error$ = this.store.select(selectTelemetriesError);

  custom = {
    endpoint: '',
  };

  constructor(
    private store: Store,
    private telemetryStoreService: TelemetryStoreService,
  ) {}

  ngOnInit() {
    this.store.dispatch(TelemetryActions.loadTelemetries());
    this.custom = history?.state?.data?.custom;

    if (this.custom) {
      this.telemetryStoreService
        .patchEndpoint({
          endpoint: this.custom.endpoint,
        })
        .subscribe((data) => {
          console.log(data);
        });
    }
  }
}
