import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Station } from 'src/app/models/Station';

@Component({
  selector: 'app-stations-list-grid-card',
  templateUrl: './stations-list-grid-card.component.html',
  styleUrls: ['./stations-list-grid-card.component.css'],
})
export class StationsListGridCardComponent {
  @Input() stations = [] as Station[];
  @Input() loading = false;
  @Input() error: HttpErrorResponse | null = null;

  @Input() extern = false;
}
