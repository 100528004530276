import { Component, Input, OnInit } from '@angular/core';
import { TelemetryMessagesService } from 'src/app/shared/telemetry-messages.service';
import { Observable } from 'rxjs';
import { TelemetryMessages } from 'src/app/shared/Telemetry-Messages';
import { MqttEventService } from 'src/app/services/mqtt-event.service';
import { ClrLoadingState } from '@clr/angular';

@Component({
  selector: 'app-telemetry-messages-grid',
  templateUrl: './telemetry-messages-grid.component.html',
  styleUrls: ['./telemetry-messages-grid.component.css'],
})
export class TelemetryMessagesGridComponent implements OnInit {
  @Input() client = '';
  @Input() extern = false;

  telemetryData$: Observable<TelemetryMessages> =
    new Observable<TelemetryMessages>();

  submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(
    private mqttService: MqttEventService,
    private telemetryMessagesService: TelemetryMessagesService,
  ) {}

  ngOnInit() {
    this.telemetryData$ = this.telemetryMessagesService.getObjectByKey(
      this.client,
    );
  }

  onUpdate() {
    this.submitBtnState = ClrLoadingState.LOADING;
    this.mqttService
      .updateMessages(this.client)
      .then((result) => {
        this.submitBtnState = ClrLoadingState.SUCCESS;
      })
      .catch((error) => {
        this.submitBtnState = ClrLoadingState.ERROR;
      });
  }
}
