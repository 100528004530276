<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <h3 class="card-header">
        <a routerLink="/">Home</a> /
        <a routerLink="/risks">Gefährdungsbeurteilung</a> / Anlage
        <span style="float: right">
          <a href="javascript:history.back()">
            <cds-icon
              shape="circle-arrow"
              direction="left"
              size="md"
            ></cds-icon>
          </a>
        </span>
      </h3>
    </div>
  </div>
</div>

<div class="card">
  <!-- <h3 class="card-header">Header</h3> -->
  <div class="card-block">
    <h4 class="card-title">Anlage</h4>
    <div class="card-text">
      <ng-container *ngIf="cabinet">
        <span style="float: right">
          <button class="btn btn-outline" (click)="onBtnEditCabinetClick()">
            Anlage bearbeiten
          </button>
          <ng-container *ngIf="isService$ || isManager$">
            <button class="btn btn-outline" (click)="onBtnEditTemplateClick()">
              Vorlage bearbeiten
            </button>
          </ng-container>
          <!-- (click)="onUpdate()" -->
        </span>
        <br />
        <ng-container *ngIf="template$ | async as template">
          <!-- <table class="table table-vertical"> -->
          <table class="table">
            <thead>
              <tr>
                <th *ngIf="imgPreviewSrc" class="left">Bild</th>
                <th class="left">Bezeichnung</th>
                <th class="left">Wert</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let anlageItem of template.Anlage; let i = index">
                <!-- <tr> -->
                <td
                  *ngIf="i === 0 && imgPreviewSrc"
                  [rowSpan]="template.Anlage.length"
                  style="vertical-align: middle"
                >
                  <!-- <td> -->
                  <div style="display: flex; flex-direction: column">
                    <img
                      [src]="imgPreviewSrc"
                      alt="Vorschaubild"
                      style="
                        align-self: top;
                        position-anchor: top;
                        vertical-align: top;
                      "
                    />
                    <!-- (click)="showImages()" -->
                    <ng-container *ngIf="cabinet">
                      <br />
                      <a
                        [routerLink]="[]"
                        class="label clickable"
                        (click)="showImages()"
                        style="
                          align-self: bottom;
                          position-anchor: bottom;
                          vertical-align: bottom;
                        "
                      >
                        weitere Bilder
                      </a>
                    </ng-container>
                  </div>
                </td>
                <!-- <div *ngFor="let anlageItem of template.Anlage"> -->
                <td class="left">
                  {{ anlageItem.Title }}
                  <!-- <button
                    *ngIf="anlageItem.Info"
                    class="btn btn-sm btn-link"
                    (click)="onHelperClick(anlageItem.Title, anlageItem.Info)"
                  > -->
                  <cds-icon
                    *ngIf="anlageItem.Info"
                    class="btn btn-sm btn-link"
                    (click)="onHelperClick(anlageItem.Title, anlageItem.Info)"
                    shape="info-standard"
                  />
                  <!-- </button> -->
                </td>
                <td class="left">{{ ValueFromItem(cabinet, anlageItem) }}</td>
                <!-- </div> -->
              </tr>
            </tbody>
          </table>
        </ng-container>
        <!-- <clr-dg-detail-body>
          <a
            [routerLink]="['review', cabinet._id]"
            class="label clickable"
          >
            <span class="text">Bewertung</span>
          </a>
        </clr-dg-detail-body> -->
      </ng-container>
    </div>
    <h4 class="card-titel">Bewertungen</h4>
    <div class="card-text">
      <app-risks-evaluations-overview
        #auswertungenTab
        [cabinet]="cabinet!"
      ></app-risks-evaluations-overview>
    </div>
    <!-- <h4 class="card-title">Bewertung</h4> -->
    <!-- <div class="card-text"> -->
    <!-- <clr-tabs> -->
    <!-- ANLAGE -->
    <!-- <clr-tab>
          <button clrTabLink>Anlage</button>
          <clr-tab-content *clrIfActive>
            <app-risks-tab
              #anlageTab
              [tabFor$]="'Anlage'"
              [cabinet$]="cabinet$"
              [cabinetCalculations$]="cabinetCalculations$"
              [itmArray$]="itmsAnlage$"
              (updated)="refresh($event)"
              (init)="initAnlage($event)"
              (changed)="calulationFor($event)"
            ></app-risks-tab>
          </clr-tab-content>
        </clr-tab> -->

    <!-- PRÜFUNG -->
    <!-- <clr-tab>
          <button clrTabLink>Prüfung</button>
          <clr-tab-content *clrIfActive>
            <app-risks-tab
              #pruefungTab
              [tabFor$]="'Pruefung'"
              [cabinetCalculations$]="cabinetCalculations$"
              [cabinet$]="cabinet$"
              [itmArray$]="itmsPruefung$"
              (updated)="refresh($event)"
              (init)="initPruefung($event)"
              (changed)="calulationFor($event)"
            ></app-risks-tab>
          </clr-tab-content>
        </clr-tab> -->

    <!-- GEFÄHRDUNGEN -->
    <!-- <clr-tab>
          <button clrTabLink>Gefährdung</button>
          <clr-tab-content *clrIfActive>
            <app-risks-tab
              #gefaehrdungTab
              [tabFor$]="'Gefaehrdung'"
              [cabinetCalculations$]="cabinetCalculations$"
              [cabinet$]="cabinet$"
              [itmArray$]="itmsGefaehrdung$"
              (updated)="refresh($event)"
              (init)="initGefaehrdung($event)"
              (changed)="calulationFor($event)"
            ></app-risks-tab>
          </clr-tab-content>
        </clr-tab> -->

    <!-- AUSWERTUNGEN -->
    <!-- <clr-tab>
          <button clrTabLink>Auswertungen</button>
          <clr-tab-content *clrIfActive>
            <app-risks-evaluations
              #auswertungenTab
              [cabinet]="cabinet"
            ></app-risks-evaluations>
          </clr-tab-content>
        </clr-tab> -->

    <!-- <clr-tab>
          <button clrTabLink>Gefährdungen</button>
          <clr-tab-content *clrIfActive>
            <form
              clrForm
              [formGroup]="formMobile"
              (ngSubmit)="onSubmitGefahren()"
            >
              <div formGroupName="Gefaehrdungen">
                <clr-select-container>
                  <label class="clr-col-12 clr-col-md-4"> Zugänglich </label>
                  <select
                    class="clr-col-12 clr-col-md-8"
                    clrSelect
                    formControlName="Zugaenglich"
                  >
                    <option value="1">Laie</option>
                    <option value="2">EUP</option>
                    <option value="3">EFK</option>
                    <option value="4">EFK (MSSB)</option>
                  </select>
                </clr-select-container>

                <clr-select-container>
                  <label class="clr-col-12 clr-col-md-4"> Verriegelung </label>
                  <select
                    class="clr-col-12 clr-col-md-8"
                    clrSelect
                    formControlName="Verriegelung"
                  >
                    <option value="1">Abgeschlossene el. Betriebsstätte</option>
                    <option value="2">Doppelbart</option>
                    <option value="3">Zylinderschloss</option>
                    <option value="4">Türhebel</option>
                    <option value="5">ohne Hilfsmittel zu öffnen</option>
                  </select>
                </clr-select-container>

                <clr-select-container>
                  <label class="clr-col-12 clr-col-md-4">
                    Bemessungsstrom des Verteilers &lt; 250A
                  </label>
                  <select
                    class="clr-col-12 clr-col-md-8"
                    clrSelect
                    formControlName="Bemessungsstrom_LT_250"
                  >
                    <option value="1">Ja</option>
                    <option value="2">Nein</option>
                  </select>
                </clr-select-container>

                <clr-select-container>
                  <label class="clr-col-12 clr-col-md-4">
                    Bemessungsstrom der Abgänge &lt; 125A
                  </label>
                  <select
                    class="clr-col-12 clr-col-md-8"
                    clrSelect
                    formControlName="Bemessungsstrom_LT_125"
                  >
                    <option value="1">Ja</option>
                    <option value="2">Nein</option>
                  </select>
                </clr-select-container>

                <clr-select-container>
                  <label class="clr-col-12 clr-col-md-4">
                    Gefahr durch elektrischen Schlag
                  </label>
                  <select
                    class="clr-col-12 clr-col-md-8"
                    clrSelect
                    formControlName="Gefahr"
                  >
                    <option value="1">Ja (Un &gt; 50VAC oder 120VDC)</option>
                    <option value="2">Nein (Un &lt; 50VAC oder 120VDC)</option>
                  </select>
                </clr-select-container>

                <clr-select-container>
                  <label class="clr-col-12 clr-col-md-4">
                    Berührungsschutz bei geöffneter Tür gegeben
                  </label>
                  <select
                    class="clr-col-12 clr-col-md-8"
                    clrSelect
                    formControlName="Beruehrungsschutz"
                  >
                    <option value="1">Ja</option>
                    <option value="2">Nein</option>
                  </select>
                </clr-select-container>

                <clr-select-container>
                  <label class="clr-col-12 clr-col-md-4">
                    Störlichtbogengefahr
                  </label>
                  <select
                    class="clr-col-12 clr-col-md-8"
                    clrSelect
                    formControlName="Stoerlichtbogengefahr"
                  >
                    <option value="1">
                      Absicherung ≤ 63 A: Arbeitskleidung (lang)
                    </option>
                    <option value="2">Kurzschlussstrom≤ 1 kA Besteht</option>
                    <option value="3">MSR &lt; 25A</option>
                    <option value="4">Berechnung notwendig</option>
                  </select>
                </clr-select-container>

                <clr-select-container>
                  <label class="clr-col-12 clr-col-md-4"> Verkehrswege </label>
                  <select
                    class="clr-col-12 clr-col-md-8"
                    clrSelect
                    formControlName="Verkehrswege"
                  >
                    <option value="1">iO</option>
                    <option value="2">n.iO</option>
                    <option value="3">
                      Gegenstände in den el. Betriebsräumen
                    </option>
                  </select>
                </clr-select-container>

                <clr-select-container>
                  <label class="clr-col-12 clr-col-md-4">
                    EMV (In &lt; 100A)
                  </label>
                  <select
                    class="clr-col-12 clr-col-md-8"
                    clrSelect
                    formControlName="EMV"
                  >
                    <option value="1">
                      In >100A und dauerhaftes Arbeiten < 30cm entfernt
                    </option>
                    <option value="2">keine Belastung</option>
                  </select>
                </clr-select-container>

                <clr-select-container>
                  <label class="clr-col-12 clr-col-md-4"> Gefahrstoffe </label>
                  <select
                    class="clr-col-12 clr-col-md-8"
                    clrSelect
                    formControlName="Gefahrstoffe"
                  >
                    <option value="1">Ölgefüllte Betriebsmittel</option>
                    <option value="2">SF6-Gas gefüllte Betriebsmittel</option>
                    <option value="3">
                      Öl- und SF6-Gas gefüllte Betriebsmittel
                    </option>
                    <option value="4">keine</option>
                  </select>
                </clr-select-container>

                <clr-select-container>
                  <label class="clr-col-12 clr-col-md-4">
                    Schaltpläne und Beschriftungen
                  </label>
                  <select
                    class="clr-col-12 clr-col-md-8"
                    clrSelect
                    formControlName="Beschriftungen"
                  >
                    <option value="1">iO</option>
                    <option value="2">keine Pläne</option>
                    <option value="3">keine Abgangsbeschriftung</option>
                    <option value="4">keine Schaltschrankbezeichnung</option>
                    <option value="5">n.iO</option>
                  </select>
                </clr-select-container>

                <clr-select-container>
                  <label class="clr-col-12 clr-col-md-4">
                    Druckausgleich
                  </label>
                  <select
                    class="clr-col-12 clr-col-md-8"
                    clrSelect
                    formControlName="Druckausgleich"
                  >
                    <option value="1">iO</option>
                    <option value="1">n.iO</option>
                    <option value="1">nicht relevant</option>
                  </select>
                </clr-select-container>

                <clr-input-container>
                  <label class="clr-col-12 clr-col-md-4">Kurzschlusstrom</label>
                  <input
                    class="clr-col-12 clr-col-md-8"
                    clrInput
                    formControlName="Kurzschlusstrom"
                  />
                </clr-input-container>

                <clr-input-container>
                  <label class="clr-col-12 clr-col-md-4"
                    >Netzimpedanz_L1L2</label
                  >
                  <input
                    class="clr-col-12 clr-col-md-8"
                    clrInput
                    formControlName="Netzimpedanz_L1L2"
                  />
                </clr-input-container>

                <clr-input-container>
                  <label class="clr-col-12 clr-col-md-4"
                    >Netzimpedanz_L2L3</label
                  >
                  <input
                    class="clr-col-12 clr-col-md-8"
                    clrInput
                    formControlName="Netzimpedanz_L2L3"
                  />
                </clr-input-container>

                <clr-input-container>
                  <label class="clr-col-12 clr-col-md-4"
                    >Netzimpedanz_L3L1</label
                  >
                  <input
                    class="clr-col-12 clr-col-md-8"
                    clrInput
                    formControlName="Netzimpedanz_L3L1"
                  />
                </clr-input-container>

                <clr-textarea-container>
                  <label class="clr-col-12 clr-col-md-4">Bemerkungen</label>
                  <textarea
                    class="clr-col-12 clr-col-md-8"
                    clrTextarea
                    formControlName="Bemerkungen"
                  ></textarea>
                </clr-textarea-container>
              </div>

              <p></p>

              <button
                type="submit"
                class="btn btn-primary"
                [clrLoading]="submitGefaehrdungenState"
              >
                speichern
              </button>
            </form>
          </clr-tab-content>
        </clr-tab> -->

    <!-- FOTOS -->
    <!-- <clr-tab>
          <button clrTabLink>Fotos</button>
          <clr-tab-content *clrIfActive>
            <app-risks-tab-files
              #imgTab
              [cabinet$]="cabinet$"
              (updated)="refresh($event)"
              (init)="initFiles($event)"
            ></app-risks-tab-files>
          </clr-tab-content>
        </clr-tab> -->
    <!-- </clr-tabs> -->
    <!-- </div> -->
  </div>
  <!-- <div class="card-footer">Footer</div> -->
</div>

<!-- Info-Dialog -->
<clr-modal [(clrModalOpen)]="helper">
  <h3 class="modal-title">{{ text | uppercase }}</h3>
  <div class="modal-body">
    <!-- <p>Schmutz sollte nach Gefühl bewertet werden.</p>
    <ul class="list list-spacer">
      <li>Neuwertige Anlagen sollten mit 1 Bewertet werden.</li>
      <li>
        Grobe Verschmutzungen, welche den Betrieb gefährden sollten mit 6
        bewertet werden und erfordern über die Bewertung hinaus sofortigen
        Handlungsbedarf.
      </li>
    </ul> -->
    <!-- <div [innerHTML]="text"></div> -->
    <div *ngIf="text === 'schmutz'">
      Schmutz sollte nach Gefühl bewertet werden.
      <p>
        Grobe Verschmutzungen, welche den Betrieb gefährden sollten mit 6
        bewertet werden und erfordern über die Bewertung hinaus sofortigen
        Handlungsbedarf.
      </p>
      <p>Neuwertige Anlagen sollten mit 1 Bewertet werden.</p>
    </div>

    <div *ngIf="text === 'feuchtigkeit'">
      Feuchtgkeit sollte in Anlehnung an DIN EN 60529 bewertet werden.
    </div>

    <div *ngIf="text === 'alterung'">
      Alterung sollte in Anlehnung an die Übliche Lebensdauererwartung von
      Elektrischen Anlagen bewertet werden.
      <p>
        Instandsetzungen und Teilerneuerungen sind positiv zu berücksichtigen.
      </p>
      <p></p>
      <ul class="list">
        <li>Älter als 40 Jahre - 30</li>
        <li>bis 40 - 5</li>
        <li>25 bis 30 - 15</li>
        <li>bis 25 - 3</li>
        <li>10 bis 15 - 2</li>
        <li>0 bis 10 - 1</li>
      </ul>
    </div>

    <div *ngIf="text === 'auslastung'">
      <b>Überlastung</b> sollte nach Gefühl bewertet werden. Die Überlastung
      kann auf Grundlage der durchschnittlichen Belastung im Verhältnis zur
      Nennbelastung bewertet werden, wenn dementsprechende Infromationen
      vorliegen. Andernfalls können Thermografiebilder oder Spuren von
      thermischer Belastung als Hinweis verwendet werden.

      <p>
        <b>Auslastung</b> von Anlagen wird beeinflusst von betrieblichen
        Verhältnissen wie beispielsweise Einschichtbetrieb (8h/24h) oder
        Mehrschichtbetrieb (24h/24h) sowie den Anlagenzwecke wie Photovoltaik-
        oder BHKW-Einspeisung.
      </p>

      <p>
        <b>Vibration</b> ist dann zu bewerten, wenn Teile einer Anlage
        beispielsweise durch Vibrations, Stoß oder Transport bewegt werden. Die
        Montage eines Schaltschrankes auf einem beweglichen Bedienterminals oder
        auf einem Ausleger einer Maschine sind Beispiele für Montagebedingungen,
        welche vibrationsbelastung hervorrufen können.
      </p>
    </div>

    <div *ngIf="text === 'umgebungsbedingungen'"></div>

    <div *ngIf="text === 'rcd'">
      Sind RCD's, Differenzstrom- oder Fehlerspannungs-Schutzschalter vorhanden,
      muss eine weitere prüfung durch Benutzer organisiert werden.
    </div>

    <div *ngIf="text === 'zugänglich'">Zugänglich</div>

    <div *ngIf="text === 'verriegelung'">Verriegelung</div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="helper = false">
      Ok
    </button>
  </div>
</clr-modal>

<!-- Images-Dialog -->
<clr-modal [(clrModalOpen)]="images">
  <h3 class="modal-title">{{ text | uppercase }}</h3>
  <div class="modal-body">
    <cds-icon
      shape="angle"
      direction="left"
      size="xl"
      (click)="imgBackwards()"
      class="btn btn-primary"
      style="position: absolute; left: 10px; top: 50%"
    ></cds-icon>
    <img [src]="imgViewSrc" alt="ANZEIGE" style="position: center" />
    <cds-icon
      shape="angle"
      direction="right"
      size="xl"
      (click)="imgForwards()"
      class="btn btn-primary"
      style="position: absolute; right: 10px; top: 50%"
    ></cds-icon>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="images = false">
      Ok
    </button>
  </div>
</clr-modal>

<!-- Info-Dialog -->
<clr-modal [(clrModalOpen)]="onHelper">
  <h3 class="modal-title">
    <cds-icon shape="tag"></cds-icon>
    {{ title }}
  </h3>
  <div class="modal-body" [innerHTML]="message"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onHelper = false">
      Ok
    </button>
  </div>
</clr-modal>
