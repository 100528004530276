import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { Factory } from 'src/app/models/Factory';

@Component({
  selector: 'app-factories-detail-item',
  templateUrl: './factories-detail-item.component.html',
  styleUrls: ['./factories-detail-item.component.css'],
})
export class FactoriesDetailItemComponent {
  @Input() factory = {} as Factory;
  @Input() loading = false;
  @Input() error: HttpErrorResponse | null = null;
}
