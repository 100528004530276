import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';

import { TemplateActions } from './template.actions';
import { AssessmentStoreService } from 'src/app/services/assessment.service';

@Injectable()
export class TemplateEffects {
  constructor(private store: Store) {}

  private service = inject(AssessmentStoreService);

  // ------------------ Template (Single)---------- //

  loadTemplate$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(TemplateActions.loadTemplate),
      mergeMap((action) => {
        // console.log(`loadTemplate$ -> action.id: ${action.id}`);
        return this.service.getTemplate(action.id).pipe(
          map((tamplate) =>
            TemplateActions.loadTemplatesSuccess({ data: [tamplate] }),
          ),
          catchError((error) =>
            of(TemplateActions.loadTemplatesFailure({ error })),
          ),
        );
      }),
    );
  });

  loadTemplateUid$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(TemplateActions.loadTemplateUid),
      mergeMap((action) =>
        this.service.getActualTemplate(action.uid).pipe(
          map((tamplate) =>
            TemplateActions.loadTemplatesSuccess({ data: [tamplate] }),
          ),
          catchError((error) =>
            of(TemplateActions.loadTemplatesFailure({ error })),
          ),
        ),
      ),
    );
  });

  // ------------------ Template (Multi)----------- //

  loadTemplatesUid$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(TemplateActions.loadTemplatesUid),
      mergeMap((action) =>
        this.service.getUsrTemplates(action.uid).pipe(
          map((tamplates) =>
            TemplateActions.loadTemplatesSuccess({ data: tamplates }),
          ),
          catchError((error) =>
            of(TemplateActions.loadTemplatesFailure({ error })),
          ),
        ),
      ),
    );
  });

  loadTemplates$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(TemplateActions.loadTemplates),
      mergeMap(() =>
        this.service.getTemplates().pipe(
          map((tamplates) =>
            TemplateActions.loadTemplatesSuccess({ data: tamplates }),
          ),
          catchError((error) =>
            of(TemplateActions.loadTemplatesFailure({ error })),
          ),
        ),
      ),
    );
  });

  // --------------- Template.Items --------------- //

  postTemplateItem$ = createEffect(() => {
    const res = inject(Actions).pipe(
      ofType(TemplateActions.postTemplateItem),
      mergeMap((action) =>
        this.service
          .postTemplateItem(
            action.tab,
            action.item,
            action.templateId /*, action.value*/,
          )
          .pipe(
            map((templates) => {
              console.log('teplate.effects -> map(teplate) ....');
              const templateData = TemplateActions.loadTemplatesSuccess({
                data: templates,
              });
              return templateData;
            }),
            catchError((error) =>
              of(TemplateActions.loadTemplatesFailure({ error })),
            ),
          ),
      ),
    );
    return res;
  });

  editTemplateItem$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(TemplateActions.editTemplateItem),
      mergeMap((action) =>
        this.service.editTemplateItem(action.tab, action.item).pipe(
          map((template) =>
            TemplateActions.loadTemplatesSuccess({ data: template }),
          ),
          catchError((error) =>
            of(TemplateActions.loadTemplatesFailure({ error })),
          ),
        ),
      ),
    );
  });

  deleteTemplateItem$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(TemplateActions.deleteTemplateItem),
      mergeMap((action) =>
        this.service.deleteTemplateItem(action.tab, action.item).pipe(
          map((template) =>
            TemplateActions.loadTemplatesSuccess({ data: [template] }),
          ),
          catchError((error) =>
            of(TemplateActions.loadTemplatesFailure({ error })),
          ),
        ),
      ),
    );
  });

  // // -------------- Template.Anlage --------------- //

  // postAnlage$ = createEffect(() => {
  //   // return inject(Actions).pipe(
  //   const res = inject(Actions).pipe(
  //     ofType(TemplateActions.postAnlage),
  //     mergeMap((action) =>
  //       this.service
  //         .postAnlage(action.item, action.templateId /*, action.value*/)
  //         .pipe(
  //           map((templates) => {
  //             console.log('teplate.effects -> map(teplate) ....');
  //             const templateData = TemplateActions.loadTemplatesSuccess({
  //               data: templates,
  //             });
  //             // const templateData = TemplateActions.publishTemplate({
  //             //   template: cabinets[0]?.Template as Template,
  //             // });
  //             // console.log(
  //             //   'cabinet.effects -> result of publishTemplate',
  //             //   templateData,
  //             // );
  //             // this.store.dispatch(CabinetActions.loadCabinet({ id: params['id'] }));
  //             return templateData;
  //           }),
  //           catchError((error) =>
  //             of(TemplateActions.loadTemplatesFailure({ error })),
  //           ),
  //         ),
  //     ),
  //   );
  //   return res;
  // });

  // // postNewAnlageItem$ = createEffect(() => {
  // //   // return
  // //   const res = inject(Actions).pipe(
  // //     ofType(TemplateActions.postNewAnlage),
  // //     mergeMap((action) => {
  // //       let returnResult = {}; // as EffectResult<Action>;
  // //       const result = this.service.postNewAnlageItem(
  // //         action.item,
  // //         action.templateId,
  // //         action.uid /*, action.value*/,
  // //       );
  // //       returnResult = result.Templates.pipe(
  // //         map((template) =>
  // //           TemplateActions.loadTemplatesSuccess({ data: template }),
  // //         ),
  // //         catchError((error) =>
  // //           of(TemplateActions.loadTemplatesFailure({ error })),
  // //         ),
  // //       );
  // //       returnResult = result.Cabinets.pipe(
  // //         map((template) =>
  // //           CabinetActions.loadCabinetsSuccess({ data: template }),
  // //         ),
  // //         catchError((error) =>
  // //           of(CabinetActions.loadCabinetsFailure({ error })),
  // //         ),
  // //       );
  // //       // return returnResult;
  // //       return result.Templates;
  // //       // return TemplateActions.postNewAnlage;
  // //       // return result;
  // //     }),
  // //   );
  // //   // return TemplateActions.loadTemplatesSuccess({ data: {} as Template[] });
  // //   return res;
  // // });

  // editAnlage$ = createEffect(() => {
  //   return inject(Actions).pipe(
  //     ofType(TemplateActions.editAnlage),
  //     mergeMap((action) =>
  //       this.service.editAnlage(action.item).pipe(
  //         map((template) =>
  //           TemplateActions.loadTemplatesSuccess({ data: template }),
  //         ),
  //         catchError((error) =>
  //           of(TemplateActions.loadTemplatesFailure({ error })),
  //         ),
  //       ),
  //     ),
  //   );
  // });

  // deleteAnlage$ = createEffect(() => {
  //   return inject(Actions).pipe(
  //     ofType(TemplateActions.deleteAnlage),
  //     mergeMap((action) =>
  //       this.service.deleteAnlage(action.item).pipe(
  //         map((template) =>
  //           TemplateActions.loadTemplatesSuccess({ data: [template] }),
  //         ),
  //         catchError((error) =>
  //           of(TemplateActions.loadTemplatesFailure({ error })),
  //         ),
  //       ),
  //     ),
  //   );
  // });

  // // ------------- Template.Pruefung -------------- //

  // postPruefung$ = createEffect(() => {
  //   return inject(Actions).pipe(
  //     ofType(TemplateActions.postPruefung),
  //     mergeMap((action) =>
  //       this.service.postPruefung(action.item /*, action.value*/).pipe(
  //         map((cabinets) => {
  //           console.log('cabinet.effects -> map(cabinets) ....');
  //           const cabinetsData = CabinetActions.loadCabinetsSuccess({
  //             data: cabinets,
  //           });
  //           const templateData = TemplateActions.publishTemplate({
  //             template: cabinets[0]?.Template as Template,
  //           });
  //           console.log(
  //             'cabinet.effects -> result of publishTemplate',
  //             templateData,
  //           );
  //           return cabinetsData;
  //         }),
  //         catchError((error) =>
  //           of(CabinetActions.loadCabinetsFailure({ error })),
  //         ),
  //       ),
  //     ),
  //   );
  // });

  // // deletePruefung$ = createEffect(() => {
  // //   return inject(Actions).pipe(
  // //     ofType(TemplateActions.deletePruefung),
  // //     mergeMap((action) =>
  // //       this.service.deletePruefung(action.id, action.id).pipe(
  // //         map(() => TemplateActions.deleteTemplateSuccess({ id: action.id })),
  // //         catchError((error) =>
  // //           of(TemplateActions.deleteTemplateFailure({ error })),
  // //         ),
  // //       ),
  // //     ),
  // //   );
  // // });

  // // ----------- Template.Gefaehrdung ------------- //

  // postGefaehrdung$ = createEffect(() => {
  //   return inject(Actions).pipe(
  //     ofType(TemplateActions.postGefaehrdung),
  //     mergeMap((action) =>
  //       this.service.postGefaehrdung(action.item /*, action.value*/).pipe(
  //         map((cabinets) => {
  //           console.log('cabinet.effects -> map(cabinets) ....');
  //           const cabinetsData = CabinetActions.loadCabinetsSuccess({
  //             data: cabinets,
  //           });
  //           const templateData = TemplateActions.publishTemplate({
  //             template: cabinets[0]?.Template as Template,
  //           });
  //           console.log(
  //             'cabinet.effects -> result of publishTemplate',
  //             templateData,
  //           );
  //           return cabinetsData;
  //         }),
  //         catchError((error) =>
  //           of(CabinetActions.loadCabinetsFailure({ error })),
  //         ),
  //       ),
  //     ),
  //   );
  // });

  // ----------- Template.Gefaehrdung ------------- //
}
