import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import { TelemetryActions } from './telemetry.actions';
import { TelemetryStoreService } from 'src/app/services/telemetry-store.service';
import { SocketClientService } from 'src/app/services/socket-client.service';
import { of } from 'rxjs';

@Injectable()
export class TelemetryEffects {
  private service = inject(TelemetryStoreService);
  private socket = inject(SocketClientService);

  loadTelemetries$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(TelemetryActions.loadTelemetries),
      switchMap(() =>
        this.service.getAll().pipe(
          map((telemetries) =>
            TelemetryActions.loadTelemetriesSuccess({
              telemetries: telemetries,
            }),
          ),
          catchError((error) =>
            of(TelemetryActions.loadTelemetriesFailure({ error })),
          ),
        ),
      ),
    );
  });

  updateTelemetry$ = createEffect(() => {
    return inject(Actions).pipe(
      ofType(TelemetryActions.updateTelemetry),
      mergeMap(() =>
        this.socket.telemetryObservable.pipe(
          map((telemetry) =>
            TelemetryActions.loadTelemetriesSuccess({
              telemetries: [telemetry],
            }),
          ),
          catchError((error) =>
            of(TelemetryActions.loadTelemetriesFailure({ error })),
          ),
        ),
      ),
    );
  });
}
