import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ArchivedMeasurement } from 'src/app/services/measurearchive-store';
// import { TelemetryMeasurement } from 'src/app/shared/Telemetry-Measurements';

export const ArchiveActions = createActionGroup({
  source: 'ArchivedMeasurement',
  events: {
    'Load ArchivedMeasurements': emptyProps(),
    'Load ArchivedMeasurements Success': props<{
      data: ArchivedMeasurement[];
    }>(),
    'Load ArchivedMeasurements Failure': props<{ error: HttpErrorResponse }>(),

    'Update ArchivedMeasurements': emptyProps(),
  },
});
