import { createReducer, on } from '@ngrx/store';
import { Document } from 'src/app/models/Document';
import { DocumentActions } from './document.actions';
import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { HttpErrorResponse } from '@angular/common/http';

export const documentFeatureKey = 'document';

export const adapter = createEntityAdapter<Document>({
  selectId: (document) => document.Uid,
});

export interface State extends EntityState<Document> {
  loading: boolean;
  error: HttpErrorResponse | null;
}

export const initialState: State = adapter.getInitialState({
  loading: false,
  error: null,
});

export const reducer = createReducer(
  initialState,

  on(DocumentActions.loadSocket, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(DocumentActions.loadDocumentsStation, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(DocumentActions.loadDocumentsContract, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(DocumentActions.loadDocumentsFactory, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(DocumentActions.loadDocumentsSuccess, (state, action): State => {
    return {
      ...adapter.setAll(action.documents, state),
      loading: false,
      error: null,
    };
  }),

  on(DocumentActions.loadDocumentsFailure, (state, action): State => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),
);
