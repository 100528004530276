import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-p404',
  templateUrl: './p404.component.html',
  styleUrls: ['./p404.component.css'],
})
export class P404Component {
  private _background = [
    'Hintergrund_Microsoft_Teams_v1.jpg',
    'Hintergrund_Microsoft_Teams_v2.jpg',
    'Hintergrund_Microsoft_Teams_v3.jpg',
    'Hintergrund_Microsoft_Teams_v4.jpg',
    'Hintergrund_Microsoft_Teams_v5.jpg',
  ];
  public background = this._background[this._background.length];

  constructor(private router: Router) {
    const min = 0;
    const max = this._background.length - 1;

    this.background =
      this._background[Math.floor(Math.random() * (max - min + 1)) + min];
  }

  onSubmit() {
    this.router.navigate(['/']);
  }
}
