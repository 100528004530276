<div class="card">
  <h3 class="card-header">
    <cds-icon shape="details" size="md"></cds-icon>&nbsp;Messwerte -
    {{ telemetry.MQTT.Id }}
    <span style="float: right">
      <cds-icon shape="line-chart" size="md"></cds-icon>
    </span>
  </h3>

  <div class="card-block">
    <!-- <h4 class="card-title">Title</h4> -->
    <div class="card-text">
      <div
        echarts
        [initOpts]="initOpts"
        [options]="chartOption"
        [merge]="mergeOption"
      ></div>
    </div>
  </div>
  <!-- <div class="card-footer">Footer</div> -->
</div>
