<clr-datagrid [clrDgLoading]="loading">
  <clr-dg-placeholder>Wir konnten keine Einträge finden!</clr-dg-placeholder>

  <clr-dg-column [clrDgField]="'Station.Name'">
    <ng-container *clrDgHideableColumn="{ hidden: false }">
      <cds-icon shape="tag"></cds-icon>&nbsp;Name
    </ng-container>
  </clr-dg-column>

  <clr-dg-column [clrDgField]="'Station.Telemetry.Name'">
    <ng-container *clrDgHideableColumn="{ hidden: hiddenLocation }">
      <cds-icon shape="flag"></cds-icon>&nbsp;Standort
    </ng-container>
  </clr-dg-column>

  <clr-dg-row *clrDgItems="let item of Items" [clrDgItem]="item">
    <clr-dg-cell>
      <a [routerLink]="['/stations', item.Station.Uid]">{{
        item.Station.Name
      }}</a>
    </clr-dg-cell>
    <clr-dg-cell>
      <a [routerLink]="['/stations', item.Station.Uid]">{{
        item.Station.Telemetry?.Name
      }}</a>
    </clr-dg-cell>
  </clr-dg-row>

  <ng-container ngProjectAs="clr-dg-detail" *ngIf="extended">
    <clr-dg-detail *clrIfDetail="let item">
      <clr-dg-detail-header>
        <cds-icon shape="tag"></cds-icon>&nbsp;<a
          [routerLink]="['/stations', item.Station.Uid]"
          >{{ item.Station.Name | uppercase }}</a
        >
      </clr-dg-detail-header>

      <clr-dg-detail-body>
        <ng-container *ngIf="item.Stromlaufplan.length > 0">
          <div cds-text="subsection">
            <cds-icon shape="file-group"></cds-icon> Schaltpläne
          </div>
          <app-documents-list-grid
            [extended]="false"
            [documents]="item.Stromlaufplan"
          ></app-documents-list-grid>
        </ng-container>

        <p></p>

        <div cds-text="subsection">
          <cds-icon shape="file-group"></cds-icon> Thermografie
        </div>
        <app-documents-list-grid
          [extended]="false"
          [documents]="item.Thermografie"
        ></app-documents-list-grid>
        <p></p>

        <div cds-text="subsection">
          <cds-icon shape="file-group"></cds-icon> Protokoll
        </div>
        <app-documents-list-grid
          [extended]="false"
          [documents]="item.Protokoll"
        ></app-documents-list-grid>
      </clr-dg-detail-body>
    </clr-dg-detail>
  </ng-container>

  <clr-dg-footer>
    <clr-dg-pagination #pagination [clrDgPageSize]="10">
      <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"
        >Stationen pro Seite</clr-dg-page-size
      >
      {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} von
      {{ pagination.totalItems }} Stationen
    </clr-dg-pagination>
  </clr-dg-footer>
</clr-datagrid>
