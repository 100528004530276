import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Document } from 'src/app/models/Document';

export const DocumentActions = createActionGroup({
  source: 'Document',
  events: {
    'Load Socket': emptyProps(),

    'Load Documents Station': props<{ uid: string }>(),
    'Load Documents Factory': props<{ uid: string }>(),
    'Load Documents Contract': props<{ uid: string }>(),

    'Load Documents Success': props<{ documents: Document[] }>(),
    'Load Documents Failure': props<{ error: HttpErrorResponse }>(),
  },
});
