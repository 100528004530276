import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { Observable } from 'rxjs';
import { Station } from 'src/app/models/Station';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { LayoutService, ThemeMode } from 'src/app/shared/layout.service';
import { DarkModeStyle } from 'src/app/constants/dark-mode-style';
import { LightModeStyle } from 'src/app/constants/light-mode-style';

interface marker {
  isValid: boolean;
  position: {
    lat: number;
    lng: number;
  };
  options: {
    animation?: any;
    opacity?: number;
    icon?: google.maps.Icon;
  };
  Station: Station;
}

@Component({
  selector: 'app-stations-list-map',
  templateUrl: './stations-list-map.component.html',
  styleUrls: ['./stations-list-map.component.css'],
})
export class StationsListMapComponent
  implements OnInit, OnChanges, AfterViewInit
{
  @Input() stations = [] as Station[];
  @Input() loading = false;
  @Input() error: HttpErrorResponse | null = null;

  @Input() extern = false;

  @ViewChild(GoogleMap) map!: GoogleMap;
  mapOptions: google.maps.MapOptions = {
    styles:
      this.layoutService.getThemeMode() === 'dark'
        ? DarkModeStyle
        : LightModeStyle,
  };
  markerPositions: marker[] = [];

  constructor(
    private router: Router,
    private layoutService: LayoutService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.onNext();
  }

  ngOnInit(): void {
    this.layoutService.themeMode$.subscribe((themeMode: ThemeMode) => {
      if (themeMode === 'dark') {
        this.map?.googleMap?.setOptions({ styles: DarkModeStyle });
      } else {
        this.map?.googleMap?.setOptions({ styles: LightModeStyle });
      }
    });
  }

  ngAfterViewInit(): void {
    this.onNext();
  }

  onNext() {
    if (!this.map) {
      return;
    }

    this.stations.forEach((station) => {
      if (station.Location?.Coordinates) {
        if (station.Location.Coordinates.length >= 2) {
          // const markerPosition = {
          //   isValid: true,
          //   Station: station,
          //   position: {
          //     lat: station.Location.Coordinates[0],
          //     lng: station.Location.Coordinates[1],
          //   },
          //   options: {
          //     // animation: google.maps.Animation.DROP,
          //     // opacity: 1.0,
          //     // icon: {
          //     //   url: 'https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png',
          //     //   // size: new google.maps.Size(64,64)
          //     // },
          //   },
          // };

          // this.markerPositions.push(markerPosition);

          const markerPosition2 = {
            isValid: true,
            Station: station,
            position: {
              lat: station.Location.Coordinates[0],
              lng: station.Location.Coordinates[1],
            },
            options: {
              // animation: google.maps.Animation.DROP,
              opacity: 1.0,
              icon: {
                url: 'https://connect.vrielmann.com/assets/logo_24x24.png',
                // size: new google.maps.Size(24, 24),
              },
            },
          };

          this.markerPositions.push(markerPosition2);
        }
      }
    });

    if (this.markerPositions.length) {
      const bounds = this.getBounds(this.markerPositions);
      this.map.fitBounds(bounds);
    }

    // const bounds = this.getBounds(this.markerPositions);
    // this.map.fitBounds(bounds);
  }

  mapClick(m: marker) {
    if (m.isValid) {
      this.router.navigate(['/stations', m.Station.Uid]);
    }
  }

  getBounds(markers: any) {
    let north, south, east, west;
    for (const marker of markers) {
      north =
        north !== undefined
          ? Math.max(north, marker.position.lat)
          : marker.position.lat;
      south =
        south !== undefined
          ? Math.min(south, marker.position.lat)
          : marker.position.lat;
      east =
        east !== undefined
          ? Math.max(east, marker.position.lng)
          : marker.position.lng;
      west =
        west !== undefined
          ? Math.min(west, marker.position.lng)
          : marker.position.lng;
    }
    return { north, south, east, west };
  }
}
