import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  inject,
} from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription, finalize } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Cabinet } from 'src/app/models/Cabinet';
import { Template } from 'src/app/models/Template';
import { AssessmentStoreService } from 'src/app/services/assessment.service';

import {
  selectTemplate,
  selectTemplatesLoading,
  selectTemplatesError,
} from '../+template/template.selectors';
import { TemplateActions } from '../+template/template.actions';

import { CabinetActions } from '../+cabinet/cabinet.actions';

@Component({
  selector: 'app-dialog-import-cabinet',
  templateUrl: './dialog-import-cabinet.component.html',
  styleUrls: ['./dialog-import-cabinet.component.css'],
})
export class DialogImportCabinetComponent implements OnInit {
  @Output() basic = new EventEmitter<boolean>();
  @Input() template$ = new Observable<Template>();

  template = {} as Template;

  cabinet$ = new Observable<Cabinet>();
  loading$ = new Observable<boolean>();
  error$ = new Observable<HttpErrorResponse | null>();

  excelFile: File | null = null;

  uploadProgress = 0;
  uploadSub$ = new Subscription();

  private formBuilder = inject(FormBuilder);

  formDynamisch = this.formBuilder.group({});

  formMobile = this.formBuilder.group({
    _id: new FormControl('', { nonNullable: true }),
    ExcelFile: this.formBuilder.group({
      Path: new FormControl('', { nonNullable: true }),
      Name: new FormControl('', { nonNullable: true }),
      id: new FormControl('', { nonNullable: true }),
      cid: new FormControl('', { nonNullable: true }),
    }),
    Files: this.formBuilder.array([]),
  });

  get filesArray() {
    return this.formMobile.get('Files') as FormArray;
  }

  constructor(
    private store: Store,
    private authService: AuthService,
    private riskService: AssessmentStoreService,
  ) {
    const uid = this.authService.readUid();
    console.log(`constructor() uid:${uid}`);
  }

  ngOnInit() {
    this.template$.subscribe((template) => {
      this.template = template;
      template.Anlage?.map((anlage) => {
        this.formDynamisch.addControl(
          anlage._id || '',
          this.formBuilder.control(''),
        );
      });
    });
  }

  onSubmitAnlage() {
    console.log(
      `onSubmitAnlage() -> template ${
        this.template ? `(${this.template._id})` : 'is null'
      }`,
    );
    const anlage = this.formDynamisch.getRawValue() as {
      _id: string;
      Template: Template;
      Anlage: [{ ItemIndex: number; Value: object }];
    };

    const anlageArrayObject = Object.entries(anlage).map(
      ([Title, Value], index) =>
        ({
          ItemId: this.template.Anlage.find((x) => x.ItemIndex == index)?._id,
          Value: Value,
        }) as { ItemId: string; Value: object },
    );

    const mobile: Cabinet = {
      _id: anlage._id,
      Uid: this.authService.readUid(),
      Template: this.template._id,
      Anlage: anlageArrayObject,
    };

    this.store.dispatch(CabinetActions.postCabinet({ cabinet: mobile }));
    this.basic.emit(true);
  }

  onSubmitExcelFile() {
    const form = this.formMobile.getRawValue();
    // // console.log(form);
    // const cabinet: Cabinet = {
    //   _id: form._id,
    //   Uid: this.authService.readUid(),
    // } as Cabinet;

    if (this.excelFile) {
      const upload$ = this.riskService
        .excelImport(this.excelFile, form.ExcelFile.Name, this.template)
        .pipe(finalize(() => this.resetUpload()));
      this.uploadSub$ = upload$.subscribe((event) => {
        if (event.type == HttpEventType.UploadProgress) {
          const total = event.total || event.loaded;
          this.uploadProgress = Math.round(100 * (event.loaded / total));
          // if (event.total == event.loaded) {
          //   console.log('event.total == event.loaded');
          //   this.basic.emit(true);
          // }
          if (this.uploadProgress == 100) {
            console.log('this.uploadProgress == 100');
            this.basic.emit(true);
          }
        }
        if (event.type == HttpEventType.Response) {
          if (event.body !== null) {
            const cabinet: Cabinet = event.body as Cabinet;
            if (cabinet._id) {
              this.store.dispatch(
                CabinetActions.loadCabinet({ id: cabinet._id }),
              );
            }
          }
        }
      });
    }
    // this.basic.emit(true);
  }

  onExcelImportSelected(event: any) {
    console.log('onExcelImportSelected()', event);
    this.excelFile = event.target.files[0];
    console.log('Filename:', this.excelFile?.name);
  }

  onCancelUpload() {
    this.uploadSub$.unsubscribe();
    this.resetUpload();
    this.closeDialog();
  }

  resetUpload() {
    this.uploadProgress = 0;
    this.uploadSub$.unsubscribe();
  }

  onUpdateFile(control: any) {
    const fileControl: FormGroup = control;

    const filePath = fileControl.get('Path')?.value;
    const fileName = fileControl.get('Name')?.value;
    const fileId = fileControl.get('id')?.value;
    const cabinetId = fileControl.get('cid')?.value;
  }

  onDeleteFile(control: any) {
    const fileControl: FormGroup = control;

    // const filePath = fileControl.get('Path')?.value;
    // const fileName = fileControl.get('Name')?.value;
    const fileId = fileControl.get('id')?.value;
    const cabinetId = fileControl.get('cid')?.value;

    this.store.dispatch(
      CabinetActions.deleteImage({ cid: cabinetId, id: fileId }),
    );
  }

  openChange(value: boolean) {
    console.log(`openChange(value: ${value})`);
    this.basic.emit(false);
  }

  closeDialog() {
    this.basic.emit(false);
  }
}
