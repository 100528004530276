import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  inject,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Cabinet } from 'src/app/models/Cabinet';
import { Template } from 'src/app/models/Template';
import { CabinetActions } from '../+cabinet/cabinet.actions';

@Component({
  selector: 'app-dialog-add-cabinet',
  templateUrl: './dialog-add-cabinet.component.html',
  styleUrls: ['./dialog-add-cabinet.component.css'],
})
export class DialogAddCabinetComponent implements OnInit {
  @Output() basic = new EventEmitter<boolean>();
  @Input() template$ = new Observable<Template>();

  cabinet$ = new Observable<Cabinet>();
  loading$ = new Observable<boolean>();
  error$ = new Observable<HttpErrorResponse | null>();
  template = {} as Template;

  private formBuilder = inject(FormBuilder);

  formDynamisch = this.formBuilder.group({});

  // get dynamisch() {
  //   return this.formDynamisch.controls;
  // }

  constructor(
    private store: Store,
    private authService: AuthService,
  ) {
    const uid = this.authService.readUid();
    console.log(`constructor() uid:${uid}`);

    // this.store.dispatch(TemplateActions.loadTemplates({ uid: uid }));
    // this.store.dispatch(TemplateActions.loadTemplatesUid({ uid: uid }));
    // this.template$ = this.store.select(selectTemplate(uid));
    // this.loading$ = this.store.select(selectTemplatesLoading);
    // this.error$ = this.store.select(selectTemplatesError);
  }

  // ngOnInit(): void {
  //   console.log('template', this.template);
  // }

  ngOnInit() {
    this.template$.subscribe((template) => {
      this.template = template;
      template.Anlage?.map((anlage) => {
        this.formDynamisch.addControl(
          anlage._id || '',
          this.formBuilder.control(''),
        );
      });
    });
  }

  onSubmitAnlage() {
    console.log('onSubmitAnlage()');
    const anlage = this.formDynamisch.getRawValue() as {
      _id: string;
      Template: Template;
      Anlage: [{ ItemIndex: number; Value: object }];
    };

    const anlageArrayObject = Object.entries(anlage).map(
      ([Title, Value], index) =>
        ({
          ItemId: this.template.Anlage.find((x) => x.ItemIndex == index)?._id,
          Value: Value,
        }) as { ItemId: string; Value: object },
    );

    const mobile: Cabinet = {
      _id: anlage._id,
      Uid: this.authService.readUid(),
      Template: this.template._id,
      Anlage: anlageArrayObject,
    };

    this.store.dispatch(CabinetActions.postCabinet({ cabinet: mobile }));
    this.basic.emit(true);
  }

  openChange(value: boolean) {
    console.log(`openChange(value: ${value})`);
    this.basic.emit(false);
  }
}
