import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface ArchivedMeasurement {
  Client: string;
  System: string;
  Location: string;
  Device: string;
  // Channel: string;
  SignalName: string;
  Time: Date;
  Value: number;
  SignalUnit: string;
}

export class ArchivedMearueOject implements ArchivedMeasurement {
  Client = '';
  System = '';
  Location = '';
  Device = '';
  // Channel: string = '';
  SignalName = '';
  Time: Date = new Date();
  Value = 0;
  SignalUnit = '';

  // constructor(
  //   Client: string,
  //   System: string,
  //   Location: string,
  //   Device: string,
  //   Channel: string,
  //   SignalName: string,
  //   Time: Date,
  //   Value: number,
  //   SignalUnit: string,
  // ) {
  //   this.Client = Client;
  //   this.System = System;
  //   this.Location = Location;
  //   this.Device = Device;
  //   this.Channel = Channel;
  //   this.SignalName = SignalName;
  //   this.Time = Time;
  //   this.Value = Value;
  //   this.SignalUnit = SignalUnit;
  // }

  public getArchiveKey(): string {
    return `${this.System}_${this.Location}_${this.Device}_${this.SignalName}`;
  }

  Key = `${this.System}_${this.Location}_${this.Device}_${this.SignalName}`;
}

@Injectable({
  providedIn: 'root',
})
export class MeasureStoreService {
  private apiUrl = environment.tsdbApi;

  constructor(private http: HttpClient) {}

  public requestedArchivedTelemetries$ = new Observable<
    ArchivedMearueOject[]
  >();

  getAll(): Observable<ArchivedMearueOject[]> {
    return this.requestedArchivedTelemetries$.pipe();
  }

  get(uid: string, json: object): Observable<ArchivedMearueOject[]> {
    if (uid == null) {
      this.requestedArchivedTelemetries$ = this.http.post<
        ArchivedMearueOject[]
      >(`${this.apiUrl}/measurements`, json);
    } else {
      this.requestedArchivedTelemetries$ = this.http.post<
        ArchivedMearueOject[]
      >(`${this.apiUrl}/measurements/${uid}`, json);
    }
    this.requestedArchivedTelemetries$.subscribe((archivedMeasurements) => {
      this.requestedArchivedTelemetries$.pipe();
    });
    return this.requestedArchivedTelemetries$.pipe();
  }

  // public requestedArchivedTelemetries$ = new Observable<
  //   ArchivedMeasurement[]
  // >();

  // getAll(): Observable<ArchivedMeasurement[]> {
  //   return this.requestedArchivedTelemetries$.pipe();
  // }

  // get(uid: string, json: object): Observable<ArchivedMeasurement[]> {
  //   if (uid == null) {
  //     this.requestedArchivedTelemetries$ = this.http.post<
  //       ArchivedMeasurement[]
  //     >(`${this.apiUrl}/measurements`, json);
  //   } else {
  //     this.requestedArchivedTelemetries$ = this.http.post<
  //       ArchivedMeasurement[]
  //     >(`${this.apiUrl}/measurements/${uid}`, json);
  //   }
  //   this.requestedArchivedTelemetries$.subscribe((archivedMeasurements) => {
  //     this.requestedArchivedTelemetries$.pipe();
  //   });
  //   return this.requestedArchivedTelemetries$.pipe();
  // }
}
