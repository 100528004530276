import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  selectUsersError,
  selectUserDeleteSuccess,
} from '../+store/user.selectors';
import { Store } from '@ngrx/store';
import { User } from 'src/app/models/User';
import { UserActions } from '../+store/user.actions';
import { ClrLoadingState } from '@clr/angular';

@Component({
  selector: 'app-users-form-item-delete',
  templateUrl: './users-form-item-delete.component.html',
  styleUrls: ['./users-form-item-delete.component.css'],
})
export class UsersFormItemDeleteComponent {
  @Input() user = {} as User;
  @Output() deleted = new EventEmitter<boolean>();

  error$ = this.store.select(selectUsersError);
  success$ = this.store.select(selectUserDeleteSuccess);

  validateBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(private store: Store) {}

  onDeleteCancel() {
    this.deleted.emit(false);
  }

  onDeleteSubmit() {
    if (this.user?._id) {
      this.validateBtnState = ClrLoadingState.LOADING;

      this.store.dispatch(UserActions.deleteUser({ id: this.user._id }));

      this.success$.subscribe((value) => {
        this.validateBtnState = ClrLoadingState.SUCCESS;
        this.deleted.emit(true);
      });

      this.error$.subscribe((error) => {
        this.validateBtnState = ClrLoadingState.ERROR;
        this.deleted.emit(false);
      });
    }
  }
}
