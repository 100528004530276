<clr-modal [clrModalOpen]="true">
  <h3 class="modal-title">
    <cds-icon shape="user" size="md"></cds-icon>
    <ng-container *ngIf="selected$ | async as selected">
      {{ selected.Username }}
    </ng-container>
  </h3>

  <div class="modal-body">
    <div *ngIf="(error$ | async)?.status">
      <div
        *ngIf="error$ | async as error"
        class="alert alert-danger"
        role="alert"
      >
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon
                class="alert-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </div>
            <span class="alert-text">
              {{ error.status }} | {{ error.statusText }}
            </span>
          </div>
        </div>
      </div>
      <p></p>
    </div>

    <ng-container *ngIf="selected$ | async as selected">
      <div cds-text="subsection">Konto</div>
      <button
        type="button"
        class="btn btn-icon btn-primary"
        (click)="onUnLock(selected)"
      >
        <cds-icon shape="unlock"></cds-icon>
        &nbsp;freigeben
      </button>
      <button
        type="button"
        class="btn btn-icon btn-warning"
        (click)="onLock(selected)"
      >
        <cds-icon shape="lock"></cds-icon>
        &nbsp;sperren
      </button>
      <p></p>

      <div cds-text="subsection">Status</div>
      <table class="table table-vertical">
        <tbody>
          <tr>
            <th style="width: 20%">Status</th>
            <td>
              <cds-icon
                *ngIf="selected.SignIn.IsBlocked"
                shape="lock"
              ></cds-icon>
              {{ selected.SignIn.Blocked | date: "EEEE, dd.MM.YYYY HH:mm:ss" }}
              <cds-icon
                *ngIf="!selected.SignIn.IsBlocked"
                shape="unlock"
              ></cds-icon>
            </td>
          </tr>
        </tbody>
      </table>
      <p></p>

      <!--  -->
      <div cds-text="subsection">Profil</div>
      <form clrForm [formGroup]="formEdit">
        <!-- Firstname -->
        <clr-input-container>
          <label class="clr-col-12 clr-col-md-4 clr-required-mark" for="mail"
            >Vorname</label
          >
          <input
            clrInput
            formControlName="Firstname"
            class="clr-col-12 clr-col-md-8"
            style="width: 90%"
            id="firstname"
            type="text"
            [class.is-invalid]="firstname?.invalid && firstname?.touched"
          />
          <clr-control-error>
            <small *ngIf="firstname?.errors?.['required']" class="text-danger"
              >Vorname ist erforderlich.</small
            >
          </clr-control-error>
        </clr-input-container>

        <!-- Lastname -->
        <clr-input-container>
          <label class="clr-col-12 clr-col-md-4 clr-required-mark" for="mail"
            >Nachname</label
          >
          <input
            clrInput
            formControlName="Lastname"
            class="clr-col-12 clr-col-md-8"
            style="width: 90%"
            id="lastname"
            type="text"
            [class.is-invalid]="lastname?.invalid && lastname?.touched"
          />
          <clr-control-error>
            <small *ngIf="lastname?.errors?.['required']" class="text-danger"
              >Nachname ist erforderlich.</small
            >
          </clr-control-error>
        </clr-input-container>

        <!-- Rollen -->
        <clr-combobox-container>
          <label class="clr-col-12 clr-col-md-4" for="multiSelect"
            >Rollen</label
          >
          <clr-combobox
            formControlName="Roles"
            class="clr-col-12 clr-col-md-8"
            style="width: 100%"
            id="multiSelect"
            clrMulti="true"
          >
            <ng-container *clrOptionSelected="let selected">
              {{ selected?.Name }}
            </ng-container>
            <clr-options>
              <clr-option
                *clrOptionItems="let role of roles; field: 'Symbol'"
                [clrValue]="role"
              >
                {{ role.Name }}
              </clr-option>
            </clr-options>
          </clr-combobox>
        </clr-combobox-container>
      </form>
      <p></p>

      <!-- <div cds-text="subsection">SignIn</div> -->
      <!-- <table class="table table-vertical">
        <tbody>
          <tr>
            <th style="width: 20%">Blocked</th>
            <td>
              <cds-icon
                *ngIf="selected.SignIn.IsBlocked"
                shape="lock"
              ></cds-icon>
              {{ selected.SignIn.Blocked | date: "EEEE, dd.MM.YYYY HH:mm:ss" }}
              <cds-icon
                *ngIf="!selected.SignIn.IsBlocked"
                shape="unlock"
              ></cds-icon>
            </td>
          </tr>
        </tbody>
      </table> -->
      <!-- <p></p> -->

      <!-- <div cds-text="subsection">SignUp</div> -->
      <!-- <table class="table table-vertical">
        <tbody>
          <tr>
            <th style="width: 20%">Invited</th>
            <td>
              <cds-icon
                *ngIf="selected.SignUp.IsInvited"
                shape="check"
              ></cds-icon>
              {{ selected.SignUp.Invited | date: "EEEE, dd.MM.YYYY HH:mm:ss" }}
              <cds-icon
                *ngIf="!selected.SignUp.IsInvited"
                shape="times"
              ></cds-icon>
            </td>
          </tr>
          <tr>
            <th>Signed</th>
            <td>
              <cds-icon
                *ngIf="selected.SignUp.IsSigned"
                shape="check"
              ></cds-icon>
              {{ selected.SignUp.Signed | date: "EEEE, dd.MM.YYYY HH:mm:ss" }}
              <cds-icon
                *ngIf="!selected.SignUp.IsSigned"
                shape="times"
              ></cds-icon>
            </td>
          </tr>
          <tr>
            <th>Verified</th>
            <td>
              <cds-icon
                *ngIf="selected.SignUp.IsVerified"
                shape="check"
              ></cds-icon>
              {{ selected.SignUp.Verified | date: "EEEE, dd.MM.YYYY HH:mm:ss" }}
              <cds-icon
                *ngIf="!selected.SignUp.IsVerified"
                shape="times"
              ></cds-icon>
            </td>
          </tr>
        </tbody>
      </table> -->
    </ng-container>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="onEditedCancel()">
      schließen
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onUpdateProfil()"
      [clrLoading]="submitBtnUser"
      [disabled]="formEdit.invalid"
    >
      <cds-icon shape="sync"></cds-icon> übernehmen
    </button>
  </div>
</clr-modal>
