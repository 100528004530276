<div
  class="login-wrapper"
  style="background-image: url(/assets/images/{{ background }});"
>
  <form class="login">
    <section class="title">
      <h3 class="welcome">
        <img src="assets/logo-c.png" height="24" alt="Vrielmann Connect" />
        Vrielmann Connect
        <!-- <cds-icon shape="connect"></cds-icon> -->
        <!-- &nbsp;<cds-icon shape="disconnect"></cds-icon> -->
      </h3>
      Herzlich Willkommen
      <h5 class="hint">
        <ng-container *ngIf="!succes && !error">
          <cds-icon shape="info-standard"></cds-icon>
          Verifizierung
        </ng-container>
        <ng-container *ngIf="succes">
          <cds-icon shape="success-standard" status="success"></cds-icon>
          Verifizierung
        </ng-container>
        <ng-container *ngIf="error">
          <cds-icon shape="error-standard" status="danger"></cds-icon>
          Verifizierung
        </ng-container>
      </h5>
    </section>

    <div class="login-group">
      <!-- <clr-input-container>
        <label>E-Mail Adresse</label>
        <input
          clrInput
          type="text"
          [(ngModel)]="model.Username"
          name="Username"
          disabled
        />
      </clr-input-container> -->

      <ng-container *ngIf="!succes && !error">
        <span class="spinner">Loading...</span>
      </ng-container>

      <ng-container *ngIf="succes">
        Ihre E-Mail-Adresse {{ model.Username }} wurde erfolgreich verifiziert.
        Sie können sich jetzt anmelden.
        <p>
          <a
            [routerLink]="['/signin']"
            [queryParams]="{ username: model.Username }"
            >Hier anmelden</a
          >
        </p>
      </ng-container>

      <ng-container *ngIf="error">
        Die Verifizierung war nicht erfolgreich. Bitte überprüfen Sie den Link
        oder kontaktieren Sie den Support.
      </ng-container>

      <!-- <a routerLink="/signin" class="btn btn-primary">LOGIN</a> -->
    </div>
  </form>
</div>
