// Angular Core and Module Imports
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { NgModule, isDevMode, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { ClarityModule, ClrCommonStringsService } from '@clr/angular';

// Third-party Library Imports
import { CodeInputModule } from 'angular-code-input';
import { MqttModule, IMqttServiceOptions } from 'ngx-mqtt';
import { ToastrModule } from 'ngx-toastr';

// Routing Module
import { AppRoutingModule } from './app-routing.module';

// Services and Interceptors
import { AuthInterceptor } from './services/auth.interceptor';
import { RouteManagerInterceptor } from 'src/app/services/route.interceptor';

// MQTT Configuration Constants
import {
  MQTT_PROTOCOL,
  MQTT_PROTOCOL_ID,
  MQTT_PROTOCOL_VERSION,
} from './constants/mqtt';

// MQTT Service Options
export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  connectOnCreate: false,
  clean: true,
  hostname: environment.mqttHostname,
  port: environment.mqttPort,
  path: environment.mqttPath,
  protocol: MQTT_PROTOCOL,
  protocolVersion: MQTT_PROTOCOL_VERSION,
  protocolId: MQTT_PROTOCOL_ID,
};

// Module
import { InfoModule } from './views/info/info.module';

// Components
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { P404Component } from './pages/p404/p404.component';
import { SignUpComponent } from './pages/sign-up/sign-up.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { VerifyComponent } from './pages/verify/verify.component';
import { RequestPwdComponent } from './pages/request-pwd/request-pwd.component';
import { RenewPwdComponent } from './pages/renew-pwd/renew-pwd.component';

// Environment and Locale
import { environment } from 'src/environments/environment';
import { germanLocale } from './translations/german';

// Clarity Icons
import '@cds/core/icon/register.js';

import {
  ClarityIcons,
  // resistorIcon,
  // inductorIcon,
  // capacitorIcon,
  envelopeIcon,
  connectIcon,
  disconnectIcon,
  loginIcon,
  logoutIcon,
  lockIcon,
  infoStandardIcon,
  successStandardIcon,
  errorStandardIcon,
  gavelIcon,
  angleIcon,
} from '@cds/core/icon';

// Additional Configuration
ClarityIcons.addIcons(
  // resistorIcon,
  // inductorIcon,
  // capacitorIcon,
  envelopeIcon,
  connectIcon,
  disconnectIcon,
  loginIcon,
  logoutIcon,
  lockIcon,
  infoStandardIcon,
  successStandardIcon,
  errorStandardIcon,
  gavelIcon,
  angleIcon,
);

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    P404Component,
    SignUpComponent,
    SignInComponent,
    VerifyComponent,
    RequestPwdComponent,
    RenewPwdComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ClarityModule,
    AppRoutingModule,
    LayoutModule,
    StoreModule.forRoot({}, {}),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    EffectsModule.forRoot([]),
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    CodeInputModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
    ToastrModule.forRoot(),
    InfoModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RouteManagerInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'de' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(commonStrings: ClrCommonStringsService) {
    commonStrings.localize(germanLocale);
  }
}
