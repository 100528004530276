import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { StationsListComponent } from './stations-list/stations-list.component';
import { StationsDetailComponent } from './stations-detail/stations-detail.component';

const routes: Routes = [
  { path: '', component: StationsListComponent },
  { path: ':uid', component: StationsDetailComponent },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class StationsRoutingModule {}
