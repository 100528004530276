import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Contract } from '../models/Contract';

@Injectable({
  providedIn: 'root',
})
export class ContractStoreService {
  private apiUrl = environment.exportApi;

  constructor(private http: HttpClient) {}

  getAll(): Observable<Contract[]> {
    const headers = new HttpHeaders({
      'X-Route-Interceptor': 'true',
      'X-Route': 'contracts',
    });

    return this.http.get<Contract[]>(this.apiUrl, { headers });
  }

  getSingle(uid: string): Observable<Contract> {
    const headers = new HttpHeaders({
      'X-Route-Interceptor': 'true',
      'X-Route': 'contracts',
      'X-Param': '/' + uid,
    });

    return this.http.get<Contract>(this.apiUrl, { headers });
  }
}
