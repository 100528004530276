import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Cabinet, LowCabinet } from '../models/Cabinet';
import { Image } from '../models/Image';
import { Template } from '../models/Template';
import { Item, LowItem } from '../models/Item';
import { Evaluation } from '../models/Evaluation';

@Injectable({
  providedIn: 'root',
})
export class AssessmentStoreService {
  private apiUrl = environment.riskApi;

  constructor(private http: HttpClient) {}

  // ------------------ Cabinet ------------------- //
  // ------------------ Cabinet (Single) ---------- //

  getCabinet(id: string): Observable<Cabinet> {
    console.log(`GET ${this.apiUrl}/cabinets/${id}`);
    return this.http.get<Cabinet>(`${this.apiUrl}/cabinets/${id}`);
  }

  postCabinet(cabinet: LowCabinet): Observable<Cabinet> {
    console.log(`POST ${this.apiUrl}/cabinets`, cabinet);
    return this.http.post<Cabinet>(`${this.apiUrl}/cabinets`, cabinet);
  }

  patchCabinet(cabinet: Cabinet): Observable<Cabinet> {
    try {
      console.log(`PATCH ${this.apiUrl}/cabinets/${cabinet._id}`);
      return this.http.patch<Cabinet>(
        `${this.apiUrl}/cabinets/${cabinet._id}`,
        cabinet,
      );
    } catch (err) {
      console.log(err);
      return new Observable<Cabinet>();
    }
  }

  deleteCabinet(id: string): Observable<Cabinet> {
    return this.http.delete<Cabinet>(`${this.apiUrl}/cabinets/${id}`);
  }

  // ------------------ Cabinet (Multi) ----------- //

  getCabinets(): Observable<Cabinet[]> {
    console.log(`${this.apiUrl}/cabinets`);
    return this.http.get<any[]>(`${this.apiUrl}/cabinets`);
  }

  // -------------- Cabinet.Image ----------------- //
  // requestNewPdfResultFor(id: string): Observable<any> {
  //   console.log(`${this.apiUrl}/pdfrequestfor/:id${id}`);
  //   return this.http.get(`${this.apiUrl}/evaluation/pdfrequestfor/${id}`);
  // }

  getImage(cabinetId: string, imageId: string): Observable<any> {
    console.log(`${this.apiUrl}/uploads/${cabinetId}/${imageId}`);
    return this.http.get(`${this.apiUrl}/uploads/${cabinetId}/${imageId}`);
  }
  // getImage(cabinetId: string, imageId: string): Observable<any> {
  //   return this.http.get<any>(`${this.apiUrl}/uploads/${imageId}`);
  // }
  // getImage(cabinetId: string, imageId: string): Observable<any> {
  //   return this.http.get<any>(
  //     `${this.apiUrl}/uploads/idc=${cabinetId}&id=${imageId}`,
  //   );
  // }

  getImages(): Observable<Image[]> {
    return this.http.get<any[]>(`${this.apiUrl}/images`);
  }

  deleteImage(cabinetId: string, id: string): Observable<Cabinet> {
    return this.http.delete<Cabinet>(
      `${this.apiUrl}/uploads/${cabinetId}/${id}`,
    );
  }

  // --------------- Cabinet.File ----------------- //

  fileUpload(file: File, fileName: string, cabinet: Cabinet) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    formData.append('cabinet', JSON.stringify(cabinet));

    return this.http.post(`${this.apiUrl}/uploads`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  excelImport(file: File, fileName: string, template: Template) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', fileName);
    formData.append('template', JSON.stringify(template));

    return this.http.post(`${this.apiUrl}/xlsximport`, formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  // --------------- Cabinet.Ergebnis-------------- //

  // getCabinetResult(id: string): Observable<CabinetCalculations> {
  //   console.log(`${this.apiUrl}/cabinets/result/:${id}`);
  //   return this.http.get<CabinetCalculations>(
  //     `${this.apiUrl}/cabinets/result/:${id}`,
  //   );
  // }

  // getCabinetEvaluation(cabinet: Cabinet) {
  //   return this.http.get<CabinetEvaluation>(
  //     `${this.apiUrl}/getcabinetevaluation/`,
  //     cabinet,
  //   );
  // }

  newEvaluationsFor(cabinetId: string | null | undefined) {
    if (cabinetId) {
      console.log(`${this.apiUrl}/evaluation/newForCabinet/${cabinetId}`);
      return this.http.get<Evaluation>(
        `${this.apiUrl}/evaluation/newForCabinet/${cabinetId}`,
      );
    } else {
      console.warn(
        `${this.apiUrl}/evaluation/newForCabinet/cabinetId \n\tcabinetId shouldn't be null`,
      );
      return new Observable<Evaluation>();
    }
  }

  getEvaluation(id: string) {
    console.log(`${this.apiUrl}/evaluation/${id}`);
    return this.http.get<Evaluation>(`${this.apiUrl}/evaluation/${id}`);
  }

  getEvaluationsFor(cabinetId: string) {
    console.log(`${this.apiUrl}/evaluation/byCabinet/${cabinetId}`);
    return this.http.get<[Evaluation]>(
      `${this.apiUrl}/evaluation/byCabinet/${cabinetId}`,
    );
  }

  getPdfEvaluation(id: string): Observable<any> {
    console.log(`${this.apiUrl}/pdfresult/:id${id}`);
    return this.http.get(`${this.apiUrl}/evaluation/pdfresult/${id}`);
  }

  requestNewPdfResultFor(id: string): Observable<any> {
    console.log(`${this.apiUrl}/pdfrequestfor/:id${id}`);
    return this.http.get(`${this.apiUrl}/evaluation/pdfrequestfor/${id}`);
  }

  getPdfEvaluationsFor(cabinetId: string): Observable<any> {
    console.log(`${this.apiUrl}/evaluation/pdfresults/${cabinetId}`);
    return this.http.get(`${this.apiUrl}/evaluation/pdfresults/${cabinetId}`);
  }

  // ------------------ Template ------------------ //
  // ------------------ Template (Single)---------- //
  getTemplate(id: string): Observable<Template> {
    console.log(`get ${this.apiUrl}/templates/:${id}`);
    return this.http.get<Template>(`${this.apiUrl}/templates/${id}`);
  }

  getActualTemplate(uid: string): Observable<Template> {
    console.log(`get ${this.apiUrl}/templates/actualbyusr/:${uid}`);
    return this.http.get<Template>(
      `${this.apiUrl}/templates/actualbyusr/${uid}`,
    );
  }

  postTemplate(tempalte: Template): Observable<Template> {
    console.log(
      `post ${this.apiUrl}/templates/ -> new for Uid'${tempalte.Uid}'`,
    );
    return this.http.post<Template>(`${this.apiUrl}/tempaltes`, tempalte);
  }

  patchTemplate(template: Template): Observable<Template> {
    console.log(`patch ${this.apiUrl}/templates/:${template._id}`);
    return this.http.patch<Template>(
      `${this.apiUrl}/templates/${template._id}`,
      template,
    );
  }

  deleteTemplate(id: string): Observable<Template> {
    console.log(`delete ${this.apiUrl}/templates/:${id}`);
    return this.http.delete<Template>(`${this.apiUrl}/templates/${id}`);
  }

  // ------------------ Template (Multi)----------- //

  getTemplates(): Observable<Template[]> {
    console.log(`get ${this.apiUrl}/templates/`);
    return this.http.get<Template[]>(`${this.apiUrl}/templates/`);
  }

  getUsrTemplates(uid: string): Observable<Template[]> {
    console.log(`${this.apiUrl}/templates/allbyusr/:${uid}`);
    return this.http.get<Template[]>(
      `${this.apiUrl}/templates/allbyusr/${uid}`,
    );
  }

  // -------------- Template.TabItems --------------- //

  postTemplateItem(
    tab: string,
    item: LowItem,
    templateId: string /*, value: Object*/,
  ): Observable<Template[]> {
    console.log(
      `post ${this.apiUrl}/items/${tab.toLowerCase()}item -> NEW item-title ${
        item.Title
      }'`,
    );
    return this.http.post<Template[]>(
      `${this.apiUrl}/items/${tab.toLowerCase()}item`,
      {
        Item: item,
        TemplateId: templateId,
        // Value: value,
      },
    );
  }

  deleteTemplateItem(tab: string, item: Item): Observable<Template> {
    console.log(`delete ${this.apiUrl}/items/${tab.toLowerCase()}item'`);
    return this.http.delete<Template>(
      `${this.apiUrl}/items/${tab.toLowerCase()}item/${item._id}`,
    );
  }

  editTemplateItem(tab: string, item: Item): Observable<Template[]> {
    console.log(
      `patch ${this.apiUrl}/items/${tab.toLowerCase()}item -> item-title ${
        item.Title
      }'`,
    );
    return this.http.patch<Template[]>(
      `${this.apiUrl}/items/${tab.toLowerCase()}item`,
      {
        Item: item,
      },
    );
  }
}
