<p></p>

<form clrForm [formGroup]="formDynamisch" (ngSubmit)="onSubmitTab()">
  <!-- <button class="btn btn-outline" (click)="onAddFieldClick()">
    <cds-icon shape="plus-circle"></cds-icon> feld hinzufügen
  </button> -->
  <button
    type="submit"
    class="btn btn-primary"
    [clrLoading]="submitTabState"
    style="margin-left: 10px"
  >
    aktualisieren
  </button>
  <br />
  <ng-container *ngIf="itmArray$">
    <ng-container *ngIf="itmArray$.length > 0" id="dropDownContainer">
      <ng-container *ngFor="let itm of itmArray$" id="inputContainer">
        <ng-container *ngIf="itm.ItemType === 'Date'">
          <clr-input-container>
            <label class="clr-col-12 clr-col-md-3" for="inputContainer">
              {{ itm.Title }}
              <ng-container *ngIf="itm.Fixed === 3">
                <ng-container
                  *ngIf="cabinetCalculations$?.containsCalculations(itm)"
                >
                  | (berechnet:
                  {{ cabinetCalculations$.getCalculationFor(itm)?.value }})
                </ng-container>
              </ng-container>
              <button
                [disabled]="!itm.Info"
                class="btn btn-sm btn-link"
                (click)="onHelperClick(itm.Title, itm.Info)"
              >
                <cds-icon shape="info-standard"></cds-icon>
              </button>
            </label>
            <input
              clrInput
              type="date"
              [formControlName]="itm._id!"
              (input)="dateChanged($event)"
              (ngModelChange)="inputChanged()"
              style="width: 100%"
              class="clr-col-12 clr-col-md-9"
            />
          </clr-input-container>
        </ng-container>
        <ng-container *ngIf="itm.ItemType === 'Strings'">
          <clr-select-container>
            <label class="clr-col-12 clr-col-md-3" for="inputContainer">
              {{ itm.Title }}
              <ng-container *ngIf="itm.Fixed === 3">
                <ng-container
                  *ngIf="cabinetCalculations$?.containsCalculations(itm)"
                >
                  | (berechnet:
                  {{ cabinetCalculations$.getCalculationFor(itm)?.value }})
                </ng-container>
              </ng-container>
              <button
                [disabled]="!itm.Info"
                class="btn btn-sm btn-link"
                (click)="onHelperClick(itm.Title, itm.Info)"
              >
                <cds-icon shape="info-standard"></cds-icon>
              </button>
            </label>
            <select
              class="clr-col-12 clr-col-md-8"
              clrSelect
              style="width: 100%"
              [formControlName]="itm._id!"
              (ngModelChange)="inputChanged()"
            >
              <option
                style="width: 100%"
                *ngFor="let ctlOption of itm.Selection"
              >
                {{ ctlOption }}
              </option>
            </select>
          </clr-select-container>
        </ng-container>
        <ng-container
          *ngIf="itm.ItemType === 'String' || itm.ItemType === 'Number'"
        >
          <clr-input-container>
            <label class="clr-col-12 clr-col-md-3" for="inputContainer">
              {{ itm.Title }}
              <ng-container *ngIf="itm.Fixed === 3">
                <ng-container
                  *ngIf="cabinetCalculations$?.containsCalculations(itm)"
                >
                  | (berechnet:
                  {{ cabinetCalculations$.getCalculationFor(itm)?.value }})
                </ng-container>
              </ng-container>
              <button
                [disabled]="!itm.Info"
                class="btn btn-sm btn-link"
                (click)="onHelperClick(itm.Title, itm.Info)"
              >
                <cds-icon shape="info-standard"></cds-icon>
              </button>
            </label>
            <input
              style="width: 100%"
              class="clr-col-12 clr-col-md-9"
              clrInput
              [formControlName]="itm._id!"
              (ngModelChange)="inputChanged()"
            />
          </clr-input-container>
        </ng-container>
      </ng-container>
    </ng-container>
    <br />
    <!-- <div class="appitem"></div> -->
    <br />
    <!-- <p></p>
    <button class="btn btn-outline" (click)="onAddFieldClick()">
      <cds-icon shape="plus-circle"></cds-icon> feld hinzufügen
    </button> -->
    <button
      type="submit"
      class="btn btn-primary"
      [clrLoading]="submitTabState"
      style="margin-left: 10px"
    >
      aktualisieren
    </button>
  </ng-container>
</form>

<!-- popup-windows -->

<clr-modal [(clrModalOpen)]="onAdd">
  <h3 class="modal-title">
    <cds-icon shape="plus-circle"></cds-icon> hinzufügen
  </h3>
  <div class="modal-body">
    <form clrForm [formGroup]="formItem" (ngSubmit)="onAddFieldSubmit()">
      <clr-toggle-container id="addPrimary">
        <label for="addPrimary">Primary</label>
        <clr-toggle-wrapper>
          <input type="checkbox" clrToggle formControlName="IsPrimary" />
        </clr-toggle-wrapper>
      </clr-toggle-container>

      <clr-input-container id="addTitle">
        <label for="addTitle">Titel</label>
        <!-- <input style="width: 100%" clrInput formControlName="Field" /> -->
        <input style="width: 100%" clrInput formControlName="Title" />
      </clr-input-container>

      <clr-select-container id="addType">
        <label for="addType">Feld-Art</label>
        <select
          clrSelect
          style="width: 100%"
          formControlName="Type"
          (change)="onFieldTypeChanged($event)"
        >
          <option style="width: 100%" value="Strings">Auswahl</option>
          <option style="width: 100%" value="String">Eingabe</option>
          <option style="width: 100%" value="Date">Datum</option>
          <option style="width: 100%" value="Number">Nummerisch</option>
          <option style="width: 100%" value="Bolean">Ja/Nein</option>
        </select>
      </clr-select-container>

      <ng-container *ngIf="showAuswahl" id="inputContainer">
        <!-- <div class="grid-container"> -->
        <div class="clr-form-control clr-row" id="inputContainerRow">
          <div class="clr-col-12 clr-col-md-2">
            <!-- <div class="grid-item-col1 clr-col-12 clr-col-md-2"> -->
            <label
              for="inputContainer"
              style="color: #6e8ba3"
              class="clr-control-label clr-col-12 clr-col-md-2"
            >
              Elemente
            </label>
          </div>
          <div class="grid-container clr-col-md-10 clr-col-12">
            <ng-container
              style="margin-left: 50px; direction: vertical"
              id="Auswahl"
              *ngFor="let ctlOption of tempAuswahlElements; index as i"
            >
              <!-- <div class="grid-item-col1 clr-col-md-10 clr-col-12"> -->
              <div class="grid-item-col1">
                <!-- [attr.data-index]="i" -->
                <label for="Auswahl"> {{ ctlOption }}</label>
                <button
                  class="btn btn-sm btn-link"
                  (click)="deleteAuswahlByIndex($event, i)"
                  title="entfernen"
                >
                  <cds-icon shape="eraser"></cds-icon>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
        <clr-input-container id="addElement">
          <label for="addElement">
            <cds-icon shape="plus-circle" /> Auswahl Element
          </label>
          <input
            style="width: 100%"
            clrInput
            formControlName="Addfield"
            (keydown)="onAddAuswahlElementClick($event)"
          />
        </clr-input-container>
      </ng-container>

      <clr-input-container id="addIndex">
        <label for="addIndex">Index</label>
        <input
          style="width: 100%"
          type="number"
          clrInput
          formControlName="ItemIndex"
        />
      </clr-input-container>

      <clr-textarea-container id="addInfo">
        <label for="addInfo">Info</label>
        <textarea
          style="width: 100%"
          clrTextarea
          formControlName="Info"
        ></textarea>
      </clr-textarea-container>

      <!-- <clr-input-container id="addValue">
        <label for="addValue">Wert</label>
        <input style="width: 100%" clrInput formControlName="Value" />
      </clr-input-container> -->

      <p>&nbsp;</p>
      <button
        type="submit"
        class="btn btn-primary"
        [clrLoading]="onAddSubmitState"
      >
        hinzufügen
      </button>
    </form>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="onDelete">
  <h3 class="modal-title"><cds-icon shape="eraser"></cds-icon> entfernen</h3>
  <div class="modal-body">
    <form clrForm [formGroup]="formItem" (ngSubmit)="onDeleteSubmit()">
      <clr-toggle-container id="delPrimaryContainer">
        <label for="delPrimaryContainer">Primary</label>
        <clr-toggle-wrapper>
          <input type="checkbox" clrToggle formControlName="IsPrimary" />
        </clr-toggle-wrapper>
      </clr-toggle-container>

      <clr-input-container id="delTitleContainer">
        <label for="delTitleContainer">Title</label>
        <input style="width: 100%" clrInput formControlName="Title" />
      </clr-input-container>

      <clr-input-container id="delIndexContainer">
        <label for="delIndexContainer">Index</label>
        <input
          style="width: 100%"
          type="number"
          clrInput
          formControlName="ItemIndex"
        />
      </clr-input-container>

      <clr-textarea-container id="delInfoContainer">
        <label for="delInfoContainer">Info</label>
        <textarea
          style="width: 100%"
          clrTextarea
          formControlName="Info"
        ></textarea>
      </clr-textarea-container>

      <p>&nbsp;</p>
      <button
        type="submit"
        class="btn btn-primary"
        [clrLoading]="onDeleteSubmitState"
      >
        entfernen
      </button>
    </form>
  </div>
  <div class="modal-footer"></div>
</clr-modal>

<clr-modal [(clrModalOpen)]="onEdit">
  <h3 class="modal-title">Bearbeiten</h3>
  <div class="modal-body">
    <form clrForm [formGroup]="formItem" (ngSubmit)="onEditFieldSubmit()">
      <clr-toggle-container id="addPrimary">
        <label for="addPrimary">Primary</label>
        <clr-toggle-wrapper>
          <input type="checkbox" clrToggle formControlName="IsPrimary" />
        </clr-toggle-wrapper>
      </clr-toggle-container>

      <clr-input-container id="addTitle">
        <label for="addTitle">Titel</label>
        <!-- <input style="width: 100%" clrInput formControlName="Field" /> -->
        <input style="width: 100%" clrInput formControlName="Title" />
      </clr-input-container>

      <clr-select-container id="addType">
        <label for="addType">Feld-Art</label>
        <select
          clrSelect
          style="width: 100%"
          formControlName="Type"
          (change)="onFieldTypeChanged($event)"
        >
          <option style="width: 100%" value="Strings">Auswahl</option>
          <option style="width: 100%" value="String">Eingabe</option>
          <option style="width: 100%" value="Date">Datum</option>
          <option style="width: 100%" value="Number">Nummerisch</option>
          <option style="width: 100%" value="Bolean">Ja/Nein</option>
        </select>
      </clr-select-container>

      <ng-container *ngIf="showAuswahl" id="inputContainer">
        <!-- <div class="grid-container"> -->
        <div class="clr-form-control clr-row" id="inputContainerRow">
          <div class="clr-col-12 clr-col-md-2">
            <!-- <div class="grid-item-col1 clr-col-12 clr-col-md-2"> -->
            <label
              for="inputContainer"
              style="color: #6e8ba3"
              class="clr-control-label clr-col-12 clr-col-md-2"
            >
              Elemente
            </label>
          </div>
          <div class="grid-container clr-col-md-10 clr-col-12">
            <ng-container
              style="margin-left: 50px; direction: vertical"
              id="Auswahl"
              *ngFor="let ctlOption of tempAuswahlElements; index as i"
            >
              <!-- <div class="grid-item-col1 clr-col-md-10 clr-col-12"> -->
              <div class="grid-item-col1">
                <!-- [attr.data-index]="i" -->
                <label for="Auswahl"> {{ ctlOption }}</label>
                <button
                  class="btn btn-sm btn-link"
                  (click)="deleteAuswahlByIndex($event, i)"
                  title="entfernen"
                >
                  <cds-icon shape="eraser"></cds-icon>
                </button>
              </div>
            </ng-container>
          </div>
        </div>
        <clr-input-container id="addElement">
          <label for="addElement">
            <cds-icon shape="plus-circle" /> Auswahl Element
          </label>
          <input
            style="width: 100%"
            clrInput
            formControlName="Addfield"
            (keydown)="onAddAuswahlElementClick($event)"
          />
        </clr-input-container>
      </ng-container>

      <clr-input-container id="addIndex">
        <label for="addIndex">Index</label>
        <input
          style="width: 100%"
          type="number"
          clrInput
          formControlName="ItemIndex"
        />
      </clr-input-container>

      <clr-textarea-container id="addInfo">
        <label for="addInfo">Info</label>
        <textarea
          style="width: 100%"
          clrTextarea
          formControlName="Info"
        ></textarea>
      </clr-textarea-container>

      <input style="visibility: hidden" clrInput formControlName="_id" />

      <!-- <clr-input-container id="addValue">
        <label for="addValue">Wert</label>
        <input style="width: 100%" clrInput formControlName="Value" />
      </clr-input-container> -->

      <p>&nbsp;</p>
      <button
        type="submit"
        class="btn btn-primary"
        [clrLoading]="onEditSubmitState"
      >
        speichern
      </button>
    </form>
  </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="onHelper">
  <h3 class="modal-title">
    <cds-icon shape="tag"></cds-icon>
    {{ title }}
  </h3>
  <div class="modal-body" [innerHTML]="message"></div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onHelper = false">
      Ok
    </button>
  </div>
</clr-modal>
