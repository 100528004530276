import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Contract } from 'src/app/models/Contract';

export const ContractActions = createActionGroup({
  source: 'Contract',
  events: {
    'Update Contract': emptyProps(),

    'Load Contracts': emptyProps(),
    'Load Contracts Success': props<{ contracts: Contract[] }>(),
    'Load Contracts Failure': props<{ error: HttpErrorResponse }>(),

    'Load Contract': props<{ uid: string }>(),
    'Load Contract Success': props<{ contract: Contract }>(),
    'Load Contract Failure': props<{ error: HttpErrorResponse }>(),
  },
});
