<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <h3 class="card-header">
        <a routerLink="/">Home</a> / Benutzerverwaltung
        <span style="float: right">
          <a href="javascript:history.back()">
            <cds-icon
              shape="circle-arrow"
              direction="left"
              size="md"
            ></cds-icon>
          </a>
        </span>
      </h3>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <app-users-list-grid
      [users$]="users$"
      [loading$]="loading$"
      [error$]="error$"
    ></app-users-list-grid>
  </div>
</div>
