<div #modalBody id="p00">
  <p></p>
  <p cds-text="section">Inhaltsverzeichnis</p>

  <p></p>
  <ol class="list">
    <li>
      <a
        tabindex="0"
        (click)="scrollTo('p01')"
        (keydown)="handleKeyDown($event, 'p01')"
        style="cursor: pointer"
        >Einleitung</a
      >
    </li>
    <li>
      <a
        tabindex="0"
        (click)="scrollTo('p02')"
        (keydown)="handleKeyDown($event, 'p02')"
        style="cursor: pointer"
        >Verantwortlicher</a
      >
    </li>
    <li>
      <a
        tabindex="0"
        (click)="scrollTo('p03')"
        (keydown)="handleKeyDown($event, 'p03')"
        style="cursor: pointer"
        >Datenschutzbeauftragter</a
      >
    </li>
    <li>
      <a
        tabindex="0"
        (click)="scrollTo('p04')"
        (keydown)="handleKeyDown($event, 'p04')"
        style="cursor: pointer"
        >Hinweis zur Datenweitergabe in die USA</a
      >
    </li>
    <li>
      <a
        tabindex="0"
        (click)="scrollTo('p05')"
        (keydown)="handleKeyDown($event, 'p05')"
        style="cursor: pointer"
        >Besuch unserer Webseite</a
      >
      <ol class="list">
        <li>
          <a
            tabindex="0"
            (click)="scrollTo('p0501')"
            (keydown)="handleKeyDown($event, 'p0501')"
            style="cursor: pointer"
            >Cookies</a
          >
          <ol class="list">
            <li>
              <a
                tabindex="0"
                (click)="scrollTo('p050101')"
                (keydown)="handleKeyDown($event, 'p050101')"
                style="cursor: pointer"
                >Google Analytics</a
              >
            </li>
          </ol>
        </li>
        <li>
          <a
            tabindex="0"
            (click)="scrollTo('p0502')"
            (keydown)="handleKeyDown($event, 'p0502')"
            style="cursor: pointer"
            >Registrierung & Verifizierung des Accounts</a
          >
        </li>
        <li>
          <a
            tabindex="0"
            (click)="scrollTo('p0503')"
            (keydown)="handleKeyDown($event, 'p0503')"
            style="cursor: pointer"
            >Newsletter</a
          >
        </li>
      </ol>
    </li>
    <li>
      <a
        tabindex="0"
        (click)="scrollTo('p06')"
        (keydown)="handleKeyDown($event, 'p06')"
        style="cursor: pointer"
        >Kontakt per Telefon, E-Mail oder Fax</a
      >
    </li>
    <li>
      <a
        tabindex="0"
        (click)="scrollTo('p07')"
        (keydown)="handleKeyDown($event, 'p07')"
        style="cursor: pointer"
        >Automatische Entscheidungsfindung und Profiling</a
      >
    </li>
    <li>
      <a
        tabindex="0"
        (click)="scrollTo('p08')"
        (keydown)="handleKeyDown($event, 'p08')"
        style="cursor: pointer"
        >Betroffenenrechte</a
      >
    </li>
    <li>
      <a
        tabindex="0"
        (click)="scrollTo('p09')"
        (keydown)="handleKeyDown($event, 'p09')"
        style="cursor: pointer"
        >Beschwerderecht</a
      >
    </li>
    <li>
      <a
        tabindex="0"
        (click)="scrollTo('p10')"
        (keydown)="handleKeyDown($event, 'p10')"
        style="cursor: pointer"
        >Änderung und Aktualisierung der Datenschutzerklärung</a
      >
    </li>
  </ol>

  <p></p>
  <p cds-text="section" id="p01">
    1. Einleitung
    <span style="float: right">
      <button
        type="button"
        class="btn btn-icon btn-link"
        (click)="scrollToTop()"
      >
        <cds-icon shape="angle" size="md"></cds-icon>
      </button>
    </span>
  </p>
  <p>
    Wir, die Vrielmann GmbH, respektieren Ihre Privat- und Persönlichkeitssphäre
    und nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Die Verarbeitung
    Ihrer personenbezogenen Daten („Ihre Daten“) durch uns erfolgt auf
    gesetzlicher Grundlage. Wichtigste gesetzliche Grundlage ist die
    EU-Datenschutzgrundverordnung (DSGVO). Selbstverständlich beachten wir auch
    alle weiteren einschlägige gesetzlichen Vorgaben, insbesondere des
    Bundesdatenschutzgesetzes (BDSG). Auf dieser Seite haben wir für Sie eine
    Reihe von Informationen zum Datenschutz bereitgestellt.
  </p>

  <p></p>
  <p cds-text="section" id="p02">
    2. Verantwortlicher
    <span style="float: right">
      <button
        type="button"
        class="btn btn-icon btn-link"
        (click)="scrollToTop()"
      >
        <cds-icon shape="angle" size="md"></cds-icon>
      </button>
    </span>
  </p>
  <p>
    Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber.
    Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
  </p>
  <ul class="list list-unstyled">
    <li>Vrielmann GmbH</li>
    <li>Heinrich-Focke-Straße 25</li>
    <li>48531 Nordhorn</li>
    <li>Telefon: +49 59 21 8 19 18-0</li>
    <li>E-Mail: info&commat;vrielmann.com</li>
  </ul>

  <p></p>
  <p cds-text="section" id="p03">
    3. Datenschutzbeauftragter
    <span style="float: right">
      <button
        type="button"
        class="btn btn-icon btn-link"
        (click)="scrollToTop()"
      >
        <cds-icon shape="angle" size="md"></cds-icon>
      </button>
    </span>
  </p>
  <p>Wir haben für unser Unternehmen eine Datenschutzbeauftragte bestellt:</p>
  <ul class="list list-unstyled">
    <li>Emerson Hilgers</li>
    <li>Kupferstraße 5</li>
    <li>49843 Uelsen</li>
    <li>Telefon: +49 5942 99989-80</li>
    <li>E-Mail: info&commat;westimage.de</li>
  </ul>

  <p></p>
  <p cds-text="section" id="p04">
    4. Hinweis zur Datenweitergabe in die USA
    <span style="float: right">
      <button
        type="button"
        class="btn btn-icon btn-link"
        (click)="scrollToTop()"
      >
        <cds-icon shape="angle" size="md"></cds-icon>
      </button>
    </span>
  </p>
  <p>
    Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in den
    USA eingebunden. Wenn diese Tools aktiv sind, können Ihre personenbezogenen
    Daten an die US-Server der jeweiligen Unternehmen weitergegeben werden. Wir
    weisen darauf hin, dass die USA je nach Gesetztes Lage mal ein und manchmal
    kein sicherer Drittstaat im Sinne des EU-Datenschutzrechts sind.
    US-Unternehmen sind dazu verpflichtet, personenbezogene Daten an
    Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen
    gerichtlich vorgehen können. Es kann daher nicht ausgeschlossen werden, dass
    US-Behörden (z.B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu
    Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir
    haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
  </p>

  <p></p>
  <p cds-text="section" id="p05">
    5. Besuch unserer Webseite
    <span style="float: right">
      <button
        type="button"
        class="btn btn-icon btn-link"
        (click)="scrollToTop()"
      >
        <cds-icon shape="angle" size="md"></cds-icon>
      </button>
    </span>
  </p>
  <p>
    Als Besucher unserer Website https://connect.vrielmann.com können Sie diese
    nutzen, um nach Wartung der Trafostationen Berichte und Protokolle zu
    erhalten sowie die weiteren hier beschriebenen Funktionen zu nutzen. Im
    Folgenden möchten wir Sie entsprechend den einschlägigen rechtlichen
    Anforderungen über die Verarbeitung Ihrer personenbezogenen Daten möglichst
    genau informieren. Ihre personenbezogenen Daten werden bei uns sicher durch
    Verschlüsselung übertragen. Wir bedienen uns dabei des Codierungssystems
    TLS. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile
    des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol
    in Ihrer Browserzeile. Wenn die TLS-Verschlüsselung aktiviert ist, können
    die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.
  </p>

  <p></p>
  <p cds-text="subsection">Externes Hosting</p>
  <p>
    Diese Website wird bei externen Dienstleistern gehostet (Hoster). Die
    personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf
    den Servern der Hoster gespeichert. Hierbei kann es sich v.a. um folgenden
    Daten handeln:
  </p>
  <ul>
    <li>IP-Adressen</li>
    <li>Meta- und Kommunikationsdaten</li>
    <li>Websitezugriffe</li>
    <li>
      sonstige Daten, die über eine Webseite generiert oder eingebunden werden,
    </li>
  </ul>
  <p>
    Im Rahmen des Zugriffs auf unser Angebot werden folgende Angaben in einem
    Logfile erfasst:
  </p>
  <ul>
    <li>IP-Adresse des Computers durch den die Anfrage erfolgte</li>
    <li>
      Browser-Informationen (genutzter Browser, Betriebssystem, Sprache, etc.)
    </li>
    <li>Angaben über den genutzten Serverdienst</li>
    <li>Protokollversion</li>
    <li>Name des angefragten Dienstes (URL)</li>
    <li>Datum und Uhrzeit der Anfrage</li>
    <li>Statusangaben (Fehlermeldungen)</li>
  </ul>
  <p>
    Der Einsatz des Hosters erfolgt zum Zwecke der Vertragserfüllung gegenüber
    unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und
    im Interesse einer sicheren, schnellen und effizienten Bereitstellung
    unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1
    lit. f DSGVO). Des Weiteren besteht unser berechtigtes Interesse besteht
    darin, unsere Anlagen und Systeme vor Angriffen zu schützen und ggf. gegen
    Angreifer rechtlich vorzugehen sowie unsere Websites zu wirtschaftlichen
    Zwecken weiterzuentwickeln. Um die datenschutzkonforme Verarbeitung zu
    gewährleisten, haben wir einen Vertrag über Auftragsverarbeitung mit unseren
    Hosting Partnern geschlossen.
  </p>
  <p>
    Zudem werden die jeweiligen Server-Aufrufe in einem Logfile protokolliert.
    Diese Logfiledaten verwenden wir zur technischen Fehlersuche und um
    rechtswidrige Angriffe auf unsere Systeme abzuwehren, aufzuklären und ggf.
    verantwortliche Personen zur Rechenschaft zu ziehen. Darüber erstellen wir,
    aus den gespeicherten Logfiles, Auswertungen, die wir zur Optimierung
    unserer Websites verwenden. Die Auswertung erfolgt dabei in anonymer Form,
    d.h. durch Zusammenfassung von Aufrufdaten, so dass die Ergebnisse keinen
    Personenbezug mehr haben.
  </p>
  <p>
    Grundsätzlich erfolgt die Verarbeitung Ihrer Daten vollautomatisiert auf
    Servern unseres externen Dienstleisters. Nur ausnahmsweise (z.B. zur
    Fehlersuche oder Aufklärung von Attacken) werden Logfiles von unserer
    IT-Abteilung genutzt und ggf. an öffentliche Stellen wie
    Strafverfolgungsbehörden und Gerichte zum Zwecke der Aufklärung bzw.
    Verfolgung von rechtswidrigen Angriffen auf unsere Systeme weitergegeben.
    Die Daten dieser Webseite liegen auf Servern in Deutschland. Ihre Daten
    werden nicht in ein Drittland oder an eine internationale Organisation
    übermittelt.
  </p>
  <p>
    Die Logfiledaten werden für 10 Tage gespeichert. Verbindungsdaten werden
    unmittelbar nach Durchführung des HTTP/S-Aufrufs gelöscht.
  </p>

  <p></p>
  <p cds-text="section" id="p0501">
    5.1. Cookies
    <span style="float: right">
      <button
        type="button"
        class="btn btn-icon btn-link"
        (click)="scrollToTop()"
      >
        <cds-icon shape="angle" size="md"></cds-icon>
      </button>
    </span>
  </p>
  <p>
    Cookies sind kleine Textdateien, bzw. sonstige Speichervermerke, die
    Informationen auf Endgeräten speichern und Informationen aus den Endgeräten
    auslesen, um z.B. den Login-Status in einem Nutzerkonto zu speichern.
    Cookies können ferner zu unterschiedlichen Zwecken eingesetzt werden, z.B.
    zu Zwecken der Funktionsfähigkeit, Sicherheit und Komfort von
    Onlineangeboten sowie der Erstellung von Analysen der Besucherströme.
  </p>
  <p>
    Wir setzen Cookies im Einklang mit den gesetzlichen Vorschriften ein. Daher
    holen wir von den Nutzern eine vorhergehende Einwilligung ein, außer wenn
    diese gesetzlich nicht gefordert ist. Eine Einwilligung ist insbesondere
    nicht notwendig, wenn das Speichern und das Auslesen der Informationen, also
    auch von Cookies, unbedingt erforderlich sind, um dem den Nutzern einen von
    ihnen ausdrücklich gewünschten digitalen Dienst (also unser Onlineangebot)
    zur Verfügung zu stellen. Zu den unbedingt erforderlichen Cookies gehören in
    der Regel Cookies mit Funktionen, die der Anzeige und Lauffähigkeit des
    Onlineangebots, dem Lastausgleich, der Sicherheit, der Speicherung der
    Präferenzen und Auswahlmöglichkeiten der Nutzer oder ähnlichen mit der
    Bereitstellung der Haupt- und Nebenfunktionen des von den Nutzern
    angeforderten Onlineangebotes zusammenhängenden Zwecken dienen. Die
    widerrufliche Einwilligung wird gegenüber den Nutzern deutlich kommuniziert
    und enthält die Informationen zu der jeweiligen Cookie-Nutzung.
  </p>
  <p>
    Auf welcher datenschutzrechtlichen Rechtsgrundlage wir die personenbezogenen
    Daten der Nutzer mit Hilfe von Cookies verarbeiten, hängt davon ab, ob wir
    Nutzer um eine Einwilligung bitten. Falls die Nutzer einwilligen, ist die
    Rechtsgrundlage der Verarbeitung Ihrer Daten die erklärte Einwilligung.
    Andernfalls werden die mithilfe von Cookies verarbeiteten Daten auf
    Grundlage unserer berechtigten Interessen (z.B. an einem
    betriebswirtschaftlichen Betrieb unseres Onlineangebotes und Verbesserung
    seiner Nutzbarkeit) verarbeitet oder, wenn dies im Rahmen der Erfüllung
    unserer vertraglichen Pflichten erfolgt, wenn der Einsatz von Cookies
    erforderlich ist, um unsere vertraglichen Verpflichtungen zu erfüllen. Zu
    welchen Zwecken die Cookies von uns verarbeitet werden, darüber klären wir
    im Laufe dieser Datenschutzerklärung oder im Rahmen von unseren
    Einwilligungs- und Verarbeitungsprozessen auf.
  </p>

  <p>
    Im Hinblick auf die Speicherdauer werden die folgenden Arten von Cookies
    unterschieden:
  </p>
  <ul>
    <li>
      Temporäre Cookies (auch: Session- oder Sitzungs-Cookies): Temporäre
      Cookies werden spätestens gelöscht, nachdem ein Nutzer ein Online-Angebot
      verlassen und sein Endgerät (z.B. Browser oder mobile Applikation)
      geschlossen hat.
    </li>
    <li>
      Permanente Cookies: Permanente Cookies bleiben auch nach dem Schließen des
      Endgerätes gespeichert. Sofern wir Nutzern keine expliziten Angaben zur
      Art und Speicherdauer von Cookies mitteilen (z. B. im Rahmen der Einholung
      der Einwilligung), sollten Nutzer davon ausgehen, dass Cookies permanent
      sind und die Speicherdauer bis zu zwei Jahre betragen kann.
    </li>
  </ul>

  <p>
    Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit widerrufen
    und der Verarbeitung entsprechend den gesetzlichen Vorgaben widersprechen.
    Hierzu können Nutzer unter anderem die Verwendung von Cookies in den
    Einstellungen ihres Browsers einschränken (wobei dadurch auch die
    Funktionalität unseres Onlineangebotes eingeschränkt sein kann). Ein
    Widerspruch gegen die Verwendung von Cookies zu Online-Marketing-Zwecken
    kann auch über die Websites
    <a href="https://optout.aboutads.info">https://optout.aboutads.info</a> und
    <a href="https://www.youronlinechoices.com/"
      >https://www.youronlinechoices.com/</a
    >
    erklärt werden.
  </p>

  <p></p>
  <p cds-text="subsection">Cookie-Einstellungen</p>
  <p>
    Die Verarbeitung personenbezogener Daten erfolgt in diesem Zusammenhang auf
    Basis unseres berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
    Falls eine Zustimmung in die Verarbeitung personenbezogener Daten notwendig
    ist, wird eine Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO) eingeholt.
  </p>
  <p>
    Wir setzen ein Verfahren zum Cookie-Einwilligungs-Management ein, in dessen
    Rahmen die Einwilligungen der Nutzer in den Einsatz von Cookies, bzw. der im
    Rahmen des Cookie-Einwilligungs-Management-Verfahrens genannten
    Verarbeitungen und Anbieter eingeholt sowie von den Nutzern verwaltet und
    widerrufen werden können. Hierbei wird die Einwilligungserklärung
    gespeichert, um deren Abfrage nicht erneut wiederholen zu müssen und die
    Einwilligung entsprechend der gesetzlichen Verpflichtung nachweisen zu
    können. Die Speicherung kann serverseitig und/oder in einem Cookie erfolgen,
    um die Einwilligung einem Nutzer, bzw. dessen Gerät zuordnen zu können.
    Vorbehaltlich individueller Angaben zu den Anbietern von
    Cookie-Management-Diensten, gelten die folgenden Hinweise: Die Dauer der
    Speicherung der Einwilligung kann bis zu zwei Jahren betragen. Hierbei wird
    ein pseudonymer Nutzer-Identifikator gebildet und mit dem Zeitpunkt der
    Einwilligung, Angaben zur Reichweite der Einwilligung (z. B. welche
    Kategorien von Cookies und/oder Diensteanbieter) sowie dem Browser, System
    und verwendeten Endgerät gespeichert.
  </p>

  <p></p>
  <p cds-text="section" id="p050101">
    5.1.1 Google Analytics
    <span style="float: right">
      <button
        type="button"
        class="btn btn-icon btn-link"
        (click)="scrollToTop()"
      >
        <cds-icon shape="angle" size="md"></cds-icon>
      </button>
    </span>
  </p>
  <p>
    Wir verwenden Google Analytics (Anbieter: Google Ireland Limited, Gordon
    House, Barrow Street, Dublin 4, Irland) zur Messung und Analyse der Nutzung
    unseres Onlineangebotes auf der Grundlage einer pseudonymen
    Nutzeridentifikationsnummer. Diese Identifikationsnummer enthält keine
    eindeutigen Daten, wie Namen oder E-Mail-Adressen. Sie dient dazu,
    Analyseinformationen einem Endgerät zuzuordnen, um zu erkennen, welche
    Inhalte die Nutzer innerhalb eines oder verschiedener Nutzungsvorgänge
    aufgerufen haben, welche Suchbegriffe sie verwendet haben, diese erneut
    aufgerufen haben oder mit unserem Onlineangebot interagiert haben. Ebenso
    werden der Zeitpunkt der Nutzung und deren Dauer gespeichert, sowie die
    Quellen der Nutzer, die auf unser Onlineangebot verweisen und technische
    Aspekte ihrer Endgeräte und Browser. Dabei werden pseudonyme Profile von
    Nutzern mit Informationen aus der Nutzung verschiedener Geräte erstellt,
    wobei Cookies eingesetzt werden können. Google Analytics protokolliert und
    speichert keine individuellen IP-Adressen für EU-Nutzer. Analytics stellt
    jedoch grobe geografische Standortdaten bereit, indem es die folgenden
    Metadaten von IP-Adressen ableitet: Stadt (und der abgeleitete Breiten- und
    Längengrad der Stadt), Kontinent, Land, Region, Subkontinent (und
    ID-basierte Gegenstücke). Beim EU-Datenverkehr werden die IP-Adressdaten
    ausschließlich für diese Ableitung von Geolokalisierungsdaten verwendet,
    bevor sie sofort gelöscht werden. Sie werden nicht protokolliert, sind nicht
    zugänglich und werden nicht für weitere Verwendungszwecke genutzt. Wenn
    Google Analytics Messdaten sammelt, werden alle IP-Abfragen auf EU-basierten
    Servern durchgeführt, bevor der Verkehr zur Verarbeitung an Analytics-Server
    weitergeleitet wird.
  </p>
  <p>
    Die Verarbeitung personenbezogener Daten erfolgt auf Basis einer
    Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO). Wir haben mit dem Anbieter
    einen Auftragsverarbeitungsvertrag geschlossen:
    <a href="https://business.safety.google/adsprocessorterms"
      >https://business.safety.google/adsprocessorterms</a
    >. Als Grundlage für die Drittlandübermittlung dient das EU-US Data Privacy
    Framework (DPF) sowie Standardvertragsklauseln (<a
      href="https://business.safety.google/adsprocessorterms"
      >https://business.safety.google/adsprocessorterms</a
    >) Weitere Informationen zum Datenschutz bei Google Analytics finden Sie
    hier:
    <a href="https://policies.google.com/privacy"
      >https://policies.google.com/privacy</a
    >. Sie haben jederzeit die Möglichkeit Ihre Einwilligung zu widerrufen. Eine
    Widerspruchsmöglichkeit (Opt-Out) finden Sie hier:
    <a href="https://tools.google.com/dlpage/gaoptout?hl=de"
      >https://tools.google.com/dlpage/gaoptout?hl=de</a
    >. Weitere Informationen zu den Arten der Verarbeitung sowie der
    verarbeiteten Daten finden Sie hier:
    <a href="https://privacy.google.com/businesses/adsservices"
      >https://privacy.google.com/businesses/adsservices</a
    >.
  </p>

  <p></p>
  <p cds-text="section" id="p0502">
    5.2. Registrierung & Verifizierung des Accounts
    <span style="float: right">
      <button
        type="button"
        class="btn btn-icon btn-link"
        (click)="scrollToTop()"
      >
        <cds-icon shape="angle" size="md"></cds-icon>
      </button>
    </span>
  </p>
  <p>
    Wir verarbeiten die vorstehend näher bezeichneten personenbezogenen Daten im
    Einklang mit den Vorschriften der DSGVO, den weiteren einschlägigen
    Datenschutzvorschriften und nur im erforderlichen Umfang. Im Einzelnen dient
    die Verarbeitung der Erfüllung der folgenden Zwecke: Die Verarbeitung
    personenbezogener Daten erfolgt zur Abwicklung der über das System
    angebotenen oder durch das System erbrachten Leistungen auf Grundlage der
    Art 6 Abs. 1 lit. b) DSGVO.
  </p>
  <p>
    Verbesserung, Aufrechterhaltung und Schutz der Sicherheit der
    Vrielmann-Angebote, Verhinderung und Aufdeckung von Sicherheitsrisiken oder
    kriminellen Handlungen, Prüfung von Duplikaten, Geltendmachung und
    Verteidigung rechtlicher Ansprüche, Beilegung rechtlicher Streitigkeiten,
    Versendung von Benachrichtigungen und Informationen über im Zusammenhang
    stehende Leistungsangebote (Rechtsgrundlage ist dann Art. 6 Abs. 1 lit. f
    DSGVO, bei einer Werbenutzung ggf. Art. 6 Abs. 1 lit. a DSGVO);
  </p>
  <p>
    Soweit dort noch nicht vorhanden, Übermittlung des Datensatzes an die für
    Vrielmann Connect genutzte und zentral verwaltete Kundendatenbank, um die
    Daten ggf. auch bei anderen Vrielmann-Angeboten zu verwenden und deren
    Nutzung für den Kunden zu vereinfachen (Rechtsgrundlage ist dann Art. 6 Abs.
    1 lit. b bzw. lit. f DSGVO).
  </p>
  <p>
    Um einen Account zu erstellen ist es notwendig sich zu registrieren. Bei der
    Registrierung verarbeiten wir die folgenden, personenbezogenen Daten:
  </p>
  <ul>
    <li>Vor- & Nachname</li>
    <li>E-Mail-Adresse</li>
    <li>Anschrift und Name des Betriebs</li>
  </ul>

  <p>
    Diese Daten verwenden wir, um Sie als Vrielmann Kunde zu identifizieren.
    Diese Identifikation erfolgt technisch, indem wir Ihren Vrielmann
    Connect-Account mit einem in unserem ERP-System bestehenden Kundenkonto
    verknüpfen. Nach erfolgreichem Abschluss der Verifizierung können Sie die
    Funktionen in unserem Vrielmann Connect nutzen. Für wichtige Änderungen etwa
    beim Angebotsumfang oder bei technisch notwendigen Änderungen nutzen wir die
    bei der Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu
    informieren.
  </p>

  In die Verarbeitung der Daten werden eingebunden:
  <li>Interne IT-Dienstleister</li>
  <li>Externe Dienstleister im Rahmen einer Auftragsverarbeitung</li>
  <li>
    Abteilungen im Rahmen der Abwicklung der Geschäftsprozesse (After Sales,
    Buchhaltung, Vertrieb)
  </li>

  <p>
    Die Daten dieser Webseite liegen auf Servern in Deutschland. Ihre Daten
    werden nicht in ein Drittland oder an eine internationale Organisation
    übermittelt.
  </p>

  <p>
    Ihre Benutzerkontodaten werden regelmäßig für die Dauer des Bestehens des
    Kundenkontos gespeichert. Wenn Sie die Löschung Ihres Benutzerkontos
    wünschen, können Sie sich per E-Mail unter ....de oder postalisch an unsere
    oben genannte Adresse wenden. Eine Löschung erfolgt, soweit keine
    gesetzliche Aufbewahrungspflicht besteht bzw. spätestens nach Ablauf
    gesetzlicher Aufbewahrungsfristen.
  </p>

  <p>
    Soweit Sie für bestimmte Verarbeitungsvorgänge eine Einwilligung (Art. 6
    Abs. 1 lit. a DSGVO) erteilt haben, verarbeiten wir die davon betroffenen
    personenbezogenen Daten auf der Grundlage dieser Einwilligung. Soweit die
    Verarbeitung der personenbezogenen Daten auf Art. 6 Abs. 1 lit. f DSGVO
    beruht, stellen die vorstehend genannten Zwecke zugleich unsere berechtigten
    Interessen dar.
  </p>

  <p></p>
  <p cds-text="section" id="p0503">
    5.3 Newsletter
    <span style="float: right">
      <button
        type="button"
        class="btn btn-icon btn-link"
        (click)="scrollToTop()"
      >
        <cds-icon shape="angle" size="md"></cds-icon>
      </button>
    </span>
  </p>
  <p>
    Wir versenden Newsletter, E-Mails und weitere elektronische
    Benachrichtigungen (nachfolgend “Newsletter“) nur mit der Einwilligung der
    Empfänger oder einer gesetzlichen Erlaubnis. Sofern im Rahmen einer
    Anmeldung zum Newsletter dessen Inhalte konkret umschrieben werden, sind sie
    für die Einwilligung der Nutzer maßgeblich. Im Übrigen enthalten unsere
    Newsletter Informationen zu unseren Leistungen und uns. Um sich zu unseren
    Newslettern anzumelden, reicht es grundsätzlich aus, wenn Sie Ihre
    E-Mail-Adresse angeben. Wir können Sie jedoch bitten, einen Namen, zwecks
    persönlicher Ansprache im Newsletter, oder weitere Angaben, sofern diese für
    die Zwecke des Newsletters erforderlich sind, zu tätigen.
  </p>
  <p>
    Die Anmeldung zu unserem Newsletter erfolgt grundsätzlich in einem
    sogenannten Double-Opt-In-Verfahren. D.h., Sie erhalten nach der Anmeldung
    eine E-Mail, in der Sie um die Bestätigung Ihrer Anmeldung gebeten werden.
    Diese Bestätigung ist notwendig, damit sich niemand mit fremden
    E-Mail-Adressen anmelden kann. Die Anmeldungen zum Newsletter werden
    protokolliert, um den Anmeldeprozess entsprechend den rechtlichen
    Anforderungen nachweisen zu können. Hierzu gehört die Speicherung des
    Anmelde- und des Bestätigungszeitpunkts als auch der IP-Adresse. Ebenso
    werden die Änderungen Ihrer bei dem Versanddienstleister gespeicherten Daten
    protokolliert. Wir können die ausgetragenen E-Mail-Adressen bis zu drei
    Jahren auf Grundlage unserer berechtigten Interessen speichern, bevor wir
    sie löschen, um eine ehemals gegebene Einwilligung nachweisen zu können. Die
    Verarbeitung dieser Daten wird auf den Zweck einer möglichen Abwehr von
    Ansprüchen beschränkt. Ein individueller Löschungsantrag ist jederzeit
    möglich, sofern zugleich das ehemalige Bestehen einer Einwilligung bestätigt
    wird. Im Fall von Pflichten zur dauerhaften Beachtung von Widersprüchen
    behalten wir uns die Speicherung der E-Mail-Adresse alleine zu diesem Zweck
    in einer Sperrliste (sogenannte “Blocklist”) vor.
  </p>
  <p>
    Die Protokollierung des Anmeldeverfahrens erfolgt auf Grundlage unserer
    berechtigten Interessen zu Zwecken des Nachweises seines ordnungsgemäßen
    Ablaufs. Soweit wir einen Dienstleister mit dem Versand von E-Mails
    beauftragen, erfolgt dies auf Grundlage unserer berechtigten Interessen an
    einem effizienten und sicheren Versandsystem. Rechtsgrundlage der
    Verarbeitung ist grundsätzlich die Einwilligung (Art. 6 Abs. 1 S. 1 lit. a)
    DSGVO). Unter Berücksichtigung der Rechtslage kann in besonderen Ausnahmen
    ein überwiegendes berechtigte Interesse (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
    als Rechtsgrundlage Anwendung finden.
  </p>
  <p>
    Sie können den Empfang unseres Newsletters jederzeit kündigen, d.h. Ihre
    Einwilligungen widerrufen, bzw. dem weiteren Empfang widersprechen. Einen
    Link zur Kündigung des Newsletters finden Sie entweder am Ende eines jeden
    Newsletters oder können sonst eine der oben angegebenen
    Kontaktmöglichkeiten, vorzugswürdig E-Mail, hierzu nutzen. Im Fall von
    Newslettern, die an Bestandskunden versendet werden, werden keine Öffnungs-
    und Klickraten gemessen.
  </p>
  <p>
    Die Newsletter enthalten einen web-beacon. Das ist eine ein pixelgroße
    Datei, die beim Öffnen des Newsletters von unserem Server, bzw., sofern wir
    einen Versanddienstleister einsetzen, von dessen Server abgerufen wird. Im
    Rahmen dieses Abrufs werden zunächst technische Informationen, wie
    Informationen zum Browser und Ihrem System, als auch Ihre IP-Adresse und der
    Zeitpunkt des Abrufs, erhoben.
  </p>
  <p>
    Diese Informationen werden zur technischen Verbesserung unseres Newsletters
    anhand der technischen Daten oder der Zielgruppen und ihres Leseverhaltens
    auf Basis ihrer Abruforte (die mit Hilfe der IP-Adresse bestimmbar sind)
    oder der Zugriffszeiten genutzt. Diese Analyse beinhaltet ebenfalls die
    Feststellung, ob die Newsletter geöffnet werden, wann sie geöffnet werden
    und welche Links geklickt werden. Diese Informationen werden den einzelnen
    Newsletter-Empfängern zugeordnet und in deren Profilen bis zu deren Löschung
    gespeichert. Die Auswertungen dienen uns dazu, die Lesegewohnheiten unserer
    Nutzer zu erkennen und unsere Inhalte an sie anzupassen oder
    unterschiedliche Inhalte entsprechend den Interessen unserer Nutzer zu
    versenden.
  </p>
  <p>
    Die Messung der Öffnungsraten und der Klickraten sowie Speicherung der
    Messergebnisse in den Profilen der Nutzer sowie deren weitere Verarbeitung
    erfolgen auf Grundlage einer Einwilligung der Nutzer.
  </p>
  <p>
    Ein getrennter Widerruf der Erfolgsmessung ist leider nicht möglich, in
    diesem Fall muss das gesamte Newsletter-Abonnement gekündigt, bzw. muss ihm
    widersprochen werden. In diesem Fall werden die gespeicherten
    Profilinformationen gelöscht. Die Rechtsgrundlage für die Datenverarbeitung
    ist die Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO).
  </p>

  <!-- <p></p>
<p cds-text="subsection">XYZ</p>
<p>E-Mail-Versand- und E-Mail-Versand- und Automatisierungsdienste</p>
<span>
  <br />Dienstanbieter: <br />Auftragsverarbeitungsvertrag:
  <br />Drittlandübermittlung: <br />Standardvertragsklauseln: <br />Weitere
  Informationen: Besondere Sicherheitsmaßnahmen:
</span> -->

  <p></p>
  <p cds-text="section" id="p06">
    6. Kontakt per Telefon, E-Mail oder Fax
    <span style="float: right">
      <button
        type="button"
        class="btn btn-icon btn-link"
        (click)="scrollToTop()"
      >
        <cds-icon shape="angle" size="md"></cds-icon>
      </button>
    </span>
  </p>
  <p>
    Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
    Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name,
    Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und
    verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.
  </p>
  <p>
    Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b
    DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt
    oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist.
  </p>
  <p>
    In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten
    Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen
    (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit.
    a DSGVO) sofern diese abgefragt wurde.
  </p>
  <p>
    Es werden, je nach Art Ihres Anliegens, in die Verarbeitung der Daten werden
    eingebunden:
  </p>
  <li>Interne IT- Dienstleister</li>
  <li>Externe IT-Dienstleister im Rahmen einer Auftragsverarbeitung</li>
  <li>
    Abteilungen im Rahmen der Abwicklung der Geschäftsprozesse (After Sales
    Support, Buchhaltung, Vertrieb)
  </li>
  <p>
    Ihre Daten werden nicht in ein Drittland oder an eine internationale
    Organisation übermittelt.
  </p>
  <p>
    Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei
    uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
    widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach
    abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche
    Bestimmungen –insbesondere gesetzliche Aufbewahrungsfristen – bleiben
    unberührt.
  </p>

  <p></p>
  <p cds-text="section" id="p07">
    7. Automatische Entscheidungsfindung und Profiling
    <span style="float: right">
      <button
        type="button"
        class="btn btn-icon btn-link"
        (click)="scrollToTop()"
      >
        <cds-icon shape="angle" size="md"></cds-icon>
      </button>
    </span>
  </p>
  <p>
    Eine automatisierte Entscheidungsfindung oder ein Profiling gemäß Artikel 22
    Absätze 1 und 4 DSGVO findet nicht statt.
  </p>

  <p></p>
  <p cds-text="section" id="p08">
    8. Betroffenenrechte
    <span style="float: right">
      <button
        type="button"
        class="btn btn-icon btn-link"
        (click)="scrollToTop()"
      >
        <cds-icon shape="angle" size="md"></cds-icon>
      </button>
    </span>
  </p>
  <p>
    Sofern wir Ihre Daten verarbeiten, haben Sie im jeweiligen gesetzlichen
    Umfang ein Recht auf:
  </p>
  <li>
    Auskunft, insbesondere über bei uns gespeicherte Daten und deren
    Verarbeitungszwecke (Art. 15 DSGVO)
  </li>
  <li>
    Berichtigung unrichtiger bzw. Vervollständigung unvollständiger Daten (Art.
    16 DSGVO)
  </li>
  <li>
    Löschung, etwa unrechtmäßig verarbeiteter oder nicht mehr erforderlicher
    Daten (Art. 17 DSGVO)
  </li>
  <li>Einschränkung der Verarbeitung (Art. 18 DSGVO)</li>
  <li>
    Widerspruch gegen die Verarbeitung, wenn diese zur Wahrung berechtigter
    Interessen des Verantwortlichen erfolgt (Art. 21 DSGVO) und
  </li>
  <li>
    Datenübertragung, sofern die Verarbeitung auf einer Einwilligung beruht oder
    zur Durchführung eines Vertrages oder mit Hilfe automatisierter Verfahren
    erfolgt (Art. 20 DSGVO)
  </li>

  <p>
    Soweit eine Verarbeitung auf einer von Ihnen erteilten Einwilligung beruht
    (Art. 6 Abs. 1 lit. a oder Art. 9 Abs. 2 lit. a DSGVO), haben Sie das Recht,
    die Einwilligung jederzeit zu widerrufen. Die Rechtmäßigkeit der
    Datenverarbeitung auf Basis Ihrer Einwilligung bis zum Zeitpunkt des
    Widerrufs wird hierdurch nicht berührt.
  </p>

  <p></p>
  <p cds-text="section" id="p09">
    9. Beschwerderecht
    <span style="float: right">
      <button
        type="button"
        class="btn btn-icon btn-link"
        (click)="scrollToTop()"
      >
        <cds-icon shape="angle" size="md"></cds-icon>
      </button>
    </span>
  </p>
  <p>
    Entsprechend den gesetzlichen Vorgaben und unbeschadet eines anderweitigen
    verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs, haben Sie ferner
    das Recht, bei einer Datenschutzaufsichtsbehörde, insbesondere einer
    Aufsichtsbehörde im Mitgliedstaat, in dem Sie sich gewöhnlich aufhalten, der
    Aufsichtsbehörde Ihres Arbeitsplatzes oder des Ortes des mutmaßlichen
    Verstoßes, eine Beschwerde einzulegen, wenn Sie der Ansicht sei sollten,
    dass die Verarbeitung der Ihre Person betreffenden personenbezogenen Daten
    gegen die DSGVO verstößt.
  </p>

  <p></p>
  <p cds-text="section" id="p10">
    10. Änderung und Aktualisierung der Datenschutzerklärung
    <span style="float: right">
      <button
        type="button"
        class="btn btn-icon btn-link"
        (click)="scrollToTop()"
      >
        <cds-icon shape="angle" size="md"></cds-icon>
      </button>
    </span>
  </p>
  <p>
    Wir bitten Sie, sich regelmäßig über den Inhalt unserer Datenschutzhinweise
    zu informieren. Wir passen die Datenschutzhinweise an, sobald die Änderungen
    der von uns durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
    informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung
    Ihrerseits (z.B. Einwilligung) oder eine sonstige individuelle
    Benachrichtigung erforderlich wird. Sofern wir in diesen
    Datenschutzhinweisen Adressen und Kontaktinformationen von Unternehmen und
    Organisationen angeben, bitten wir zu beachten, dass die Adressen sich über
    die Zeit ändern können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
  </p>

  <!-- <p></p> -->

  <!-- <div class="clr-form-control">
    <label class="clr-control-label"
      >Ich habe die Datenschutzrichtlinien gelesen und akzeptiere diese.</label
    >
    <div class="clr-control-container">
      <div class="clr-checkbox-wrapper">
        <input
          type="checkbox"
          id="vertical-checkbox1"
          name="checkbox-full-1"
          value="option1"
          class="clr-checkbox"
        />
        <label for="vertical-checkbox1" class="clr-control-label"
          >option 1</label
        >
      </div>
      <div class="clr-subtext-wrapper">
        <cds-icon
          class="clr-validate-icon"
          shape="exclamation-circle"
        ></cds-icon>
        <span class="clr-subtext">Zustimmung</span>
      </div>
    </div>
  </div> -->

  <!-- <button type="button" class="btn btn-primary" (click)="onClick()">
    akzeptieren
  </button> -->

  <!-- <button type="button" class="btn btn-outline" (click)="onClick()">
    schließen
  </button> -->
</div>
