import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DatenschutzCardComponent } from './datenschutz-card/datenschutz-card.component';
import { ImpressumCardComponent } from './impressum-card/impressum-card.component';

const routes: Routes = [
  { path: 'datenschutz', component: DatenschutzCardComponent },
  { path: 'impressum', component: ImpressumCardComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InfoRoutingModule {}
