<clr-modal [clrModalOpen]="true" [clrModalClosable]="false">
  <h3 class="modal-title">
    <cds-icon shape="envelope" size="md"></cds-icon>
    Einladung
  </h3>

  <div class="modal-body">
    <div *ngIf="(error$ | async)?.status">
      <div
        *ngIf="error$ | async as error"
        class="alert alert-danger"
        role="alert"
      >
        <div class="alert-items">
          <div class="alert-item static">
            <div class="alert-icon-wrapper">
              <cds-icon
                class="alert-icon"
                shape="exclamation-circle"
              ></cds-icon>
            </div>
            <span class="alert-text">
              {{ error.status }} | {{ error.statusText }}
              <ng-container *ngIf="error.error.name">
                | {{ error.error.name }} | {{ error.error.message }}
              </ng-container>
            </span>
          </div>
        </div>
      </div>
    </div>

    <form clrForm [formGroup]="formInvite">
      <!-- Username -->
      <clr-input-container>
        <label class="clr-col-12 clr-col-md-4 clr-required-mark" for="mail"
          >E-Mail</label
        >
        <input
          clrInput
          formControlName="Username"
          class="clr-col-12 clr-col-md-8"
          style="width: 90%"
          id="mail"
          type="email"
          [class.is-invalid]="username?.invalid && username?.touched"
        />
        <clr-control-error>
          <small *ngIf="username?.errors?.['required']" class="text-danger"
            >E-Mail Adresse ist erforderlich.</small
          >
          <small *ngIf="username?.errors?.['pattern']" class="text-danger"
            >Bitte geben Sie eine gültige E-Mail-Adresse an.</small
          >
        </clr-control-error>
      </clr-input-container>

      <!-- Firstname -->
      <clr-input-container>
        <label class="clr-col-12 clr-col-md-4 clr-required-mark" for="mail"
          >Vorname</label
        >
        <input
          clrInput
          formControlName="Firstname"
          class="clr-col-12 clr-col-md-8"
          style="width: 90%"
          id="firstname"
          type="text"
          [class.is-invalid]="firstname?.invalid && firstname?.touched"
        />
        <clr-control-error>
          <small *ngIf="firstname?.errors?.['required']" class="text-danger"
            >Vorname ist erforderlich.</small
          >
        </clr-control-error>
      </clr-input-container>

      <!-- Lastname -->
      <clr-input-container>
        <label class="clr-col-12 clr-col-md-4 clr-required-mark" for="mail"
          >Nachname</label
        >
        <input
          clrInput
          formControlName="Lastname"
          class="clr-col-12 clr-col-md-8"
          style="width: 90%"
          id="lastname"
          type="text"
          [class.is-invalid]="lastname?.invalid && lastname?.touched"
        />
        <clr-control-error>
          <small *ngIf="lastname?.errors?.['required']" class="text-danger"
            >Nachname ist erforderlich.</small
          >
        </clr-control-error>
      </clr-input-container>

      <!-- Selected -->
      <ng-container *ngIf="(isService$ | async) === true">
        <clr-combobox-container>
          <label class="clr-col-12 clr-col-md-4" for="singleSelect">
            Kennung
            <span class="spinner spinner-inline" *ngIf="loadingContacts"></span>
          </label>
          <clr-combobox
            formControlName="Selected"
            class="clr-col-12 clr-col-md-8"
            style="width: 100%"
            id="singleSelect"
          >
            <clr-options>
              <clr-option
                *clrOptionItems="let contact of contacts; field: 'Lastname'"
                [clrValue]="contact"
              >
                {{ contact.Lastname }}, {{ contact.Firstname }}
                <span class="badge">{{ contact.Factories.length }}</span>
              </clr-option>
            </clr-options>
          </clr-combobox>
        </clr-combobox-container>
      </ng-container>

      <!-- Rollen -->
      <ng-container *ngIf="(isManager$ | async) === true">
        <clr-combobox-container>
          <label class="clr-col-12 clr-col-md-4" for="multiSelect">
            Rollen
            <span class="spinner spinner-inline" *ngIf="loadingRoles"></span>
          </label>
          <clr-combobox
            formControlName="Roles"
            class="clr-col-12 clr-col-md-8"
            style="width: 100%"
            id="multiSelect"
            clrMulti="true"
          >
            <ng-container *clrOptionSelected="let selected">
              {{ selected?.Name }}
            </ng-container>
            <clr-options>
              <clr-option
                *clrOptionItems="let role of roles; field: 'Symbol'"
                [clrValue]="role"
              >
                {{ role.Name }}
              </clr-option>
            </clr-options>
          </clr-combobox>
        </clr-combobox-container>
      </ng-container>
    </form>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="onInviteCancel()">
      abbrechen
    </button>
    <button
      type="button"
      class="btn btn-primary"
      (click)="onInviteSubmit()"
      [disabled]="formInvite.invalid"
      [clrLoading]="validateBtnState"
    >
      senden
    </button>
  </div>
</clr-modal>
