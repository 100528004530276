import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ComboboxModel } from '@clr/angular/forms/combobox/model/combobox.model';
import { ContactStoreService } from 'src/app/services/contact-store.service';
import { User } from 'src/app/models/User';
import { Contact } from 'src/app/models/Contact';

import {
  selectProfil,
  selectProfilError,
  selectProfilLoading,
  selectProfilRoles,
  selectUpdated,
} from '../+profil/profil.selectors';
import { ProfilActions } from '../+profil/profil.actions';

import { ClrLoadingState } from '@clr/angular';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-users-profile',
  templateUrl: './users-profile.component.html',
  styleUrls: ['./users-profile.component.css'],
})
export class UsersProfileComponent implements OnInit {
  defaultValues = {
    Firstname: '',
    Lastname: '',
    Notification: false,
    Authentication: false,
  };

  formProfil = this.formBuilder.group({
    Username: new FormControl(
      { value: '', disabled: true },
      {
        nonNullable: true,
        validators: [Validators.required],
      },
    ),
    Password: new FormControl(
      { value: '', disabled: true },
      {
        nonNullable: true,
        validators: [Validators.required],
      },
    ),

    Notification: new FormControl(
      { value: false, disabled: false },
      {
        nonNullable: true,
        validators: [Validators.required],
      },
    ),

    Authentication: new FormControl(
      { value: false, disabled: true },
      {
        nonNullable: true,
        validators: [Validators.required],
      },
    ),

    Firstname: new FormControl(
      { value: '', disabled: false },
      {
        nonNullable: true,
        validators: [Validators.required],
      },
    ),
    Lastname: new FormControl(
      { value: '', disabled: false },
      {
        nonNullable: true,
        validators: [Validators.required],
      },
    ),

    Uid: new FormControl(
      { value: '', disabled: false },
      {
        nonNullable: true,
        validators: [Validators.required],
      },
    ),
  });

  get Username() {
    return this.formProfil.get('Username');
  }

  formContact = this.formBuilder.group({
    Uid: new FormControl('', { nonNullable: false }),
    Firstname: new FormControl('', { nonNullable: true }),
    Lastname: new FormControl('', { nonNullable: true }),
    Selected: new FormControl({} as Contact, { nonNullable: false }),
  });

  updated$ = this.store.select(selectUpdated);

  profil$ = this.store.select(selectProfil);
  loading$ = this.store.select(selectProfilLoading);
  error$ = this.store.select(selectProfilError);

  isService$ = this.store.select(selectProfilRoles(['service']));

  mfaMailToken = '';

  mfaMailDisable = false;
  mfaMailEnable = false;
  isErrorMfaMail = false;
  // TODO: Fehlermeldungen anzeigen.
  errorMfaMail = new HttpErrorResponse({});
  errorMfaRequest = new HttpErrorResponse({});
  errorMfaStatus = new HttpErrorResponse({});

  detail = false;
  kennung = false;
  loading = false;
  error = new HttpErrorResponse({});

  contacts = [] as Contact[];
  selected = {} as Contact;

  submitBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;
  requestMfaMailBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;
  activateMfaMailBtnState: ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(
    private store: Store,
    private formBuilder: FormBuilder,
    private contactStoreService: ContactStoreService,
    private authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.profil$.subscribe((profil) => {
      if (profil?._id) {
        this.defaultValues.Firstname = profil.Firstname;
        this.defaultValues.Lastname = profil.Lastname;
        this.defaultValues.Notification = profil.SignIn.Notification;
        this.defaultValues.Authentication = profil.MAIL.IsEnabled;
      }
    });

    this.profil$.subscribe((profil) => {
      if (profil.Uid) {
        this.loading = true;

        this.formProfil.get('Uid')?.setValue(profil.Uid);
        this.formProfil.get('Username')?.setValue(profil.Username);
        // this.formProfil.get('Password')?.setValue(profil.Password);
        this.formProfil.get('Firstname')?.setValue(profil.Firstname);
        this.formProfil.get('Lastname')?.setValue(profil.Lastname);
        this.formProfil
          .get('Notification')
          ?.setValue(profil.SignIn.Notification);
        this.formProfil.get('Authentication')?.setValue(profil.MAIL.IsEnabled);

        this.contactStoreService.getSingle(profil?.Uid).subscribe(
          (contact) => {
            if (contact?.Uid) {
              this.selected = contact;

              this.formContact.get('Uid')?.setValue(contact.Uid || '');

              this.formContact
                .get('Firstname')
                ?.setValue(contact.Firstname || '');

              this.formContact
                .get('Lastname')
                ?.setValue(contact.Lastname || '');

              this.formContact.get('Selected')?.setValue(contact);

              this.loading = false;
            } else {
              this.formContact.get('Selected')?.reset();
              this.formContact.get('Firstname')?.reset();
              this.formContact.get('Lastname')?.reset();
            }
          },
          (error) => {
            this.error = error;
            this.loading = false;
          },
        );
      }

      if (profil?.SignIn?.Notification) {
        this.formProfil
          .get('Notification')
          ?.setValue(profil.SignIn.Notification);
      }
    });

    this.updated$.subscribe((updated) => {
      this.kennung = false;

      if (updated) {
        this.submitBtnState = ClrLoadingState.SUCCESS;
      }
    });

    this.error$.subscribe((error) => {
      this.kennung = false;

      if (error?.status) {
        this.submitBtnState = ClrLoadingState.ERROR;
      }
    });
  }

  onNeueKennung() {
    this.formContact.get('Selected')?.disable();

    this.loading = true;
    this.kennung = true;
    this.error = new HttpErrorResponse({});

    this.contactStoreService.getAll().subscribe(
      (contacts) => {
        this.contacts = contacts
          .filter((contact) => contact.Factories.length > 0)
          .sort((a, b) => {
            if (a.Lastname && b.Lastname) {
              return a.Lastname.localeCompare(b.Lastname);
            }
            return 0;
          });

        this.loading = false;

        this.formContact.get('Selected')?.enable();
      },
      (error) => {
        this.error = error;
        this.loading = false;
      },
      () => {
        //
      },
    );
  }

  onNeueKennungOk() {
    this.submitBtnState = ClrLoadingState.LOADING;

    const selected = (this.formContact.get('Selected')?.value || {}) as Contact;

    if (selected.Uid) {
      const profil = {
        Uid: selected.Uid,
      } as User;

      this.store.dispatch(ProfilActions.updateKennung({ profil: profil }));
    } else {
      const profil = {
        Uid: '000000000',
      } as User;

      this.store.dispatch(ProfilActions.updateKennung({ profil: profil }));
    }
  }

  onNeueKennungCancel() {
    this.kennung = false;
  }

  onDeleteKennung() {
    const profil = {
      // FIXME: Auslagern in `env` - Datei
      Uid: '000000000',
    } as User;

    this.store.dispatch(ProfilActions.updateKennung({ profil: profil }));

    this.kennung = false;
  }

  onProfilUpdateSubmit() {
    const profil = {
      Firstname:
        this.formProfil.get('Firstname')?.value ?? this.defaultValues.Firstname,
      Lastname:
        this.formProfil.get('Lastname')?.value ?? this.defaultValues.Lastname,
      SignIn: {
        Notification:
          this.formProfil.get('Notification')?.value ??
          this.defaultValues.Notification,
      },
    } as User;

    this.store.dispatch(ProfilActions.updateProfil({ profil: profil }));
  }

  onProfilUpdateReset() {
    this.formProfil.patchValue({
      Firstname: this.defaultValues.Firstname,
      Lastname: this.defaultValues.Lastname,
      Notification: this.defaultValues.Notification,
      Authentication: this.defaultValues.Authentication,
    });
  }

  onDetail() {
    this.detail = true;
  }

  clrSelectionChange(value: ComboboxModel<Contact>) {
    this.selected = value.model as Contact;
  }

  onNotificationClick(event: any) {
    const profil = {
      SignIn: {
        Notification: event.currentTarget.checked,
      },
    } as User;

    this.store.dispatch(ProfilActions.updateProfil({ profil: profil }));

    // if(event.currentTarget.checked) {
    // } else {
    // }
  }

  onRequestMfaMailEnable() {
    this.requestMfaMailBtnState = ClrLoadingState.LOADING;

    this.authService.mfaMailRequestEnable().subscribe(
      (result) => {
        this.requestMfaMailBtnState = ClrLoadingState.SUCCESS;
      },
      (error) => {
        this.requestMfaMailBtnState = ClrLoadingState.ERROR;
      },
      () => {
        // this.requestMfaMailBtnState = ClrLoadingState.DEFAULT;
      },
    );
  }

  requestMfaMailEnable() {
    this.mfaMailEnable = true;

    this.authService.mfaMailRequestEnable().subscribe(
      (result) => {
        this.requestMfaMailBtnState = ClrLoadingState.SUCCESS;
      },
      (error) => {
        this.requestMfaMailBtnState = ClrLoadingState.ERROR;
      },
      () => {
        // this.requestMfaMailBtnState = ClrLoadingState.DEFAULT;
      },
    );
  }

  confirmMfaMailEnable() {
    this.activateMfaMailBtnState = ClrLoadingState.LOADING;

    this.authService.mfaMailEnable(this.mfaMailToken).subscribe(
      (result) => {
        this.activateMfaMailBtnState = ClrLoadingState.SUCCESS;
        this.store.dispatch(ProfilActions.loadProfil());
        this.mfaMailEnable = false;
      },
      (error) => {
        this.activateMfaMailBtnState = ClrLoadingState.ERROR;
      },
      () => {
        // this.activateMfaMailBtnState = ClrLoadingState.DEFAULT;
      },
    );
  }

  onRequestMfaMailDisable() {
    this.requestMfaMailBtnState = ClrLoadingState.LOADING;

    this.authService.mfaMailRequestDisable().subscribe(
      (result) => {
        this.requestMfaMailBtnState = ClrLoadingState.SUCCESS;
      },
      (error) => {
        this.requestMfaMailBtnState = ClrLoadingState.ERROR;
      },
      () => {
        // this.requestMfaMailBtnState = ClrLoadingState.DEFAULT;
      },
    );
  }

  requestMfaMailDisable() {
    this.mfaMailDisable = true;

    this.authService.mfaMailRequestDisable().subscribe(
      (result) => {
        this.requestMfaMailBtnState = ClrLoadingState.SUCCESS;
      },
      (error) => {
        this.requestMfaMailBtnState = ClrLoadingState.ERROR;
      },
      () => {
        // this.requestMfaMailBtnState = ClrLoadingState.DEFAULT;
      },
    );
  }

  confirmMfaMailDisable() {
    this.activateMfaMailBtnState = ClrLoadingState.LOADING;

    this.authService.mfaMailDisable(this.mfaMailToken).subscribe(
      (result) => {
        this.activateMfaMailBtnState = ClrLoadingState.SUCCESS;
        this.store.dispatch(ProfilActions.loadProfil());
        this.mfaMailDisable = false;
      },
      (error) => {
        this.activateMfaMailBtnState = ClrLoadingState.ERROR;
      },
      () => {
        // this.activateMfaMailBtnState = ClrLoadingState.DEFAULT;
      },
    );
  }

  onCodeChanged(code: string) {
    this.mfaMailToken = code;
  }

  onCodeCompleted(code: string) {
    this.mfaMailToken = code;
  }
}
