import { Component, OnInit } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Store } from '@ngrx/store';

import { AuthService } from '../services/auth.service';
import { SocketClientService } from '../services/socket-client.service';
// import { MqttEventService } from '../services/mqtt-event.service';
// import { TelemetryMessagesService } from '../shared/telemetry-messages.service';
// import { TelemetryPresetsService } from '../shared/telemetry-presets.service';

import {
  selectProfil,
  selectProfilError,
  selectProfilLoading,
  selectProfilRoles,
} from '../views/users/+profil/profil.selectors';
import { ProfilActions } from '../views/users/+profil/profil.actions';

// import {
//   selectTelemetries,
//   selectTelemetriesError,
//   selectTelemetriesLoading,
// } from '../views/telemetries/+store/telemetry.selectors';
// import { TelemetryActions } from '../views/telemetries/+store/telemetry.actions';

import { LayoutService } from '../shared/layout.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
})
export class LayoutComponent implements OnInit {
  clrVerticalNavCollapsed = false;

  info = 0;
  warn = 0;
  alarm = 0;
  update = false;

  // FIXME: loading$ und error$, werden diese angezeigt?
  profil$ = this.store.select(selectProfil);
  loading$ = this.store.select(selectProfilLoading);
  error$ = this.store.select(selectProfilError);

  // // FIXME: loading$ und error$, werden diese angezeigt?
  // telemetries$ = this.store.select(selectTelemetries);
  // loadingTelemetries$ = this.store.select(selectTelemetriesLoading);
  // errorTelemetries$ = this.store.select(selectTelemetriesError);

  isManager$ = this.store.select(selectProfilRoles(['manager']));
  isService$ = this.store.select(selectProfilRoles(['service']));

  constructor(
    // private MqttEventService: MqttEventService,
    // private telemetryMessagesService: TelemetryMessagesService,
    // private telemetryPresetsService: TelemetryPresetsService,
    private swUpdate: SwUpdate,
    private store: Store,
    private authService: AuthService,
    private socketService: SocketClientService,
    private layoutService: LayoutService,
  ) {}

  ngOnInit(): void {
    if (this.swUpdate.isEnabled) {
      console.log('Checking for updates...');
      this.swUpdate.checkForUpdate().then(() => {
        console.log('Activating update...');
        this.swUpdate.activateUpdate().then((updateAvailable) => {
          console.log(`Update available: ${updateAvailable}`);
          if (updateAvailable) {
            console.log('Update has been performed.');
            this.update = true;
          }
        });
      });
    }

    this.store.dispatch(ProfilActions.loadProfil());

    this.profil$.subscribe((profil) => {
      if (profil?.Uid) {
        this.socketService.connectClientSession(profil.Uid);
      }
    });

    // this.profil$.subscribe((profil) => {
    //   if (profil?.Uid) {
    //     this.store.dispatch(TelemetryActions.loadTelemetries());
    //     this.telemetries$.subscribe((telemetries) => {
    //       telemetries.forEach((telemetry) => {
    //         if (telemetry.MQTT?.Id) {
    //           this.MqttEventService.create(telemetry);
    //         }
    //       });
    //       telemetries.forEach((telemetry) => {
    //         if (telemetry.MQTT?.Id) {
    //           telemetry.Presets?.forEach((preset) => {
    //             this.telemetryPresetsService.update(telemetry.MQTT.Id, preset);
    //           });
    //         }
    //       });
    //     });
    //     this.telemetryMessagesService.info.subscribe((value) => {
    //       this.info = value;
    //     });
    //     this.telemetryMessagesService.warn.subscribe((value) => {
    //       this.warn = value;
    //     });
    //     this.telemetryMessagesService.alarm.subscribe((value) => {
    //       this.alarm = value;
    //     });
    //   }
    // });
  }

  onLogout() {
    this.authService.logout();
    this.socketService.closeClientSession();
  }

  onUpdate() {
    document.location.reload();
  }

  readThemeMode() {
    return this.layoutService.readThemeMode();
  }

  public onToggleTheme() {
    this.layoutService.toggleMode();
  }
}
