import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  Input,
  inject,
} from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEventType,
} from '@angular/common/http';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription, finalize } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { Cabinet } from 'src/app/models/Cabinet';
import { Template } from 'src/app/models/Template';
import { AssessmentStoreService } from 'src/app/services/assessment.service';

import {
  selectTemplate,
  selectTemplatesLoading,
  selectTemplatesError,
} from '../+template/template.selectors';
import { TemplateActions } from '../+template/template.actions';

import { CabinetActions } from '../+cabinet/cabinet.actions';

@Component({
  selector: 'app-dialog-add-file',
  templateUrl: './dialog-add-file.component.html',
  styleUrls: ['./dialog-add-file.component.css'],
})
export class DialogAddFileComponent implements OnInit {
  @Output() basic = new EventEmitter<boolean>();
  @Input() cabinet = {} as Cabinet;

  cabinet$ = new Observable<Cabinet>();
  loading$ = new Observable<boolean>();
  error$ = new Observable<HttpErrorResponse | null>();

  file: File | null = null;

  uploadProgress = 0;
  uploadSub$ = new Subscription();

  private formBuilder = inject(FormBuilder);

  formDynamisch = this.formBuilder.group({});

  formMobile = this.formBuilder.group({
    _id: new FormControl('', { nonNullable: true }),
    File: this.formBuilder.group({
      Path: new FormControl('', { nonNullable: true }),
      Name: new FormControl('', { nonNullable: true }),
      id: new FormControl('', { nonNullable: true }),
      cid: new FormControl('', { nonNullable: true }),
    }),
    Files: this.formBuilder.array([]),
    Image: this.formBuilder.group({
      Path: new FormControl('', { nonNullable: true }),
      Name: new FormControl('', { nonNullable: true }),
      id: new FormControl('', { nonNullable: true }),
      cid: new FormControl('', { nonNullable: true }),
    }),
    Images: this.formBuilder.array([]),
  });

  get filesArray() {
    return this.formMobile.get('Files') as FormArray;
  }

  constructor(
    private store: Store,
    private authService: AuthService,
    private riskService: AssessmentStoreService,
  ) {
    const uid = this.authService.readUid();
    console.log(`constructor() uid:${uid}`);
  }

  ngOnInit() {
    // TODO
  }

  onFileSelected(event: any) {
    this.file = event.target.files[0];
  }

  onSubmitFileBackup() {
    if (this.file) {
      console.log('onSubmitFile - content');

      const form = this.formMobile.getRawValue();

      const cabinet: Cabinet = {
        _id: form._id,
        Uid: this.authService.readUid(),
      } as Cabinet;

      const upload$ = this.riskService
        .fileUpload(this.file, '0815', cabinet)
        .pipe(finalize(() => this.resetUpload()));

      this.uploadSub$ = upload$.subscribe((event) => {
        if (event.type == HttpEventType.UploadProgress) {
          const total = event.total || event.loaded;
          this.uploadProgress = Math.round(100 * (event.loaded / total));
        }

        if (event.type == HttpEventType.Response) {
          if (event.body !== null) {
            const cabinet: Cabinet = event.body as Cabinet;

            if (cabinet._id) {
              this.store.dispatch(
                CabinetActions.loadCabinet({ id: cabinet._id }),
              );
            }
          }
        }
      });
    } else {
      console.log('onSubmitFile - empty');
    }
  }

  onSubmitFile() {
    const form = this.formMobile.getRawValue();
    if (this.file) {
      const upload$ = this.riskService
        .fileUpload(this.file, form.Image.Name, this.cabinet)
        .pipe(finalize(() => this.resetUpload()));

      this.uploadSub$ = upload$.subscribe((event) => {
        if (event.type == HttpEventType.UploadProgress) {
          const total = event.total || event.loaded;
          this.uploadProgress = Math.round(100 * (event.loaded / total));

          if (this.uploadProgress >= 100) this.closeDialog();
        }

        if (event.type == HttpEventType.Response) {
          if (event.body !== null) {
            const cabinet: Cabinet = event.body as Cabinet;
            this.closeDialog();
            if (cabinet._id) {
              this.store.dispatch(
                CabinetActions.loadCabinet({ id: cabinet._id }),
              );
            }
          }
        }
      });
    }
  }

  onCancelUpload() {
    this.uploadSub$.unsubscribe();
    this.resetUpload();
    this.closeDialog();
  }

  resetUpload() {
    this.uploadProgress = 0;
    this.uploadSub$.unsubscribe();
  }

  onUpdateFile(control: any) {
    const fileControl: FormGroup = control;
    const filePath = fileControl.get('Path')?.value;
    const fileName = fileControl.get('Name')?.value;
    const fileId = fileControl.get('id')?.value;
    const cabinetId = fileControl.get('cid')?.value;
  }

  onDeleteFile(control: any) {
    const fileControl: FormGroup = control;
    // const filePath = fileControl.get('Path')?.value;
    // const fileName = fileControl.get('Name')?.value;
    const fileId = fileControl.get('id')?.value;
    const cabinetId = fileControl.get('cid')?.value;
    this.store.dispatch(
      CabinetActions.deleteImage({ cid: cabinetId, id: fileId }),
    );
  }

  openChange(value: boolean) {
    console.log(`openChange(value: ${value})`);
    this.basic.emit(false);
  }

  closeDialog() {
    this.basic.emit(false);
  }
}
