import { Template } from './Template';
import { Item } from './Item';
import { BaseImage } from './Evaluation';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { selectTemplate } from '../views/risks/+template/template.selectors';

export interface Cabinet extends LowCabinet {
  _id: string;

  Anlage: {
    ItemId: string;
    Value: object;
  }[];
}

export interface CabinetImg extends BaseImage {
  AsPreview: boolean;
}

export interface LowCabinet {
  _id?: string;

  Uid: string;
  Template?: string;

  Anlage?: {
    ItemId: string;
    Value: object;
  }[];

  Pruefung?: {
    ItemId: string;
    Value: object;
  }[];

  Gefaehrdung?: {
    ItemId: string;
    Value: object;
  }[];

  Images?: CabinetImg[];
}

export class CabinetCalculations {
  Calculations: [{ value: number; id: string | undefined }] = [] as unknown as [
    { value: number; id: string },
  ];
  id_Schmutz: string | undefined = undefined;
  id_Feuchte: string | undefined = undefined;
  id_Alterng: string | undefined = undefined;
  id_Ausltng: string | undefined = undefined;
  id_Umgebungsbedingungen: string | undefined = undefined;
  Id_SummeBewertungsZahl: string | undefined = undefined; // Ergebnis
  Id_SchlechtesteBewertung: string | undefined = undefined; // Ergebnis
  Id_Redunktionsfaktor: string | undefined = undefined; // Ergebnis
  id_NI_L1L2: string | undefined = undefined;
  id_NI_L2L3: string | undefined = undefined;
  id_NI_L3L1: string | undefined = undefined;
  Id_Kurzschlusstrom: string | undefined = undefined; // Ergebnis

  constructor(cabinet: Cabinet, template: Template) {
    try {
      this.id_Schmutz = template.Pruefung?.find((p) => p.Title === 'Schmutz')
        ?._id;
      this.id_Feuchte = template.Pruefung?.find(
        (p) => p.Title === 'Feuchtigkeit',
      )?._id;
      this.id_Alterng = template.Pruefung?.find((p) => p.Title === 'Alterung')
        ?._id;
      this.id_Ausltng = template.Pruefung?.find((p) => p.Title === 'Auslastung')
        ?._id;
      this.id_Umgebungsbedingungen = template.Pruefung?.find(
        (p) => p.Title === 'Umgebungsbedingungen',
      )?._id;
      this.Id_SummeBewertungsZahl = template.Pruefung?.find(
        (p) => p.Title === 'SummeBewertungsZahl',
      )?._id as string;
      this.Id_SchlechtesteBewertung = template.Pruefung?.find(
        (p) => p.Title === 'SchlechtesteBewertung',
      )?._id as string;
      this.Id_Redunktionsfaktor = template.Pruefung?.find(
        (p) => p.Title === 'Redunktionsfaktor',
      )?._id as string;
      this.id_NI_L1L2 = template?.Gefaehrdung?.find(
        (p) => p.Title === 'Netzimpedanz_L1L2',
      )?._id;
      this.id_NI_L2L3 = template?.Gefaehrdung?.find(
        (p) => p?.Title === 'Netzimpedanz_L2L3',
      )?._id;
      this.id_NI_L3L1 = template?.Gefaehrdung?.find(
        (p) => p.Title === 'Netzimpedanz_L3L1',
      )?._id;
      this.Id_Kurzschlusstrom = template?.Gefaehrdung?.find(
        (p) => p.Title === 'Kurzschlusstrom',
      )?._id as string;
      this.updateCalculation(cabinet);
    } catch (err) {
      console.error(err);
    }
  }

  containsCalculations(item: Item): boolean {
    try {
      const calcItm = this.Calculations?.find((cc) => cc.id === item._id);
      if (calcItm) {
        return true;
      }
    } catch (err) {
      console.error('FEHLER : ', err);
    }
    return false;
  }

  getCalculationFor(
    item: Item,
  ): { value: number; id: string | undefined } | undefined {
    let result = undefined as unknown as
      | { value: number; id: string | undefined }
      | undefined;
    try {
      result = this.Calculations?.find((cc) => cc.id === item._id);
    } catch (err) {
      console.error('FEHLER : ', err);
    }
    return result;
  }

  /**
   * will calculate new results by cabinet
   * @param cabinet Cabinet
   */
  updateCalculation(cabinet: Cabinet): void {
    try {
      const itm1Schmutz = cabinet.Pruefung?.find(
        (p) => p.ItemId == this.id_Schmutz,
      );
      const itm2Feuchte = cabinet.Pruefung?.find(
        (p) => p.ItemId == this.id_Feuchte,
      );
      const itm3Alterng = cabinet.Pruefung?.find(
        (p) => p.ItemId == this.id_Alterng,
      );
      const itm4Ausltng = cabinet.Pruefung?.find(
        (p) => p.ItemId == this.id_Ausltng,
      );
      const itmUmgebungsbedingungen = cabinet.Pruefung?.find(
        (p) => p.ItemId == this.id_Umgebungsbedingungen,
      );
      const itmN_L1L2 = cabinet.Gefaehrdung?.find(
        (p) => p.ItemId == this.id_NI_L1L2,
      );
      const itmN_L2L3 = cabinet.Gefaehrdung?.find(
        (p) => p.ItemId == this.id_NI_L2L3,
      );
      const itmN_L3L1 = cabinet.Gefaehrdung?.find(
        (p) => p.ItemId == this.id_NI_L3L1,
      );
      let Value_SummeBewertungsZahl = NaN;
      let Value_SchlechtesteBewertung = NaN;
      let Value_Redunktionsfaktor = NaN;
      let Value_Kurzschlusstrom = NaN;
      const a1Schmutz = Number.parseInt(
        itm1Schmutz?.Value?.toString() as string,
        10,
      ) as number;
      const a2Feuchte = Number.parseInt(
        itm2Feuchte?.Value?.toString() as string,
        10,
      ) as number;
      const a3Alterng = Number.parseInt(
        itm3Alterng?.Value?.toString() as string,
        10,
      ) as number;
      const a4Ausltng = Number.parseInt(
        itm4Ausltng?.Value?.toString() as string,
        10,
      ) as number;
      const nichtsBesonderes =
        itmUmgebungsbedingungen?.Value?.toString() ==
        'keine besonderen Bedingungen';
      if (
        !Number.isNaN(a1Schmutz) &&
        !Number.isNaN(a2Feuchte) &&
        !Number.isNaN(a3Alterng) &&
        !Number.isNaN(a4Ausltng)
      ) {
        Value_SummeBewertungsZahl = (a1Schmutz +
          a2Feuchte +
          a3Alterng +
          a4Ausltng) as number;
      }
      if (!Number.isNaN(Value_SummeBewertungsZahl)) {
        Value_SchlechtesteBewertung = Math.max(
          a1Schmutz,
          a2Feuchte,
          a3Alterng,
          a4Ausltng,
        ) as number;
      }
      let sumFaktor = NaN as number;
      let maxFaktor = NaN as number;
      if (
        nichtsBesonderes &&
        !Number.isNaN(Value_SummeBewertungsZahl) &&
        Value_SummeBewertungsZahl !== undefined &&
        !Number.isNaN(Value_SchlechtesteBewertung) &&
        Value_SchlechtesteBewertung !== undefined
      ) {
        switch (true) {
          case Value_SummeBewertungsZahl < 13:
            sumFaktor = 1;
            break;
          case Value_SummeBewertungsZahl < 16:
            sumFaktor = 0.75;
            break;
          case Value_SummeBewertungsZahl < 19:
            sumFaktor = 0.5;
            break;
          default:
            sumFaktor = 0.25;
        }
        switch (true) {
          case Value_SchlechtesteBewertung < 4:
            maxFaktor = 1;
            break;
          case Value_SchlechtesteBewertung < 5:
            maxFaktor = 0.75;
            break;
          case Value_SchlechtesteBewertung < 6:
            maxFaktor = 0.5;
            break;
          default:
            maxFaktor = 0.25;
        }
        Value_Redunktionsfaktor = Math.min(sumFaktor, maxFaktor);
      } else if (
        !Number.isNaN(Value_SummeBewertungsZahl) &&
        Value_SummeBewertungsZahl !== undefined &&
        !Number.isNaN(Value_SchlechtesteBewertung) &&
        Value_SchlechtesteBewertung !== undefined
      ) {
        switch (true) {
          case Value_SummeBewertungsZahl < 16:
            sumFaktor = 1;
            break;
          case Value_SummeBewertungsZahl < 19:
            sumFaktor = 0.75;
            break;
          default:
            sumFaktor = 0.5;
        }
        switch (true) {
          case Value_SchlechtesteBewertung < 5:
            maxFaktor = 1;
            break;
          case Value_SchlechtesteBewertung < 6:
            maxFaktor = 0.75;
            break;
          default:
            maxFaktor = 0.5;
        }
        Value_Redunktionsfaktor = Math.min(sumFaktor, maxFaktor);
      }
      const ni1 = Number.parseFloat(itmN_L1L2?.Value?.toString() as string);
      const ni2 = Number.parseFloat(itmN_L2L3?.Value?.toString() as string);
      const ni3 = Number.parseFloat(itmN_L3L1?.Value?.toString() as string);
      try {
        if (!Number.isNaN(ni1) && !Number.isNaN(ni2) && !Number.isNaN(ni3)) {
          Value_Kurzschlusstrom =
            (1.1 * 400) /
            ((Math.sqrt(3) *
              Math.min(
                (ni1 - ni3 + ni2) / 2,
                (ni1 + ni3 - ni2) / 2,
                (-ni1 + ni3 + ni2) / 2,
              )) /
              1.15);
        }
      } catch (err) {
        console.error(err);
        Value_Kurzschlusstrom = NaN;
      }

      const sumBewertungsZahl = this.Calculations.find(
        (c) => c.id == this.Id_SummeBewertungsZahl,
      );
      if (sumBewertungsZahl) {
        sumBewertungsZahl.value = Value_SummeBewertungsZahl;
      } else {
        this.Calculations.push({
          value: Value_SummeBewertungsZahl,
          id: this.Id_SummeBewertungsZahl,
        });
      }
      const schlechtesteBewertung = this.Calculations.find(
        (c) => c.id == this.Id_SchlechtesteBewertung,
      );
      if (schlechtesteBewertung) {
        schlechtesteBewertung.value = Value_SchlechtesteBewertung;
      } else {
        this.Calculations.push({
          value: Value_SchlechtesteBewertung,
          id: this.Id_SchlechtesteBewertung,
        });
      }
      const redunktionsfaktor = this.Calculations.find(
        (c) => c.id == this.Id_Redunktionsfaktor,
      );
      if (redunktionsfaktor) {
        redunktionsfaktor.value = Value_Redunktionsfaktor;
      } else {
        this.Calculations.push({
          value: Value_Redunktionsfaktor,
          id: this.Id_Redunktionsfaktor,
        });
      }
      const kurzschlusstrom = this.Calculations.find(
        (c) => c.id == this.Id_Kurzschlusstrom,
      );
      if (kurzschlusstrom) {
        kurzschlusstrom.value = Value_Kurzschlusstrom;
      } else {
        this.Calculations.push({
          value: Value_Kurzschlusstrom,
          id: this.Id_Kurzschlusstrom,
        });
      }
    } catch (err) {
      console.error('FEHLER : ', err);
    }
  }
}
