import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { User } from 'src/app/models/User';

export const ProfilActions = createActionGroup({
  source: 'Profil',
  events: {
    'Update Kennung': props<{ profil: User }>(),
    // 'Update Kennung Success': props<{ profil: User }>(),
    // 'Update Kennung Failure': props<{ error: HttpErrorResponse }>(),

    'Update Profil': props<{ profil: User }>(),
    'Update Profil Success': props<{ profil: User }>(),
    'Update Profil Failure': props<{ error: HttpErrorResponse }>(),

    'Load Profil': emptyProps(),
    'Load Profil Success': props<{ profil: User }>(),
    'Load Profil Failure': props<{ error: HttpErrorResponse }>(),

    // 'Signin User': props<{
    //   username: string;
    //   password: string;
    //   token: string | null;
    // }>(),
  },
});
