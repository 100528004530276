import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectProfil } from '../+profil/profil.selectors';
import { ProfilActions } from '../+profil/profil.actions';

import { selectCombinedFactories } from '../../factories/+store/factory.selectors';
import { FactoryActions } from '../../factories/+store/factory.actions';

import { HttpErrorResponse } from '@angular/common/http';
import { Factory } from 'src/app/models/Factory';
import {
  selectUsers,
  selectUsersError,
  selectUsersLoading,
} from '../+store/user.selectors';
import { UserActions } from '../+store/user.actions';

@Component({
  selector: 'app-users-rights-factories',
  templateUrl: './users-rights-factories.component.html',
  styleUrls: ['./users-rights-factories.component.css'],
})
export class UsersRightsFactoriesComponent implements OnInit {
  profil$ = this.store.select(selectProfil);

  users$ = this.store.select(selectUsers);
  loading$ = this.store.select(selectUsersLoading);
  error$ = this.store.select(selectUsersError);

  combinedFactories$ = this.store.select(selectCombinedFactories);

  selected = {} as Factory;
  factories = [] as Factory[];
  loading = false;
  error: HttpErrorResponse | null = null;

  detail = false;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(ProfilActions.loadProfil());

    this.profil$.subscribe((profil) => {
      if (profil?._id) {
        this.store.dispatch(FactoryActions.loadFactories());
      }
    });

    this.profil$.subscribe((profil) => {
      if (profil?._id) {
        this.store.dispatch(UserActions.loadUsers());
      }
    });

    this.combinedFactories$.subscribe(({ factories, loading, error }) => {
      this.factories = factories;
      this.loading = loading;
      this.error = error;
    });
  }

  onSelectedEdit() {
    this.detail = true;
  }

  onSelectedClose(value: boolean) {
    this.detail = false;
  }
}
