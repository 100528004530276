import { Injectable } from '@angular/core';
import { IMqttMessage, MqttService } from 'ngx-mqtt';
import { Telemetry } from '../models/Telemetry';
import { environment } from 'src/environments/environment';

import {
  MQTT_PROTOCOL,
  MQTT_PROTOCOL_ID,
  MQTT_PROTOCOL_VERSION,
} from '../constants/mqtt';

import { TelemetryMessagesService } from '../shared/telemetry-messages.service';
import { TelemetryPresetsService } from '../shared/telemetry-presets.service';

import { TelemetryMeasurementsService } from '../shared/telemetry-measurements.service';

@Injectable({
  providedIn: 'root',
})
export class MqttEventService {
  readonly API = 'v1';

  private mqttServices: MqttService[] = [];

  constructor(
    private telemetryMessagesService: TelemetryMessagesService,
    private telemetryPresetsService: TelemetryPresetsService,
    private telemetryMeasurementsService: TelemetryMeasurementsService,
  ) {}

  create(telemetry: Telemetry) {
    const offline = {
      Connected: false,
      Message: 'Offline',
    };

    const clientId =
      telemetry.MQTT.Id.toUpperCase() +
      `_` +
      new Date().toISOString().replace(/\D/g, '');

    const _mqttService = new MqttService({
      clientId: clientId,
      username: telemetry.MQTT?.Username,
      password: telemetry.MQTT?.Password,

      connectOnCreate: true,
      clean: true,

      hostname: environment.mqttHostname,
      port: environment.mqttPort,
      path: environment.mqttPath,

      protocol: MQTT_PROTOCOL,
      protocolVersion: MQTT_PROTOCOL_VERSION,
      protocolId: MQTT_PROTOCOL_ID,

      will: {
        topic: `${this.API}/${clientId}/offline`,
        payload: JSON.stringify(offline),
        qos: 0,
        retain: false,
      },
    });

    _mqttService.onConnect.subscribe((message: IMqttMessage) => {
      const online = {
        Connected: true,
        Message: 'Online',
        Timestamp: new Date(),
      };

      _mqttService.unsafePublish(
        `${this.API}/${clientId}/online`,
        JSON.stringify(online),
      );
    });

    telemetry.Measurements.forEach((measurement) => {
      measurement.Channels.forEach((channel) => {
        const topic =
          `${this.API}` +
          `/${telemetry.MQTT?.Id}` +
          `/measure` +
          `/${measurement.System.Name}` +
          `/${measurement.Location.Name}` +
          `/${measurement.Device.Name}` +
          `/${channel.Properties.Name}`;

        this.telemetryMeasurementsService.addMeasurement({
          Client: telemetry.MQTT.Id,
          System: measurement.System.Name,
          Location: measurement.Location.Name,
          Device: measurement.Device.Name,
          Channel: channel.Properties.Name,
          Signals: [],
        });

        _mqttService.observe(topic).subscribe((data) => {
          const byteArray = new Uint8Array(data.payload);
          const dataView = new DataView(byteArray.buffer);

          // Extrahieren der ersten 8 Bytes
          const first8Bytes = byteArray.slice(0, 8);

          // Konvertieren der Bytes in eine Zahl (64-Bit Integer)
          const number = new DataView(first8Bytes.buffer).getBigInt64(0, true);

          // Konvertieren des BigInt in Millisekunden
          const milliseconds = Number(number) / 1e6;

          // Erstellen eines Date-Objekts
          const time = new Date(milliseconds);

          const signals: {
            Name: string;
            Alias: string;
            Unit: string;
            Time: Date;
            Type: string;
            Value: any;
          }[] = [];

          channel.Signals.forEach((signal, index) => {
            const value = dataView.getFloat32(8 + index * 4, true).toFixed(0);

            signals.push({
              Name: signal.Name,
              Alias: signal.Alias,
              Unit: signal.Unit,
              Type: signal.Type,
              Time: time,
              Value: value,
            });
          });

          const t = data.topic.split('/');
          // const t0 = t[0]; // version
          const t1 = t[1]; // Id
          // const t2 = t[2]; // measure
          const t3 = t[3]; // system: UBGST
          const t4 = t[4]; // location: K01
          const t5 = t[5]; // device: 55A1
          const t6 = t[6]; // channel: UL

          this.telemetryMeasurementsService.updateSignals(
            t1, // client
            t3, // system
            t4, // location
            t5, // devivce
            t6, // channel
            signals, //
          );
        });
      });
    });

    _mqttService
      .observe(`${this.API}/${telemetry.MQTT?.Id}/messages`)
      .subscribe((data) => {
        const key = this.extractKeyFromMqttTopic(data.topic);
        const telemetryMessages = JSON.parse(data.payload.toString());
        this.telemetryMessagesService.update(key, telemetryMessages);
      });

    _mqttService
      .observe(`${this.API}/${telemetry.MQTT?.Id}/presets`)
      .subscribe((data) => {
        const key = this.extractKeyFromMqttTopic(data.topic);
        const telemetryPresets = JSON.parse(data.payload.toString());
        this.telemetryPresetsService.update(key, telemetryPresets);
      });

    this.mqttServices.push(_mqttService);
  }

  private extractKeyFromMqttTopic(topic: string): string {
    const topicParts = topic.split('/');
    return topicParts.length >= 2 ? topicParts[1] : '';
  }

  updateMessages(id: string) {
    const mqttService = this.mqttServices.find((service) =>
      service.clientId.startsWith(id),
    );

    if (!mqttService) {
      return Promise.reject('Kein passender MQTT-Service gefunden');
    }

    const _id = mqttService.clientId.slice(0, -18);
    const payload = {
      Channel: 'active',
      Parameter: {
        Update: true,
      },
    };

    mqttService.unsafePublish(
      `${this.API}/${_id}/messages/cmd`,
      JSON.stringify(payload),
    );

    return mqttService
      .publish(`${this.API}/${_id}/messages/cmd`, JSON.stringify(payload))
      .toPromise();
  }
}
