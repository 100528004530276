import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Cabinet } from 'src/app/models/Cabinet';
import { Template } from 'src/app/models/Template';
import { AuthService } from 'src/app/services/auth.service';
import { AssessmentStoreService } from 'src/app/services/assessment.service';
import {
  selectCabinets,
  selectCabinetsError,
  selectCabinetsLoading,
} from '../+cabinet/cabinet.selectors';
import { CabinetActions } from '../+cabinet/cabinet.actions';
import {
  selectTemplates,
  selectTemplateUid,
} from '../+template/template.selectors';
import { TemplateActions } from '../+template/template.actions';
import { Item } from 'src/app/models/Item';
import { ClrDatagridComparatorInterface } from '@clr/angular';
import { selectProfil } from '../../users/+profil/profil.selectors';
import { Evaluation } from 'src/app/models/Evaluation';

class MyComparator implements ClrDatagridComparatorInterface<Cabinet> {
  constructor(private id: string) {}

  compare(a: Cabinet, b: Cabinet) {
    const aIdxItm = a?.Anlage?.find(
      (itm: { ItemId: any }) => itm?.ItemId == this.id,
    ) as {
      ItemId: string;
      Value: object;
    };
    const bIdxItm = b?.Anlage?.find(
      (itm: { ItemId: any }) => itm?.ItemId == this.id,
    ) as {
      ItemId: string;
      Value: object;
    };
    let result = 0;
    if (aIdxItm?.Value == bIdxItm?.Value) result = 0;
    else if (aIdxItm?.Value < bIdxItm?.Value) result = -1;
    else result = 1;

    // console.log(
    //   `id: ${this.id} a.Value:${aIdxItm?.Value} b.Value:${bIdxItm?.Value} result:${result}`,
    // );
    return result;
  }
}

@Component({
  selector: 'app-risks-list-grid',
  templateUrl: './risks-list-grid.component.html',
  styleUrls: ['./risks-list-grid.component.css'],
})
export class RisksListGridComponent implements OnInit {
  private service = inject(AssessmentStoreService);
  templates$ = this.store.select(selectTemplates); // selectTemplateUid(this.authService.readUid()));
  template$ = new Observable<Template>(); // this.store.select(selectTemplateUid(this.authService.readUid()));
  cabinets$ = this.store.select(selectCabinets);
  loading$ = this.store.select(selectCabinetsLoading);
  error$ = this.store.select(selectCabinetsError);
  dialogAddOpen = false;
  dialogImportOpen = false;
  dialogDeleteOpen = false;
  selected = [] as Cabinet[];
  forDetailEvaluations = [] as Evaluation[];
  @ViewChild('gridchild') gridchild: any; // as ClrDatagrid;
  @ViewChild('listfilter') listfilter: any; // as ClrDatagrid;

  constructor(
    private store: Store,
    private router: Router,
    private riskService: AssessmentStoreService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    const profil = this.store.select(selectProfil);
    profil.subscribe((usr) => {
      if (usr.Uid != undefined) {
        // this.cabinets$ = this.service.getUsrCabinets(usr.Uid);
        this.cabinets$ = this.service.getCabinets();
        // this.store.dispatch(CabinetActions.loadCabinetsUid({ uid: usr.Uid }));
        // // this.loading$ = this.store.select(selectCabinetsLoading);
        // this.error$ = this.store.select(selectCabinetsError);
        this.store.dispatch(TemplateActions.loadTemplatesUid({ uid: usr.Uid }));
        this.template$ = this.store.select(selectTemplateUid(usr.Uid));
      }
    });
    this.cabinets$.subscribe((c) =>
      console.log(`${c.length} Cabinets gefunden`),
    );
  }

  getValues(valueObjects: { ItemId: string; Value: object }[]) {
    const list = [] as any[];
    try {
      valueObjects.forEach((x) => {
        const valueObject = {
          [`col${x.ItemId}`]: x?.Value,
          ItemId: x.ItemId,
        };
        list.push(valueObject);
      });
    } catch (err) {
      console.error(err);
    }
    return list;
  }

  addSortationFor(itemId: string): MyComparator {
    return new MyComparator(itemId);
  }

  ValueFromItem(cabinet: Cabinet, item: object) {
    try {
      const itm = item as Item;
      const result = cabinet?.Anlage?.find((a) => a.ItemId == itm._id)?.Value;
      if (result == undefined) return '';
      return result; // cabinet?.Anlage?.find((a) => a.ItemId == item._id)?.Value;
    } catch (err) {
      console.error(err);
      return '';
    }
  }

  onDetail(cabinet: Cabinet) {
    if (cabinet === null) {
      this.forDetailEvaluations = [] as Evaluation[];
    } else {
      console.log(cabinet);
      const evaluations = this.riskService.getEvaluationsFor(cabinet._id);
      evaluations.subscribe((evaluations) => {
        this.forDetailEvaluations = evaluations;
        evaluations?.forEach((evaluation) => {
          console.log('Evaluation: ', evaluation);
        });
      });
    }
  }

  onBtnEditClick() {
    const cabinet = this.selected[0] as Cabinet;
    this.router.navigate(['review', cabinet._id], {
      relativeTo: this.activatedRoute,
    });
  }

  onDialogAddClick() {
    this.dialogAddOpen = false;
  }

  onBtnAddClick() {
    this.dialogAddOpen = true;
  }

  onBtnImportClick() {
    this.dialogImportOpen = true;
  }

  onDialogImportClick() {
    this.dialogImportOpen = false;
  }

  onDeleteSelected() {
    this.dialogDeleteOpen = true;
  }

  deleteSelected() {
    this.selected.map((s) => {
      this.store.dispatch(CabinetActions.deleteCabinet({ id: s._id || '' }));
    });

    this.dialogDeleteOpen = false;
  }
}
