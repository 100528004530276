<clr-modal [clrModalOpen]="true">
  <h3 class="modal-title">{{ factory.Nr }} | {{ factory.Name }}</h3>
  <div class="modal-body">
    <clr-datagrid
      [clrDgLoading]="(loading$ | async) === true"
      [clrDgRowSelection]="true"
      [(clrDgSelected)]="selected"
    >
      <clr-dg-placeholder>
        Wir konnten keine Einträge finden!
      </clr-dg-placeholder>

      <clr-dg-column [clrDgField]="'Username'">
        <cds-icon shape="user"></cds-icon>&nbsp;Username</clr-dg-column
      >

      <clr-dg-row
        *clrDgItems="let item of storage"
        [clrDgItem]="item"
        [(clrDgSelected)]="item.IsEnabled"
      >
        <clr-dg-cell>
          <a [routerLink]="['/users', item.User._id]">
            {{ item.User.Username }}
          </a>
        </clr-dg-cell>
      </clr-dg-row>
      <clr-dg-footer>{{ storage.length }} Benutzer</clr-dg-footer>
    </clr-datagrid>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="onSubmit()">
      übernehmen
    </button>
    <button type="button" class="btn btn-outline" (click)="onCancel()">
      abbrechen
    </button>
  </div>
</clr-modal>
