import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { Factory } from 'src/app/models/Factory';
import { Contract } from 'src/app/models/Contract';

interface Item {
  Factory: Factory;
  Contracts: Contract[];
}

@Component({
  selector: 'app-factories-list-grid',
  templateUrl: './factories-list-grid.component.html',
  styleUrls: ['./factories-list-grid.component.css'],
})
export class FactoriesListGridComponent implements OnInit, OnChanges {
  @Input() factories = [] as Factory[];
  @Input() loading = false;

  @Input() extended = true;
  @Input() hiddenName = false;
  @Input() hiddenFactory = false;

  Items = [] as Item[];

  constructor(private store: Store) {}

  ngOnChanges(changes: SimpleChanges): void {
    this.updateItems();
  }

  ngOnInit(): void {
    this.updateItems();
  }

  updateItems() {
    this.Items = this.factories?.map((factory) => ({
      Factory: factory,
      Contracts: factory.Contracts,
    }));
  }
}
