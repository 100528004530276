import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { inject } from '@angular/core';

export const AuthGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const authService = inject(AuthService);

  if (authService.loggedIn()) {
    if (authService.isTokenExpired()) {
      return router.createUrlTree(['signin'], {
        queryParams: { returnUrl: state.url },
      });
    }
    return true;
  }

  return router.createUrlTree(['signin'], {
    queryParams: { returnUrl: state.url },
  });
};
