<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <h3 class="card-header">
        <a routerLink="/">Home</a> / <a routerLink="/contracts">Verträge</a> /
        <ng-container *ngIf="combinedContract$ | async as combinedContract">
          {{ combinedContract.contract.Name }}
        </ng-container>
        <span style="float: right">
          <a href="javascript:history.back()">
            <cds-icon
              shape="circle-arrow"
              direction="left"
              size="md"
            ></cds-icon>
          </a>
        </span>
      </h3>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <div *ngIf="combinedContract$ | async as combinedContract">
      <app-contracts-detail-item
        [contract]="combinedContract.contract"
        [loading]="combinedContract.loading"
        [error]="combinedContract.error"
      ></app-contracts-detail-item>
    </div>
  </div>
</div>
