import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClarityModule } from '@clr/angular';
import { NgxEchartsModule } from 'ngx-echarts';

import { ContractsRoutingModule } from './contracts-routing.module';
import { ContractsListComponent } from './contracts-list/contracts-list.component';
import { ContractsListGridCardComponent } from './contracts-list-grid-card/contracts-list-grid-card.component';
import { ContractsListGridComponent } from './contracts-list-grid/contracts-list-grid.component';
import { ContractsListTreeComponent } from './contracts-list-tree/contracts-list-tree.component';
import { ContractsDetailComponent } from './contracts-detail/contracts-detail.component';
import { ContractsDetailItemComponent } from './contracts-detail-item/contracts-detail-item.component';

import { StoreModule } from '@ngrx/store';
import * as fromContract from './+store/contract.reducer';
import { EffectsModule } from '@ngrx/effects';
import { ContractEffects } from './+store/contract.effects';

import { StationsModule } from '../stations/stations.module';
import { DocumentsModule } from '../documents/documents.module';
import { HelperModule } from 'src/app/helper/helper.module';
import { PipesModule } from 'src/app/pipes/pipes.module';

import '@cds/core/icon/register.js';

import {
  ClarityIcons,
  pieChartIcon,
  tagIcon,
  tableIcon,
  buildingIcon,
  hashtagIcon,
  hostGroupIcon,
  hostIcon,
  treeIcon,
} from '@cds/core/icon';

ClarityIcons.addIcons(
  pieChartIcon,
  tagIcon,
  tableIcon,
  buildingIcon,
  hashtagIcon,
  hostGroupIcon,
  hostIcon,
  treeIcon,
);

@NgModule({
  declarations: [
    ContractsListComponent,
    ContractsListGridComponent,
    ContractsListTreeComponent,
    ContractsDetailComponent,
    ContractsDetailItemComponent,
    ContractsListGridCardComponent,
  ],
  imports: [
    ContractsRoutingModule,
    CommonModule,
    ClarityModule,
    StoreModule.forFeature(
      fromContract.contractFeatureKey,
      fromContract.reducer,
    ),
    EffectsModule.forFeature([ContractEffects]),
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
    StationsModule,
    DocumentsModule,
    HelperModule,
    PipesModule,
  ],
  exports: [
    ContractsListGridCardComponent,
    ContractsListGridComponent,
    ContractsListTreeComponent,
    ContractsDetailItemComponent,
  ],
})
export class ContractsModule {}
