import { Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-datenschutz',
  templateUrl: './datenschutz.component.html',
  styleUrls: ['./datenschutz.component.css'],
})
export class DatenschutzComponent {
  @ViewChild('modalBody', { static: false }) modalBody!: ElementRef;

  scrollTo(sectionId: string) {
    const element = this.modalBody.nativeElement.querySelector(`#${sectionId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  handleKeyDown(event: KeyboardEvent, sectionId: string) {
    if (event.key === 'Enter' || event.key === ' ') {
      this.scrollTo(sectionId);
    }
  }

  scrollToTop() {
    this.modalBody.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
