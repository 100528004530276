import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TelemetryMessages } from './Telemetry-Messages';

@Injectable({
  providedIn: 'root',
})
export class TelemetryMessagesService {
  private InfoSource = new BehaviorSubject<number>(0);
  info = this.InfoSource.asObservable();
  private WarnSource = new BehaviorSubject<number>(0);
  warn = this.WarnSource.asObservable();
  private AlarmSource = new BehaviorSubject<number>(0);
  alarm = this.AlarmSource.asObservable();

  private myDictionarySubject = new BehaviorSubject<{
    [key: string]: TelemetryMessages;
  }>({});
  myDictionary$ = this.myDictionarySubject.asObservable();

  update(key: string, telemetryMessages: TelemetryMessages): void {
    const currentDictionary = { ...this.myDictionarySubject.value };
    currentDictionary[key] = telemetryMessages;
    this.myDictionarySubject.next(currentDictionary);

    this.InfoSource.next(
      telemetryMessages.Signals.filter(
        (signal) => signal.Class.toLowerCase() === 'info',
      ).length,
    );

    this.WarnSource.next(
      telemetryMessages.Signals.filter(
        (signal) => signal.Class.toLowerCase() === 'warnung',
      ).length,
    );

    this.AlarmSource.next(
      telemetryMessages.Signals.filter(
        (signal) => signal.Class.toLowerCase() === 'alarm',
      ).length,
    );
  }

  getObjectByKey(key: string): Observable<TelemetryMessages> {
    return this.myDictionary$.pipe(
      map((dictionary) => {
        const telemetryMessages = dictionary[key];
        if (telemetryMessages && telemetryMessages.Signals) {
          telemetryMessages.Signals.sort(
            (a, b) =>
              new Date(b.Timestamp).getTime() - new Date(a.Timestamp).getTime(),
          );
        }
        return telemetryMessages;
      }),
    );
  }
}
