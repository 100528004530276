import {
  Component,
  Input,
  OnInit,
  inject,
  Output,
  EventEmitter,
  AfterViewInit,
  OnChanges,
} from '@angular/core';
// import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Cabinet, CabinetImg } from 'src/app/models/Cabinet';

import { CabinetActions } from '../+cabinet/cabinet.actions';
import { ClrLoadingState } from '@clr/angular';
import { Observable } from 'rxjs';
import { Item } from 'src/app/models/Item';
import { User } from 'src/app/models/User';
import { selectProfil } from '../../users/+profil/profil.selectors';
import { AssessmentStoreService } from 'src/app/services/assessment.service';

@Component({
  selector: 'app-risks-tab-files',
  templateUrl: './risks-tab-files.component.html',
  styleUrls: ['./risks-tab-files.component.css'],
})
export class RiskTabFilesComponent implements OnInit, AfterViewInit, OnChanges {
  // cabinet = undefined as unknown as Cabinet;
  cabinet = { Images: [] } as unknown as Cabinet;
  @Input() cabinet$ = new Observable<Cabinet>() as Observable<Cabinet> | null;

  @Output() updated = new EventEmitter();
  @Output() changed = new EventEmitter();
  @Output() init = new EventEmitter();

  dialogAddFile = false;
  title = '';
  message = '';
  imgViewSrc = '';
  dialogViewImg = false;
  user = {} as User;
  currentPreviewImage = {} as CabinetImg;

  // private formBuilder = inject(FormBuilder);

  // formMobile = this.formBuilder.group({
  //   _id: new FormControl('', { nonNullable: true }),
  //   Image: this.formBuilder.group({
  //     Path: new FormControl('', { nonNullable: true }),
  //     Name: new FormControl('', { nonNullable: true }),
  //     Preview: new FormControl('', { nonNullable: true }),
  //     id: new FormControl('', { nonNullable: true }),
  //     cid: new FormControl('', { nonNullable: true }),
  //   }),
  //   Images: this.formBuilder.array([]),
  // });

  submitTabState: ClrLoadingState = ClrLoadingState.DEFAULT;

  onEditSubmitState: ClrLoadingState = ClrLoadingState.DEFAULT;
  onAddSubmitState: ClrLoadingState = ClrLoadingState.DEFAULT;
  onDeleteSubmitState: ClrLoadingState = ClrLoadingState.DEFAULT;

  constructor(
    private store: Store,
    private riskService: AssessmentStoreService,
  ) {
    if (this.cabinet$) {
      this.cabinet$.subscribe((cabinet) => {
        console.log('constructor() cabinet:', cabinet);
        this.cabinet = cabinet;
        this.init.emit(this);
      });
    }
  }

  refresh(itemsArray: Item[]) {
    console.log('refresh()');
    this.ngOnInit();
  }

  onDialogAddFileClick() {
    this.dialogAddFile = false;
  }

  onAddFileClick() {
    this.dialogAddFile = true;
    return false;
  }

  // getimagesArray() {
  //   return this.formMobile.get('Images') as FormArray;
  // }

  getItemImage(): string {
    // var image  =  this.riskService. (imageId).subscribe(data=> {
    //   return `<img src=${data.image}  onerror="this.src='./assets/img/default-img-300x180.gif'  class="rounded-circle UserProfileimg" width="40" /><br>`;
    // });
    // const img = this.cabinet.Images?.find((i) => i._id == imageId);
    // return `<img src=${img?.Thumb}  class="rounded-circle UserProfileimg" width="40" /><br>`;
    return this.imgViewSrc;
  }

  getThumbImage(image: CabinetImg) {
    const str64 = String.fromCharCode.apply(null, image.Thumb.data);
    return `data:${image.MIME};base64,${btoa(str64)}`;
  }

  showImage(image: CabinetImg) {
    this.riskService
      .getImage(this.cabinet._id, image._id)
      .subscribe((result) => {
        this.imgViewSrc = `data:${image.MIME};base64,${result.data}`;
        this.dialogViewImg = true;
      });
  }

  inputChanged() {
    console.log('inputChanged()');
    // const tab = this.formMobile.getRawValue();
    // const arrayItem = Object.entries(tab).map(
    //   ([Title, Value], index) =>
    //     ({
    //       ItemId: this.itmArray$.find((x) => x.ItemIndex == index)?._id,
    //       Value: Value,
    //     }) as { ItemId: string; Value: object },
    // );
    // const cabinet: Cabinet = {
    //   _id: this.cabinet._id,
    // } as Cabinet;
    // cabinet.Images = arrayItem;
    // this.changed.emit(cabinet);
  }

  ngOnInit() {
    this.init.emit(this);
    console.log('ngOnInit()');

    const profil = this.store.select(selectProfil);
    profil.subscribe((usr) => {
      this.user = usr;
    });
  }

  ngAfterViewInit() {
    if (this.cabinet$) {
      this.cabinet$?.subscribe((cabinet) => {
        this.cabinet = cabinet;
        console.log('cabinet-id:', this.cabinet._id);
        if (this.cabinet.Images && this.cabinet.Images.length > 0) {
          this.cabinet?.Images?.map((img) => {
            if (img) {
              console.log('img-Name:', img?.Name);
            }
            // this.imagesArray.push(
            //   this.formBuilder.group({
            //     Path: img.Path,
            //     Name: img.Name,
            //     Preview: img.Thumb,
            //     id: img._id,
            //     cid: img._id,
            //   }),
            // );
          });
        }
      });
    }
  }

  ngOnChanges(changes: any): void {
    try {
      if (changes['itmArray$']) {
        console.log('ngOnChanges: -> itmArray$');
      }
      if (changes['cabinet$']) {
        console.log('ngOnChanges: -> cabinet$');
      }
      if (changes['cabinet']) {
        console.log('ngOnChanges: -> cabinet');
      }
    } catch (err) {
      console.error('FEHLER : ', err);
    }
  }

  onSubmit() {
    this.submitTabState = ClrLoadingState.LOADING;
    this.store.dispatch(CabinetActions.patchCabinet({ cabinet: this.cabinet }));
    if (this.cabinet$) {
      this.cabinet$.subscribe((cabinet) => {
        console.log('onSubmit() cabinet:', cabinet._id);
        this.cabinet = cabinet;
        this.init.emit(this);
      });
    }
    this.submitTabState = ClrLoadingState.SUCCESS;
  }

  onSetPreviewImg(image: CabinetImg) {
    this.submitTabState = ClrLoadingState.LOADING;
    this.cabinet?.Images?.map((img) => {
      if (img._id == image._id) {
        console.log('Img.AsPreview = true', img._id);
        img.AsPreview = true;
      } else {
        console.log('Img.AsPreview = false', img._id);
        img.AsPreview = false;
      }
    });
    console.log('onSetPreviewImg(image) cabinet:', this.cabinet);
    this.store.dispatch(CabinetActions.patchCabinet({ cabinet: this.cabinet }));
    this.submitTabState = ClrLoadingState.SUCCESS;
  }

  onUpdateFile(control: any) {
    // const imageControl: FormGroup = control;
    // const imagePath = imageControl.get('Path')?.value;
    // const imageName = imageControl.get('Name')?.value;
    // const imageId = imageControl.get('id')?.value;
    // const cabinetId = imageControl.get('cid')?.value;
  }

  onDeleteFile(control: any) {
    // const imageControl: FormGroup = control;
    // // const imagePath = imageControl.get('Path')?.value;
    // // const imageName = imageControl.get('Name')?.value;
    // const imageId = imageControl.get('id')?.value;
    // const cabinetId = imageControl.get('cid')?.value;
    // this.store.dispatch(
    //   CabinetActions.deleteImage({ cid: cabinetId, id: imageId }),
    // );
  }
}
