<div class="card">
  <h3 class="card-header">
    <cds-icon shape="details" size="md"></cds-icon>&nbsp;Messwerte -
    {{ telemetry.MQTT.Id }}
    <!-- <ng-container *ngIf="extern; else intern">
      &nbsp;<a [routerLink]="['/factories']">Firmen</a>
    </ng-container>
    <ng-template #intern> &nbsp;Firmen </ng-template> -->
    <span style="float: right">
      <cds-icon shape="view-list" size="md"></cds-icon>
    </span>
  </h3>

  <div class="card-block">
    <!-- <h4 class="card-title">Title</h4> -->
    <div class="card-text">
      <ng-container *ngIf="telemetryData$ | async as factories">
        <clr-datagrid>
          <clr-dg-placeholder
            >Wir konnten keine Einträge finden!</clr-dg-placeholder
          >
          <clr-dg-column [clrDgField]="'Client'">
            <ng-container *clrDgHideableColumn="{ hidden: true }">
              <cds-icon shape="hashtag"></cds-icon>&nbsp;Id
            </ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'System'">
            <ng-container *clrDgHideableColumn="{ hidden: false }">
              System
            </ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'Location'">
            <ng-container *clrDgHideableColumn="{ hidden: false }">
              Ort
            </ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'Device'">
            <ng-container *clrDgHideableColumn="{ hidden: false }">
              Gerät
            </ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'Channel'">
            <ng-container *clrDgHideableColumn="{ hidden: false }">
              Kanal
            </ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'Signal.Name'">
            <ng-container *clrDgHideableColumn="{ hidden: false }">
              Signal
            </ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'Signal.Value'">
            <ng-container *clrDgHideableColumn="{ hidden: false }">
              Wert
            </ng-container>
          </clr-dg-column>
          <clr-dg-column [clrDgField]="'Signal.Time'">
            <ng-container *clrDgHideableColumn="{ hidden: false }">
              Zeit
            </ng-container>
          </clr-dg-column>

          <ng-container *ngFor="let client of factories">
            <clr-dg-row *ngFor="let signal of client.Signals">
              <clr-dg-cell>{{ client.Client }}</clr-dg-cell>
              <clr-dg-cell>{{ client.System }}</clr-dg-cell>
              <clr-dg-cell>{{ client.Location }}</clr-dg-cell>
              <clr-dg-cell>{{ client.Device }}</clr-dg-cell>
              <clr-dg-cell>{{ client.Channel }}</clr-dg-cell>

              <clr-dg-cell>{{ signal.Name }}</clr-dg-cell>
              <clr-dg-cell>{{ signal.Value }} {{ signal.Unit }}</clr-dg-cell>
              <clr-dg-cell>{{
                signal.Time | date: "dd.MM.YYYY HH:mm:ss"
              }}</clr-dg-cell>
            </clr-dg-row>
          </ng-container>

          <clr-dg-footer>
            <clr-dg-pagination #pagination [clrDgPageSize]="10">
              <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]"
                >Messwerte pro Seite</clr-dg-page-size
              >
              {{ pagination.firstItem + 1 }} - {{ pagination.lastItem + 1 }} von
              {{ pagination.totalItems }} Messwerte
            </clr-dg-pagination>
          </clr-dg-footer>
        </clr-datagrid>
        <p></p>
      </ng-container>
    </div>
  </div>
  <!-- <div class="card-footer">Footer</div> -->
</div>
