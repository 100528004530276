import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Contract } from 'src/app/models/Contract';
import { Station } from 'src/app/models/Station';
import { Document } from 'src/app/models/Document';

import * as CATEGORIES from 'src/app/constants/categories';

interface Item {
  Contract: Contract;
  Auftrag: Document[];
  Stations: Station[];
}

@Component({
  selector: 'app-contracts-list-grid',
  templateUrl: './contracts-list-grid.component.html',
  styleUrls: ['./contracts-list-grid.component.css'],
})
export class ContractsListGridComponent implements OnInit, OnChanges {
  @Input() contracts = [] as Contract[];
  @Input() loading = false;

  @Input() extended = true;
  @Input() hiddenName = false;
  @Input() hiddenFactory = false;

  Items = [] as Item[];

  AUFTRAG = CATEGORIES.AUFTRAG;

  ngOnChanges(changes: SimpleChanges): void {
    this.updateItems();
  }

  ngOnInit(): void {
    this.updateItems();
  }

  updateItems() {
    this.Items = this.contracts?.map((contract) => ({
      Contract: contract,
      Auftrag: contract.Documents.filter(
        (document) =>
          document.Categories?.some(
            (category) => category.Uid === CATEGORIES.AUFTRAG,
          ),
      ),
      Stations: contract.Stations,
    }));
  }
}
