<clr-modal [clrModalOpen]="true">
  <h3 class="modal-title">
    <cds-icon shape="eraser" size="md"></cds-icon>
    Entfernen
  </h3>
  <div class="modal-body">
    <div
      *ngIf="error$ | async as error"
      class="alert alert-danger"
      role="alert"
    >
      <div class="alert-items">
        <div class="alert-item static">
          <div class="alert-icon-wrapper">
            <cds-icon class="alert-icon" shape="exclamation-circle"></cds-icon>
          </div>
          <span class="alert-text">
            {{ error.status }} | {{ error.statusText }}
            <ng-container *ngIf="error.error.name">
              | {{ error.error.name }} | {{ error.error.message }}
            </ng-container>
          </span>
        </div>
      </div>
    </div>

    <form clrForm>
      <clr-input-container>
        <label for="username">Username</label>
        <input clrInput id="username" value="{{ user.Username }}" readonly />
      </clr-input-container>
      <clr-input-container>
        <label for="firstname">Vorname</label>
        <input clrInput id="firstname" value="{{ user.Firstname }}" readonly />
      </clr-input-container>
      <clr-input-container>
        <label for="lastname">Nachname</label>
        <input clrInput id="lastname" value="{{ user.Lastname }}" readonly />
      </clr-input-container>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline" (click)="onDeleteCancel()">
      abbrechen
    </button>
    <button
      type="button"
      class="btn btn-danger"
      (click)="onDeleteSubmit()"
      [clrLoading]="validateBtnState"
    >
      Entfernen
    </button>
  </div>
</clr-modal>
