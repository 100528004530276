<p></p>

<div class="card">
  <h3 class="card-header">
    <cds-icon shape="details" size="md"></cds-icon>&nbsp;Bewertung -
    (Anlagen-Id-intern: {{ cabinet._id }} )
    <span style="float: right">
      <cds-icon shape="view-list" size="md"></cds-icon>
    </span>
  </h3>
  <div class="card-block">
    <h4 class="card-title">
      <ng-container *ngIf="cabinet">
        <!-- Cabinet-Id:
        {{ cabinet.Uid }} -->
        <span style="float: right">
          <button
            class="btn btn-outline"
            (click)="onNewPdfResultClick()"
            [disabled]="disabledPdfCreateBtn()"
          >
            <cds-icon shape="pdf-file" />
            PDF erstellen
          </button>
          <button
            [clrLoading]="onNewEvaluation"
            class="btn btn-outline"
            (click)="newEvaluationClick()"
          >
            Neue Evaluation
          </button>
          <!-- (click)="onUpdate()" -->
        </span>
        <br />
      </ng-container>
    </h4>
    <div class="card-text">
      <ng-container *ngIf="evaluations$ | async as evaluations">
        <!-- ------------------------------- -->
        <clr-datagrid #anlagegrid [(clrDgSelected)]="selected">
          <clr-dg-placeholder
            >Wir konnten keine Einträge finden!</clr-dg-placeholder
          >
          <ng-container *ngIf="evaluations && evaluations.length > 0">
            <!-- ----------column-------------- -->
            <clr-dg-column [clrDgField]="'createdAt'">
              Erstellt am
            </clr-dg-column>
            <clr-dg-column [clrDgField]="'updatedAt'">
              Letzte Änderung
            </clr-dg-column>
            <!-- <ng-container *if="showAsPreviewBtn">
              <clr-dg-column [clrDgField]="'AsPreview">
                Vorschauauswahl
              </clr-dg-column>
            </ng-container> -->
            <!-- <clr-dg-column [clrDgField]="'Name'"> Name </clr-dg-column> -->
            <clr-dg-column [clrDgField]="'PDFResult'"> Bewertet </clr-dg-column>
            <clr-dg-column [clrDgField]="'PDFResult.File'">
              PDF-Dokument
            </clr-dg-column>
            <!-- -------------row---------------- -->
            <clr-dg-row
              *clrDgItems="let item of evaluations"
              [clrDgItem]="item"
            >
              <clr-dg-cell
                >{{ item.createdAt | date: "yyyy-MM-dd HH:mm:ss" }}
              </clr-dg-cell>
              <clr-dg-cell>{{
                item.updatedAt | date: "yyyy-MM-dd HH:mm:ss"
              }}</clr-dg-cell>
              <!-- <clr-checkbox-container style="align-items: start; display: flex">
                <clr-checkbox-wrapper style="align-items: start; display: flex">
                  <input
                    style="align-items: start; margin-top: 0px; display: flex"
                    type="checkbox"
                    clrCheckbox
                    [value]="currentPreView"
                    [checked]="currentPreView"
                    disabled="false"
                    name="Als Vorschaubild"
                    id="cbpv{{ item._id }}"
                  />
                  <label for="cbpv{{ item._id }}">Bewertung erfolgt</label>
                </clr-checkbox-wrapper>
              </clr-checkbox-container> -->
              <clr-dg-cell style="align-items: start; display: flex">
                <clr-checkbox-container
                  style="align-items: start; display: flex"
                >
                  <clr-checkbox-wrapper
                    style="align-items: start; display: flex"
                  >
                    <input
                      style="align-items: start; margin-top: 0px; display: flex"
                      type="checkbox"
                      clrCheckbox
                      [value]="
                        item.PDFResult.File !== undefined &&
                        item.PDFResult.File !== null
                      "
                      [checked]="
                        item.PDFResult.File !== undefined &&
                        item.PDFResult.File !== null
                      "
                      disabled="true"
                      name="Bewertung erfolgt"
                      id="cb{{ item._id }}"
                    />
                    <label for="cb{{ item._id }}">Bewertung erfolgt</label>
                  </clr-checkbox-wrapper>
                </clr-checkbox-container>
              </clr-dg-cell>
              <clr-dg-cell>
                <ng-container
                  *ngIf="
                    item.PDFResult.File !== undefined &&
                    item.PDFResult.File !== null
                  "
                >
                  <cds-icon shape="pdf-file" />
                  <a [routerLink]="[]" (click)="showPdf(item)" role="button">
                    {{ item.PDFResult.File }}
                  </a>
                </ng-container>
              </clr-dg-cell>
            </clr-dg-row>
            <!-- ------------detail-(Anlage)----- -->
          </ng-container>
        </clr-datagrid>
        <!-- ------------------------------- -->
        <br />
        <div class="appitem"></div>
        <br />
      </ng-container>
      <p></p>
      <!-- </form> -->

      <!-- popup-windows -->

      <clr-modal [(clrModalOpen)]="onNewEvaluation">
        <h3 class="modal-title">
          <cds-icon shape="tag"></cds-icon>
          {{ title }}
        </h3>
        <div class="modal-body" [innerHTML]="message">
          <!-- <app-risks-evaluations
            #auswertungenTab
            [cabinet$]="cabinet$"
            (updated)="refresh($event)"
            (init)="initAuswertung($event)"
            (changed)="calulationFor($event)"
          ></app-risks-evaluations> -->
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            (click)="onNewEvaluation = false"
          >
            Ok
          </button>
        </div>
      </clr-modal>

      <clr-modal [(clrModalOpen)]="onHelper">
        <h3 class="modal-title">
          <cds-icon shape="tag"></cds-icon>
          {{ title }}
        </h3>
        <div class="modal-body" [innerHTML]="message"></div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            (click)="onHelper = false"
          >
            Ok
          </button>
        </div>
      </clr-modal>

      <clr-modal [(clrModalOpen)]="extended" clrModalSize="lg">
        <h3 class="modal-title">
          <cds-icon shape="pdf-file" size="md"></cds-icon>
          {{ title }}
        </h3>
        <div class="modal-body">
          <ngx-extended-pdf-viewer
            *ngIf="pdfShown; else elsetest"
            [base64Src]="pdfBase64Src"
            [height]="'60vh'"
            [showBorders]="true"
            [useBrowserLocale]="true"
            [showHandToolButton]="true"
            [handTool]="true"
            [filenameForDownload]="'FILENAME.pdf'"
          >
          </ngx-extended-pdf-viewer>
          <ng-template #elsetest>
            <div class="pdf-viewer-template">
              <span class="spinner spinner-inline"></span>
              <!-- <span *ngIf="document?.Filesize">
          {{ document.Filesize | filesize }}
          | {{ document.Filename }}
        </span> -->
            </div>
          </ng-template>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-primary"
            (click)="extended = false"
          >
            Ok
          </button>
        </div>
      </clr-modal>
    </div>
  </div>
</div>
