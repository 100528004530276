import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MediaMatcher } from '@angular/cdk/layout';
import { LayoutService } from './shared/layout.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  darkModeMatcher: MediaQueryList;

  custom = {
    endpoint: '',
  };

  constructor(
    private swUpdate: SwUpdate,
    private mediaMatcher: MediaMatcher,
    private layoutService: LayoutService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    console.log(`SwUpdate - isEnabled: ` + this.swUpdate.isEnabled);

    this.swUpdate.versionUpdates.subscribe((event) => {
      let current,
        latest,
        VERSION_DETECTED,
        NO_NEW_VERSION_DETECTED,
        version,
        error;

      switch (event.type) {
        case 'VERSION_READY':
          current = event.currentVersion.appData;
          latest = event.latestVersion.appData;
          console.log('VERSION_READY');
          console.log(current);
          console.log(latest);
          break;
        case 'VERSION_DETECTED':
          VERSION_DETECTED = event.version;
          console.log('VERSION_DETECTED');
          console.log(VERSION_DETECTED.appData);
          break;
        case 'NO_NEW_VERSION_DETECTED':
          NO_NEW_VERSION_DETECTED = event.version;
          console.log('NO_NEW_VERSION_DETECTED');
          console.log(NO_NEW_VERSION_DETECTED.appData);
          break;
        case 'VERSION_INSTALLATION_FAILED':
          version = event.version;
          error = event.error;
          console.log('VERSION_INSTALLATION_FAILED');
          console.log(version);
          console.log(error);
          break;
      }
    });

    // TODO: `darkModeMatcher` oder persönliche Einstellung, zusätzlcihes Bit zur Unterscheidung.

    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      console.log('ThemeMode: DARK');
      this.layoutService.setDarkThemeMode();
    } else {
      console.log('ThemeMode: LIGHT');
      this.layoutService.setLightThemeMode();
    }

    this.darkModeMatcher = this.mediaMatcher.matchMedia(
      '(prefers-color-scheme: dark)',
    );

    this.darkModeMatcher.addEventListener(
      'change',
      this.handleDarkModeChange.bind(this),
    );
  }

  ngOnInit(): void {
    // this.swPush.messages.subscribe((message) => {
    //   console.log('message');
    //   console.log(message);
    // });

    // this.swPush.notificationClicks.subscribe((event) => {
    //   console.log('event');
    //   console.log(event);
    //   console.log(event.action);
    //   console.log('notification');
    //   console.log(event.notification);
    //   console.log('data');
    //   console.log(event.notification.data);
    //
    //   this.custom = event.notification.data;
    //
    //   // this.telemetryStoreService
    //   //   .patchEndpoint({
    //   //     endpoint: this.custom.endpoint,
    //   //   })
    //   //   .subscribe((data) => {
    //   //     console.log(data);
    //   //   });
    //
    //   this.router.navigate(['/telemetries/messages'], {
    //     state: { data: this.custom },
    //   });
    // });

    this.layoutService.themeMode$.subscribe((themeMode) => {
      this.renderer.setAttribute(this.document.body, 'cds-theme', themeMode);
    });
  }

  handleDarkModeChange(event: MediaQueryListEvent) {
    console.log(event.matches);
    const newColorScheme = event.matches ? 'dark' : 'light';
    this.layoutService.setThemeMode(newColorScheme);
  }
}
