import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plural',
})
export class PluralPipe implements PipeTransform {
  transform(number: number, singularText: string, pluralText = ''): string {
    const pluralWord = pluralText ? pluralText : `${singularText}s`;
    return number > 1 ? `${number} ${pluralWord}` : `${number} ${singularText}`;
  }
}
