import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import { ProfilActions } from './profil.actions';
import { User } from 'src/app/models/User';

export const profilFeatureKey = 'profil';

export interface State {
  profil: User;
  loading: boolean;
  updated: boolean;
  error: HttpErrorResponse | null;
}

export const initialState: State = {
  profil: {} as User,
  loading: false,
  updated: false,
  error: null,
};

export const reducer = createReducer(
  initialState,

  on(ProfilActions.updateKennung, (state): State => {
    return {
      ...state,
      loading: true,
      updated: false,
      error: null,
    };
  }),

  on(ProfilActions.updateProfil, (state): State => {
    return {
      ...state,
      loading: true,
      updated: false,
      error: null,
    };
  }),

  on(ProfilActions.updateProfilSuccess, (state, action): State => {
    return {
      ...state,
      profil: action.profil,
      loading: false,
      updated: true,
      error: null,
    };
  }),

  on(ProfilActions.updateProfilFailure, (state, action): State => {
    return {
      ...state,
      loading: false,
      updated: false,
      error: action.error,
    };
  }),

  on(ProfilActions.loadProfil, (state): State => {
    return {
      ...state,
      loading: true,
      error: null,
    };
  }),

  on(ProfilActions.loadProfilSuccess, (state, action): State => {
    return {
      ...state,
      profil: action.profil,
      loading: false,
      error: null,
    };
  }),

  on(ProfilActions.loadProfilFailure, (state, action): State => {
    return {
      ...state,
      loading: false,
      error: action.error,
    };
  }),
);
