<div class="clr-row">
  <div class="clr-col-12">
    <div class="card">
      <h3 class="card-header">
        <a routerLink="/">Home</a> / Firmen
        <span style="float: right">
          <a href="javascript:history.back()">
            <cds-icon
              shape="circle-arrow"
              direction="left"
              size="md"
            ></cds-icon>
          </a>
        </span>
      </h3>
    </div>
  </div>
</div>

<div class="clr-row">
  <div class="clr-col-12">
    <div *ngIf="combinedFactories$ | async as combinedFactories">
      <app-factories-list-grid-card
        [factories]="combinedFactories.factories"
        [loading]="combinedFactories.loading"
        [error]="combinedFactories.error"
        [extern]="false"
      ></app-factories-list-grid-card>
    </div>
  </div>
</div>
