import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, props } from '@ngrx/store';
import { Evaluation } from 'src/app/models/Evaluation';

export const EvaluationActions = createActionGroup({
  source: 'Evaluation',
  events: {
    'Load Evaluation': props<{ cabinetId: string }>(),
    'Load Evaluation Success': props<{ data: Evaluation }>(),
    'Load Evaluation Failure': props<{ error: HttpErrorResponse }>(),
  },
});
