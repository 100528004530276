import { ClrCommonStrings } from '@clr/angular';

export const germanLocale: Partial<ClrCommonStrings> = {
  showColumns: 'Auswahl',
  selectAll: 'alle auswählen',
  pickColumns: 'AUSWAHL',
  minValue: 'minimum',
  maxValue: 'maximum',
  filterItems: '',
};
